import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { namespace } from "../../../environment";
import React from "react";

const StepRedirector = (kycData, steps) => {
    // const [searchParams, setSearchParams] = useSearchParams();
    // const navigate = useNavigate();
    // console.log(kycData, steps)
    // const steps = searchParams.get("stepcount");
    // console.log(steps)
    if(kycData?.fp_investment_account_id || kycData?.fp_investor_id ){
        
        // navigate({
        //         pathname: `${namespace}/dashboard/mutualfund`,
        //     });
            return true
    }
    else if (kycData?.is_kyc_compliant) {
        if (kycData?.status === 'kyc_check') {
            if (steps <= 2) {
                return false; // If steps is "2", return false
            } else {
                return true;  // Otherwise, return true
            }

        }
        else if (kycData?.status === "confirm_pan") {
            if (steps <= 3) {
                return false; 
            } else {
                return true;  
            }
        }
        else if (kycData.status === "personal_details") {
            if (steps <= 4) {
                return false; 
            } else {
                return true;  
            }
        }
        else if (kycData.status === 'occupation_details') {
            if (steps <= 5) {
                return false; 
            } else {
                return true;  
            }
        }
        else if (kycData.status === 'bank_details') {
            if (steps <= 6) {
                return false; 
            } else {
                return true;  
            }
        }
        else if (kycData.status === "address_details") {
            if (steps <= 7) {
                return false; 
            } else {
                return true;  
            }
        }
        else if (kycData.status === "signature") {
            if (steps <= 8) {
                return false; 
            } else {
                return true;  
            }
        }
        // else if (kycData.status === "video") {
        //     // navigate({
        //     //     pathname: `${namespace}/onBoarding`,
        //     //     search: `?${createSearchParams({ stepcount: "5" })}`,
        //     // });
        // }

    } else {
        if(steps < 9){
            return true;
        }
            if (kycData.status === 'kyc_check') {
                if (steps <= 9) {
                    return false; // If steps is "2", return false
                } else {
                    return true;  // Otherwise, return true
                }
                //   navigate({
            //     pathname: `${namespace}/onBoarding`,
            //     search: `?${createSearchParams({ stepcount: "9" })}`,
            //   });
            }
            else if (kycData.status === 'confirm_pan') {
                if (steps <= 10) {
                    return false; // If steps is "2", return false
                } else {
                    return true;  // Otherwise, return true
                }
            //   navigate({
            //     pathname: `${namespace}/onBoarding`,
            //     search: `?${createSearchParams({ stepcount: "10" })}`,
            //   });
            }
            else if (kycData.status === 'personal_details') {
                if (steps <= 11) {
                    return false; // If steps is "2", return false
                } else {
                    return true;  // Otherwise, return true
                }
                //   navigate({
            //     pathname: `${namespace}/onBoarding`,
            //     search: `?${createSearchParams({ stepcount: "11" })}`,
            //   });
            }
            else if (kycData.status === 'occupation_details') {
                if (steps <= 12) {
                    return false; // If steps is "2", return false
                } else {
                    return true;  // Otherwise, return true
                }
                //   navigate({
            //     pathname: `${namespace}/onBoarding`,
            //     search: `?${createSearchParams({ stepcount: "12" })}`,
            //   });
            }
            else if (kycData.status === 'bank_details') {
                if (steps <= 13) {
                    return false; // If steps is "2", return false
                } else {
                    return true;  // Otherwise, return true
                }
                //   navigate({
            //     pathname: `${namespace}/onBoarding`,
            //     search: `?${createSearchParams({ stepcount: "13" })}`,
            //   });
            }
            else if (kycData.status === "nominee") {
                if (steps <= 14) {
                    return false; // If steps is "2", return false
                } else {
                    return true;  // Otherwise, return true
                }
                //   navigate({
            //     pathname: `${namespace}/onBoarding`,
            //     search: `?${createSearchParams({ stepcount: "14" })}`,
            //   });
            }
            else if (kycData.status === "proof_of_identity" || kycData.status === "geo_tag") {
                if (steps <= 16) {
                    return false; // If steps is "2", return false
                } else {
                    return true;  // Otherwise, return true
                }
                //   navigate({
            //     pathname: `${namespace}/onBoarding`,
            //     search: `?${createSearchParams({ stepcount: "15" })}`,
            //   });
            }
            // else if (kycData.status === "proof_of_identity" || kycData.status === "geo_tag") {
            //     if (steps <= 16) {
            //         return false; // If steps is "2", return false
            //     } else {
            //         return true;  // Otherwise, return true
            //     }
            //     //   navigate({
            // //     pathname: `${namespace}/onBoarding`,
            // //     search: `?${createSearchParams({ stepcount: "16" })}`,
            // //   });
            // }
            else if (kycData.status === "address_details" ) {
                if (steps <= 17) {
                    return false; // If steps is "2", return false
                } else {
                    return true;  // Otherwise, return true
                }
                
                //   navigate({
            //     pathname: `${namespace}/onBoarding`,
            //     search: `?${createSearchParams({ stepcount: "17" })}`,
            //   });
            }
            else if (kycData.status === "signature") {
                if (steps <= 18) {
                    return false; // If steps is "2", return false
                } else {
                    return true;  // Otherwise, return true
                }
                // navigate({
                //   pathname: `${namespace}/onBoarding`,
                //   search: `?${createSearchParams({ stepcount: "18" })}`,
                // });
              }
              else if (kycData.status === "signature") {
                if (steps <= 18) {
                    return false; // If steps is "2", return false
                } else {
                    return true;  // Otherwise, return true
                }
                // navigate({
                //   pathname: `${namespace}/onBoarding`,
                //   search: `?${createSearchParams({ stepcount: "18" })}`,
                // });
              } else if  (kycData.status === "initiate_esign" && kycData?.fp_esign_id)  return true;  

    }
    return

}

export default StepRedirector;



// // import { useNavigate, useSearchParams } from "react-router-dom";
// import React, { useEffect } from "react";
// import { namespace } from "../../../environment";

// const StepRedirector = ({ kycData }) => {
//     // const navigate = useNavigate();
//     // const [searchParams] = useSearchParams();
//     // const steps = searchParams.get("stepcount");

//     useEffect(() => {
//         if (kycData) {
//             // Add your redirection logic here based on the compliance data
//             if (kycData?.is_kyc_compliant) {
//                 if (kycData?.status === 'kyc_check') {
//                     // if (steps !== 2) {
//                         // navigate({
//                         //     pathname: `${namespace}/dashboard/mutualfund`,
//                         //     // search: `?${createSearchParams({ stepcount: "2" })}`,
//                         // });
//                     // }
//                     // navigate({
//                     //     pathname: `${namespace}/onBoarding`,
//                     //     search: `?${createSearchParams({ stepcount: "2" })}`,
//                     // });

//                 }
//                 else if (kycData?.status === "confirm_pan") {
//                     // navigate({
//                     //     pathname: `${namespace}/onBoarding`,
//                     //     search: `?${createSearchParams({ stepcount: "3" })}`,
//                     // });
//                 }
//                 // else if (Kyc_status.data.data.status === "confirm_pan") {
//                 //   navigate({
//                 //     pathname: `${namespace}/onBoarding`,
//                 //     search: `?${createSearchParams({ stepcount: "6" })}`,
//                 //   });
//                 // }
//                 else if (kycData.status === "personal_details") {
//                     // navigate({
//                     //     pathname: `${namespace}/onBoarding`,
//                     //     search: `?${createSearchParams({ stepcount: "7" })}`,
//                     // });
//                 }
//                 else if (kycData.status === "address_details") {
//                     // navigate({
//                     //     pathname: `${namespace}/onBoarding`,
//                     //     search: `?${createSearchParams({ stepcount: "8" })}`,
//                     // });
//                 }
//                 else if (kycData.status === "signature") {
//                     // navigate({
//                     //     pathname: `${namespace}/onBoarding`,
//                     //     search: `?${createSearchParams({ stepcount: "9" })}`,
//                     // });
//                 }
//                 else if (kycData.status === "video") {
//                     // navigate({
//                     //     pathname: `${namespace}/onBoarding`,
//                     //     search: `?${createSearchParams({ stepcount: "5" })}`,
//                     // });
//                 }

//             } else {
//                 if (kycData.status === 'kyc_check') {
//                     // navigate({
//                     //     pathname: `${namespace}/onBoarding`,
//                     //     search: `?${createSearchParams({ stepcount: "9" })}`,
//                     // });
//                 }
//                 if (kycData.status === 'confirm_pan') {
//                     // navigate({
//                     //     pathname: `${namespace}/onBoarding`,
//                     //     search: `?${createSearchParams({ stepcount: "10" })}`,
//                     // });
//                 }
//                 if (kycData.status === 'personal_details') {
//                     // navigate({
//                     //     pathname: `${namespace}/onBoarding`,
//                     //     search: `?${createSearchParams({ stepcount: "11" })}`,
//                     // });
//                 }
//                 if (kycData.status === "video") {
//                     // navigate({
//                     //     pathname: `${namespace}/onBoarding`,
//                     //     search: `?${createSearchParams({ stepcount: "25" })}`,
//                     // });
//                 }
//                 else if (kycData.status === "bank_details") {
//                     // navigate({
//                     //     pathname: `${namespace}/onBoarding`,
//                     //     search: `?${createSearchParams({ stepcount: "21" })}`,
//                     // });
//                 }
//                 else if (kycData.status === "proof_of_identity" || kycData.status === "geo_tag") {
//                     //     navigate({
//                     //         pathname: `${namespace}/onBoarding`,
//                     //         search: `?${createSearchParams({ stepcount: "23" })}`,
//                     //     });
//                 }
//             }
//         }
//     }, [kycData]);

//     return null; // No need to render anything in this component
// };

// export default StepRedirector;



// import { useNavigate, useSearchParams } from "react-router-dom";
// import React, { useEffect } from "react";
// import { namespace } from "../../../environment";

// const StepRedirector = ( complientData ) => {
//     // const navigate = useNavigate();
//     // const [searchParams] = useSearchParams();
//     console.log('adf')

//     // if (complientData) {
//     //     console.log('adf')
//     //     // navigate({
//     //     //     pathname: `${namespace}/dashboard/mutualfund`,
//     //     //     // search: `?${createSearchParams({ stepcount: "2" })}`,
//     //     // });
//     //     return null; // No need to render anything in this component
//     // }
 
//         if (complientData) {
//             console.log('adsfsa')
//             // Add your redirection logic here based on the compliance data
//             // if (complientData.is_kyc_compliant) {
//             //     navigate("/kyc-compliant-route"); // example route
//             // } else {
//             //     navigate("/kyc-not-compliant-route");
//             // }
//             return null
//         } else return null

    
// };

// export default StepRedirector;
