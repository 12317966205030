import { Box, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import TextInputField from "../Input/TextInputField";
import TitleLayoult from "../TitleLayoult";
import { useStyles } from "../styles";
import DatePickerField from "../Input/DatePickerField";
import { useForm, Controller } from "react-hook-form";
import ErrorMessage from "../../Validation/ErrorMessage";
import { CONFIRM_PAN, GET_KYC_DATA } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import { useNavigate, createSearchParams, useSearchParams } from "react-router-dom";
import moment from "moment";
import { namespace } from "../../../environment";
import CustomToast from "../../UI/CustomToast";
import StepRedirector from "../Common/StepRedirector";
import StatusApiData from "../Common/StatusApiData";

function Step4({ title, subtitle, descriptionData, setData }) {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();
  const [searchParams, setSearchParams] = useSearchParams();

  const steps = searchParams.get("stepcount");

  const navigate = useNavigate();

  const today = new Date();

  const [loading, setLoading] = useState(false);
  const [dob, setDob] = useState(null);
  const [dobError, setDobError] = useState(false);
  const [error, setError] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [isCompliant, setIsComplient] = useState("");
  const [panDeta, setPanDeta] = useState({});

  const handleDob = (date) => {
    setDob(date);
  };

  const resetPanDeatisl = () => {
    const panDetails = JSON.parse(localStorage.getItem("panDetails"));
    const complient = localStorage.getItem("complient");

    setIsComplient(complient);
  };

  useEffect(async () => {
    if (descriptionData.panNumber) {
      const complient = localStorage.getItem("complient");
      setIsComplient(complient);

      reset({
        panNumber: descriptionData.panNumber?.toUpperCase(),
        fullName: complient == "Yes" ? descriptionData.fullName : "",
      });
    } else {
      const httpConfig = {
        method: "GET",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
      };
      try {
        const response = await httpInstance(`${GET_KYC_DATA}`, httpConfig);
        const pan = response.data?.data;
        setPanDeta(pan);

        reset({
          // panNumber: pan.panNum?.toUpperCase(),
          // fullName: pan.panFullName,
          panNumber: pan.pan?.toUpperCase(),
          fullName: pan.full_name,
        });
        setDob(moment(pan.date_of_birth));

        setIsComplient(pan.is_kyc_compliant);
        const complient = await StatusApiData()
        // setIsComplient(complient.data?.data?.isKycCompliant);
        // setIsComplient(complient.data.data?.is_kyc_compliant)
        // console.log(complient?.data)
        const dataFinal = await StepRedirector(complient.data.data, steps) // secutiry redirection
        // console.log(dataFinal)
        if (dataFinal) {
          navigate({
            pathname: `${namespace}/dashboard/mutualfund`,
            // search: `?${createSearchParams({ stepcount: "2" })}`,
          });
        }

      } catch { }
    }
  }, []);

  const submitPanDetails = async (data) => {
    if (!dob) {
      setDobError(true);
    } else {
      setDobError(false);
      setLoading(true)
      setError(false)
      setApiError(null)
      const formData = {
        // panNumber: data.panNumber,
        // fullName: data.fullName,
        // dateOfBirth: moment(dob).format("YYYY-MM-DD"),
        // isCompliant: isCompliant === "Yes" ? true : false,
        user_id: localStorage.getItem("user_id"),
        pan: data.panNumber,
        full_name: data.fullName,
        date_of_birth: moment(dob).format("YYYY-MM-DD")
      };

      localStorage.setItem(
        "panDetails",
        JSON.stringify({
          panNumber: data.panNumber,
          fullName: data.fullName,
        })
      );

      const httpConfig = {
        method: "POST",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      };

      try {
        await httpInstance(`${CONFIRM_PAN}`, httpConfig);
        setData({ panNumber: null });
        // console.log(isCompliant)
        navigate({
          pathname: `${namespace}/onBoarding`,
          // search: `?${createSearchParams(isCompliant==="Yes" ? {stepcount:"6"}: {stepcount:"19"} )}`,
          // search: `?${createSearchParams({ stepcount: "19" })}`,
          search: `?${createSearchParams(isCompliant === true ? { stepcount: "3" } : { stepcount: "10" })}`,
        });
        setLoading(false)
        CustomToast(panDeta?.date_of_birth ? "Updated Pan Details" : "Added Pan Detials", "SUCCESS")
      } catch (error) {
        // setError(error.response.data.error || 'Somthing went wrong')
        // console.log(error.response.data.error || 'Somthing went wrong' )
        // navigate({
        //   pathname: `${namespace}/signIn`,
        //   // search: `?${createSearchParams(isCompliant==="Yes" ? {stepcount:"6"}: {stepcount:"19"} )}`,
        //   search: `?${createSearchParams({ stepcount: "19" })}`,
        // });
        CustomToast(error.response.data.error, "FAILED")
        setApiError(error?.response?.data?.error)
        setError(true)
        setLoading(false)

      }
    }
  };

  const handleback = () => {
    navigate({
      pathname: `${namespace}/onBoarding`,
      search: `?${createSearchParams({ stepcount: "1" })}`,
    });
  };

  return (
    <React.Fragment>

      <Box display={"flex"} justifyContent="center">
        <Box
          display="flex"
          justifyContent="flex-start"
          // px={20}
          className={classes.backButton}
          // onClick={handleback}
        >
          {/* <img src="/images/backIcon.svg" height='20px' width='20px' alt="back"/> */}
        </Box>
        <Box display={"flex"} alignItems="flex-start" flexDirection={"column"}>
          <Box>
            <TitleLayoult
              title={title ? title : "Confirm Pan Details"}
              description="Please Enter your Pan Card Number for Verification"
            />
          </Box>
          <form onSubmit={handleSubmit(submitPanDetails)} style={{ maxWidth: '296.7px' }}>
            <Box my={1}>
              <TextInputField
                name="panNumber"
                label="Enter Pan Card Number"
                placeholder=""
                register={register}
                disable={true}
              />
              {errors.panNumber && (
                <ErrorMessage message="Enter Pan Card Number" />
              )}
            </Box>

            <Box my={1}>
              <TextInputField
                name="fullName"
                label="Full Name"
                placeholder="Full Name"
                disable={descriptionData.fullName ? true : false}
                register={register}
                inputPattern={/^(?=(?:^\w))([a-zA-Z](\s?)([a-zA-Z]\s?)+)*\s*$/}
              />
              {errors.fullName && errors.fullName.type == "required" && (
                <ErrorMessage message="Enter Full Name" />
              )}
              {errors.fullName && errors.fullName.type == "pattern" && (
                <ErrorMessage message="Enter Valid Name" />
              )}
            </Box>

            <Box my={1}>
              <DatePickerField
                name="DOB"
                label="Date of Birth"
                placeholder="Date of Birth"
                handleChange={handleDob}
                dateValue={dob}
                maximumDate={today.setFullYear(today.getFullYear() - 18)}
                defaultDate={today.setFullYear(today.getFullYear())}
              />
              {dobError && <ErrorMessage message="Select Date of Birth" />}
            </Box>
            {/* {error && <ErrorMessage message={error} />} */}
            {error && <ErrorMessage message={apiError || "Something wrong happened"} />}
            <Box mt={4}>
              <button className={classes.primaryButton} type="submit" disabled={loading}>
                {loading ? (
                  <CircularProgress size="1rem" color="inherit" />
                ) : (
                  "Next"
                )}
                {/* Confirm */}
              </button>
            </Box>
          </form>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default Step4;
