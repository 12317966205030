import React, { useEffect, useState } from "react";
import {
    Box,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    makeStyles,
    CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import {
    INITAITE_ASSISTED_PAYMENT,
    GET_CONSENT_LUMPSUM_OTP,
    INVEST_ASSISTED_FUND,
} from "../../../utils/endpoints";
import NomineeDataEdit from "./NomineeDataEdit";
import httpInstance from "../../../utils/instance";
import { KYC_STATUS } from "../../../utils/endpoints";

const DialogEditNominee = ({ show, handleClose, fundData, totalAmount, nomineeData }) => {
    const useStyles = makeStyles({
        root: {
            "& .MuiDialogContent-root": {
                fontSize: "0.8rem",
                lineHeight: "25px",
                //padding:"20px 25px"
            },
            "& .MuiDialog-paperWidthSm": {
                minWidth: "350px",
                width: '100%',
                maxWidth: "400px",
                maxHeight: "100%",
                borderRadius: '12px',
            },
            "& .MuiDialogTitle-root ": {
                padding: "5px",
            },
        },
        dialogTitle: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "-0px",
            "& .MuiTypography-body1": {
                fontSize: "1rem",
                fontWeight: 600,
            },
        },
        closeIcon: {
            padding: "2px",
            justifyContent: "flex-end",
        },
        inputBox: {
            background: "#E6E6E6",
            padding: "0.7rem 0.95rem",
            borderRadius: "10px",
            border: "none",
            width: "-webkit-fill-available",
            fontFamily: "inherit",
            marginBottom: "15px",
        },
        label: {
            fontSize: "0.8rem",
            marginBottom: "5px",
            fontWeight: 600,
        },
        primaryButton: {
            color: "white",
            backgroundColor: "#0E76BC",
            border: "none",
            borderRadius: "39px",
            fontSize: "15px",
            fontWeight: "500",
            padding: "10px 5rem",
            fontFamily: "inherit",
            cursor: "pointer",
            margin: "0rem 1rem",
        },
    });

    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const [isComplient, setIsComplient] = useState(false);
    const [kycData, setKycData] = useState('')

    const get_kyc_status = async () => {
        const httpConfig = {
            method: "GET",
            tokenType: "user",
            headers: {
                "Content-Type": "application/json",
            },
        };

        try {
            const response = await httpInstance(`${KYC_STATUS}`, httpConfig);
            setIsComplient(response.data?.data?.is_kyc_compliant);
            setKycData(response.data?.data)
        } catch {

        }
    };

    useEffect(() => {
        get_kyc_status();
    }, []);


    return (
        <div>
            <Dialog className={classes.root} open={show} onClose={handleClose}>
                <Box display="flex" className={classes.closeIcon}>
                    {" "}
                </Box>
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    height="20px"
                    style={{ cursor: "pointer" }}
                    onClick={handleClose}
                    className={classes.closeIcon}
                >
                    <CloseIcon />
                </Box>
                <DialogTitle className={classes.dialogTitle}>

                    <Typography className={classes.title}>Nominee Detials</Typography>
                </DialogTitle>

                <DialogContent>
                    <NomineeDataEdit kycData={kycData} nomineeDataAll={nomineeData} handleClose={handleClose} />
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default DialogEditNominee;
