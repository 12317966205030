import React, { useState, useEffect } from "react";
import {
  Box,
  MenuItem,
  Select,
  Typography,
  FormControl,
  createTheme,
  ThemeProvider,
} from "@material-ui/core";
import TextInputField from "../Input/TextInputField";
import { useStyles } from "../styles";
import DatePickerField from "../Input/DatePickerField";
import { useForm, Controller } from "react-hook-form";
import ErrorMessage from "../../Validation/ErrorMessage";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import moment from "moment";


function Nominees({count,
      register,
      errors,
      handleDobChange,
      dobValue,
      age,
      handleDelete, dobError,relation,control,reset,nomineeData,allNoominees,guardianName, Edit}) {

      
   const [percent,setPercent]=useState('') 
   const [fullName,setFullName]=useState('')  

   const handleChange=(e)=>{
    const re = /^[0-9\b]+$/
    if (e.target.value === '' || re.test(e.target.value)) {
      setPercent(e.target.value)
    }
   }  

   const handleNameChange=(e)=>{
    setFullName(e.target.value)
    }
    


        const classes = useStyles();

        const themedate = createTheme({
          overrides: {
            MuiInputBase: {
              input: {
               padding: "0px",

              },
            },
          
          },
        });
      

        const theme = createTheme({
            overrides: {
              MuiInputBase: {
                input: {
                 padding: "10px 10px",
 
                },
              },
              MuiSelect: {
                select: {
                  "&:focus": {
                    backgroundColor: "transparent",
                  },
                 
                },
              },
            },
          });

         

          useEffect(()=>{
            if(nomineeData){
              // console.log(allNoominees, allNoominees[1]?.relationship)
                reset({
                  
                  //[`fullName${count}`]:nomineeData?.nomineeFullName,
                  // [`percentage${count}`]:nomineeData?.allocationPercentage.toFixed(0), 
                //   guardianName1:allNoominees[0]?.guardianName,
                //   guardianName2:allNoominees[1]?.guardianName,
                //   guardianName3:allNoominees[2]?.guardianName,
                //   relationShips1:allNoominees[0]?.nomineeRelationType?.id,
                //   relationShips2:allNoominees[1]?.nomineeRelationType?.id,
                //   relationShips3:allNoominees[2]?.nomineeRelationType?.id,                 
                //  // [`gaurdRelationShip${count}`]:nomineeData?.guardianRelationship?.id,
                //   gaurdRelationShip1:allNoominees[0]?.guardianRelationship?.id,
                //   gaurdRelationShip2:allNoominees[1]?.guardianRelationship?.id,
                //   gaurdRelationShip3:allNoominees[2]?.guardianRelationship?.id,
                guardianName1:allNoominees[0]?.guardian_name,
                guardianName2:allNoominees[1]?.guardian_name,
                guardianName3:allNoominees[2]?.guardian_name,
                relationShips1:allNoominees[0]?.relationship?.charAt(0).toUpperCase() + allNoominees[0]?.relationship?.slice(1),
                relationShips2:allNoominees[1]?.relationship?.charAt(0).toUpperCase() + allNoominees[1]?.relationship?.slice(1),
                relationShips3:allNoominees[2]?.relationship?.charAt(0).toUpperCase() + allNoominees[2]?.relationship?.slice(1),                 
               // [`gaurdRelationShip${count}`]:nomineeData?.guardianRelationship?.id,
                gaurdRelationShip1:allNoominees[0]?.guardian_relationship?.charAt(0).toUpperCase() + allNoominees[0]?.guardian_relationship?.slice(1),
                gaurdRelationShip2:allNoominees[1]?.guardian_relationship?.charAt(0).toUpperCase() + allNoominees[1]?.guardian_relationship?.slice(1),
                gaurdRelationShip3:allNoominees[2]?.guardian_relationship?.charAt(0).toUpperCase() + allNoominees[2]?.guardian_relationship?.slice(1),
  
                })
              }
        
              // setPercent(nomineeData?.allocationPercentage)
              // setFullName(nomineeData?.nomineeFullName)
              setPercent(nomineeData?.allocation_percentage)
              setFullName(nomineeData?.name)
            
          },[nomineeData])

          

        return (
    <div>
    <Box
      // display={"flex"}
      alignItems="flex-start"
      flexDirection={"column"}
      justifyContent="flex-start"
    >
      <Box display="flex" justifyContent="space-between" my={2}>
        <Typography className={classes.nomineeHeader}>
          {`Nominee ${count}`}
        </Typography>
        {count != "1" && (
          <DeleteIcon
            sx={{ fontSize: "20px" }}
            onClick={() => handleDelete(count)}
          />
        )}
      </Box>
      {/* <Box>
        <TextInputField
          name={`fullName${count}`}
          label="Full Name"
          placeholder=""
          register={register}
          inputPattern={/^(?=(?:^\w))([A-Za-z ]+)$/}
         
        />
        {errors[`fullName${count}`] && (
          <ErrorMessage message="Enter fullName" />
        )} */}
        {/* {count === "2" && errors.fullName2 && (
          <ErrorMessage message="Enter fullName" />
        )}
        {count === "3" && errors.fullName3 && (
          <ErrorMessage message="Enter fullName" />
        )} */}
      {/* </Box> */}

      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="flex-start"
        flexDirection="column"
        width={"100%"}
      >
        <label className={classes.label}>Full Name</label>
        <input
          type="text"
          className={classes.inputBox}
          value={fullName}
          {...register(`fullName${count}`,{
           
            required:true,
            //pattern: /^[A-Za-z]+([\ A-Za-z]+)*/,
            pattern:/^(?=(?:^\w))([a-zA-Z](\s?)([a-zA-Z]\s?)+)*\s*$/,
            onChange: (e)=> handleNameChange(e)
          })} 
        />
         { errors[`fullName${count}`] && (
          errors[`fullName${count}`].type === "pattern" && <ErrorMessage message="Enter valid Name" />)}
        { errors[`fullName${count}`] && ( errors[`fullName${count}`].type === "required" && <ErrorMessage message="Enter Full Name" />
      )}
      {/* {count === "2" && errors.percentage2 && (
        <ErrorMessage message="Enter Percentage" />
      )}
      {count === "3" && errors.percentage3 && (
        <ErrorMessage message="Enter Percentage" />
      )} */}
      </Box>

      {/* <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="flex-start"
        flexDirection="column"
        width={"100%"}
      >
        <label className={classes.label}>Percentage</label>
        <input
          type="text"
          className={classes.inputBox}
          value={percent}
          {...register(`fullName${count}`,{
           
            required:true,
            pattern:/^(?=(?:^\w))([A-Za-z ]+)$/,
            
          })} 
        />
         { errors[`fullName${count}`] && (
        <ErrorMessage message="Enter Percentage" />
      )} */}
      {/* {count === "2" && errors.percentage2 && (
        <ErrorMessage message="Enter Percentage" />
      )}
      {count === "3" && errors.percentage3 && (
        <ErrorMessage message="Enter Percentage" />
      )} */}
      {/* </Box> */}
      
      <Box my={1} 
      sx={{'& .MuiInputBase-input': {
                    padding: "8px 0px"
                  },
                  '& .MuiButtonBase-root':{
                    padding:"0px"
                  }
                  }}>
        <DatePickerField
          name="dob"
          label="Date of Birth"
          placeholder=""
          handleChange={handleDobChange}
          dateValue={dobValue}
          maximumDate={moment().subtract(1, 'day').toDate()}
        />
      </Box>
     

      {dobError &&  <ErrorMessage message="Select DOB" />}
      {/* {count === "1" && dobError.dob1Error && (
        <ErrorMessage message="Select DOB" />
      )}
      {count === "2" && dobError.dob2Error && (
        <ErrorMessage message="Select DOB" />
      )}
      {count === "3" && dobError.dob3Error && (
        <ErrorMessage message="Select DOB" />
      )} */}
    </Box>

    

    <ThemeProvider theme={theme}>
     
      <Box
        my={1}
        display="flex"
        alignItems={"flex-start"}
        justifyContent="flex-start"
        flexDirection="column"
        width={"100%"}
      >
        <label className={classes.label}>Relationship</label>
        <Box width={"100%"} className={`${classes.Select} ${classes.Select2}`}>
        <FormControl fullWidth className={classes.Select}>
            <Controller
              control={control}
              name={`relationShips${count}`}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  disableUnderline
                  className={classes.selectItem}
                  IconComponent={KeyboardArrowDownIcon}
                  MenuProps={{
                    style: {
                      maxHeight: 250,
                      top: 5,
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                 
                >
                  {relation &&
                    relation?.map((ele) => {
                      return (
                        <MenuItem
                          key={ele.id}
                          className={classes.menuItem}
                          value={ele.type}
                        >
                          {ele.type}
                        </MenuItem>
                      );
                    })}
                </Select>
              )}
            />
          </FormControl>
        </Box>
         {errors[`relationShips${count}`] && (
          <ErrorMessage message="Select Relationship" />
        )}
        
      </Box>
      
    </ThemeProvider>
    <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="flex-start"
        flexDirection="column"
        width={"100%"}
      >
        <label className={classes.label}>Percentage</label>
        <input
          type="text"
          className={classes.inputBox}
          value={percent}
          {...register(`percentage${count}`,{
           
            required:true,
            pattern:/^[0-9]+$/,
            onChange: (e)=> handleChange(e)
          })} 
        />
         { errors[`percentage${count}`] && (
        <ErrorMessage message="Enter Percentage" />
      )}
      {/* {count === "2" && errors.percentage2 && (
        <ErrorMessage message="Enter Percentage" />
      )}
      {count === "3" && errors.percentage3 && (
        <ErrorMessage message="Enter Percentage" />
      )} */}
      </Box>
    <Box my={1}>
      {/* <TextInputField
        name={`percentage${count}`}
        label="Percentage"
        placeholder=""
        register={register}
        inputPattern={/^[0-9]+$/}
      /> */}
     
    </Box>

    {age < 18 ? (
      <React.Fragment>
        <Box my={1}>
          <TextInputField
            name={`guardianName${count}`}
            label="Guardian Name"
            placeholder=""
            register={register}
            inputPattern={/^(?=(?:^\w))([A-Za-z ]+)$/}
          />
          { errors[`guardianName${count}`] && (
            <ErrorMessage message="Enter Guardian Name" />
          )}
          {/* {count === "2" && errors.guardianName2 && (
            <ErrorMessage message="Enter Guardian Name" />
          )}
          {count === "3" && errors.guardianName3 && (
            <ErrorMessage message="Enter Guardian Name" />
          )} */}
        </Box>

        <ThemeProvider theme={theme}>

        <Box
          my={1}
          display="flex"
          alignItems={"flex-start"}
          justifyContent="flex-start"
          flexDirection="column"
          width={"100%"}
        >
          <label className={classes.label}>Relationship</label>
          <Box width={"100%"} className={`${classes.Select} ${classes.Select2}`}>
          <FormControl fullWidth className={classes.Select}>
              <Controller
                control={control}
                name={`gaurdRelationShip${count}`}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    disableUnderline
                    className={classes.selectItem}
                    IconComponent={KeyboardArrowDownIcon}
                    MenuProps={{
                      style: {
                        maxHeight: 250,
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {relation &&
                      relation?.map((ele) => {
                        return (
                          <MenuItem
                            key={ele.id}
                            className={classes.menuItem}
                            value={ele.type}
                          >
                            {ele.type}
                          </MenuItem>
                        );
                      })}
                  </Select>
                )}
              />
             
            </FormControl>
          </Box>
          {errors[`gaurdRelationShip${count}`] && (
          <ErrorMessage message="Select Relationship" />
        )}
          
        </Box>
        </ThemeProvider>
      </React.Fragment>
    ) : (
      ""
    )}
  </div>
  )
}

export default Nominees