import React, { useState, useEffect } from 'react'
import { Box, Typography, makeStyles, Grid, FormControlLabel, Menu, MenuItem } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { GET_BANK_DETAILS, UPDATE_PRIMARY_BANK, UPDATE_INVESTOR_ACCOUNT, GET_MANDATE, GET_ALL_BANK, GET_KYC_DATA, GET_USER_ONBOARDING_DETAILS, SETTINGS_SUBMIT } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { fontSize } from '@mui/system'
import ReactLoading from 'react-loading';
import { BASE_URL } from '../../../environment'
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';


const Index = () => {
    const useStyles = makeStyles((theme) => ({
        root: {

        },
        header: {
            fontWeight: 600
        },
        imageBack: {
            padding: "4px 5px 0px",
            background: "#ADDFFF",
            borderRadius: "50%"
        },
        title1: {
            fontSize: "0.8rem",
            padding: "0px 10px",
            fontWeight: 600
        },
        text: {
            fontSize: "0.75rem",
            fontWeight: 500
        },
        textblue: {
            fontSize: "0.75rem",
            fontWeight: 500,
            color: "#0E76BC",
            display: "flex",
            alignItems: "center",
            marginLeft: "10px"
        },
        text2: {
            fontSize: "0.8rem",
            padding: "5px 0px"
        },
        text3: {
            fontSize: "0.8rem",
            padding: "5px 0px",
            fontWeight: 600
        },
        borderBox: {
            border: "1px solid #C0C0C0",
            borderRadius: "5px",

        },



        blueBorderBox: {
            border: "3px solid #0E76BC",
            borderRadius: "5px"
        },

        gridPadding: {
            padding: "0px 7rem 0px 0px "
        },
        primaryButton: {
            padding: "0.8rem 2rem",
            background: "#0E76BC",
            color: "#fff",
            fontSize: "0.8rem",
            fontWeight: 600,
            borderRadius: "45px",
            border: "none",
            cursor: "pointer",
            marginTop: "3rem",
            fontFamily: "inherit"
        },
        checkBox: {
            "& .MuiCheckbox-colorPrimary.Mui-checked": {
                color: "#0E76BC"
            },
            "& .MuiTypography-body1": {
                fontSize: "0.8rem"
            }
        },
        menu: {
            "& .MuiIconButton-root": {
                padding: "2px"
            },
        },

        grid: {
            background: '#f5f8fa',
            borderRadius: "5px",
            borderBottom: "1px solid #C0C0C0",
            // "&:last-child": {
            //   borderBottom: "none",
            // },
        },

        gridCursor: {
            borderBottom: "1px solid #C0C0C0",
            // "&:last-child": {
            //   borderBottom: "none",
            // },
        },

    }))
    const classes = useStyles()

    const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#0E76BC',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));


    const { userStore } = useSelector((state) => state)
    const [dailyPortfolioUpdate, setDailyPortfolioUpdate] = useState(false)
    const [whatsAppnotification, setWhatsAppnotification] = useState(false)
    const [biometricnotification, setBiometricnotification] = useState(true)

    //----------------------------------POST all SETTINGS------------------------------------------------------//
    const post_settings = async (daily, whatsapp) => {
        const httpConfig = {
            method: "POST",
            tokenType: "user",
            headers: {
                "Content-Type": "application/json",
            },
            data:
            {
                is_daily_portfolio_updates: daily,
                is_whatsapp_notifications: whatsapp,
                is_enable_biometrics: biometricnotification
            },
        };
        try {
            const response = await httpInstance(`${SETTINGS_SUBMIT}`, httpConfig)

        } catch {

        }
    }
    //---------------------------------------------------------------------------------//

     //----------------------------------POST all SETTINGS------------------------------------------------------//
    //  const get_settings = async () => {
    //     const httpConfig = {
    //         method: "GET",
    //         tokenType: "user",
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //         // data:
    //         // {
    //         //     is_daily_portfolio_updates: daily,
    //         //     is_whatsapp_notifications: whatsapp,
    //         //     is_enable_biometrics: biometricnotification
    //         // },
    //     };
    //     try {
    //         const response = await httpInstance(`${SETTINGS_SUBMIT}`, httpConfig)

    //     } catch {

    //     }
    // }

    // useEffect(() => {
    //     get_settings()
    // }, [])


    const handle_whatsApp = (e) => {
        setWhatsAppnotification(e.target.checked);
        post_settings(dailyPortfolioUpdate, e.target.checked)

    }
    const handle_portfolio = (e) => {
        setDailyPortfolioUpdate(e.target.checked);
        post_settings(e.target.checked, whatsAppnotification)
    };
    

    return (
        <div>
            <Box px={{ xs: 2, md: 20 }} mt={5} minHeight={{xs:'auto', md: '40vh'}}>
                <Typography className={classes.header}>Settings</Typography>
                <Box display={'flex'} flexDirection={'column'} mt={2}>
                    <FormControlLabel
                        label="WhatsApp Notification"
                        control={<IOSSwitch sx={{ m: 1 }} defaultChecked onChange={handle_whatsApp} checked={whatsAppnotification} />}
                    />
                    <FormControlLabel
                        control={<IOSSwitch sx={{ m: 1 }} defaultChecked onChange={handle_portfolio} checked={dailyPortfolioUpdate} />}
                        label="Daily Portfolio Update"
                    />
                </Box>
            </Box>
        </div>
    )
}

export default Index