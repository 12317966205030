import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import {
  CREATE_LUMSUM,
  INITIATE_PAYMENT,
  KYC_DATA,
  GET_LUMSUM_DETAIL,

} from "../../../utils/endpoints";
import { useNavigate, useParams } from "react-router-dom";
import httpInstance from "../../../utils/instance";
import ErrorMessage from "../../../components/Validation/ErrorMessage";
import { useSelector } from "react-redux";
import {
  GET_CONSENT_LUMPSUM_OTP,
  VERIFY_CONSENT_LUMPSUM_OTP,
  POST_TRANSACTION_BASKETS,
  POST_TRANSACTION_BASKETS_GENERATE_CONSENT,
  VERIFY_AND_VALIDATE_BASKET_CONSENT_LUMPSUMP_OTP,
} from "../../../utils/endpoints";
import { namespace } from "../../../environment";
import OTPInput from "react-otp-input";
import CustomToast from "../../../components/UI/CustomToast";

const Lumsum = ({ fundData, fpData, kycStatus, show_banner, kycData }) => {
  // console.log(fundData, fpData, kycStatus, show_banner, kycData, 'kycData')
  const useStyles = makeStyles((theme) => ({
    rupeesSymbol: {
      position: 'relative',
      right: '70px',
      top: '32px',
      marginRight: '5px',
      fontSize: '13px',
      fontFamily: "Open Sans",

    },
    inputBox: {
      // background: "#E6E6E6",
      background: "#fff",
      padding: "0.7rem 0.95rem",
      // borderRadius: "10px",
      border: "none",
      borderBottom: "1px solid #000",
      width: "90.5%",
      fontFamily: "inherit",
      paddingLeft: '20px',
      outline: "none",
      caretColor: "#0E76BC",
    },
    label: {
      fontSize: "0.7rem",
      fontWeight: 500,
    },
    primaryButton: {
      padding: "0.8rem 3rem",
      background: "#0E76BC",
      color: "#fff",
      fontSize: "0.8rem",
      fontWeight: 600,
      borderRadius: "45px",
      cursor: "pointer",
      border: "1px solid #0E76BC",
      '&:hover': {
        backgroundColor: "#fff",
        color: "#0E76BC",
      },
      '&:disabled': {
        opacity: '0.7',
        cursor: 'not-allowed',
      },
    },
  }));

  const classes = useStyles();

  const [error, setError] = useState("");
  const [amount, setAmount] = useState((fundData?.stateValue?.transaction_basket_items[0]?.amount && fundData?.stateValue?.transaction_basket_items[0]?.transaction_type === "lumpsum") ? fundData?.stateValue?.transaction_basket_items[0]?.amount : "");
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");

  const { userStore } = useSelector((state) => state);
  const navigate = useNavigate();

  const { code } = useParams();
  const [otp, SetOTP] = useState("");
  const [consentId, SetConsentId] = useState((fundData?.stateValue?.transaction_basket_items[0]?.id && fundData?.stateValue?.transaction_basket_items[0]?.transaction_type === "lumpsum") ? fundData?.stateValue?.transaction_basket_items[0]?.id : null);
  const [timer, setTimer] = useState(30);
  const [isTimerActive, setIsTimerActive] = useState(true);
  const [otpHasBeenSent, setotpHasBeenSent] = useState(true);
  const [handleOTP, setHandleOTP] = useState((fundData?.stateValue?.transaction_basket_items[0]?.id && fundData?.stateValue?.transaction_basket_items[0]?.transaction_type === "lumpsum") ? 1 : 0);

  useEffect(() => {
    setAmount("");
  }, [code]);

  useEffect(() => {
    if (fundData?.stateValue?.transaction_basket_items[0]?.id && fundData?.stateValue?.transaction_basket_items[0]?.transaction_type === "lumpsum") {
      setAmount(fundData?.stateValue?.transaction_basket_items[0]?.amount)
      handle_lumsum();
    }
  }, [fundData?.stateValue?.transaction_basket_items[0]?.id, kycData, kycStatus]);

  const handle_amount = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "").toLowerCase();

    setAmount(e.target.value);
  };

  const handle_otp_value = (code) => {
    // e.target.value = e.target.value.replace(/[^0-9]/g, "").toLowerCase();
    // SetOTP(e.target.value);
    SetOTP(code);
  };



  const handle_otp = async () => {
    // if (sessionStorage.getItem("mpin")) {

    if ((kycData?.status == 'nominee' && kycData?.is_kyc_compliant == true && kycStatus == 'pending') || (!kycData?.is_kyc_compliant && kycStatus == "successful")) {
      if (
        amount <
        (fundData?.folio_number
          ? fpData?.min_additional_investment
          : fpData?.min_initial_investment)
      ) {
        setError(
          `Minimum Investment is ₹${fundData?.folio_number
            ? fpData?.min_additional_investment
            : fpData?.min_initial_investment
          }`
        );
      }
      else if (
        amount >
        (fundData?.folio_number
          ? fpData?.max_additional_investment
          : fpData?.max_initial_investment)
      ) {
        setError(
          `Minimum Investment is ₹${fundData?.folio_number
            ? fpData?.max_additional_investment
            : fpData?.max_initial_investment
          }`
        );
      } else {
        setError("");
        setLoading(true);

        const formDataOTP = {
          transaction_basket_id: consentId,
          otp: otp,
        };

        const httpConfigOTP = {
          method: "POST",
          tokenType: "user",
          headers: {
            "Content-Type": "application/json",
          },
          data: formDataOTP,
        };

        const formData = {
          userId: userStore.user?.id,
          transactionTypeId: 1,
          fundPlanId: fundData?.scheme_code,
          fundAmount: amount,
          fundFolioNumber: fundData?.folio_number
            ? fundData?.folio_number
            : "",
          isin: fundData?.isin,
          nav: fundData?.nav,
          amcId: fundData?.amc_code,
          planName: fundData?.scheme_name,
          mfLumpsumConsentId: consentId,
        };

        const httpConfig = {
          method: "POST",
          tokenType: "user",
          headers: {
            "Content-Type": "application/json",
          },
          data: formData,
        };

        try {
          const responseOTP = await httpInstance(
            `${VERIFY_AND_VALIDATE_BASKET_CONSENT_LUMPSUMP_OTP}`,
            httpConfigOTP
          );
          // console.log(responseOTP)
          navigate(`${namespace}/mutualfund/order-summary`, {
            state: {
              orderData: responseOTP.data?.data,
              type: "lumsum",
              schemeCode: fundData?.id,
              fundData: fundData,
              consentId: consentId,
            },
          });
          // if (responseOTP["data"]["responseStatus"] == "Success") {
          //   const createRes = await httpInstance(
          //     `${CREATE_LUMSUM}`,
          //     httpConfig
          //   );

          //   navigate(`${namespace}/mutualfund/order-summary`, {
          //     state: {
          //       orderData: createRes.data?.data,
          //       type: "lumsum",
          //       schemeCode: fundData?.scheme_code,
          //     },
          //   });
          // }

          // const formData2 = {
          //   userId: userStore.user?.id,
          //   paymentType: "NETBANKING",
          //   euin: createRes?.data?.data?.euin,
          //   referenceCode: "",
          //   id: createRes?.data?.data?.id,
          //   isWeb: true,
          // };
          // const httpConfig1 = {
          //   method: "POST",
          //   tokenType: "user",
          //   headers: {
          //     "Content-Type": "application/json",
          //   },
          //   data: formData2,
          // };
          // sessionStorage.removeItem("sip")
          // sessionStorage.setItem("lumsumId", createRes?.data?.data?.id);
          // const response = await httpInstance(
          //   `${INITIATE_PAYMENT}`,
          //   httpConfig1
          // );
          // window.location.replace(response.data?.data?.tokenUrl);
        } catch (error) {
          setLoading(false);
          setApiError(error.response.data?.message);
        }
      }
    } else {
      show_banner();
      setApiError(
        kycStatus == "submitted"
          ? "Your KYC is under review"
          : "Please complete your KYC"
      );
    }
    // } else {
    //   navigate(`${namespace}/user`);
    // }
  };


  // const handle_lumsum_otp = async (id) => {
  //   if (id) {
  //     // try {
  //       const httpConfig1 = {
  //         method: "POST",
  //         tokenType: "user",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         params: { transaction_basket_id: id},
  //       }
  //       console.log(httpConfig1)
  //       const responseGenerate = await httpInstance(
  //         `${POST_TRANSACTION_BASKETS_GENERATE_CONSENT}`,
  //         httpConfig1
  //       );
  //       console.log(responseGenerate)
  //       SetOTP("");
  //       // SetConsentId(response.data?.data.id);

  //       var timeLeft = 30; // set the time limit in seconds
  //       var timer = setInterval(function () {
  //         setIsTimerActive(true);
  //         setotpHasBeenSent(true);
  //         timeLeft--;
  //         setTimer(timeLeft);

  //         if (timeLeft <= 0) {
  //           clearInterval(timer);
  //           setotpHasBeenSent(false);
  //           setIsTimerActive(false);
  //         }
  //       }, 1000); // set the interval to 1000 milliseconds (1 second)

  //     // } catch (error) {


  //     // }
  //   }
  // }


  const handle_lumsum = async () => {
    if (
      userStore?.user?.id == "" ||
      userStore?.user?.id == "undefined" ||
      userStore?.user?.id == null
    ) {
      navigate(`${namespace}/user`);
    }
    console.log("kycStatus", kycStatus);
    console.log(kycData)
    if ((kycData?.status == 'nominee' && kycData?.is_kyc_compliant == true && kycStatus == 'pending') || (!kycData?.is_kyc_compliant && kycStatus == "successful")) {
      // console.log(
      //   "fpData?.minAdditionalInvestment",
      //   fpData?.minAdditionalInvestment
      // );
      console.log("fpData?.minInitialInvestment", fpData?.minInitialInvestment);
      // console.log("fundData?.folio_number", fundData?.folio_number);
      if (
        amount <
        (fundData?.folio_number
          ? fpData?.min_additional_investment
          : fpData?.min_initial_investment)
      ) {
        setError(
          `Minimum Investment is ₹${fundData?.folio_number
            ? fpData?.min_additional_investment
            : fpData?.min_initial_investment
          }`
        );
      }
      else if (
        amount >
        (fundData?.folio_number
          ? fpData?.max_additional_investment
          : fpData?.max_initial_investment)
      ) {
        setError(
          `Minimum Investment is ₹${fundData?.folio_number
            ? fpData?.max_additional_investment
            : fpData?.max_initial_investment
          }`
        );
      } else {
        try {
          setError("");
          setLoading(true)
          // setHandleOTP(1);
          const formData = {

            user_id: localStorage.getItem("user_id"),
            transaction_basket_items: [
              {
                transaction_type: "lumpsum",
                fund_isin: fpData?.isin,
                folio_number: fundData?.folio_number ? fundData?.folio_number : null,
                amount: amount
              }
            ]
          }
          const httpConfig = {
            method: "POST",
            tokenType: "user",
            headers: {
              "Content-Type": "application/json",
            },
            data: formData,
          };

          console.log(httpConfig)
          // const response = await httpInstance(
          //   `${GET_CONSENT_LUMPSUM_OTP}`,
          //   httpConfig
          // );
          // console.log("response.data?.data.otp", response.data?.data.otp);
          var idSet = consentId
          if (!consentId) {
            const response = await httpInstance(
              `${POST_TRANSACTION_BASKETS}`,
              httpConfig
            );
            console.log(response?.data?.data)
            SetConsentId(response.data?.data.id);

            idSet = response.data.data.id
          }
          const httpConfig1 = {
            method: "POST",
            tokenType: "user",
            headers: {
              "Content-Type": "application/json",
            },
            params: { transaction_basket_id: idSet },
          }
          console.log(httpConfig1)
          const responseGenerate = await httpInstance(
            `${POST_TRANSACTION_BASKETS_GENERATE_CONSENT}`,
            httpConfig1
          );
          // console.log(responseGenerate, 'responseGenerate')
          // SetConsentId(response.data?.data.id);
          // handle_lumsum_otp(response.data?.data.id);
          SetOTP("");
          setHandleOTP(1)
          CustomToast("OTP sent successfully", "SUCCESS")
          setLoading(false)
          var timeLeft = 30; // set the time limit in seconds
          var timer = setInterval(function () {
            setIsTimerActive(true);
            setotpHasBeenSent(true);
            timeLeft--;
            setTimer(timeLeft);

            if (timeLeft <= 0) {
              clearInterval(timer);
              setotpHasBeenSent(false);
              setIsTimerActive(false);
            }
          }, 1000); // set the interval to 1000 milliseconds (1 second)
        } catch (error) {
          CustomToast(error?.response?.data?.error ?? error?.response?.data?.message ?? "Somthing went wrong", "FAILED")
          setLoading(false)
          setApiError(error?.response?.data?.error ?? error?.response?.data?.message ?? "Somthing went wrong")

        }
      }
    }
  };



  return (
    <div>
      {handleOTP == 1 ? (
        <Box m={3} p={3}>
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} my={0}>
            <img src="/images/otpScreen.svg" alt="otp" height={100} width={100} />
            <Typography variant="h6">Confirm your order with OTP</Typography>
          </Box>
          <label className={classes.label}>Enter OTP</label>
          {/* <input
            className={classes.inputBox}
            type="text"
            placeholder="One Time Password"
            onChange={handle_otp_value}
            value={otp}
          /> */}
          <OTPInput
            value={otp}
            onChange={handle_otp_value}
            numInputs={4}
            renderSeparator={<span style={{ width: "8px" }}></span>}
            isInputNum={true}
            shouldAutoFocus={true}
            renderInput={(props) => <input {...props} />}
            inputStyle={{
              border: "none",
              borderRadius: "0px",
              borderBottom: "1px solid #000",
              width: "40px",
              height: "40px",
              fontSize: "13px",
              color: "#000",
              fontWeight: "400",
              caretColor: "blue",
            }}
            focusStyle={{
              border: "none",
              borderRadius: "0px",
              borderBottom: "1px solid #000", // Ensure this matches inputStyle
              background: "#E6E6E6",
              outline: "none",
            }}
          />
          <div className="resendOTP">
            {otpHasBeenSent ? (
              <></>
            ) : (
              <>
                <p className="otpWillRecivie">
                  OTP has been sent you will receive shortly
                </p>
              </>
            )}
            {isTimerActive ? (
              <div>
                <p className="resendOTPTimer">Resend OTP in {timer} seconds</p>
              </div>
            ) : (
              <div>
                <p className="resendOTPRequest">
                  Didn't receive OTP?{" "}
                  {/* <a href="javascript:void" onClick={handle_lumsumss}>
                    Resend OTP
                  </a> */}
                  <p
                    onClick={handle_lumsum}
                    style={{
                      color: "blue",
                      textDecoration: "underline",
                      cursor: "pointer",
                      display: "inline", // Keeps the text inline with "Didn't receive OTP?"
                    }}
                  >
                    Resend OTP
                  </p>
                </p>
              </div>
            )}
          </div>
          {apiError && <ErrorMessage message={apiError} />}
          <button
            className={classes.primaryButton}
            onClick={handle_otp}
            style={{ marginTop: "10px" }}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size="1rem" color="inherit" />
            ) : (
              "Verify OTP"
            )}
          </button>
          <div>{error && <ErrorMessage message={error} />}</div>
        </Box>
      ) : (
        <Box p={3}>
          <label className={classes.label}>Enter Amount</label>
          <span className={classes.rupeesSymbol} style={{ marginRight: '5px' }}>₹</span>
          <input
            className={classes.inputBox}
            type="text"
            placeholder="Enter Amount"
            onChange={handle_amount}
            value={amount}
          />
          {error && <ErrorMessage message={error} />}

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt={15}
          >
            {apiError && <ErrorMessage message={apiError} />}
            <button className={classes.primaryButton} onClick={handle_lumsum} disabled={!fpData || loading}>
              {loading ? (
                <CircularProgress size="1rem" color="inherit" />
              ) : (
                "Invest"
              )}
            </button>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default Lumsum;
