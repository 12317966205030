import React, { useEffect, useState } from "react";
import { Box, Typography, makeStyles, Grid, Select, MenuItem, Paper, useMediaQuery, useTheme } from "@material-ui/core";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link, useParams, useLocation } from "react-router-dom";
import { GET_FUND_DETAILS, GET_FUND_FP_DATA, GET_FUND_DETAILS_RETURN_CALCULTAE } from "../../utils/endpoints";
import httpInstance from "../../utils/instance";
import FundGraph from "./FundGraph";
import RiskAndValidation from "./RiskAndValidation";
import FundPerformance from "./FundPerformance";
import PeerComparison from "./PeerComparison";
import FundHouseDetails from "./FundHouseDetails";
import moment from "moment";
import ReturnCal from "./ReturnCal";
import Holdings from "./Holdings";
import HoldingAnalysis from "./HoldingAnalysis";
import AlternativeFunds from "./AlternativeFunds";
import Mf_Invest from "./Mf_Invest";
import Kyc_status from "../Dashboard/Funds/KycStatusCheck/Kyc_status";
import { namespace, BASE_URL } from "../../environment";
import CountUp from "react-countup";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Skeleton } from "@mui/material";

const RatingImage = ({ rating }) => {
  if (rating > 5 || rating < 1) {
    rating = 0;
  }
  // else if (rating == "Not Rated"){
  //   rating = 0;
  // }
  if (rating === "Not Rated") {
    return (
      <div style={{ fontSize: '0.8rem' }}    >
        Not Rated
      </div>
    )
  }

  // Handle case where rating is 0 or -1
  if (rating === 0 || rating === -1) {
    // Render an empty star or another image of your choice
    return [...Array(5)].map((img, i) => {
      return (
        <img
          key={i}
          style={{ marginRight: "4px" }}
          src="/images/starRatingEmpty.svg"
          height="10px"
          width="10px"
        />
      );
    });
    // return (
    //   <img
    //     src="/images/starRatingEmpty.svg"  // Change this to the path of your empty star image
    //     height="10px"
    //     width="10px"
    //   />

    // );
  }

  return [...Array(rating)].map((img, i) => {
    return (
      <img
        key={i}
        style={{ marginRight: "4px" }}
        src="/images/starRating.svg"
        height="10px"
        width="10px"
      />
    );
  });
};

const FundDetail = () => {
  const useStyles = makeStyles((theme) => ({
    root: {},
    mainGrid: {
      padding: "30px 4rem 0px 8rem",
    },
    companyLogoImage: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      flexDirection: "column",
    },
    subTabtext: {
      fontSize: "0.7rem",
      fontFamily: "inherit",
      fontWeight: 500,
    },
    textUnderline: {
      fontSize: "0.7rem",
      textDecoration: "underline",
      fontFamily: "inherit",
    },
    text: {
      fontSize: "0.8rem",
      fontWeight: 700,
    },
    subText: {
      fontSize: "0.7rem",
      fontFamily: "inherit",
      fontWeight: 500,
      paddingLeft: '5px',
    },
    text1: {
      fontSize: "0.8rem",
      margin: "0.5rem 0",
      display: 'flex',
      alignItems: 'center',
      borderBottom: "1px solid #e4e4e4",
    },

    textBold: {
      fontSize: "0.8rem",
      fontWeight: 700,
      margin: "0.5rem 0",
      display: 'flex',
      alignItems: 'center',
      borderBottom: "1px solid #e4e4e4",
    },
    rupees: {
      fontFamily: "Open Sans",
    },
    yearWiseSetup: {
      display: 'flex',
      justifyContent: 'between',
    },
    investmentComponent: {
      padding: "0rem 1rem",
    },
    "@media (max-width: 959.99px)": {
      yearWiseSetup: {
        paddingTop: '5px',
      },
      investmentComponent: {
        padding: "0rem 0rem",
      },
    },
    "@media (max-width: 767px)": {
      mainGrid: {
        padding: "0px 10px 0px 10px",
      },
    },
    companyDataDesign: {
      padding: '10px 10px',
      border: '1px solid #E4E4E4',
      // boxShadow: '0 0 8px -3px #444444',
      boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
      borderRadius: '12px',
    },
    selectorSelect: {
      color: '#1E1E1E', // Text color
      backgroundColor: 'transparent', // Background color
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#0E76BC', // Border color
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#0E76BC', // Hover border color
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#0E76BC', // Change the border color when focused (removes purple)
      },
      '& .MuiSvgIcon-root': {
        color: '#0E76BC', // Dropdown arrow color
      },
      '&.Mui-focused': {
        backgroundColor: 'transparent', // Ensures background doesn't change on focus
      },
      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent', // Removes background color change when dropdown is open
      },
      '&:after': {
        borderBottom: '2px solid #0E76BC', // Focused/active border bottom color
      },
      '&:hover:after': {
        borderBottom: '2px solid #0E76BC', // Hover border bottom color
      },
      '&:before': {
        borderBottom: '1px solid #0E76BC', // Default border bottom color
      },
      '&:hover:before': {
        borderBottom: '2px solid #0E76BC', // Hover border bottom color
      },
    },
    menuItemSelect: {
      '&:hover': {
        backgroundColor: '#0E76BC', // Background color on hover
        color: '#FFF', // Text color on hover
      },
      // Style for the selected option inside the dropdown menu
      '&.Mui-selected': {
        backgroundColor: '#0C97F0', // Background color of the selected item
        color: '#FFF', // Text color of the selected item
      },
      '&.Mui-selected:hover': {
        backgroundColor: '#0C97F0', // Slightly different on hover over selected item
      },
    },
    dropdownStyle: {
      backgroundColor: '#FFF', // Dropdown background color
      color: '#0E76BC', // Dropdown text color
    },
  }));
  const classes = useStyles();
  const location = useLocation();

  const [fundData, setFundData] = useState(null);
  const [fundDataPerformance, setFundDataPerformance] = useState(null);
  const [fpData, setFpData] = useState();
  const [showBanner, setShowBanner] = useState();
  const [loading, setLoading] = useState(false);
  const [holdingDataOfSectors, setHoldingDataOfSectors] = useState([])
  // const [folioNumber, setFolioNumber]= useState('')

  const { code } = useParams();
  const { state } = useLocation()
  const theme = useTheme();
  const isTab = useMediaQuery(theme.breakpoints.down('sm'));

  // const {folioNumber}=state
  console.log(state, 'state')
  // useEffect(() => {
  //   if (state?.folioNumber && fundData) {
  //     console.log('sdfasdfadsfasds')
  //     // setFolioNumber(state?.folioNumber)
  //     setFundData(prevData => ({
  //       ...prevData,
  //       folio_number: state?.folioNumber // Assuming folio_number comes from an input field
  //     }));
  //   }
  // }, [ ]);

  // useEffect(() => {
  //   localStorage.setItem("fund_id", code);
  //   if (location.state?.data) {
  //     setFundData(location.state?.data);
  //   }
  // }, [code]);

  const get_fund_data = async () => {
    const httpConfig = {
      method: "GET",
      // tokenType: "user",
      test: 'test'
      // headers: {
      //   "Content-Type": "application/json",
      // },
      // data: {
      //   scheme_code: code,
      //   plan_name: "",
      //   option_name: "growth",
      //   holding_period: "1M",
      // },
    };

    const httpConfig1 = {
      method: "GET",
      tokenType: "user",
      // headers: {
      //   "Content-Type": "application/json",
      // },
    };
    try {
      const response = await httpInstance(`${GET_FUND_DETAILS}?fundPlanId=${code}`, httpConfig);
      const updatedFundData = { ...response.data?.data };

      // console.log(type);
      // console.log(selectionOfBank);

      // Make changes to the copied object
      updatedFundData['stateValue'] = state?.fundData ? state?.fundData : null;
      updatedFundData['folio_number'] = state?.folioNumber ? state?.folioNumber : null
      // console.log(updatedSelectionOfBank);

      // Now you can use the updatedSelectionOfBank as needed
      // setSelectedBank(updatedSelectionOfBank);
      // console.log(updatedFundData)
      setFundData(updatedFundData);
      // show_banner()
      // console.log(response.data.data);
      // const fpres = await httpInstance(`${GET_FUND_FP_DATA}`, {
      //   ...httpConfig1,
      //   params: { isin: response.data?.data?.isin },
      // });
      // setFpData(fpres.data?.data);
    } catch { }
  };

  const get_fund_data_return_calculate = async (sliderValue, typeValue) => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      // test: 'test'
      // headers: {
      //   "Content-Type": "application/json",
      // },
      // data: {
      //   scheme_code: code,
      //   plan_name: "",
      //   option_name: "growth",
      //   holding_period: "1M",
      // },
    };

    const httpConfig1 = {
      method: "GET",
      tokenType: "user",
      // headers: {
      //   "Content-Type": "application/json",
      // },
    };
    try {
      const response = await httpInstance(`${GET_FUND_DETAILS_RETURN_CALCULTAE}?plan_id=${code}&amount=${sliderValue}&calculation_type=${typeValue}`, httpConfig);
      // setFundData((prevData) => ({ ...prevData, performance: response.data?.data }));
      setFundDataPerformance(response.data?.data)
      // setFundData( ...fundData, ...response.data?.data);
      // const fpres = await httpInstance(`${GET_FUND_FP_DATA}`, {
      //   ...httpConfig1,
      //   params: { isin: response.data?.data?.isin },
      // });
      // setFpData(fpres.data?.data);
    } catch { }
  };


  const get_fund_fp_data = async () => {
    // console.log(fundData)
    const httpConfig1 = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: { isin: fundData?.isinCode },
    };

    try {
      const fpres = await httpInstance(`${GET_FUND_FP_DATA}`, httpConfig1);
      // console.log(fpres?.data?.data.sip_frequency_data?.MONTHLY, 'fadfadfadsfasdfasdfasdf')

      setFpData(fpres.data?.data);
    } catch { }
  };

  useEffect(() => {
    // console.log(location.state, 'location.state');
    // if (!fpData && fundData) {
    if (fundData) {
      get_fund_fp_data();
    }
  }, [fundData]);


  useEffect(() => {
    // console.log("location.state", location.state)
    // if (!location.state) {
    if (!fundData) {
      get_fund_data();
    }
  }, []);

  useEffect(() => {
    // console.log("location.state", location.state)
    // if (!location.state) {
    get_fund_data_return_calculate(5000, 'sip');
    // }
  }, []);

  const show_banner = () => {
    setShowBanner(true);
  };

  const sliderAmountChangeIntheReturnCalculator = (value, type) => {
    // console.log(data, sdata)
    get_fund_data_return_calculate(value, type)
  }


  const [selectedPeriod, setSelectedPeriod] = useState('1year');

  const handlePeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
  };

  const getReturnValue = () => {
    switch (selectedPeriod) {
      case '1year':
        return fundData?.fundRet1year ? parseFloat(fundData?.fundRet1year) : 0;
      case '3year':
        return fundData?.fundRet2year ? parseFloat(fundData?.fundRet3year) : 0;
      case '5year':
        return fundData?.fundRet5year ? parseFloat(fundData?.fundRet5year) : 0;
      default:
        return 0;
    }
  };

  // console.log(fundData)

  return (
    <div className={classes.root}>
      {/* <Box
        display="flex"
        px={{ xs: 1, md: 17 }}
        pt={2}
        style={{ borderBottom: "1px solid #E6E3E3" }}
      >
        <Link
          to={`${namespace}/dashboard/mutualfund`}
          style={{ color: "black" }}
        >
          <Typography className={classes.textUnderline}>
            Find Mutual Funds
          </Typography>
        </Link>
        <Box display="flex">
          <ChevronRightIcon style={{ height: "1rem" }} />
          <Typography
            className={classes.subTabtext}
            display="flex"
            alignItems="start"
          >
            {fundData?.schemeName}
          </Typography>
        </Box>
      </Box> */}
      <Box mt={2} />
      {/* kyc banner */}
      {showBanner && <Kyc_status />}
      <Box
        display="flex"
        justifyContent="flex-start"
        px={{ xs: 2, md: 15 }}
        mt={5}
        className={classes.backButton}
      >
        {state?.fundData ?
          <Link to={`${namespace}/myportfolio`} style={{display: "flex", alignItems: 'center'}}>
            <img src="/images/backIcon.svg" height="auto" width="20px" />
          </Link>
          :
          <Link to={`${namespace}/dashboard/mutualfund`} style={{display: "flex", alignItems: 'center'}}>
            <img src="/images/backIcon.svg" height="auto" width="20px" />
          </Link>
        }
        <Typography variant="h6" style={{paddingLeft: '10px'}}>Fund Details</Typography>
      </Box>
      {/* <Box width={'100%'} display="flex" alignItems={'center'} mt={1}> */}

      {/* <Box display="flex"
          justifyContent="center" width={'100%'} > */}
      {/* <Grid container >
            <Grid item xs={3} md={1} className={classes.companyLogoImage}> */}
      {/* <Box>
            <img
              // src={fundData?.img_url} 
              // src={`${BASE_URL}${fundData?.amcLogoUrl}`}
              src={`${BASE_URL}${fundData?.amc?.logoUrl}`}

              height="50px" width="50px" style={{ borderRadius: '10px', marginRight: '10px' }} /> */}
      {/* </Grid>
            <Grid item xs={9} md={11}> */}
      {/* </Box>
          <Box> */}
      {/*  <Typography>{fundData?.scheme_name} </Typography> */}
      {/* <Typography>{fundData?.schemeName} </Typography>
            <Box display='flex' flexDirection='row' alignItems='center'>
              <Typography className={classes.text} > */}
      {/*  {fundData?.asset_sub_category} */}
      {/* {fundData?.primaryCategoryName} */}
      {/* {fundData?.category?.primaryCategoryName}
              </Typography>
              <Typography className={classes.subText}>&#x2022; {fundData?.colour?.risk} </Typography>
              <Typography className={classes.subText}>&#x2022; {fundData?.category?.categoryName.replace(`${fundData?.category?.primaryCategoryName}:`, "")} </Typography> */}
      {/* <RatingImage rating={fundData?.vr_rating.length} /> */}
      {/* {fundData.fundRating} */}
      {/* </Box> */}
      {/* <RatingImage rating={fundData?.fundRating} /> */}


      {/* </Grid>
          </Grid> */}
      {/* </Box> */}
      {/* </Box> */}
      {/* </Box> */}

      <Grid container className={classes.mainGrid}>
        <Grid item xs={12} md={8}
        //  order={{sm: 2, xs: 2, lg: 1, md: 1}}
        >
          <Grid container className={classes.companyDataDesign}>
            <Grid item xs={3} sm={3} md={3} lg={1} className={classes.companyLogoImage}>
              {/* <img
                // src={fundData?.img_url} 
                // src={`${BASE_URL}${fundData?.amcLogoUrl}`}
                src={`${BASE_URL}${fundData?.amc?.logoUrl}`}

                height="50px" width="50px" style={{ borderRadius: '10px' }} /> */}
              {fundData ? (
                <Box display="flex" alignItems="center">
                  <img
                    src={`${BASE_URL}${fundData.amc.logoUrl}`}
                    height="50px"
                    width="50px"
                    style={{ borderRadius: '10px' }}
                    alt="Company Logo"
                  />
                </Box>
              ) : (
                <Skeleton variant="rounded" border width={50} height={50} />
              )}
            </Grid>
            <Grid item xs={9} sm={9} md={9} lg={8}>
              {fundData ? (
                <>
                  {/*  <Typography>{fundData?.scheme_name} </Typography> */}
                  <Typography>{fundData?.schemeName} </Typography>
                  <Box display='flex' flexDirection='row' alignItems='center'>
                    <Typography className={classes.text} >
                      {/*  {fundData?.asset_sub_category} */}
                      {/* {fundData?.primaryCategoryName} */}
                      {fundData?.category?.primaryCategoryName}
                    </Typography>
                    <Box display='flex' flexDirection='row' alignItems='center'>
                      <Typography className={classes.subText}>&#x2022; </Typography>
                      <Typography className={classes.subText}> {fundData?.colour?.risk} </Typography>
                    </Box>
                    <Box display='flex' flexDirection='row' alignItems='center'>
                      <Typography className={classes.subText}>&#x2022; </Typography>
                      <Typography className={classes.subText}> {fundData?.category?.categoryName.replace(`${fundData?.category?.primaryCategoryName}:`, "")} </Typography>
                    </Box>
                    {/* <RatingImage rating={fundData?.vr_rating.length} /> */}
                    {/* {fundData.fundRating} */}
                  </Box>
                  {/* <RatingImage rating={fundData?.fundRating} /> */}

                </>
              ) : (
                <>
                  <Skeleton variant="text" width="60%" />
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Skeleton variant="text" width="50%" />
                    {/* <Skeleton variant="text" width="20%" />
                    <Skeleton variant="text" width="30%" /> */}
                  </Box>
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} className={` ${classes.companyLogoImage}`}>
              <Box className={`${classes.yearWiseSetup}`} width={"100%"} >

                <Box width={'100%'} >
                  {fundData ? <Typography className={classes.textBold} style={{ fontSize: "1rem", fontWeight: 600, margin: 0, display: 'flex', justifyContent: 'center', paddingTop: '1px', border: 'none' }}><span className={classes.rupees}>₹</span> <CountUp end={fundData.nav} decimals={2} decimal="." duration={2.5} />  </Typography> : <Skeleton variant="text" width="70%" />}
                  {fundData ? <Typography className={classes.text1} style={{ margin: 0, paddingTop: '10px', display: 'flex', justifyContent: 'center', border: 'none' }}> Nav &#40;{moment(fundData?.navDate).format("DD MMM YYYY")}&#41;	 </Typography> : (<Skeleton variant="text" width="90%" />)}

                </Box>
                <Box>
                  <Typography style={{ fontSize: "1rem", color: "#0E76BC", fontWeight: 600 }}>
                    {/* {fundData?.performance?.fiveY}% */}
                    {/* {fundData?.ret5year ? fundData?.ret5year : 0}% */}
                    {/* {fundData?.ret5year ? fundData?.fundRet5year : 0}% */}
                    <CountUp end={getReturnValue()} decimals={2} decimal="." duration={2.0} />%
                  </Typography>
                  {/* <Typography className={classes.text}>Returns( 5yrs )</Typography> */}
                  <Select
                    value={selectedPeriod}
                    onChange={handlePeriodChange}
                    label="Period"
                    className={classes.selectorSelect}
                    IconComponent={ExpandMoreIcon} // Use custom icon for dropdown
                    MenuProps={{
                      classes: { paper: classes.dropdownStyle },
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null, // Prevents default center alignment
                    }}
                  >
                    <MenuItem value="1year" className={classes.menuItemSelect}>
                      1 Year
                    </MenuItem>
                    <MenuItem value="3year" className={classes.menuItemSelect}>
                      3 Years
                    </MenuItem>
                    <MenuItem value="5year" className={classes.menuItemSelect}>
                      5 Years
                    </MenuItem>
                  </Select>
                </Box>
              </Box>
            </Grid>
          </Grid>
          {/* Graph */}
          <FundGraph code={code} />
          <Grid container className={classes.companyDataDesign}>
            {/* <Grid item xs={6} sm={3} className={classes.textBold}>
              {fundData ? (<> Nav :{moment(fundData.navDate).format("DD MMM YYYY")} </>) : (<Skeleton variant="text" width="50%" />)}

            </Grid>
            <Grid item xs={6} sm={3} className={classes.text1}>

              {fundData ? <><span className={classes.rupees}>₹</span>{fundData.nav.toFixed(2)}</> : <Skeleton variant="text" width="50%" />}
            </Grid> */}

            <Grid item xs={6} sm={3} className={classes.textBold}>
              {" "}
              {fundData ? "Min. Investment/SIP " : <Skeleton variant="text" width="50%" />}
            </Grid>
            <Grid item xs={6} sm={3} className={classes.text1}>

              {/* {fundData?.min_sip_amount} */}

              {fundData ? <><span className={classes.rupees}>₹</span>{fpData?.min_initial_investment}/<span className={classes.rupees}>₹</span>{fpData?.min_sip_amount}  </> : <Skeleton variant="text" width="50%" />}

            </Grid>

            {/* <Grid item xs={3} className={classes.textBold}>
              Rating{" "}
            </Grid>
            <Grid item xs={3} className={classes.text1}>
              {fundData?.fundRating == "Not Rated" ||
                fundData?.fundRating == "" ? (
                "Not Rated"
              ) : (
                <RatingImage
                  // rating={fundData?.vr_rating.length}
                  rating={fundData?.fundRating}

                />
              )}
            </Grid> */}


            {/*<Grid item xs={3} className={classes.textBold}>
              Fund size{" "}
            </Grid>
            <Grid item xs={3} className={classes.text1}>
              <span className={classes.rupees}>₹</span>
              {fundData?.fund_size.toFixed(2)}Cr.
            </Grid>*/}
            <Grid item xs={6} sm={3} className={classes.textBold}>
              {" "}

              {fundData ? "Fund Size" : <Skeleton variant="text" width="50%" />}
              {" "}
            </Grid>
            <Grid item xs={6} sm={3} className={classes.text1}>
              {fundData ? <> <span className={classes.rupees}>₹</span>
                {/* {fundData?.aum.toFixed(2).toLocaleString('en-IN')} */}
                {fundData?.aum ? Number(fundData.aum.toFixed(2)).toLocaleString('en-IN') : '0'}
                cr</> : <Skeleton variant="text" width="50%" />}
            </Grid>
            {/* <Grid item xs={6} sm={3} className={classes.textBold}>
              {" "}

              {fundData ? "Min. Initial SIP Amount " : <Skeleton variant="text" width="50%" />}
              {" "}
            </Grid>
            <Grid item xs={6} sm={3} className={classes.text1}>
              {fundData ? <> <span className={classes.rupees}>₹</span>{fundData?.minInitialInvestment}</> : <Skeleton variant="text" width="50%" />}
            </Grid> */}
            <Grid item xs={6} sm={3} className={classes.textBold}>
              {" "}
              {fundData ? "Expense Ratio" : <Skeleton variant="text" width="50%" />}
              {" "}
            </Grid>
            <Grid item xs={6} sm={3} className={classes.text1}>
              <span className={classes.rupees}></span>
              {/* {fundData?.min_initial_investment} */}
              {/* {fundData?.expenseRatio.toFixed(2)}% */}
              {fundData ? <>{fundData?.expenseRatio.toFixed(2)}%</> : <Skeleton variant="text" width="50%" />}

            </Grid>
            <Grid item xs={6} sm={3} className={classes.textBold}>
              {" "}
              {/* Benchmark */}
              {fundData ? "Benchmark" : <Skeleton variant="text" width="50%" />}
              {" "}
            </Grid>
            <Grid item xs={6} sm={3} className={classes.text1}>
              <span className={classes.rupees}></span>
              {/* {fundData?.min_initial_investment} */}
              {/* {fundData?.benchmark?.shortName} */}
              {fundData ? <>{fundData?.benchmark?.shortName}</> : <Skeleton variant="text" width="50%" />}

            </Grid>
            <Grid item xs={6} sm={3} className={classes.textBold}>
              {" "}
              {/* Exit Load */}
              {fundData ? "Exit Load" : <Skeleton variant="text" width="50%" />}
              {" "}
            </Grid>
            <Grid item xs={6} sm={3} className={classes.text1} style={{ paddingRight: '5px' }}>
              <span className={classes.rupees}></span>
              {/* {fundData?.min_initial_investment} */}
              {/* {fundData?.exitLoad} */}
              {fundData ? <>{fundData?.note}</> : <Skeleton variant="text" width="50%" />}

            </Grid>
            <Grid item xs={6} sm={3} className={classes.textBold}>
              {" "}
              {/* Lock In */}
              {fundData ? "Lock In" : <Skeleton variant="text" width="50%" />}
              {" "}
            </Grid>
            <Grid item xs={6} sm={3} className={classes.text1}>
              <span className={classes.rupees}></span>
              {/* {fundData?.min_initial_investment} */}
              {/* {fundData?.lockInPeriodDays} */}
              {fundData ? <>{fundData?.lockInPeriodDays ? fundData?.lockInPeriodDays : "No lock in period"}</> : <Skeleton variant="text" width="50%" />}
            </Grid>
            <Grid item xs={6} sm={3} className={classes.textBold}>
              {" "}
              {/* Riskometer */}
              {fundData ? "Riskometer" : <Skeleton variant="text" width="50%" />}
              {" "}
            </Grid>
            <Grid item xs={6} sm={3} className={classes.text1}>
              <span className={classes.rupees}></span>
              {/* {fundData?.min_initial_investment} */}
              {/* {fundData?.colour?.colourName} */}
              {fundData ? <>{fundData?.colour?.colourName}</> : <Skeleton variant="text" width="50%" />}
            </Grid>
            <Grid item xs={6} sm={3} className={classes.textBold}>
              {" "}
              {/* SID */}
              {fundData ? "SID" : <Skeleton variant="text" width="50%" />}
              {" "}
            </Grid>
            <Grid item xs={6} sm={3} className={classes.text1}>
              {fundData ? <span className={classes.rupees}>
                <a href={fundData?.amc.website} target="_blank" rel="noopener noreferrer">
                  Click here
                </a>
              </span>
                :
                <Skeleton variant="text" width="50%" />}


              {/* {fundData?.min_initial_investment} */}
              {/* {fundData?.amc.website} */}
            </Grid>
          </Grid>

          {/* Return caluculator */}
          <ReturnCal
            fundData={fundData}
            // returns={fundData?.performance}
            returns={fundDataPerformance}
            sliderAmountChangeIntheReturnCalculator={sliderAmountChangeIntheReturnCalculator}
          />

          {/* Less than mobile view part */}
          {isTab && <>
            {!loading && (
              <Mf_Invest
                fundData={fundData}
                fpData={fpData}
                show_banner={show_banner}
              // folioNumber = {folioNumber}
              />
            )}
          </>}

          {/* Holdings */}
          <Holdings schemeCode={code} holdingDataOfSector={(data) => {
            setHoldingDataOfSectors(data)
          }} />

          {/* Risk Validation */}
          <RiskAndValidation fundData={fundData} />

          {/* Fund Performance  */}
          <FundPerformance schemeCode={code} fundData={fundData} />

          {/* Peer  comparison */}
          <PeerComparison schemeCode={code} />

        </Grid>
        {!isTab && <Grid
          item
          xs={12}
          md={4}
          // style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
          // className="padding-2rem"
          className={classes.investmentComponent}
        // order={{sm: 1, xs: 1, lg: 2, md: 2}}

        >
          {!loading && (
            <Mf_Invest
              fundData={fundData}
              fpData={fpData}
              show_banner={show_banner}
            // folioNumber = {folioNumber}
            />
          )}
        </Grid>}
      </Grid>


      {/* Funding House Details */}
      <FundHouseDetails schemeCode={code} fundData={fundData} />

      {/* Holding Analysis */}
      {/* <HoldingAnalysis
        exitLoad={fundData?.exitLoad}
        fundSize={fundData?.aum}
        schemeCode={code}
        fundData={fundData}
        holdingDataOfSectors={holdingDataOfSectors}
      /> */}

      {/* Alternative funds */}

      {/* <AlternativeFunds asset_catogory={fundData?.asset_sub_category} /> */}
    </div>
  );
};

export default FundDetail;
