import {
  Box,
  MenuItem,
  Select,
  FormControl,
  CircularProgress,
  createTheme,
  ThemeProvider,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React, { useEffect, useState } from "react";
import TextInputField from "../Input/TextInputField";
import TitleLayoult from "../TitleLayoult";
import { useStyles } from "../styles";
import { useForm, Controller } from "react-hook-form";
import ErrorMessage from "../../Validation/ErrorMessage";
import {
  GET_OCCUPATION_LIST,
  ADD_PERSONAL_DETAILS,
  GET_PERSONAL_DETAILS,
  KYC_STATUS,
} from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import { useNavigate, createSearchParams, useSearchParams } from "react-router-dom";
import NriPopup from "../NriPopup";
import ConnectModal from "../../ConnectModal";
import ConnectSuccess from "../../ConnectSuccess";
import { namespace } from "../../../environment";
import CustomToast from "../../UI/CustomToast";
import StepRedirector from "../Common/StepRedirector";
import StatusApiData from "../Common/StatusApiData";

function Step5({ step }) {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const steps = searchParams.get("stepcount");

  const navigate = useNavigate();

  const defaultValues = {
    fatherName: "",
    motherName: "",
    marital: "",
    occupation: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({ defaultValues });

  const theme = createTheme({
    overrides: {
      MuiInputBase: {
        input: {
          padding: "8px 10px",
        },
      },
      MuiSelect: {
        select: {
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      },
    },
  });

  const [loading, setLoading] = useState(false);

  const [occupation, setOccupation] = useState([]);
  const [dialog, setDialog] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [personalData, setPersonalData] = useState({});
  const [isComplient, setIsComplient] = useState(false);
  const [error, setError] = useState();
  const [apiError, setApiError] = useState(null);

  const open_dialog = () => setDialog(true);
  const close_dialog = () => setDialog(false);

  const open_contact = () => setShowContact(true);
  const close_contact = () => setShowContact(false);

  const open_success = () => setShowSuccess(true);
  const close_success = () => setShowSuccess(false);

  //-------------reset persoanl details------------------------------------------//
  const resetData = (data) => {
    console.log(data.father_name)
    reset({
      fatherName: data.father_name,
      motherName: data.mother_name,
      marital: data.marital_status,
      gender: data.gender,
    });
  };
  // --------------------- get occupation list 




  //---------------------get occupation list-----------------------------------------//
  useEffect(async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      // const response = await httpInstance(`${GET_OCCUPATION_LIST}`, httpConfig);
      // setOccupation(response?.data?.data);
      const personalDeatils = await httpInstance(
        `${GET_PERSONAL_DETAILS}`,
        httpConfig
      );
      setPersonalData(personalDeatils?.data?.data);
      resetData(personalDeatils?.data?.data);
      // const complient = await httpInstance(`${KYC_STATUS}`, httpConfig);
      const complient = await StatusApiData()
      // setIsComplient(complient.data?.data?.isKycCompliant);
      setIsComplient(complient.data.data?.is_kyc_compliant)
      // console.log(complient?.data)
      const dataFinal = StepRedirector(complient.data.data, steps) // secutiry redirection
      if (dataFinal) {
        navigate({
          pathname: `${namespace}/dashboard/mutualfund`,
        });
      }
    } catch (error) {
      CustomToast(error?.data?.message || "Somthing went wrong", "FAILED")
    }
  }, []);

  //------------------------------if NRI-----------------------------------------------------//
  const handleNRI = (e) => {
    // if (e.target.value === "NRI") {
    //   open_dialog();
    // }
  };

  //------------------------------submit details--------------------------------------------------------//
  const submit_personal_details = async (data) => {
    setLoading(true)
    setError(false)
    setApiError(null)
    // console.log(data);
    const userDetails = JSON.parse(localStorage.getItem("panDetails"));
    // if (data.nationality === "NRI") {
    //   open_dialog();
    // } else {
    const formData = {
      user_id: localStorage.getItem("user_id"),
      father_name: data.fatherName,
      mother_name: data.motherName,
      marital_status: data.marital,
      gender: data.gender
    };

    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    };

    const httpConfig1 = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      await httpInstance(`${ADD_PERSONAL_DETAILS}`, httpConfig);

      navigate({
        pathname: `${namespace}/onBoarding`,
        // search: `?${createSearchParams({ stepcount: "7" })}`,
        // Fatca Details
        // search: `?${createSearchParams({ stepcount: "20" })}`,
        search: `?${createSearchParams(isComplient === true ? { stepcount: "4" } : { stepcount: "11" })}`,
      });
      setLoading(false)
      CustomToast(personalData?.fatherName ? "Updated Personal Details" : "Added Personal Detials", "SUCCESS")
    } catch (error) {

      // navigate({
      //   pathname: `${namespace}/signIn`,
      //   // search: `?${createSearchParams({ stepcount: "7" })}`,
      //   // Fatca Details
      //   search: `?${createSearchParams({ stepcount: "20" })}`,
      // });
      CustomToast(error.response.data.error, "FAILED")
      setApiError(error?.response?.data?.error)
      setError(true)
      setLoading(false)
    }
    // }
  };

  const handleback = () => {
    navigate({
      pathname: `${namespace}/onBoarding`,
      search: `?${createSearchParams(
        isComplient ? { stepcount: "2" } : { stepcount: "9" }
      )}`,
    });
  };
  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent="flex-start"
        // px={20}
        className={classes.backButton}
      // onClick={handleback}
      >
        {/* <img src="/images/backIcon.svg" /> */}
      </Box>
      <Box display={"flex"} justifyContent="center">
        <Box
          display={"flex"}
          alignItems="flex-start"
          flexDirection={"column"}
        // sx={{ width: "40%" }}
        >
          <Box
            display="flex"
            justifyContent="flex-start"
            // px={20}
            className={classes.backButton}
            onClick={handleback}
          >
            <img src="/images/backIcon.svg" alt="back" height={'20px'} width={'20px'} />
          </Box>
          <Box>
            <TitleLayoult
              title="Add Personal Details"
              description="Please enter your personal details so we can set up your profile"
            />
          </Box>
          <Box>
            <form onSubmit={handleSubmit(submit_personal_details)}>
              <Box my={1}>
                <TextInputField
                  name="fatherName"
                  label="Father's Name"
                  placeholder="Father's Name"
                  register={register}
                  inputPattern={
                    /^(?=(?:^\w))([a-zA-Z](\s?)([a-zA-Z]\s?)+)*\s*$/
                  }
                />
                {errors.fatherName && errors.fatherName.type === "required" && (
                  <ErrorMessage message="Enter Father's Name" />
                )}
                {errors.fatherName && errors.fatherName.type === "pattern" && (
                  <ErrorMessage message="Enter valid Name" />
                )}
              </Box>

              <Box my={1}>
                <TextInputField
                  name="motherName"
                  label="Mother's Name"
                  placeholder="Mother's Name"
                  register={register}
                  inputPattern={
                    /^(?=(?:^\w))([a-zA-Z](\s?)([a-zA-Z]\s?)+)*\s*$/
                  }
                />
                {errors.motherName && errors.motherName.type === "required" && (
                  <ErrorMessage message="Enter Mother's Name" />
                )}
                {errors.motherName && errors.motherName.type === "pattern" && (
                  <ErrorMessage message="Enter valid Name" />
                )}
              </Box>

              <ThemeProvider theme={theme}>
                <Box
                  my={1}
                  display="flex"
                  alignItems={"flex-start"}
                  justifyContent="flex-start"
                  flexDirection="column"
                  width={"100%"}
                >
                  <label className={classes.label}>Marital Status</label>
                  <Box width={"100%"} className={`${classes.Select} ${classes.Select2}`}>
                    <FormControl fullWidth className={classes.Select}>
                      <Controller
                        control={control}
                        name={`marital`}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            disableUnderline
                            className={classes.selectItem}
                            //classes={{paper:classes.selectPaper}}
                            IconComponent={KeyboardArrowDownIcon}
                            placeholder="Marital Status"
                            displayEmpty
                            MenuProps={{
                              style: {
                                maxHeight: 250,
                                top: 5,
                                minWidth: 200,
                              },
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                          >
                            <MenuItem value={null} disabled hidden className={classes.menuItem}>
                              Marital Status
                            </MenuItem>
                            <MenuItem className={classes.menuItem} value={'married'}>
                              Married
                            </MenuItem>
                            <MenuItem className={classes.menuItem} value={'unmarried'}>
                              Unmarried
                            </MenuItem>
                            <MenuItem className={classes.menuItem} value={'others'}>
                              Others
                            </MenuItem>
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Box>
                  {errors.marital && (
                    <ErrorMessage message="Please Select Marital Status" />
                  )}
                </Box>
              </ThemeProvider>
              {/* <ThemeProvider theme={theme}>
                <Box
                  my={1}
                  display="flex"
                  alignItems={"flex-start"}
                  justifyContent="flex-start"
                  flexDirection="column"
                  width={"100%"}
                >
                  {" "}
                  <label className={classes.label}>Occupation</label>
                  <Box width={"100%"} className={classes.Select}>
                    <FormControl fullWidth className={classes.Select}>
                      <Controller
                        control={control}
                        name={`occupation`}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            disableUnderline
                            className={classes.selectItem}
                            //classes={{paper:classes.selectPaper}}
                            IconComponent={KeyboardArrowDownIcon}
                            MenuProps={{
                              style: {
                                maxHeight: 250,
                                top: 6,
                                minWidth: 200,
                              },
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },

                              getContentAnchorEl: null,
                            }}
                          >
                            {occupation &&
                              occupation?.map((ele) => {
                                return (
                                  <MenuItem
                                    key={ele.id}
                                    className={classes.menuItem}
                                    value={ele.id}
                                  >
                                    {ele.type}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Box>
                </Box>
                {errors.occupation && (
                  <ErrorMessage message="Please Select Occupation" />
                )}
              </ThemeProvider> */}
              <Box
                display="flex"
                flexDirection="column"
                alignItems={"flex-start"}
                justifyContent="flex-start"
                width={"100%"}
              >
                <Box my={1}>
                  <Box my={1} className={classes.label}>
                    Gender
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <input
                        type="radio"
                        height="16px"
                        width="16px"
                        name="fillType"
                        style={{ height: "16px", width: "16px", margin: 0 }}
                        value={'male'}
                        {...register("gender", { required: true })}
                      />
                      <Box fontSize={12} fontWeight={500} ml={1}>
                        Male
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="flex-start"
                      ml={6}
                    >
                      <input
                        type="radio"
                        height="16px"
                        width="16px"
                        name="fillType"
                        style={{ height: "16px", width: "16px", margin: 0 }}
                        value={'female'}
                        {...register("gender", { required: true })}
                      />
                      <Box fontSize={12} fontWeight={500} ml={1}>
                        Female
                      </Box>
                    </Box>
                    {/* <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="flex-start"
                      ml={6}
                    >
                      <input
                        type="radio"
                        height="16px"
                        width="16px"
                        name="fillType"
                        style={{ height: "16px", width: "16px", margin: 0 }}
                        value={'transgender'}
                        {...register("gender", { required: true })}
                      />
                      <Box fontSize={12} fontWeight={500} ml={1}>
                        Transgender
                      </Box>
                    </Box> */}
                  </Box>
                </Box>
                {errors.gender && (
                  <ErrorMessage message={"Please select Gender"} />
                )}
              </Box>
              {error && <ErrorMessage message={apiError || "Something wrong happened"} />}

              <Box mt={2}>
                <button className={classes.primaryButton} type="submit" disabled={loading}>
                  {loading ? (
                    <CircularProgress size="1rem" color="inherit" />
                  ) : (
                    "Next"
                  )}
                </button>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
      <NriPopup
        dialog={dialog}
        openDialog={open_dialog}
        closeDialog={close_dialog}
        handleContact={open_contact}
      />
      <ConnectModal
        showModal={showContact}
        handleCloseModal={close_contact}
        handleShowSuccess={open_success}
      />
      <ConnectSuccess
        handleCloseSuccessModal={close_success}
        showSuccessModal={showSuccess}
      />
    </React.Fragment>
  );
}

export default Step5;
