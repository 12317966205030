import React, { useState, useEffect } from "react";
import {
  Box,
  makeStyles,
  Typography,
  Paper,
  InputBase,
} from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ReactLoading from "react-loading";
import SearchIcon from "@mui/icons-material/Search";
import StpCard from "./StpCard";
import { GET_STP_ALTERNATIVE } from "../../../../utils/endpoints";
import httpInstance from "../../../../utils/instance";
import InfiniteScroll from "react-infinite-scroll-component";


const LoaderComp = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "1rem",
        overflow: "hidden",
      }}
    >
      {/* <CircularProgress size="2rem" /> */}
      <ReactLoading
        type="spinningBubbles"
        color="#0E76BC"
        height={"4%"}
        width={"4%"}
      />
    </div>
  );
};



const Index = () => {
  const useStyles = makeStyles({
    subTabtext: {
      fontSize: "0.7rem",
      fontFamily: "inherit",
      fontWeight: 500,
    },
    textUnderline: {
      fontSize: "0.7rem",
      textDecoration: "underline",
      fontFamily: "inherit",
    },

    title: {
      fontSize: "1.1rem",
      fontWeight: 600,
    },
    textgrey: {
      fontSize: "0.8rem",
      color: "#626262",
    },
    searchBar: {
      padding: "5px 8px",
      display: "flex",
      alignItems: "center",
      margin: "20px 0px 30px 0px",
      borderRadius: "10px",
      "& .MuiSvgIcon-root": {
        opacity: 0.4,
      },
    },
    input: {
      marginLeft: "10px",
      flex: 1,
      width: 100,
    },
    text: {
      textAlign: "center",
    },
  });
  const classes = useStyles();

  const [fundList, setFundList] = useState([]);
  const [loading, setLoading] = useState(false);

  const { state } = useLocation();
  const { fundData, schemeData, stpData } = state;
  const [filteredFund, setFilteredFund] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const set_page = () => setPageNumber(pageNumber + 1);

  const get_fund_list = async () => {
    if (pageNumber == 0) {
      setLoading(true);
    }
    const formData = {
      amcIds: [fundData?.amc?.id],
      lockIn: 2,
      minBeta: -100,
      maxBeta: 100,
      minAlpha: -100,
      maxAlpha: 100,
      minStandardDeviation: -100,
      maxStandardDeviation: 100,
      limit: 10,
      pageNumber: pageNumber + 1,
      ratings: [],
      aumRange: [],
    }
    const httpConfig = {
      method: "POST",
      test: 'test',
      // tokenType: "user",
      // headers: {
      //   "Content-Type": "application/json",
      // },
      // params: {
      //   amc_code: fundData?.amc_code,
      //   scheme_code: fundData?.scheme_code,
      //   stp_amount: schemeData?.redeemableAmount,
      // },
      data: formData,
    };
    try {
      const response = await httpInstance(`${GET_STP_ALTERNATIVE}`, httpConfig);
      if (response?.data?.responseCount == 0) {
        setHasMore(false);
        setLoading(false);
      } else {
        if (response?.data?.responseCount >= 10) {
          setFundList([...fundList, ...response.data?.data]);
          setFilteredFund([...filteredFund, ...response.data?.data]);
          setLoading(false);
          set_page();
          console.log('more is there ')
        } else {
          if (response?.data?.data) {
            setFundList([...fundList, ...response?.data?.data]);
            setFilteredFund([...filteredFund, ...response?.data?.data]);
          }
          setLoading(false);
          set_page();
          setHasMore(false);
          console.log("no more")
        }

      }
      // setFundList(response.data?.data);
      // setFilteredFund(response.data?.data);
      // setLoading(false);
    } catch { }
  };

  useEffect(() => {
    get_fund_list();
  }, []);

  const handle_search = async(e) => {
    let filteredArray = fundList.filter((item) =>
      item.schemeName.toLowerCase().includes(e.target.value)
    );
    setFilteredFund(filteredArray);
  };

  return (
    <div>
      <Box
        display="flex"
        alignItems="start"
        px={{ xs: 1, md: 17 }}
        pt={2}
      // style={{ borderBottom: "1px solid #E6E3E3" }}
      >
        {/* <Link
          to="/myportfolio?tab=mutualfund&category=diy"
          style={{ color: "black" }}
        >
          <Typography className={classes.textUnderline}>
            Mutual Funds
          </Typography>
        </Link>
        <Box display="flex">
          <ChevronRightIcon style={{ height: "1rem" }} />
          <Typography className={classes.subTabtext}>
            Systematic Withdrawal Plan
          </Typography>
        </Box> */}
      </Box>


      <Box px={{ xs: 1, md: 17 }} mt={2}>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignContent={'center'}
          // px={17}
          mb={2}
          className={classes.backButton}
        >
          <Link to="/myportfolio" style={{display: 'flex', alignItems:' center', marginRight: '10px'}}>
            <img src="/images/backIcon.svg" height="20px" width="20px" alt="back" />
          </Link>
          <Typography className={classes.title}>

            STP ( Systematic Transfer Plan)
          </Typography>
        </Box>
        <Typography className={classes.textgrey}>
          Tansfer a fixed amount of money every month from a mutual fund
        </Typography>

        <Box mt={4}>
          <Typography className={classes.textgrey}>
            SELECT THE FUND YOU WANT TO START STP FOR
          </Typography>

          {/* search */}
          <Paper className={classes.searchBar} variant="outlined">
            <SearchIcon />
            <InputBase
              className={classes.input}
              placeholder="Search Funds"
              inputProps={{ "aria-label": "Search for Mutual Funds" }}
              onChange={handle_search}
            />
          </Paper>

          {/* list */}

          {!loading ? (
            <InfiniteScroll
              dataLength={filteredFund.length}
              next={get_fund_list}
              hasMore={hasMore}
              loader={<LoaderComp />}
              endMessage={
                <Typography className={classes.text}>
                  No result to display
                </Typography>
              }
            >
              {filteredFund?.map((fund, i) => {
                return (
                  <StpCard
                    key={i}
                    fundToData={fund}
                    schemeData={schemeData}
                    fundFromData={fundData}
                    stpData={stpData}
                  />
                );
              })}
            </InfiniteScroll>
          ) : (
            <Box mt={3} display="flex" justifyContent="center">
              <ReactLoading
                type="spinningBubbles"
                color="#0E76BC"
                height={"4%"}
                width={"4%"}
              />
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default Index;
