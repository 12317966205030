// SecondSection.js
import React, { useEffect, useState } from 'react';
import { Box, Container, Typography, Grid, Button, makeStyles } from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import { namespace } from "../environment";
import { useSelector } from "react-redux";
import CountUp from 'react-countup';


function FifthSection({ offset, handleShowModal }) {
  const [refreshToken, setRefreshToken] = useState("");
  useEffect(() => {
    setRefreshToken(localStorage.getItem("refresh_token"));
  }, [localStorage]);
  // console.log(refreshToken);
  const useStyles = makeStyles((theme) => ({
    root: {
      // backgroundImage: "url('./images/introBG.svg')",
      background: "#F7F7F9",
      padding: "50px 0rem 55px 0rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      flexDirection: "column",

    },
    headingOne: {
      fontSize: "48px",
      fontWeight: 600,
      color: "#0E76BC",
      marginBottom: "1.2rem",
      textAlign: "center",
    },
    headingOneColor: {
      color: "#0E76BC",
    },
    headingTwo: {
      fontSize: "16px",
      fontWeight: 400,
      textAlign: "start",
      color: "#FFFFFF",
      padding: "0px 10px 20px 0px",
      opacity: 0.8,
    },
    headingThree: {
      fontFamily: 'Roboto',
      fontSize: "20px",
      fontWeight: 400,
      textAlign: "start",
      color: "#E6F1F8",
      padding: "0px 0px 5px 0px",
      opacity: 0.8,
    },
    button: {
      padding: "1rem 4rem",
      background: "#0E76BC",
      color: "#FFFFFF",
      borderRadius: "5px",
      border: "none",
      margin: "0 1.8rem 0 0",
      cursor: "pointer",
      fontSize: "18px",
      fontWeight: 600,
    },

    boxShadow: {
      background: '#0E76BC',
      boxShadow: ' 0px 4px 4px 0px #00000040',
      minWidth: '110px',
      borderRadius: '12px',
    },
    boxShadowNumber: {
      fontFamily: "Poppins",
      fontSize: '20px',
      fontWeight: 400,
      textAlign: 'center',
      color: "#000000",
    },
    // boxShadowData: {
    //   fontFamily: "Poppins",
    //   fontSize: '14px',
    //   fontWeight: 700,
    //   textAlign: 'center',
    //   color: "#0E76BC",
    // },
    boxShadowSubHeadingOne: {
      fontFamily: "Poppins",
      fontSize: '16px',
      fontWeight: 500,
      textAlign: 'center',
      color: "#FFFFFF",
      padding: '6px 0rem 6px 0rem',
      marginTop: '-6px'
    },
    boxShadowSubBox: {
      background: '#042338',
      padding: ' 20px 16px 20px 16px',
      gap: '10px',
    },
    boxShadowSubBoxStartData: {
      fontFamily: "Poppins",
      fontSize: '14px',
      fontWeight: 400,
      textAlign: 'start',
      color: "#FFFFFF",
    },
    boxShadowSubBoxEndData: {
      fontFamily: "Poppins",
      fontSize: '18px',
      fontWeight: 600,
      textAlign: 'center',
      color: "#9EC8E4",
    },
    boxShadowSubBoxButton: {
      fontFamily: "Poppins",
      fontSize: '18px',
      fontWeight: 600,
      textAlign: 'center',
      color: "#0E76BC",
      boxShadow: "0px 4px 4px 0px #00000040",
      backgroundColor: "#FFFFFF",
      width: '100%',
      // margin: ' 20px 16px 20px 16px',
      "&:hover": {
        backgroundColor: "#0E76BC",
        color: "#FFFFFF",
      }
    },
    floatingImage: {
      bottom: '60px',
    },
    "@media (max-width: 900px)": {
      root: {
        padding: "8rem 0rem 1rem 0rem",
      },
      headingOne: {
        fontSize: "1.6rem",
      },
      headingTwo: {
        fontSize: "12px",
      },
      floatingImage: {
        bottom: '140px',
      }
    },
  }));
  const classes = useStyles();
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem("user_token");

  return (
    <div className={classes.root} height="100%">
      <Box width={'100%'} style={{ height: '0px', display: 'flex', justifyContent: 'end', }}>
        <img src="/images/floatingFiveOne.svg" alt="Section 2" width="153.43px" height='100px' className={`img-fluid ${classes.floatingImage}`} style={{ position: 'relative', left: '0px' }} />
      </Box>
      <Container maxWidth="lg">
        {/* <Box width={'100%'} style={{ height: '0px', display: 'flex', justifyContent: 'end',  }}>
          <img src="/images/floatingFiveOne.svg" alt="Section 2" width="153.43px" height='100px' className='img-fluid' style={{ position: 'relative', bottom: '60px', left: '100px' }} />
        </Box> */}
        <Box display='flex' justifyContent={'center'} >
          <Box maxWidth={'500px'}>

            <Typography variant="h4" component="div" gutterBottom className={classes.headingOne} >
              Efficient Investment Shaped for you
            </Typography>
            <img src="/images/StaticWebDesignIcon.svg" alt="Section 2" width="100%" height='30px' className='img-fluid' />

          </Box>
        </Box>

        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={4} style={{ paddingTop: '40px' }}>
            <Box className={classes.boxShadow} sx={{ textAlign: 'center' }} >
              <Box padding={'31px 27px'}>
                <img src="/images/Frame 427320539.svg" alt="Section 2" width="100%" height='182px' className='img-fluid' style={{ marginLeft: '-5px' }} />
                <Typography variant="h4" component="div" className={classes.boxShadowSubHeadingOne} style={{ backgroundColor: '#76BC0E' }} >
                  Goal Based Investment
                </Typography>
                <Box display={'flex'} alignItems={'center'} className={classes.boxShadowSubBox}>
                  <img src="/images/image 7.svg" alt="Section 2" width="auto" height='40px' className='img-fluid' />
                  <Typography variant="h4" component="div" className={classes.boxShadowSubBoxStartData} >
                    Bajaj Finserv Mutual Funds
                  </Typography>
                  <Typography variant="h4" component="div" className={classes.boxShadowSubBoxEndData}  >
                    ₹15,000
                  </Typography>
                </Box>
                <Box display={'flex'} alignItems={'center'} className={classes.boxShadowSubBox} borderTop={'1px solid #fff'}>
                  <img src="/images/image 7.svg" alt="Section 2" width="auto" height='40px' className='img-fluid' />
                  <Typography variant="h4" component="div" className={classes.boxShadowSubBoxStartData} >
                    Bajaj Finserv Mutual Funds
                  </Typography>
                  <Typography variant="h4" component="div" className={classes.boxShadowSubBoxEndData}  >
                    ₹15,000
                  </Typography>
                </Box>
              </Box>
              <Box padding={'0px  27px 31px 27px'} >
                <Button className={classes.boxShadowSubBoxButton}
                  onClick={() => {
                    navigate(`${namespace}/user`);
                  }}
                >
                  Invest 50,000
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} style={{ paddingTop: '40px' }}>
            <Box className={classes.boxShadow} sx={{ textAlign: 'center' }} >
              <Box padding={'31px 27px'}>
                <img src="/images/Frame 427320539 (0).svg" alt="Section 2" width="100%" height='182px' className='img-fluid' style={{ marginLeft: '-5px' }} />
                <Typography variant="h4" component="div" className={classes.boxShadowSubHeadingOne} style={{ backgroundColor: '#BC0E76' }} >
                  Long Term Portfolio
                </Typography>
                <Box display={'flex'} alignItems={'center'} className={classes.boxShadowSubBox}>
                  <img src="/images/image 7 (1).svg" alt="Section 2" width="auto" height='40px' className='img-fluid' />
                  <Typography variant="h4" component="div" className={classes.boxShadowSubBoxStartData} >
                    Franklin Templeton  Mutual Funds
                  </Typography>
                  <Typography variant="h4" component="div" className={classes.boxShadowSubBoxEndData}  >
                    ₹15,000
                  </Typography>
                </Box>
                <Box display={'flex'} alignItems={'center'} className={classes.boxShadowSubBox} borderTop={'1px solid #fff'}>
                  <img src="/images/image 7 (1).svg" alt="Section 2" width="auto" height='40px' className='img-fluid' />
                  <Typography variant="h4" component="div" className={classes.boxShadowSubBoxStartData} >
                    Franklin Templeton  Mutual Funds
                  </Typography>
                  <Typography variant="h4" component="div" className={classes.boxShadowSubBoxEndData}  >
                    ₹15,000
                  </Typography>
                </Box>
              </Box>
              <Box padding={'0px  27px 31px 27px'} >
                <Button className={classes.boxShadowSubBoxButton}
                  onClick={() => {
                    navigate(`${namespace}/user`);
                  }}
                >
                  Invest 30,000
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} style={{ paddingTop: '40px' }}>
            <Box className={classes.boxShadow} sx={{ textAlign: 'center' }} >
              <Box padding={'31px 27px'}>
                <img src="/images/Frame 427320539 (0).svg" alt="Section 2" width="100%" height='182px' className='img-fluid' style={{ marginLeft: '-2px' }} />
                <Typography variant="h4" component="div" className={classes.boxShadowSubHeadingOne} style={{ backgroundColor: '#BC540E' }} >
                  Wealth Wellness
                </Typography>
                <Box display={'flex'} alignItems={'center'} className={classes.boxShadowSubBox}>
                  <img src="/images/image 7 (0).svg" alt="Section 2" width="auto" height='40px' className='img-fluid' />
                  <Typography variant="h4" component="div" className={classes.boxShadowSubBoxStartData} >
                    Aditya Birla Sun Life Mutual Funds
                  </Typography>
                  <Typography variant="h4" component="div" className={classes.boxShadowSubBoxEndData}  >
                    ₹8,000
                  </Typography>
                </Box>
                <Box display={'flex'} alignItems={'center'} className={classes.boxShadowSubBox} borderTop={'1px solid #fff'}>
                  <img src="/images/image 7 (0).svg" alt="Section 2" width="auto" height='40px' className='img-fluid' />
                  <Typography variant="h4" component="div" className={classes.boxShadowSubBoxStartData} >
                    Aditya Birla Sun Life Mutual Funds
                  </Typography>
                  <Typography variant="h4" component="div" className={classes.boxShadowSubBoxEndData}  >
                    ₹8,000
                  </Typography>
                </Box>
              </Box>
              <Box padding={'0px  27px 31px 27px'} >
                <Button className={classes.boxShadowSubBoxButton}
                  onClick={() => {
                    navigate(`${namespace}/user`);
                  }}
                >
                  Take Action
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box style={{ height: '0px', display: 'flex', justifyContent: 'start', }}>
          <img src="/images/floatingFiveTwo.svg" alt="Section 2" width="153.43px" height='100px' className='img-fluid' style={{ position: 'relative', bottom: '60px', right: '165px' }} />
        </Box>
      </Container>
    </div>
  );
}

export default FifthSection;

