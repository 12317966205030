import React, { useEffect, useState } from "react";
import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import {
    useNavigate,
    useLocation,
    useSearchParams,
    Link,
} from "react-router-dom";
import { namespace } from "../../environment";
import Lottie from "react-lottie";
import animationData from '../../lotties/processing.json';
import animationDataConfirm from '../../lotties/Confirmation.json';
import { CircularProgress } from "@mui/material";
import httpInstance from "../../utils/instance";
import { KYC_STATUS } from "../../utils/endpoints";



function EsignDone() {
    const [step, setStep] = React.useState(1);
    const [descriptionData, setDescriptionData] = useState({});

    const [searchParams, setSearchParams] = useSearchParams();

    const steps = searchParams.get("stepcount");

    const stepCount = (step) => {
        setStep(step);
    };

    const setData = (data) => {
        setDescriptionData({ ...descriptionData, ...data });
    };

    const useStyles = makeStyles((theme) => ({
        // rotatedOne: {
        //   // width: '886.68px',
        //   width: '900px',
        //   height: '1167px',
        //   // bottom: '-100px',
        //   left: '-400px',
        //   top: '-115px',
        //   borderRadius: '66px',
        //   position: 'fixed',
        //   // angle: '154.95 deg',

        //   // barderRaduis: '106.11 deg',
        //   background: ' #0E76BC',

        //   // background: 'linear-gradient(to bottom, #1a237e, #283593)', /* Gradient colors */
        //   transform: 'rotate(20deg)',
        //   // zIndex: '-1',
        //   // angle: 106.11 deg
        //   zIndex: '-1',
        // },
        // rotatedTwo: {
        //   width: '900px',
        //   height: '1167px',
        //   left: '-350px',
        //   top: '100px',
        //   borderRadius: '66px',
        //   position: 'fixed',
        //   background: '#25AAE1',
        //   // background: 'linear-gradient(to bottom, #1a237e, #283593)', /* Gradient colors */
        //   transform: 'rotate(20deg)',
        //   zIndex: '-2',
        // },
        rotatedOne: {
            // content: ''; /* Create a pseudo-element */
            // position: absolute;
            // top: 0;
            // left: 0;
            // width: 100%;
            // height: 100%;
            // background: url('your-background-image.jpg'); /* Set your background image */

            // width: '886.68px',
            width: '1180px',
            height: '1180px',
            // bottom: '-100px',
            left: '-513px',
            top: '-442px',
            borderRadius: '100%',
            position: 'fixed',
            // angle: '154.95 deg',

            // barderRaduis: '106.11 deg',
            background: ' #25AAE1',
            transition: 'all 1s ease-out',
            // background: 'linear-gradient(to bottom, #1a237e, #283593)', /* Gradient colors */
            // transform: 'rotate(20deg)',
            // zIndex: '-1',
            // angle: 106.11 deg
            zIndex: '-1',
            [theme.breakpoints.up('lg')]: {
                left: '-513px',
                top: '-442px',
            },
            [theme.breakpoints.down('lg')]: {
                left: '-620px', // Adjust based on your requirement
                top: '-442px', // Adjust based on your requirement
            },
            [theme.breakpoints.down('md')]: {
                left: '-750px', // Adjust for smaller screens
                top: '-442px', // Adjust for smaller screens
            },
            [theme.breakpoints.down('sm')]: {
                left: '-850px', // Adjust for smaller screens
                top: '-442px', // Adjust for smaller screens
            },
        },
        rotatedTwo: {
            width: '1180px',
            height: '1180px',
            left: '-470px',
            top: '-442px',
            borderRadius: '50%',
            position: 'fixed',
            background: '#0E76BC',
            transition: 'all 1s ease-out',
            // background: 'linear-gradient(to bottom, #1a237e, #283593)', /* Gradient colors */
            // transform: 'rotate(20deg)',
            zIndex: '-2',

            [theme.breakpoints.up('lg')]: {
                left: '-470px',
                top: '-442px',
            },
            [theme.breakpoints.down('lg')]: {
                left: '-577px', // Adjust based on your requirement
                top: '-442px', // Adjust based on your requirement
            },
            [theme.breakpoints.down('md')]: {
                left: '-720px', // Adjust for smaller screens
                top: '-442px', // Adjust for smaller screens
            },
            [theme.breakpoints.down('sm')]: {
                left: '-820px', // Adjust for smaller screens
                top: '-442px', // Adjust for smaller screens
            },
        },
        section1: {
            // background: "#0E76BC",
            minHeight: "100vh",
            width: "30%",
            boxSizing: "border-box",
            position: "fixed",
            height: "100%",
        },
        sectionTwo: {
            height: "80vh",
            padding: "4rem 0",
            display: "flex",
            //alignItems: "center",
            //justifyContent: "center",
            flexDirection: "column",
            flex: 1,
            width: "70%",
            overflowY: "scroll",
            position: "sticky",
        },
        detailsBox: {
            margin: "0 auto",
        },
        headingOne: {
            // fontSize: "1.6rem",
            fontSize: '40px',
            color: "white",
            fontWeight: 400,
            padding: "0px 2rem",
        },
        headingTwo: {
            // fontSize: "1.3rem",
            fontSize: '32px',
            color: "#fff",
            fontWeight: 400,
            textAlign: "center",
        },
        headingThree: {
            fontSize: "0.9rem",
            color: "#fff",
            fontWeight: 400,
            textAlign: "center",
        },
        paragraph: {
            fontSize: "0.7rem",
            color: "white",
        },
        headingText: {
            fontSize: "0.9rem",
            color: "#000",
            fontWeight: 600,
            textAlign: "center",
        },
        textRedirect: {
            fontSize: "0.7rem",
            color: "#000",
            fontWeight: 400
        },
        primaryButton: {
            padding: "0.8rem 7rem",
            // background: "#0E76BC",
            background: '#0E76BC',
            color: "#fff",
            fontSize: "0.9rem",
            fontWeight: 600,
            borderRadius: "45px",
            border: "none",
            cursor: "pointer",
            width: "100%",
            '&:hover': {
                background: '#2fa7f7', // Change background on hover
            },
            '&:disabled': {
                opacity: '0.5',
            },
        },
        "@media (max-width: 480px)": {
            section1: {
                display: "none",
            },
            rotatedOne: {
                display: "none",
            },
            rotatedTwo: {
                display: "none",
            },
            sideSection: {
                display: "none",
            }
        },
    }));
    const classes = useStyles();

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [kycData, setKycData] = useState('')
    const [kycStatus, setKycStatus] = useState(null)
    const [timer, setTimer] = useState(10); // Initialize timer with 4


    const handleMoveToDashBoard = () => {
        navigate({
            pathname: `${namespace}/dashboard/mutualfund`,
            // search: `?${createSearchParams({ stepcount: "2" })}`,
        });
    }

    const get_kycData = async () => {
        setLoading(true);
        const httpConfig = {
            method: "GET",
            tokenType: "user",
            headers: {
                "Content-Type": "application/json",
            },
        };
        try {
            const response = await httpInstance(`${KYC_STATUS}`, httpConfig);
            setKycData(response?.data?.data)
            setKycStatus(response.data?.data?.fp_kyc_status);
            setLoading(false);
            if (response?.data?.data?.fp_esign_id && response?.data?.data?.fp_esign_status === "successful") {
                const countdown = setInterval(() => {
                    setTimer((prev) => {
                        if (prev <= 1) {
                            clearInterval(countdown); // Clear the interval when timer reaches 0
                            handleMoveToDashBoard(); // Navigate to the dashboard
                            return 0;
                        }
                        return prev - 1; // Decrement the timer
                    });
                }, 1000); // Trigger every second

                // Cleanup interval on component unmount
                return () => clearInterval(countdown);
            }
        } catch (error) {
            setKycStatus("empty");
            setLoading(false);
        }
    };

    useEffect(() => {
        get_kycData();

    }, []);



    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const comfirmOptions = {
        loop: false,
        autoplay: true,
        animationData: animationDataConfirm,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }

    return (
        <React.Fragment>
            <Box display="flex" flexDirection="row" flex={1} height="100%">
                <Box width={'35%'} className={classes.sideSection}>
                    <Box className={classes.rotatedOne}>
                    </Box>
                    <Box className={classes.rotatedTwo}>
                    </Box>
                    <Box className={classes.section1} position={'fixed'} py={4}>
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="space-between"
                            justifyContent="space-around"
                            height="100%"
                            px={2}
                        >
                            <Box
                                display="flex"
                                alignItems="flex-start"
                                justifyContent="center"
                                flexDirection="column"
                                className={classes.detailsBox}
                            >
                                <Box className={classes.headingOne} pl={4} mb={3}>
                                    Investment made simple
                                    {/* <br />  */}
                                </Box>
                            </Box>
                            <img src="/images/pngtree-financial-expert-concept-with-woman-specialist-with-laptop-sit-on-stack.svg" height="318" width="100%" />
                            <Box
                                display="flex"
                                alignItems="flex-start"
                                justifyContent="center"
                                flexDirection="column"
                                className={classes.detailsBox}
                            >
                                {/* <Box className={classes.headingTwo} mt={3} textAlign="center">
                              Invest in Mutual Funds
                              // {/* <span style={{ color: "#6FDDB8" }}>
                              //    Mutual Funds
                              //   </span> 
                            </Box>
                            <Box className={classes.headingThree} mt={2}>
                              MF Wealth is secure and private
                            </Box>
                            <Link
                              to="/terms-conditions"
                              style={{ textDecoration: "none", color: "inherit" }}
                            >
                              <Box className={classes.paragraph} mt={1}>
                                Terms & Conditions *
                              </Box>
                            </Link> */}
                            </Box>
                        </Box>
                    </Box>
                </Box>
                {/* </Box> */}

                <Box className={`${classes.sectionTwo}`} >
                    <Box display={'flex'} justifyContent={'center'}>
                        <Box height={80} width={160} overflow={'hidden'} display={'flex'} justifyContent={'center'}>
                            <img src="/MF 5 6.svg" height={100} width={250} alt="logo" onClick={handleMoveToDashBoard} style={{ cursor: "pointer" }} />
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="row"
                        height="70%"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {/* Logo Container */}
                        <Box display="flex" justifyContent="center" alignItems="center" flexDirection='column'>
                            {/* <Box
                                height={80}
                                width={160}
                                overflow="hidden"
                                display="flex"
                                justifyContent="center"
                            >
                                <img
                                    src="/MF 5 6.svg"
                                    height={100}
                                    width={250}
                                    alt="logo"
                                    style={{ cursor: "pointer" }}
                                />
                            </Box> */}

                            {/* Content Container */}
                            <Box>
                                <Box className={classes.headingText} mb={6} textAlign="center">
                                    {(kycData?.fp_esign_id && kycData?.fp_esign_status === "successful")
                                        ?
                                        "E - Sign successfully done "
                                        :
                                        "Checking for e-sign confirmation"
                                    }
                                </Box>
                                <Lottie
                                    options={kycData ? comfirmOptions : defaultOptions}
                                    height={150}
                                    width={150}
                                />
                            </Box>
                            <Box mt={2}>
                                {kycData &&
                                    <p className={classes.textRedirect}>
                                        {timer > 1
                                            ? `Redirecting to the main page in ${timer - 1} seconds...`
                                            : "Redirecting now..."}
                                    </p>
                                }
                                <button className={classes.primaryButton} onClick={() => {
                                    handleMoveToDashBoard();
                                }} disabled={loading}>
                                    {loading ? (
                                        <CircularProgress size="1rem" color="inherit" />
                                    ) : (
                                        "Contiune"
                                    )}
                                </button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </React.Fragment>
    );
}

export default EsignDone;

