import Contact from "./Contact";
import React, { Fragment, useState } from "react";
import { ThemeProvider, createTheme } from "@material-ui/core";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Container } from "@material-ui/core";
import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import Search from "./UI/Search";
import { Line } from "recharts";
import { Link, useNavigate } from "react-router-dom";
import { namespace } from "../environment";
import { Link as ScrollLink } from 'react-scroll';
import CloseIcon from '@mui/icons-material/Close';

function Header({ handleShowModal, }) {
  // const { handleShowModal } = props;

  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!open);
  };

  const customTheme = createTheme({
    typography: {
      fontFamily: ["Poppins"].join(","),
    },
  });

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    header: {
      top: 0,
      position: "sticky",
      zIndex: 100,
      background: "white",
      height: "6rem",
    },
    connectButton: {
      border: "1px solid #0E76BC",
      color: "#0E76BC",
      borderRadius: "30px",
      padding: "0.6rem 1.8rem",
      fontWeight: 500,
      fontSize: "14px",
      textDecoration: "none",
    },

    links: {
      fontSize: "14px",
      fontWeight: "500",
      fontFamily: "Poppins",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&:hover": {
        color: "#0E76BC",
      }
    },
    menu: {
      top: "2rem!important",
    },
    mobileResponsive: {
      display: "none",
    },

    dropdown: {
      position: "relative",
      display: "inline-block",
    },

    ".dropdown:hover .dropdown-content": {
      display: "block",
    },
    dropdownContent: {
      display: "block",
      position: "absolute",
      backgroundColor: "#fff",
      minWidth: "100px",
      boxShadow: "0px 4px 4px 0px rgba(0,0,0,0.1)",
      padding: "12px",
      zIndex: 1,
      fontFamily: "Poppins",
      marginTop: "0.5rem",
    },
    subLinks: {
      borderBottom: "1px solid #333",
      padding: "0.6rem",
      "&:nth-child(2)": {
        border: "none",
      },
    },
    "@media (max-width: 900px)": {
      mobileResponsive: {
        display: "block",
        position: "fixed",
        height: "70px",
        zIndex: "50!important",
        background: "#fff",
        width: "100%",
        boxShadow: "0px 15px 10px -15px #e5e5e5",
      },
      header: {
        display: "none",
      },
      headerContainer: {
        height: "100vh",
        width: "100%",
        position: "fixed",
        zIndex: 100,
        background: "#fff!important",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        margin: 0,
      },
    },

    "@media (max-width: 280px)": {
      mobileResponsive: {
        width: "85%",
      },
    },
    circularIcon: {
      padding: "5px",
    },

    dialog: {
      borderRadius: "32px",
    },
  }));

  const classes = useStyles();
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem("user_token");

  const [anchorEl, setAnchorEl] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(!anchorEl);
  };

  const handleClose = () => {
    setAnchorEl(false);
  };

  const [showResponsive, setshowResponsive] = useState(false);

  const toggleResponsive = () => {
    // const URL_origin = window.location
    // console.log(URL_origin)
    // // if()
    setshowResponsive(!showResponsive);
  };

  const URL_path = window.location?.pathname
  // console.log(URL_path)
  const URL_origin = window.location.origin


  // const handleJump = (data) => {
  //   const URL = window.location
  //   const URL_origin = window.location.origin
  //   const URL_path = URL?.pathname
  //   if (URL_path) window.location.href = `${URL_origin}#${data}`;

  // }

  const ResponsiveHeader = () => {
    return (
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        className={classes.mobileResponsive}
      >
        <Box py={1} px={3}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            py={2}
            width="100%"
          >
            {/* <img src="./images/MF 5 5.svg" height="22px" width="22px" /> */}
            <Box height="25px" width="25px" overflow={'hidden'} display="flex" justifyContent="center" alignItems="center">
              <img src="./MF 5 6.svg" height={40} width={'auto'} alt="lpgp" />

            </Box>
            {/* <Search /> */}
            {!showResponsive ?
              <img
                src="./images/hamburger.svg"
                height="22px"
                width="22px"
                onClick={() => toggleResponsive(true)}
                alt="burger"
                style={{ cursor: "pointer",  }} 
              />
              :
              <CloseIcon
                onClick={() => toggleResponsive(true)}
                style={{ cursor: "pointer",  }} />
            }
          </Box>
        </Box>

        {showResponsive ? (
          <React.Fragment>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              className={classes.headerContainer}
            >
              {/* <a
                href="/"
                style={{ textDecoration: "none", color: "#000" }}
              > */}
              {URL_path === '/' ?
                <>
                  <ScrollLink to="home" smooth duration={500} style={{ textDecoration: "none", color: "#000", cursor: "pointer" }} onClick={() => toggleResponsive(true)}>

                    <Box fontWeight="fontWeightBold" fontSize={18} my={2} >
                      Home
                    </Box>
                    {/* </a> */}
                  </ScrollLink>


                  {/* <a
                href="/"
                style={{ textDecoration: "none", color: "#000" }}
              > */}
                  <ScrollLink to="product" smooth duration={500} style={{ textDecoration: "none", color: "#000", cursor: "pointer" }} onClick={() => toggleResponsive(true)}>

                    <Box fontWeight="fontWeightBold" fontSize={18} my={2} >
                      Product
                    </Box>
                    {/* </a> */}
                  </ScrollLink>
                  {/* <a
                href="/"
                style={{ textDecoration: "none", color: "#000" }}
              > */}
                  <ScrollLink to="about" smooth duration={500} offset={-50} style={{ textDecoration: "none", color: "#000", cursor: "pointer" }} onClick={() => toggleResponsive(true)}>

                    <Box fontWeight="fontWeightBold" fontSize={18} my={2}>
                      About
                    </Box>
                    {/* </a> */}
                  </ScrollLink>

                  {/* <a
                href="/"
                style={{ textDecoration: "none", color: "#000" }}
              > */}
                  <ScrollLink to="insight" smooth duration={500} offset={-50} style={{ textDecoration: "none", color: "#000", cursor: "pointer" }} onClick={() => toggleResponsive(true)}>

                    <Box fontWeight="fontWeightBold" fontSize={18} my={2}>
                      Insight
                    </Box>
                    {/* </a> */}
                  </ScrollLink>

                  {/* <a
                href="/"
                style={{ textDecoration: "none", color: "#000" }}
              > */}
                  <ScrollLink to="services" smooth duration={500} offset={-50} style={{ textDecoration: "none", color: "#000", cursor: "pointer" }} onClick={() => toggleResponsive(true)}>

                    <Box fontWeight="fontWeightBold" fontSize={18} my={2}>
                      Services
                    </Box>
                    {/* </a> */}
                  </ScrollLink>
                </>
                :
                <Box fontWeight="fontWeightBold" fontSize={18} my={2}
                  onClick={() => {
                    if (URL_path !== '/') window.location.href = `${URL_origin}/`;
                  }}
                >
                  Home
                </Box>

              }
              <Box display="flex" justifyContent="center" mt={2}>
                <Button
                  className={classes.connectButton}
                  // onClick={handleShowModal}
                  onClick={() => {
                    navigate(`${namespace}/user`);
                  }}
                >
                  Join Now

                </Button>
              </Box>
            </Box>
          </React.Fragment>
        ) : (
          ""
        )}
      </Box>
    );
  };

  const DesktopHeader = () => {
    return (
      <Box className={classes.header}>
        <Container maxWidth="lg">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-around"
            alignItems="center"
            className={classes.headerContainer}
          >
            <Grid item lg={3}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="start"
                alignItems="center"
              >
                <Link to={`/${namespace}`}>
                  {" "}
                  <img src="./MF 5 6.svg" height={102} width={'auto'} />
                </Link>
              </Box>
            </Grid>
            <Grid item lg={4}>
              {/* <Search /> */}
            </Grid>
            <Grid item lg={3}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent={`${URL_path ? 'end' : 'center'} `}
                className={classes.linksContainer}
              >
                {/* <Box className={classes.dropdown}>
                  <Typography className={classes.links} onClick={handleClick}>
                    Our Services
                    <KeyboardArrowDownIcon />
                  </Typography>

                  {anchorEl ? (
                    <Box className={classes.dropdownContent}>
                      <a
                        style={{ textDecoration: "none", color: "#000" }}
                        href="https://www.neosurge.money/USStocks"
                      >
                        {" "}
                        <Box className={classes.subLinks}>US Stocks</Box>
                      </a>
                      <a
                        style={{
                          textDecoration: "none",
                          color: "#000",
                          border: "none",
                        }}
                        href="https://www.neosurge.money/Equity"
                      >
                        <Box
                          className={classes.subLinks}
                          style={{ border: "none" }}
                        >
                          Equity
                        </Box>
                      </a>
                    </Box>
                  ) : (
                    ""
                  )}
                </Box> */}


                {URL_path === '/' ?
                  <>
                    <Box mx={2}>
                      {/* <a
                    href=""
                    style={{ textDecoration: "none", color: "#000" }}
                  > */}
                      <ScrollLink to="home" smooth duration={500} offset={-50} style={{ textDecoration: "none", color: "#000" }}
                      // onClick={
                      //   () => handleJump('home')
                      // }
                      >

                        <Typography className={classes.links} >Home</Typography>
                        {/* </a> */}
                      </ScrollLink>
                    </Box>
                    <Box mx={2}>
                      {/* <a
                    href=""
                    style={{ textDecoration: "none", color: "#000" }}
                  > */}
                      <ScrollLink to="product" smooth duration={500} offset={-50} style={{ textDecoration: "none", color: "#000" }}
                      // onClick={
                      //   () => handleJump('product')
                      // }
                      >

                        <Typography className={classes.links}>Product</Typography>
                        {/* </a> */}
                      </ScrollLink>
                    </Box>
                    <Box mx={2}>
                      {/* <a
                    href=""
                    style={{ textDecoration: "none", color: "#000" }}
                  > */}
                      <ScrollLink to="about" smooth duration={500} offset={-50} style={{ textDecoration: "none", color: "#000" }}
                      // onClick={
                      //   () => handleJump('about')
                      // }
                      >

                        <Typography className={classes.links}>About</Typography>
                        {/* </a> */}
                      </ScrollLink>

                    </Box>
                    <Box mx={2}>
                      {/* <a
                    href=""
                    style={{ textDecoration: "none", color: "#000" }}
                  > */}
                      <ScrollLink to="insight" smooth duration={500} offset={-50} style={{ textDecoration: "none", color: "#000" }}
                      // onClick={
                      //   () => handleJump('insight')
                      // }
                      >
                        <Typography className={classes.links}>Insight</Typography>
                        {/* </a> */}
                      </ScrollLink>

                    </Box>
                    <Box mx={2} >
                      {/* <a
                    href=""
                    style={{ textDecoration: "none", color: "#000" }}
                  > */}
                      <ScrollLink to="services" smooth duration={500} offset={-50} style={{ textDecoration: "none", color: "#000" }}
                      // onClick={
                      //   () => handleJump('services')
                      // }
                      >
                        <Typography className={classes.links}>Services</Typography>
                        {/* </a> */}
                      </ScrollLink>

                    </Box>

                    {/* <a
                  href=""
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  <Typography className={classes.links}>Blogs</Typography>
                </a> */}
                  </>
                  :
                  <Box mx={2}
                    onClick={() => {
                      if (URL_path !== '/') window.location.href = `${URL_origin}/`;
                    }}
                  >
                    <Typography className={classes.links} >Home</Typography>
                  </Box>
                }
              </Box>

            </Grid>

            <Grid item lg={2}>
              <Box display="flex" justifyContent="end">
                <Button
                  className={classes.connectButton}
                  // onClick={handleShowModal}
                  onClick={() => {
                    navigate(`${namespace}/user`);
                  }}
                >
                  {isLoggedIn ? "Fund Page" : "Join Now"}
                </Button>
              </Box>
            </Grid>
          </Box>
        </Container>
      </Box>
    );
  };

  return (
    <Fragment>
      <ThemeProvider theme={customTheme}>
        <DesktopHeader />
        <ResponsiveHeader />
      </ThemeProvider>
    </Fragment>
  );
}

export default Header;
