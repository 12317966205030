import logo from "./logo.svg";
import "./App.css";
import Routes from "./AllRoutes";
import { Provider } from "react-redux";
import { useStore } from "./redux/store/confugureStore";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import { ToastContainer } from "react-toastify";
import { useStyles } from "./components/signup/styles";
import { useEffect } from "react";
import { detectDevTools } from "./utils/devDetector/devToolsDetection";

const App = () => {
  const store = useStore();
  if (process.env.REACT_APP_ENVIRONMENT === "production") {
    disableReactDevTools();
  }

  const classes = useStyles();

  // const logoutUser = () => {
  //   alert("Unauthorized access detected. Logging out.");
  //   // Clear user session (e.g., remove tokens, cookies)
  //   localStorage.clear(); // Ensure clear is invoked as a function
  //   // Redirect to login page
  //   window.location.href = "/";
  // };

  // useEffect(() => {
  //   console.log(process.env.REACT_APP_ENVIRONMENT)
  //   if (process.env.REACT_APP_ENVIRONMENT === "production") {
  //     // detectDevTools(logoutUser);
  //   }
  // }, []);

  return (
    <Provider store={store}>
      <Routes />
      <ToastContainer bodyClassName={classes.toastMessage} />
    </Provider>
  );
};

export default App;
