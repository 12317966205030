//token check
export const IS_EXPIRED = `/api/auth/user`;

//user login
export const EMAIL_LOGIN = `api/v1/usermgmt/user/login`;
// export const GOOGLE_LOGIN = `/api/v1/usermgmt/user/social_login`;\
export const GOOGLE_LOGIN = `/api/auth/verify_google`;
export const VERIFY_EMAIL_OTP = `api/v1/usermgmt/user/email_verify`;

// mobile otp generator for login and create account 
export const ADD_MOBILE_NUMBER = `/api/auth/generate_otp`;
export const VERIFY_MOBILE_OTP_LOGIN  = `/api/auth/verify_otp`;

// create account 
export const CREATE_ACCOUNT = `/api/users`;

// export const VERIFY_MOBILE_OTP = `api/v1/usermgmt/otp/verify_otp`;
export const VERIFY_MOBILE_OTP =`/api/auth/verifymobile`;

export const GET_USER_ONBOARDING_DETAILS = `/api/onboarding/get_onboarding_details`
export const GET_USER_ONBOARDING_STATUS = `/api/onboarding/get_status`

//Create invester and and investment account 
export const POST_CREATING_INVESTER = `/api/onboarding/create_investor`;
export const POST_CREATE_INVESTMENT_ACCOUNT = `/api/onboarding/create_investment_account`;


//GET USER DATA
// export const GET_USER = `api/v1/usermgmt/user/get_user`;
export const GET_USER = `/api/auth/user`;
export const MPIN_LOGIN = `api/v1/usermgmt/user/mpin_login`;
export const SETUP_MPIN = `api/v1/usermgmt/user/set_mpin`;
export const FORGOT_MPIN = `api/v1/usermgmt/user/forgot_mpin`;
export const VERIFY_FORGOT_MPIN = `api/v1/usermgmt/user/verify_forgot_mpin_otp`;
export const UPLOAD_PROFILE_PHOTO = `api/v1/usermgmt/ProfilePhoto/addOrUpdateProfilePhoto`;
export const PROFILE_DATA_EDIT = `api/onboarding/update_investor`;

//GET NOTIFICATIONS
// export const GET_NOTIFICATION_DATA = `api/v1/appnotificationservice/notification/get_app_notification_by_user_id`;
export const GET_NOTIFICATION_DATA = `api/notifications/all`;
export const READ_NOTIFICATIONS = `api/v1/appnotificationservice/notification/update_isread`;
export const GET_UNREAD_COUNT = `api/v1/appnotificationservice/notification/get_notification_count`;
export const ALL_READ = `api/v1/appnotificationservice/notification/read_all_notification`;
//pan verification

// export const VERIFY_PAN_STATUS = `api/v1/kycservice/kycStatus/get_pan_status`;
export const VERIFY_PAN_STATUS = `/api/onboarding/check_kyc`;
// export const CONFIRM_PAN = `api/v1/kycservice/kycStatus/confirm_pan`;
export const CONFIRM_PAN =  `/api/onboarding/confirm_pan_details`;
export const GET_OCCUPATION_LIST = `api/v1/usermgmt/getAll/getAllOccupationTypes`;
// export const ADD_PERSONAL_DETAILS = `api/v1/usermgmt/personalDetails/updatePersonalDetails`;
export const ADD_PERSONAL_DETAILS = `/api/onboarding/add_personal_details`;
export const GET_UPLOADED_PHOTO = `/api/onboarding/get_photo`;
export const UPLOAD_PHOTO = `/api/onboarding/add_photo`;
export const POST_GEO_TAG = `/api/onboarding/add_geo_tag`;
// export const UPLOAD_PAN = `api/v1/kycservice/doc_uploads/pan_and_profile_upload`;
export const GET_UPLOADED_PAN = `/api/proofs/proof_of_identity`;
export const UPLOAD_PAN = `/api/proofs`;
export const IDENTITY_PROOF_DOCUMENT = `api/onboarding/identity_documents`;
// export const GET_PERSONAL_DETAILS = `api/v1/usermgmt/personalDetails/getPersonalDetailsByUserId`;
export const GET_PERSONAL_DETAILS = `/api/onboarding/get_personal_details`;
// export const GET_KYC_DATA = `api/v1/kycservice/doc_uploads/get_kyc_data`;
export const GET_KYC_DATA = `/api/onboarding/confirm_pan_details`;

//fatca details //Add Occupaiton details
export const GET_ALL_WEALTH_SOURCE = `api/v1/kycservice/getAll/get_all_source_of_wealth`;
// export const GET_ALL_WEALTH_SOURCE = `/api/onboarding/get_occupation_details`;
export const GET_ALL_COUNTRIES = `api/v1/usermgmt/getAll/getAllCountries`;
// export const ADD_FATCA_DETAILS = `api/v1/kycservice/user_fatca_details/add_or_update`;
export const ADD_FATCA_DETAILS = `/api/onboarding/add_occupation_details`;
// export const GET_FATCA_DETAILS = `api/v1/kycservice/user_fatca_details/get`;
export const GET_FATCA_DETAILS = `/api/onboarding/get_occupation_details`;

//Address
export const GET_ALL_STATES = `api/v1/usermgmt/getAll/getAllStates`;
export const GET_ADDRESS = `/api/address`
// export const ADD_ADDRESS = `api/v1/usermgmt/addressDetails/addOrUpdateAddressDetails`;
export const ADD_ADDRESS =`/api/address`;
export const GET_PINCODE_BASED_API = `/api/address/pincode?pincode=`;

//nominees
export const GET_ALL_RELATIONSHIP = `api/v1/usermgmt/getAll/getAllRelationTypes`;
// export const ADD_NOMINEES = `api/v1/usermgmt/nominee_details/add_or_update`;
export const ADD_NOMINEES = `/api/nominee`;
// export const GET_NOMINEES = `api/v1/usermgmt/nominee_details/get_all_nominee_by_userid`;
export const GET_NOMINEES = `/api/nominee`;
export const DELETE_NOMINEE = `api/v1/usermgmt/nominee_details/delete_nominee_by_id`;

//cancel check
export const UPLOAD_CANCEL_CHECK = `api/v1/usermgmt/bankDetails/add_or_update_cancel_cheque`;

//bank details
// export const GET_DETAILS_BY_IFSC = `api/v1/usermgmt/bankDetails/get_bankdetails_by_ifsc?ifscCode=`;
export const GET_DETAILS_BY_IFSC = `/api/bank/ifsc`;
export const GET_ACCOUNT_TYPES = `api/v1/usermgmt/getAll/getAllAccountTypes`;
// export const ADD_BANK_DETAILS = `api/v1/usermgmt/bankDetails/addOrUpdateBankDetails`;
export const ADD_BANK_DETAILS = `/api/bank`;
// export const GET_BANK_DETAILS = `api/v1/usermgmt/bankDetails/getBankDetailsByUserId`;
export const GET_BANK_DETAILS = `/api/bank`;
export const OCR_READING = `api/v1/kycservice/pichain/cancelChequeocrReading`;
//not required in this place 
export const ADD_PROFILE_BANK = `api/v1/usermgmt/bankDetails/add_or_update_profile_bank_details`;
// add additional bank details for the profile in the header section 
export const ADD_ADDITIONAL_BANK=`/api/bank/add_additional_bank`
//bank account verification
export const VERIFY_BANK_ACCOUNT = `api/v1/usermgmt/bankDetails/bankAccountVerifications`;

//Get all the banks
export const GET_ALL_BANK = `/api/bank/all`;




//signature upload
// export const SIGNATURE_UPLOAD = `api/v1/kycservice/doc_uploads/sign_upload`;
export const GET_SIGNATURE_UPLOAD  = '/api/onboarding/get_signature';
export const SIGNATURE_UPLOAD = `/api/onboarding/add_signature`;


// investor account api
export const CREAT_INVESTER_ACCOUNT = `api/v1/kycservice/investor_account/create`;
export const CREAT_MF_ACCOUNT = `api/v1/kycservice/mf_investment_account/create`;

//kyc status
// export const KYC_STATUS = `api/v1/kycservice/kycStatus/kyc_step_flag`;
export const KYC_STATUS = `/api/onboarding/get_status`;
export const REJECT_KYC = `api/v1/kycservice/kycStatus/retake_kyc`;
export const SET_KYC_POPUP = `api/v1/usermgmt/user/set_kyc_popup`;

//UPLOAD ADDRESS PROOF
export const GET_ADDRESS_PROOF = `/api/proofs/proof_of_address`;
// export const UPLOAD_ADDRESS_PROOF = `api/v1/kycservice/doc_uploads/kyc_address_proof_upload`;
export const UPLOAD_ADDRESS_PROOF = `/api/proofs`;

//create KYC
export const CREAT_KYC = `api/v1/kycservice/kyc_create_request/submit`;

//upload video
// export const UPLOAD_VIDEO = `api/v1/kycservice/doc_uploads/web_video_upload`;
export const GET_UPLOADED_VIDEO = `/api/onboarding/get_video`
export const UPLOAD_VIDEO = `/api/onboarding/add_video`;
// export const GET_VIDEO_OTP = `api/v1/kycservice/getAll/get_video_otp`;
export const GET_VIDEO_OTP = `/api/onboarding/get_video_otp`;

//adhar verification
export const GET_ADHAR_VERIFICATION  = `/api/onboarding/get_aadhaar_number`;
// export const ADHAR_VERIFICATION = `api/v1/kycservice/doc_uploads/upload_aadhar_number`;
export const ADHAR_VERIFICATION = `/api/onboarding/add_aadhaar_number`;

//eSign submit
// export const ESIGN_SUBMIT = `api/v1/kycservice/kyc_create_request/submit_esign`;
export const ESIGN_SUBMIT =  `/api/onboarding/initiate_esign`;


// settings 
export const SETTINGS_SUBMIT =  `/api/users/update_settings`;





//----------------------------digital gold-------------------------------------------------------------//
const baseurl = `api/v1/goldservice/`;
export const GET_ALL_GRAPH_VALUE = `${baseurl}graph/historic_data`;
export const GET_FILTERED_GRAPH_VALUES = `${baseurl}graph/historic_data_from_to`;

//create gold user

export const CREATE_GOLD_USER = `${baseurl}user/create_commodity_user`;
export const ACCEPT_TERMS = `${baseurl}user/accept_terms_conditions`;
export const ADD_GOLD_BANK = `${baseurl}bank_details/add_bank`;
export const CREAT_GOLD_KYC = `${baseurl}kyc/update_kyc`;
export const UPDATE_PROFILE_KYC = `${baseurl}kyc/update_profile_kyc`;

export const GET_GOLD_SILVER_RATE = `${baseurl}rate/get_current_rate`;

export const CREATE_CHECKOUT = `${baseurl}rate/checkout_rate`;
export const CREATE_ORDER = `${baseurl}razor_pay/create_order`;
export const POST_PAYMENT = `${baseurl}razor_pay/post_payment`;
export const GET_GOLD_USER = `${baseurl}user/get_user_data`;
export const SELL_GOLD_SILVER = `${baseurl}buy_sell/sell_request`;

//product
export const GET_PRODUCT = `${baseurl}delivery/get_all_product_list_by_metal_type`;
export const GET_ALL_PRODUCT = `${baseurl}delivery/get_all_product_list`;

export const GET_USER_ADDRESS = `${baseurl}address_details/get_by_user`;
export const DELIVERY_CHECKOUT = `${baseurl}delivery/checkout_response`;

//get state
export const GET_ALL_STATE = `${baseurl}states/get_all_states`;
export const GET_CITY_BY_STATEID = `${baseurl}states/city_list`;

//add address
export const ADD_DELIVERY_ADDRESS = `${baseurl}address_details/add`;
export const DELETE_ADDRESS = `${baseurl}address_details/delete_by_id`;

//get transactions
export const GET_BUY_LIST = `${baseurl}razor_pay/get_buy_list`;
export const GET_SELL_LIST = `${baseurl}razor_pay/get_sold_list`;
export const GET_DELIVERY_LIST = `${baseurl}razor_pay/get_delivery_list`;

export const GET_BUYLIST_STATUS = `${baseurl}razor_pay/get_buy_list_by_status`;
export const GET_SELL_LIST_STATUS = `${baseurl}razor_pay/get_sold_list_by_status`;

export const GET_TRANS_LIST_BYID = `${baseurl}razor_pay/get_list_by_type_and_id`;
export const GET_DELIVERY_TRANS = `${baseurl}razor_pay/get_delivery_list_by_status`;
export const GET_TRANS_BY_STATUS = `${baseurl}razor_pay/get_transaction_list_by_status`;

//------------------------mutual fund------------------------------------------------------------------//
export const KYC_DATA = `api/v1/kycservice/doc_uploads/get_kyc_data`;

//search all funds
// export const SEARCH_FUNDS = `api/v1/mutualfund/pulselab/SearchAllFundsWeb`;
export const SEARCH_FUNDS = `/api/v1/mutual_funds/discover/discover_mutual_funds`;
export const GET_ALL_SPECIFIC_AMC_LIST = `/api/amcs`;
export const GET_ALL_AMC = `api/v1/mutualfund/pulselab/get_all_amc_logos`;
export const SEARCH_FUNDS_BY_NAME = `/api/v1/mutual_funds/explore/search_plans_by_name`;
export const SEARCH_BY_ISIN = `api/v1/mutualfund/pulselab/SearchByIsin`;

// get all the list of category in the amc 
export const GET_ALL_AMC_CATEGORY_LIST = `/api/v1/mutual_funds/category/get_all`;


//get fund detail
// export const GET_FUND_DETAILS = `api/v1/mutualfund/pulselab/get_fund_details`;
// export const GET_FUND_DETAILS = `api/v1/mutualfund/pulselab/get_fund_details`;
export const GET_FUND_DETAILS = `/api/v1/mutual_funds/mutual_funds_details/get_fund_plan_by_plan_id`;
// export const GET_NAV_HISTORY = `api/v1/mutualfund/nav/navhistory`;
export const GET_NAV_HISTORY = `/api/v1/mutual_funds/mutual_funds_details/get_graph_by_plan_id`;
// export const GET_HOLDINGS = `api/v1/mutualfund/schemeholdings/schemeholdingssecurity`;
export const GET_HOLDINGS = `/api/v1/mutual_funds/mutual_funds_details/allFundsAllocationEquity`;
export const GET_ANALYTICAL_DATA = `api/v1/mutualfund/pulselab/analytical-data`;
export const GET_HOLDING_SECTOR = `api/v1/mutualfund/pulselab/schemeholdingssector`;

//Return calculator
export const GET_FUND_DETAILS_RETURN_CALCULTAE = `/api/goals/get_fund_detail_return`;

//Performance Data
export const GET_FUND_PERFORMANCE_DATA = `/api/transaction-baskets/getbenchmark_fund_details`;

//Peer Comparison data
export const GET_PEER_COMPARISON_DATA = `/api/v1/mutual_funds/mutual_funds_details/getComparePlanDetailsByIdRatingReturn`;

//Fund Housing Details
export const GET_FUND_HOUSING_DETAILS = `api/v1/mutual_funds/mutual_funds_details/get_fund_managers_by_plan_id`;

//Initiate payment
export const INITIATE_PAYMENT = `/api/transaction-baskets/initiate-basket-payment`;

//lumsum
export const CREATE_LUMSUM = `api/v1/transactionservice/lumpsum/create_lumpsum_purchase`;
// export const INITIATE_PAYMENT = `api/v1/transactionservice/lumpsum/initiate_payment`;
export const GET_LUMSUM_DETAIL = `api/v1/transactionservice/lumpsum/get_lumpsum_purchase_details`;

//Sip
export const CREATE_SIP = `api/v1/transactionservice/sip/add_sip_orders`;
// get the mandates list of the bank
export const  MANDATES_DETAILS_OF_BANK = `/api/mandates`;
export const GET_MANDATE_INDIVIDUAL = `/api/mandates/individual`;

//mandate
export const GET_MANDATE = `api/v1/transactionservice/usermandate/getUserMandate`;
export const ADD_MANDATE = `api/v1/transactionservice/usermandate/addMultipleUserMandate`;
export const ACTIVATE_MANDATE = `api/v1/transactionservice/usermandate/authorize_mandate`;
export const DELETE_MANDATE = `api/v1/transactionservice/usermandate/deleteUserMandateByMandateId`;

//mf portfolio
export const GET_ACTIVE_SIP = `api/v1/transactionservice/sip/getActiveSip`;
export const GET_INACTIVE_SIP = `api/v1/transactionservice/sip/getInActiveSip`;
export const GET_INSTALLMENT_BYID = `api/v1/transactionservice/sip/fetchInstallmentsByUserIdAndSipId`;
export const GET_SCHEMERETURNS = `api/v1/transactionservice/schemewisereturns/getByUser`;
export const GET_ALL_RETURNS = `api/v1/transactionservice/schemewisereturns/investment_returns_by_user_id`;
// export const STOP_SIP = `api/v1/transactionservice/sip/stopSip`;
export const STOP_SIP = `api/transaction-baskets/stop_systematic`;
// export const GET_MY_INVESTMENT = `api/v1/transactionservice/orders/get_sip_stp_and_swp`
export const GET_MY_INVESTMENT = `/api/portfolio/holdings`

//Resume the Investemnt form where it is stoped
export const GET_PENDING_INVESTMENT_RESUME_INVESTMENT = `/api/transaction-baskets/get_pending_three_days`;
export const POST_PENDING_INVESTMENT_DEACTIVATE_BASKET_ITEMS = `/api/transaction-baskets/deactivate_basket_items`;

//order in progress
export const MF_ORDER_IN_PROGRESS = `api/v1/transactionservice/orders/getOrdersInProgress`;

//foliowise holding return
// export const GET_FOLIO_HOLDINGS = `api/v1/transactionservice/holdings/get_holdings`;
export const GET_FOLIO_HOLDINGS = `/api/portfolio/dashboard`;
export const GET_TRANS_HISTORY = `api/v1/transactionservice/schemewisereturns/list_all_transactions_by_isin`;

//View Analysis data 
//Get Analysis grpah data of porfolio
export const GET_PORTFOLIO_RETURN_GRAPH = `/api/portfolio/returns_graph`;
export const GET_PORTFOLIO_RETURN_NAV_GRAPH = `/api/portfolio/benchmark_nav_graph`;
export const GET_ASSEST_ALLOCATION = `/api/portfolio/portfolio_analysis`;

//redumption
// export const CREATE_REDUMPTION = `api/v1/transactionservice/redemption/create`;
//create reducption is same as the transaction basket creating
export const CREATE_REDUMPTION = `/api/transaction-baskets/`;

// export const VERIFY_REDEEM_OTP = `api/v1/transactionservice/redemption/verify_redemption_otp`;
export const VERIFY_REDEEM_OTP =`/api/transaction-baskets/validate-basket-consent`;
export const GET_PRIMARY_BANK = `api/v1/usermgmt/bankDetails/getPrimaryBank`;
//RESEND OTP
export const RESEND_OTP = `api/v1/transactionservice/otp/otp_resend`;
//switch
// export const GENERATE_SWITCH = `api/v1/transactionservice/switch/create_switch_generate`;
export const GENERATE_SWITCH = `/api/transaction-baskets/`;
// export const SWITCH_ALTERNATIVE_FUND = `api/v1/mutualfund/pulselab/switch_Altranative_funds`;
export const SWITCH_ALTERNATIVE_FUND = `/api/v1/mutual_funds/filter/filter_funds`;
export const VERIFY_SWITCH_OTP = `/api/transaction-baskets/validate-basket-consent`;
// export const VERIFY_SWITCH_OTP = `api/v1/transactionservice/switch/create_switch_verify`;

//SWP
// export const GET_FUND_FP_DATA = `api/v1/mutualfund/fundscheme/getFundDetailsByIsin`;
export const GET_FUND_FP_DATA = `/api/transaction-baskets/fpfund`;
// export const CREATE_SWP = `api/v1/transactionservice/swp/create_swp_generate`;
//create reducption is same as the transaction basket creating
export const CREATE_SWP = `/api/transaction-baskets/`;
export const VERIFY_SWP_OTP = `/api/transaction-baskets/validate-basket-consent`;
// export const VERIFY_SWP_OTP = `api/v1/transactionservice/swp/create_swp_verify`;
export const STOP_SWP = `/api/transaction-baskets/stop_systematic`;
export const GET_SWP_INSTALLMENTS = `api/v1/transactionservice/swp/get_swp_installments`;

//STP
// export const GET_STP_ALTERNATIVE = `api/v1/mutualfund/pulselab/Stp_Altranative_funds`;
export const GET_STP_ALTERNATIVE = `/api/v1/mutual_funds/filter/filter_funds`;
// export const CREATE_STP = `api/v1/transactionservice/stp/create_stp_generate`;
//create reducption is same as the transaction basket creating
export const CREATE_STP = `/api/transaction-baskets/`;
export const VERIFY_STP_OTP = `/api/transaction-baskets/validate-basket-consent`;
// export const VERIFY_STP_OTP = `api/v1/transactionservice/stp/create_stp_verify`;
export const STOP_STP = `/api/transaction-baskets/stop_systematic`;
export const GET_STP_INSTALLMENTSS = `api/v1/transactionservice/stp/fetchInstallmentsByUserIdAndStpId`;
//------------------------------ASSISTED SERVICES--------------------------------------//
export const GET_USER_RISK_PROFILE =`/api/risk_profiles/get_user_risk_profile`;
// export const GET_USER_QUESTIONS = `api/v1/risk_profiles/map_quize_choice/get_all_quize_choices`;
export const GET_USER_QUESTIONS = `/api/risk_profiles/get_quiz`;
// export const SUBMIT_ANSWERS = `api/v1/risk_profiles/map_user_quize_choice/map_user_quize_choices`;
export const SUBMIT_ANSWERS = `/api/risk_profiles/record_user_answer`;
export const GET_ASSISTED_SRVICE_AMOUNT = `api/v1/admin_service/assisted/get_assisted_service_details`;
export const CREATE_ASSISTED_ORDER = `api/v1/admin_service/razor_pay/create_order`;
export const POST_ASSISTED_PAYEMNT = `api/v1/admin_service/razor_pay/post_payment`;
export const GET_SUGGESTED_FUND = `api/v1/admin_service/assisted/suggestedFunds`;
export const INVEST_ASSISTED_FUND = `api/v1/admin_service/assisted/payForAssistedFunds`;
export const INITAITE_ASSISTED_PAYMENT = `api/v1/admin_service/assisted/initiate_payment`;

//portfolio

export const GET_ASSISTED_HOLDINGS = `api/v1/transactionservice/holdings/get_assisted_holdings`;
export const ASSISTED_SWITCH_FUNDS = `api/v1/mutualfund/pulselab/assisted_switch_Altranative_funds`;

//------------------------------------USER PROFILE-------------------------------------------------------//
// export const UPDATE_PRIMARY_BANK = `api/v1/usermgmt/bankDetails/update_primary_account_by_id`;
export const UPDATE_PRIMARY_BANK = `api/bank/update_primary_account`;
export const UPDATE_INVESTOR_ACCOUNT = `api/v1/kycservice/investor_account/update`;
export const UPDATE_BANK = `api/v1/usermgmt/bankDetails/update_profile_bank_details`;

export const GET_REPORTS = `api/v1/transactionservice/pdfholdings/mutual_fund_summary_report`;
export const CHANGE_MPIN = `api/v1/usermgmt/user/change_mpin`;

//------------------------------------USER ORDERS-------------------------------------------------------//
export const GET_ORDERS = `api/v1/transactionservice/orders/getAllOrders`;
export const GET_ORDER_DETAILS = `/api/transaction-baskets/fetch_transaction`;
export const GET_LUMPSUM_ORDER_STATUS_COMPLETED_STATUS = `api/order-status/completed_lumpsum`;
export const GET_LUMPSUM_ORDER_STATUS_PENDING_STATUS = `api/order-status/inprogress_lumpsum`;
export const GET_LUMPSUM_ORDER_STATUS_FAILED_STATUS = `api/order-status/failed_lumpsum`;

export const GET_NO_MANDATE_SIPS_ACTIVE_STATUS = `api/order-status/active_no_mandate_sips`;
export const GET_NO_MANDATE_SIPS_INACTIVE_STATUS = `api/order-status/inactive_no_mandate_sips`;
export const GET_NO_MANDATE_SIPS_DETAILS =`api/order-status/get_no_mandate_sip_details`

export const GET_REDEMPTION_ORDER_COMPLETED_STATUS = `api/order-status/completed_redemption`;
export const GET_REDEMPTION_ORDER_PENDING_STATUS = `api/order-status/inprogress_redemption`;
export const GET_REDEMPTION_ORDER_FAILED_STATUS = `api/order-status/failed_redemption`;
export const GET_SIPS_ACTIVE_STATUS = `api/order-status/active_sips`;
export const GET_SIPS_INACTIVE_STATUS = `api/order-status/inactive_sips`;
export const GET_SIPS_PENDING_STATUS = `api/order-status/pending_sips`;
export const GET_SIPS_DETAILS = `api/order-status/get_sip_details`;


export const GET_SMART_SIPS_ORDER_COMPLETED_STATUS = `api/order-status/active_smart_sips`;
export const GET_SMART_SIPS_ORDER_PENDING_STATUS = `api/order-status/pending_smart_sips`;
export const GET_SMART_SIPS_ORDER_FAILED_STATUS = `api/order-status/inactive_smart_sips`;

export const GET_STP_ORDER_COMPLETED_STATUS = `api/order-status/active_stps`;
export const GET_STP_ORDER_PENDING_STATUS = `api/order-status/pending_stps`;
export const GET_STP_ORDER_FAILED_STATUS = `api/order-status/inactive_stps`;

export const GET_SWITCH_ORDER_COMPLETED_STATUS = `api/order-status/completed_switches`;
export const GET_SWITCH_ORDER_PENDING_STATUS = `api/order-status/inprogress_switches`;
export const GET_SWITCH_ORDER_FAILED_STATUS = `api/order-status/failed_switches`;

export const GET_SWP_ORDER_COMPLETED_STATUS = `/api/order-status/active_swps`;
export const GET_SWP_ORDER_PENDING_STATUS = `api/order-status/pending_swps`;
export const GET_SWP_ORDER_FAILED_STATUS = `api/order-status/inactive_swps`;

// export const GET_LUMPSUM_ORDER_STATUS_FAILED_STATUS = `api/order-status/failed_lumpsum`;

export const GET_ASSISTED_ORDERS = `api/v1/transactionservice/orders/getAllAssistedOrders`;

// ----------------------- capital gain report -------------------------//
export const GET_ALL_CAPITAL_GAIN_REPORT = `api/portfolio/capital_gain_report`;

// ----------------------- settings -------------------------//
export const POST_SETTINGS_CHANGE = `api/user/update_settings`;

//-----------------Admin dashboard------------------------//
//login
export const ADMIN_EMAIL = `api/v1/admin_service/admin_login`;
export const EMAIL_VERIFY = `api/v1/admin_service/email_verify`;
export const GET_RECENT_ACTION = `api/v1/admin_service/dashboard/recent_actions`;

//notification
export const ADMIN_NOTIFICATION = `api/v1/appnotificationservice/adminnotification/get_app_notification_by_user_id`;
export const GET_ADMIN_UNREAD_COUNT = `api/v1/appnotificationservice/adminnotification/get_notification_count`;
export const READ_ADMIN_NOTIFICATIONS = `api/v1/appnotificationservice/adminnotification/update_isread`;
export const READ_ALL_ADMIN_NOTIFICATIONS = `api/v1/appnotificationservice/adminnotification/read_all_notification`;
//dashboard
export const ALLOCATION_TO_FUNDHOUSE = `api/v1/admin_service/dashboard/fundhouse_allocation`;
export const SUCCESSFULL_RECOMMENDATION = `api/v1/admin_service/assisted/amc_advice_expenditure_chart_details`;
export const GET_TOTAL_INVESTMENT = `api/v1/admin_service/dashboard/total_investments`;
export const GET_TOTAL_THEMATIC = `api/v1/admin_service/dashboard/assisted_total_investments`;
export const GET_DAILY_USER = `api/v1/admin_service/dashboard/active_users`;
export const GET_MONTHLY_USER = `api/v1/admin_service/dashboard/monthly_active_users`;
//Customer-------------------------------------//
export const GET_CUSTOMER = `api/v1/admin_service/customer/get_customers`;
export const DOWNLOAD_CSV = `api/v1/admin_service/customer/download_csv`;

//Timestamps-------------------------------------//
export const GET_TIMESTAMPS = `api/v1/admin_service/get_all_timestamps`;
export const ADD_TIMESTAMPS = `api/v1/admin_service/create_assisted_service_timestamp`;
export const UPDATE_TIMESTAMPS = `api/v1/admin_service/update_assisted_service_timestamp`;
export const DELETE_TIMESTAMPS = `api/v1/admin_service/delete_assisted_service_timestamp`;
//Investor insight--------------------------------------//
export const GET_ALL_RISK_THEMATIC = `api/v1/admin_service/risk_thematic_cap_percentage/get_all`;
export const UPDATE_RISK_THEMATIC = `api/v1/admin_service/risk_thematic_cap_percentage/update`;
export const GET_QUESTIONARES = `api/v1/risk_profiles/map_quize_choice/get_all_quize_choices`;
export const UPDATE_QUESTIONARES = `api/v1/admin_service/risk_profile/update_questionaire`;
export const ADD_QUESTION = `api/v1/admin_service/risk_profile/add_questionaire`;
export const DELETE_QUESTION = `api/v1/admin_service/risk_profile/delete_questionaire`;

//User management------------------------------------//
export const GET_ALL_ADMINS = `api/v1/admin_service/user/get_all_admins`;
export const ACTIVATE_DEACTIVATE = `api/v1/admin_service/user/activate_admin`;
export const DELETE_ADMIN = `api/v1/admin_service/user/remove_admin`;
export const ADD_ADMINS = `api/v1/admin_service/user/add_admin`;
export const APPROVAL_LIST = `api/v1/admin_service/user/get_requests_by_super_admin`;
export const APPROVE_REQUEST = `api/v1/admin_service/user/approve_request`;
export const REJECT_REQUEST = `api/v1/admin_service/user/reject_request`;
export const USER_STATUS = `api/v1/admin_service/user/get_requests_by_admin`;
export const VIEW_CHANGES = `/api/v1/admin_service/user/view_changes`;
export const GET_USER_KYC_DATA = `${baseurl}kyc/get_all_user_kyc`;
export const SIMULATE_GOLD_KYC = `${baseurl}kyc/simulate_gold_kyc`;

//Allocation management--------------------------------------//
export const GET_ALL_ALLOCATION = `api/v1/admin_service/allocation_management/get_all_allocation`;
export const GET_LAG_PERCENTAGE = `api/v1/admin_service/allocation_management/get_lag_percentage`;
export const UPDATE_LAG_PERCENTAGE = `api/v1/admin_service/allocation_management/update_lag_percentage`;
export const GET_MAJOR_MINOR_AMC = `api/v1/admin_service/allocation_management/get_major_minor_amcs`;
export const ACTIVATE_DEACTIVATE_AMC = `api/v1/admin_service/allocation_management/activate_amc`;
export const UPDATE_ALLOCATION = `api/v1/admin_service/allocation_management/update_allocation`;
export const GET_ALL_AMCS = `api/v1/admin_service/allocation_management/get_all_amcs`;
export const ADD_AMCS = `api/v1/admin_service/allocation_management/add_amc`;
export const DELETE_AMC = `api/v1/admin_service/allocation_management/delete_amc`;
export const MOVE_AMC = `api/v1/admin_service/allocation_management/move_amc`;
export const GET_SUBSCRIPTION_FEES = `api/v1/admin_service/assisted/get_assisted_service_details`;
export const UPDATE_SUBSCRIPTION_FEES = `api/v1/admin_service/assisted/update_assisted_service_details`;

//fund management
export const GET_FUND_LIST = `api/v1/admin_service/fundManagement/list_all_funds`;
export const ADD_FUND_TO_ASSISTED = `api/v1/admin_service/fundManagement/add_fund`;
export const REMOVE_FROM_ASSISTED = `/api/v1/admin_service/fundManagement/remove_fund`;
export const INITIATE_TRIGGER = `api/v1/admin_service/fundManagement/initate_exit_trigger`;
export const CONFIRM_TRIGGER = `api/v1/admin_service/fundManagement/confirm_exit_trigger`;
export const DELETE_CATEGORY = `api/v1/admin_service/fundManagement/delete_exit_trigger_by_assetSubCategory`;
export const DELETE_FUND = `api/v1/admin_service/fundManagement/delete_exit_trigger_by_planId`;
export const CONFIRM_DELETE = `api/v1/admin_service/fundManagement/confirm_Deletion_exit_trigger`;

// Consent OTP for Payments

export const GET_CONSENT_SIP_OTP = `/api/v1/transactionservice/sip/create_consent`;
export const VERIFY_CONSENT_SIP_OTP = `/api/v1/transactionservice/sip/verify_consent`;
// validate the sip consent otp 
export const VERIFY_AND_VALIDATE_BASKET_CONSENT_SIP_OTP =`/api/transaction-baskets/validate-basket-consent`;


export const GET_CONSENT_LUMPSUM_OTP = `/api/v1/transactionservice/lumpsum/create_consent`;
export const VERIFY_CONSENT_LUMPSUM_OTP = `/api/v1/transactionservice/lumpsum/verify_consent`;


export const POST_TRANSACTION_BASKETS = `/api/transaction-baskets/`;
export const POST_TRANSACTION_BASKETS_GENERATE_CONSENT = `/api/transaction-baskets/generate-consent`;
export const VERIFY_AND_VALIDATE_BASKET_CONSENT_LUMPSUMP_OTP =`/api/transaction-baskets/validate-basket-consent`;



export const VERIFY_ASSISTED_SERVICE_PIN = `/api/v1/admin_service/razor_pay/verify_assisted_services_pin`;
export const GET_ASSISTED_PINS = `/api/v1/admin_service/get_all_pins`;
export const CREATE_PIN = `/api/v1/admin_service/create_assisted_service_pin`;
export const DELETE_PIN = `/api/v1/admin_service/delete_assisted_service_pin`;


// CAS system
export const GET_CAS_LIST = `/api/external_funds`;
export const POST_CAS_OTP = `/api/external_funds/otp`;
export const GET_CAS_FUND_DATA = `/api/external_funds/`;


// Goals page apis
export const GET_GOALS_LIST = `/api/goals`;
export const POST_GOALS_CALCULATE_MY_FIRST_GOAL = `/api/goals/calculate_my_first_crore`;
export const POST_GOALS_CALCULATE_TAX_SAVER = `/api/goals/calculate_tax_saver`;
export const POST_GOALS_CALCULATE_EMERGENCY_FUND =  `/api/goals/calculate_emergency_fund`;
export const POST_GOALS_CALCULATE_PARK_YOUR_SPARE_MONEY = `/api/goals/calculate_parked_money`;
export const POST_GOALS_CALCULATE_LONG_TERM_WEALTH = `/api/goals/calculate_long_term`;
export const POST_GOALS_CALCULATE_RETIREMENT_PLAN = `/api/goals/calculate_retirement`;
export const GET_GOALS_CALCULATE_FUTURE_VALUE=  `/api/goals/get_future_value`;
// Get The fund distribute of recommended funds
export const GET_PORTFOLIO_FUND_DISTRIBUTE = `/api/model-portfolio/fund_distribute`;
export const GET_PORTFOLIO_FUND_CHANGEDDATA_DISTRIBUTE = `/api/model-portfolio/change_fund`;
