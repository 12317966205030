// Set the base URL dynamically based on the environment
let BASE_URL = "https://stagingapi.maxwealth.money/"; // Default to development URL

if (process.env.REACT_APP_ENVIRONMENT === "production") {
  BASE_URL = "https://api.maxwealth.money/"; // Use production URL if in production
}

let namespace = "";

// Set the tenant ID dynamically if needed
let tenant_id = "stagingmfwealth"; // Default tenant ID for development
if (process.env.REACT_APP_ENVIRONMENT === "production") {
  tenant_id = "mfwealth"; // Tenant ID for production
}

// Export the variables
export { BASE_URL, namespace, tenant_id };
