export const sourceIncome = ([
    { id: 1, type: 'Upto 1 lakh', value: 'upto_1lakh' },
    { id: 2, type: 'Above 1 lakh upto 5 lakh', value: 'above_1lakh_upto_5lakh' },
    { id: 3, type: 'Above 5 lakh upto 10 lakh', value: 'above_5lakh_upto_10lakh' },
    { id: 4, type: 'Above 10 lakh upto 25 lakh', value: 'above_10lakh_upto_25lakh' },
    { id: 5, type: 'Above 25 lakh upto 1 cr', value: 'above_25lakh_upto_1cr' },
    { id: 6, type: 'Above 1cr', value: 'above_1cr' },
]);


  
export const occupationType = ([
    // { id: 1, type: 'Business', value: 'business' },
    // { id: 2, type: 'Professional', value: 'professional' },
    // { id: 3, type: 'Self employed', value: 'self employed', },
    // { id: 4, type: 'Retired', value: 'retired' },
    // { id: 5, type: 'Housewife', value: 'housewife' },
    // { id: 6, type: 'Student', value: 'housewife' },
    // { id: 7, type: 'Public sector', value: 'public sector' },
    // { id: 8, type: 'Private sector', value: 'private sector' },
    // { id: 9, type: 'Government sector', value: 'government sector' },
    // { id: 10, type: 'Others', value: 'others' }
    { id: 1, type: 'business', value: 'business' },
    { id: 2, type: 'professional', value: 'professional' },
    { id: 3, type: 'self employed', value: 'self_employed' },
    { id: 4, type: 'retired', value: 'retired' },
    { id: 5, type: 'housewife', value: 'housewife' },
    { id: 6, type: 'student', value: 'student' },
    { id: 7, type: 'public sector', value: 'public_sector' },
    { id: 8, type: 'private sector', value: 'private_sector' },
    { id: 9, type: 'government sector', value: 'government_sector' },
    { id: 10, type: 'others', value: 'others' }
  ])