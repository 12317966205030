import React from 'react';
import { Box, Grid, Skeleton, Button } from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardContent: {
    display: "flex",
  },
  cardContentStar: {
    // display: "flex",
  },
  cardContent2: {
    //display:'flex',
    margin: "10px 0px",
  },
  mainCard: {
    //height:'190px',
    //width:'839px'
    // border: "1px solid #DDDDDD",
    border: '1px solid #E4E4E4',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
    borderRadius: "10px",
    marginTop: "1rem",
    padding: "10px",
    opacity: 0,  // Initially hidden
    animation: "$fadeIn 0.5s ease-out forwards", // Apply animation
    // transform: 'translateY(20px)',  // Starts slightly off position
    // animation: `$fadeIn 0.5s ease-out forwards`, // Animation rule
    "& p": {
      margin: "4px 0px 4px 0px",
    },
  },
  "@keyframes fadeIn": {
    from: {
      opacity: 0,
      // transform: 'translateY(20px)',  // Slide up from 20px below
    },
    to: {
      opacity: 1,
      // transform: 'translateY(0)',  // Back to original position
    },
  },
  fundName: {
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: "400",
    paddingLeft: "10px",
  },
  fundType: {
    fontFamily: "Poppins",
    fontSize: "13px",
    fontWeight: "400",
  },
  rating: {
    fontFamily: "Poppins",
    fontSize: "13px",
    fontWeight: "400",
  },

  nav: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "400",
  },
  navValue: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "600",
  },
  perReturns: {
    fontFamily: "Poppins",
    color: "#0E76BC",
    fontSize: "16px",
    fontWeight: "600",
  },
  yearReturns: {
    fontFamily: "Poppins",
    fontSize: "13px",
    fontWeight: "400",
  },
  primaryButton: {
    padding: "0.9rem 0px",
    fontFamily: "Poppins",
    background: "#0E76BC",
    color: "#fff",
    fontSize: "0.9rem",
    fontWeight: 500,
    borderRadius: "45px",
    border: "none",
    cursor: "pointer",
    marginTop: "3rem",
    width: "100%",
    border: "1px solid #0E76BC",
    transition: "0.3s ease-in-out",
    boxShadow: "none !important",
    textTransform: 'capitalize',
    "&:hover": {
      backgroundColor: "#fff",
      color: "#0E76BC",
    }
  },

  ratingBox: {
    paddingLeft: "1rem",
    paddingRight: "0.2rem",
    display: 'flex',
    alignItems: 'center'
  },

  starImag: {
    padding: "0px 4px",
  },
  rupees: {
    fontFamily: "Open Sans",
  },
  logo: {
    paddingRight: "10px",
  },
  "@media (max-width: 959.99px)": {
    ratingBox: {
      paddingLeft: "10px",
      display: 'flex',
      justifyContent: 'end',
      width: '100%',
    },
    primaryButton: {
      marginTop: "1rem",
      width: '300px',
    },
  },
  "@media (max-width: 600px)": {
    ratingBox: {
      paddingLeft: "10px",
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    primaryButton: {
      marginTop: "1rem",
      width: '200px',
    },
  },
});

const FundSkeleton = () => {
  const classes = useStyles();

  return (
    <Box className={classes.mainCard}>
      <Grid container>
        <Grid item xs={12} md={10} container>
          <Grid item xs={12} className={classes.fundName}>
            <Box display={'flex'} width={'100%'}>
              <Skeleton variant="rounded" width={54} height={54} style={{ marginRight: '10px' }} />
              <Box width={'100%'}>
                <Skeleton variant="text" width={'50%'} />
                <div className={classes.cardContent}>
                  <Grid item xs={12}>
                    <Skeleton variant="text" width={'40%'} />
                  </Grid>
                  {/* <Grid item xs={6}>
                    <Skeleton variant="text" width={100} />
                  </Grid> */}
                </div>
              </Box>
            </Box>

            <Grid item xs={12} container className={classes.cardContent2}>
              {[1, 2, 3, 4, 5, 6].map((key, index) => (
                <Grid key={index} item xs={5} sm={3} md={2}>
                  <p className={classes.navValue}>
                    <Skeleton variant="text" width={80} sx={{ fontSize: '1.2rem' }}/>
                  </p>
                  <Skeleton variant="text" width={50} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={2} className={classes.ratingBox}>
          <Skeleton variant="rounded" width={200} height={50} style={{borderRadius:'45px'}}/>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FundSkeleton;
