import React, { useEffect, useState } from "react";
import TitleLayoult from "../TitleLayoult";
import { useDropzone } from "react-dropzone";
import {
  Box,
  ThemeProvider,
  MenuItem,
  Select,
  createTheme,
  FormControl,
} from "@material-ui/core";
import FileUpload from "../uploader/FileUpload";
import { useStyles } from "../styles";
import ErrorMessage from "../../Validation/ErrorMessage";
import { UPLOAD_PAN, GET_UPLOADED_PAN, GET_KYC_DATA, IDENTITY_PROOF_DOCUMENT, KYC_STATUS } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import { useNavigate, createSearchParams, useSearchParams } from "react-router-dom";
import { BASE_URL, namespace } from "../../../environment";
import CustomToast from "../../UI/CustomToast";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useForm, Controller } from "react-hook-form";
import StepRedirector from "../Common/StepRedirector";

function UploadPan() {

  const defaultValues = {
    type: null,
    // motherName: "",
    // marital: "",
    // occupation: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    control,
    watch
  } = useForm({ defaultValues });
  const navigate = useNavigate();

  const classes = useStyles();

  const [searchParams, setSearchParams] = useSearchParams();
  const steps = searchParams.get("stepcount");

  const [panUrl, setPanUrl] = useState("");
  const [nextbutton, setNextButton] = useState(false);
  const [loading, setLoading] = useState(false)
  const [acceptedFile, setAcceptedFile] = useState([])

  var typeIsThere = getValues("type")

  const urlPath = 'onBoarding';
  const url = window.location.origin;
  const strippedUrl = url.replace(/^https?:\/\//, '');


  const theme = createTheme({
    overrides: {
      MuiInputBase: {
        input: {
          padding: "8px 10px",
        },
      },
      MuiSelect: {
        select: {
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      },
    },
  });

  useEffect(async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      // const response = await httpInstance(`${GET_KYC_DATA}`, httpConfig);
      const response = await httpInstance(`${GET_UPLOADED_PAN}`, httpConfig);
      // setPanUrl(response.data?.data?.userKycFilesData?.panPublicUrl);
      setPanUrl(response.data?.data?.front_document_path)
      reset({
        type: response.data?.data?.document_type || null,
      });
      const responseKyc = await httpInstance(`${KYC_STATUS}`, httpConfig);

      const dataFinal = StepRedirector(responseKyc.data?.data, steps) // secutiry redirection
      // console.log(dataFinal, steps)
      if (dataFinal) {
        navigate({
          pathname: `${namespace}/dashboard/mutualfund`,
          // search: `?${createSearchParams({ stepcount: "2" })}`,
        });
      }
    } catch (error) {

    }
  }, []);



  const submit_signature = async (acceptedFile, handleNext) => {
    setAcceptedFile(acceptedFile)
    // console.log(handleNext, acceptedFile)
    setNextButton(true);
  }
  // const [startPolling, setStartPolling] = useState(false); // Track when to start polling

  const submit_signatures = async (data) => {
    // console.log('asdfasdfa')
    setLoading(true)
    const httpConfig1 = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      // data: formData,
    };



    // console.log(strippedUrl)
    if (acceptedFile[0]) {
      // console.log(acceptedFile)
      // console.log(handleNext)
      const formData = new FormData();
      // formData.append("pan_file", acceptedFile[0]);
      formData.append("front_side_file", acceptedFile[0]);
      // formData.append("front_side_file", acceptedFile[0]);
      formData.append("user_id", localStorage.getItem("user_id"));
      formData.append("type", "proof_of_identity");
      formData.append("document_type", "pan")

      const httpConfig = {
        method: "POST",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      };
      const httpConfig1 = {
        method: "POST",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          url: strippedUrl,
          pathname: urlPath,
          step: 16

        },
      };
      try {
        await httpInstance(`${UPLOAD_PAN}`, httpConfig);
        setNextButton(true);
        // handleNext();
        // navigate({
        //   pathname: `${namespace}/onBoarding`,
        //   search: `?${createSearchParams({stepcount:"6"})}`,
        // });
        CustomToast('Upload Proof is uploaded', "SUCCESS")
        setLoading(false)
        try {
          const response = await httpInstance(`${IDENTITY_PROOF_DOCUMENT}`, httpConfig1);
          const url = response?.data?.data?.fetch?.redirect_url;
          if (response?.data?.data?.fetch?.status === "successful" && !url) {
            // setNextButton(true);
            handleNext();

          } else {
            // // Open the link in a new tab
            // const newTab = window.open(url, '_blank');


            // // Check if the user has completed the action in the new tab
            // const interval = setInterval(() => {
            //   // Assuming the API or URL change or signal indicates completion
            //   if (newTab.closed) {
            //     clearInterval(interval);
            //     // Navigate to /mutualfund
            //     navigate('/mutualfund');
            //   }
            // }, 1000);
            // Replace tab witn window.replace
            window.location.replace(url)
          }

        } catch (error) {
          CustomToast(error?.response?.data?.message ?? error?.response?.data?.error ?? "Somthing went wrong", "FAILED");
          setLoading(false)
        }

      } catch (error) {
        CustomToast(error?.response?.data?.message ?? error?.response?.data?.error ?? "Somthing went wrong", "FAILED");
        setLoading(false)
      }
    } else {
      if (panUrl) {
        try {
          const response = await fetch(BASE_URL + panUrl);
          const blob = await response.blob();
          const fileName = panUrl.split('/').pop(); // Extract the file name from the URL
          const file = new File([blob], fileName, { type: blob.type });
          const formData = new FormData();
          // formData.append("pan_file", acceptedFile[0]);
          formData.append("front_side_file", file);
          // formData.append("front_side_file", acceptedFile[0]);
          formData.append("user_id", localStorage.getItem("user_id"));
          formData.append("type", "proof_of_identity");
          formData.append("document_type", "pan")

          const httpConfig = {
            method: "POST",
            tokenType: "user",
            headers: {
              "Content-Type": "application/json",
            },
            data: formData,
          };
          const httpConfig1 = {
            method: "POST",
            tokenType: "user",
            headers: {
              "Content-Type": "application/json",
            },
            data: {
              url: strippedUrl,
              pathname: urlPath,
              step: 16
            },
          };

          await httpInstance(`${UPLOAD_PAN}`, httpConfig);
          setNextButton(true);
          // handleNext();
          // navigate({
          //   pathname: `${namespace}/onBoarding`,
          //   search: `?${createSearchParams({stepcount:"6"})}`,
          // });
          CustomToast('Upload Proof is uploaded', "SUCCESS")
          setLoading(false)
          try {
            const response = await httpInstance(`${IDENTITY_PROOF_DOCUMENT}`, httpConfig1);
            const url = response?.data?.data?.fetch?.redirect_url;

            if (response?.data?.data?.fetch?.status === "successful" && !url) {
              // setNextButton(true);
              handleNext();

            } else {
              // // Open the link in a new tab
              // const newTab = window.open(url, '_blank');
              // // setStartPolling(true); // Start polling after opening the new tab

              // // const newTabEnd = window.screen('https://api.maxwealth.money/api/onboarding/identity_postback/mfwealth')
              // // // console.log(newTab, newTabEnd)
              // // // Check if the user has completed the action in the new tab
              // // const interval = setInterval(() => {
              // //   try {
              // //     // Check if the new tab's URL includes "&status=failed"
              // //     if (newTabEnd?.location.href.includes("&status=failed")) {
              // //       newTab.close(); // Close the tab
              // //       clearInterval(interval); // Clear the interval

              // //       // Optionally, handle failure logic or show an error
              // //       console.log("Failed status detected, tab closed.");
              // //     } else if (newTab.closed) {
              // //       // If the user completes the action and closes the tab
              // //       clearInterval(interval);
              // //       navigate('/mutualfund'); // Navigate to the mutual fund page
              // //     }
              // //   } catch (error) {
              // //     // Catch any cross-origin errors if the URL is not from the same origin
              // //     console.error("Cross-origin access denied, cannot check the tab URL.");
              // //   }
              // // }, 1000);
              // const interval = setInterval(() => {
              //   // Assuming the API or URL change or signal indicates completion
              //   if (newTab.closed) {
              //     clearInterval(interval);
              //     // Navigate to /mutualfund
              //     // navigate('/mutualfund');
              //     handleNext();
              //   }
              // }, 1000);

              // Replace tab witn window.replace
              window.location.replace(url)

            }

          } catch (error) {
            CustomToast(error?.response?.data?.message ?? error?.response?.data?.error ?? "Somthing went wrong", "FAILED");
            setLoading(false)
          }

          // handleNext();
          setLoading(false)


        } catch (error) {
          CustomToast(error?.response?.data?.message ?? error?.response?.data?.error ?? "Somthing went wrong", "FAILED");
          setLoading(false)
        }
      }
    }
  };

  // const [status, setStatus] = useState(null);


  // useEffect(() => {
  //   if (!startPolling) return; // Start polling only if handleIdentityProof is triggered

  //   const interval = setInterval(async () => {
  //     try {
  //       const statusResponse = await fetch('https://api.maxwealth.money/api/onboarding/identity_postback/mfwealth'); // Replace with your backend status endpoint
  //       const data = await statusResponse.json();
  //       if (data.status) {
  //         setStatus(data.status);
  //         clearInterval(interval); // Stop polling once we get a response
  //         setStartPolling(false); // Stop further polling
  //       }
  //     } catch (error) {
  //       console.error("Error fetching identity status:", error);
  //     }
  //   }, 1000);

  //   return () => clearInterval(interval); // Clean up on unmount
  // }, [startPolling]);

  // // Redirect based on status
  // useEffect(() => {
  //   if (status === 'failed') {
  //     navigate('/mutualfund');
  //   }
  // }, [status, navigate]);

  const handleNext = () => {
    navigate({
      pathname: `${namespace}/onBoarding`,
      // search: `?${createSearchParams({ stepcount: "6" })}`,
      search: `?${createSearchParams({ stepcount: "16" })}`,
    });
  };

  const handleback = () => {
    navigate({
      pathname: `${namespace}/onBoarding`,
      search: `?${createSearchParams({ stepcount: "14" })}`,
    });
  };

  const typeValue = watch("type"); // Watch the "type" field for changes


  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent="flex-start"
        // px={20}
        className={classes.backButton}
      // onClick={handleback}
      >
        {/* <img src="/images/backIcon.svg" /> */}
      </Box>
      <Box display={"flex"} justifyContent="center">
        <Box display={"flex"} alignItems="flex-start" flexDirection={"column"}>
          <Box
            display="flex"
            justifyContent="flex-start"
            // px={20}
            className={classes.backButton}
            onClick={handleback}
          >
            <img src="/images/backIcon.svg" alt="back" height={'20px'} width={'20px'} />
          </Box>
          <TitleLayoult
            title={"Upload Proof Of Identity"}
            description={"Please Upload PAN Card as Proof of Identity"}
            align={""}
          />
          <ThemeProvider theme={theme}>
            <Box
              my={3}
              display="flex"
              alignItems={"flex-start"}
              justifyContent="flex-start"
              flexDirection="column"
              width={"100%"}
            >
              <label className={classes.label}>Select Document Type</label>
              <Box width={"100%"} className={`${classes.Select} ${classes.Select2}`}>
                <FormControl fullWidth className={classes.Select}>
                  <Controller
                    control={control}
                    name={`type`}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        disableUnderline
                        className={classes.selectItem}
                        //classes={{paper:classes.selectPaper}}
                        IconComponent={KeyboardArrowDownIcon}
                        placeholder="Select Document type"
                        displayEmpty
                        MenuProps={{
                          style: {
                            maxHeight: 250,
                            top: 5,
                            minWidth: 200,
                          },
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        <MenuItem value={null} disabled hidden className={classes.menuItem}>
                          Select Document Type
                        </MenuItem>
                        <MenuItem className={classes.menuItem} value={'pan'}>
                          Pan
                        </MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </Box>
              {errors.type && (
                <ErrorMessage message="Please Select Type" />
              )}
            </Box>
          </ThemeProvider>
          {/* {typeValue === "pan" && 'sdfsd'} */}
          {typeIsThere &&
            <Box>
              <FileUpload
                handleSubmit={submit_signature}
                fileUrl={panUrl}
                handleNext={submit_signatures}
                nextbutton={nextbutton}
                validation={/\.(png|jpg)$/}
                type=".png/.jpg /.jpeg"
                loadings={loading}
              />
            </Box>
          }
          {/* {uploadCount === 3 ? (
        <Box mt={3}>
          <button className={classes.primaryButton} >
            Next
          </button>
        </Box>
      ) : (
        ""
      )} */}
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default UploadPan;
