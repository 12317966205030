import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ScrollTop from "./components/ScrollTop";

const theme = createTheme({
  typography: {
    fontFamily: 
      "Poppins"
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#ff5252',
    },
  },
  
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <ThemeProvider theme={theme}>
      <ScrollTop/>
     <App />
    </ThemeProvider>
     
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);


reportWebVitals();
