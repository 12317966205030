import React from 'react'
import BankDetails from '../../containers/MyProfile/BankDetails'

const Bank_and_autoMandate = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '54dvh' }}>
        <BankDetails/>
    </div>
  )
}

export default Bank_and_autoMandate