import React from 'react'
import AllTransactions from '../../../containers/Portfolio/MutualFund/AllTransactions'

const All_fund_transactions = () => {
  return (
    <div>
        <AllTransactions/>
    </div>
  )
}

export default All_fund_transactions