import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import IntroSection from "../../components/IntroSection";
import ConnectModal from "../../components/ConnectModal";
import ConnectSuccess from "../../components/ConnectSuccess";
import ThirdSection from "../../components/ThirdSection";
import SecondSection from "../../components/SecondSection";
import FourthSection from "../../components/FourthSection";
import FifthSection from "../../components/FifthSection";
import SixthSection from "../../components/SixthSection";
import SeventhSection from "../../components/SeventhSection";
import EightSection from "../../components/EightSection";
import NinthSection from "../../components/NinthSection";
import FooterStatic from "../../components/FooterStatic";
import { Element, scroller } from 'react-scroll';
import { useLocation, useNavigate } from "react-router-dom";

function Index() {
  const [showModal, setshowModal] = useState(false);
  const [showSuccessModal, setSuccessModal] = useState(false);

  const handleCloseModal = () => {
    setshowModal(false);
  };

  const handleShowModal = () => setshowModal(true);
  const handleShowSuccess = () => setSuccessModal(true);
  const handleCloseSuccessModal = () => setSuccessModal(false);

  // const location = useLocation();
  // const navigate = useNavigate();

  // useEffect(() => {
  //   // Listen for route changes and scroll to the target section if needed
  //   console.log(window.history)
  //   if (location.hash) {
  //     // scroller.scrollTo(location.hash.substring(1), {
  //     //   smooth: true,
  //     //   duration: 500,
  //     // });

  //     // Add a delay before scrolling
  //     const timer = setTimeout(() => {
  //       scroller.scrollTo(location.hash.substring(1), {
  //         smooth: true,
  //         duration: 500,
  //       });
  //     }, 1000); // Delay in milliseconds 

  //     // Remove the hash from the URL after scrolling
  //     window.history.replaceState(null, null, " ");
  //     // Cleanup timer when the component unmounts
  //     return () => clearTimeout(timer);
  //   }
  // }, [location]);

  return (
    <React.Fragment>
      <div className="font-body">

        <Header handleShowModal={handleShowModal} />
        <Element name="home">
          <section id="home">
            <IntroSection handleShowModal={handleShowModal} />
          </section>
        </Element>

        <SecondSection handleShowModal={handleShowModal} />
        <ThirdSection handleShowModal={handleShowModal} />
        <Element name="product">
          <section id="product">
            <FourthSection handleShowModal={handleShowModal} />
          </section>
        </Element>
        <FifthSection handleShowModal={handleShowModal} />
        <Element name="services">
          <section id="services">
            <SixthSection handleShowModal={handleShowModal} />
          </section>
        </Element>
        <Element name="about">
          <section id="about">
            <SeventhSection handleShowModal={handleShowModal} />
          </section>
        </Element>
        <Element name="insight">
          <section id="insight">
            <EightSection handleShowModal={handleShowModal} />
          </section>
        </Element>
        <NinthSection handleShowModal={handleShowModal} />
        <FooterStatic />
        <ConnectModal
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          handleShowSuccess={handleShowSuccess}
        />
        {/* connect success */}
        <ConnectSuccess
          handleCloseSuccessModal={handleCloseSuccessModal}
          showSuccessModal={showSuccessModal}
        />
      </div >
    </React.Fragment >
  );
}

export default Index;
