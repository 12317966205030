import React, { useState, useEffect } from "react";
import {
  Box,
  makeStyles,
  Typography,
  Paper,
  InputBase,
} from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SwitchCard from "./SwitchCard";
import {
  SWITCH_ALTERNATIVE_FUND,
  ASSISTED_SWITCH_FUNDS,
} from "../../../../utils/endpoints";
import httpInstance from "../../../../utils/instance";
import ReactLoading from "react-loading";
import SearchIcon from "@mui/icons-material/Search";
import { namespace } from "../../../../environment";
import InfiniteScroll from "react-infinite-scroll-component";
import { CircularProgress } from "@material-ui/core";


const LoaderComp = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "1rem",
        overflow: "hidden",
      }}
    >
      {/* <CircularProgress size="2rem" /> */}
      <ReactLoading
        type="spinningBubbles"
        color="#0E76BC"
        height={"4%"}
        width={"4%"}
      />
    </div>
  );
};



const Index = () => {
  const useStyles = makeStyles({
    subTabtext: {
      fontSize: "0.7rem",
      fontFamily: "inherit",
      fontWeight: 500,
    },
    textUnderline: {
      fontSize: "0.7rem",
      textDecoration: "underline",
      fontFamily: "inherit",
    },

    title: {
      fontSize: "1.1rem",
      fontWeight: 600,
    },
    textgrey: {
      fontSize: "0.8rem",
      color: "#626262",
    },
    searchBar: {
      padding: "5px 8px",
      display: "flex",
      alignItems: "center",
      margin: "20px 0px 30px 0px",
      borderRadius: "10px",
      "& .MuiSvgIcon-root": {
        opacity: 0.4,
      },
    },
    input: {
      marginLeft: "10px",
      flex: 1,
      width: 100,
    },
    text: {
      textAlign: "center",
    },
  });
  const classes = useStyles();

  const [alternativeFund, setAlternativeFund] = useState([]);
  const [filteredFund, setFilteredFund] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const set_page = () => setPageNumber(pageNumber + 1);

  const { state } = useLocation();
  const { schemeData, fundData, switchData, category } = state;
  // console.log(state);

  const get_fund = async () => {

    const formData = {
      amcIds: [fundData?.amc?.id],
      lockIn: 2,
      minBeta: -100,
      maxBeta: 100,
      minAlpha: -100,
      maxAlpha: 100,
      minStandardDeviation: -100,
      maxStandardDeviation: 100,
      limit: 10,
      pageNumber: pageNumber + 1,
      ratings: [],
      aumRange: [],
    }
    const httpConfig = {
      method: "POST",
      // tokenType: "user",
      test: 'test',
      // headers: {
      //   "Content-Type": "application/json",
      // },
      // params: {
      //   amc_code: fundData?.amc_code,
      //   scheme_code: fundData?.scheme_code,
      //   switch_amount: schemeData?.redeemableAmount,
      // },
      data: formData,
    };


    try {
      const response = await httpInstance(
        // `${category == "diy" ? SWITCH_ALTERNATIVE_FUND : ASSISTED_SWITCH_FUNDS}`,
        `${SWITCH_ALTERNATIVE_FUND}`,
        httpConfig
      );

      if (response?.data?.responseCount == 0) {
        setHasMore(false);
        setLoading(false);
      } else {
        if (response?.data?.responseCount >= 10) {
          setAlternativeFund([...alternativeFund, ...response.data?.data]);
          setFilteredFund([...filteredFund, ...response.data?.data]);
          setLoading(false);
          set_page();
          console.log('more is there ')
        } else {
          if (response?.data?.data){
          setAlternativeFund([...alternativeFund, ...response?.data?.data]);
          setFilteredFund([...filteredFund, ...response?.data?.data]);
          }
          setLoading(false);
          set_page();
          setHasMore(false);
          console.log("no more")
        }

      }



      // console.log(response.data?.data)
      // setAlternativeFund([...alternativeFund, ...response.data?.data]);
      // setFilteredFund([...filteredFund, ...response.data?.data]);
      // setLoading(false);
      // set_page();
    } catch { }
  };

  useEffect(() => {
    get_fund();
  }, []);

  // const get_fund_data = async () => {
  //   const httpConfig = {
  //     method: "GET",
  //     // tokenType: "user",
  //     test: 'test',
  //     // headers: {
  //     //   "Content-Type": "application/json",
  //     // },
  //     data: {
  //      formData
  //     },
  //   };

  //   const httpConfig1 = {
  //     method: "GET",
  //     tokenType: "user",
  //     // headers: {
  //     //   "Content-Type": "application/json",
  //     // },
  //   };

  // };

  const get_funds = ( ) =>{
    get_fund();
  }



  const handle_search = (e) => {
    let filteredArray = alternativeFund.filter((item) =>
      item.schemeName.toLowerCase().includes(e.target.value)
    );
    setFilteredFund(filteredArray);
  };

  return (
    <div>
      <Box
        display="flex"
        alignItems="start"
        px={{ xs: 1, md: 17 }}
        pt={2}
        // style={{ borderBottom: "1px solid #E6E3E3" }}
      >
        {/* <Link
          to={`${namespace}/myportfolio?tab=mutualfund`}
          style={{ color: "black" }}
        >
          <Typography className={classes.textUnderline}>
            Mutual Funds
          </Typography>
        </Link>
        <Box display="flex">
          <ChevronRightIcon style={{ height: "1rem" }} />
          <Typography className={classes.subTabtext}>SWITCH</Typography>
        </Box> */}
      </Box>
      {/* <Box
        display="flex"
        justifyContent="flex-start"
        px={{ xs: 2, md: 17 }}
        mt={2}
        className={classes.backButton}
      >
        <Link to={`${namespace}/myportfolio?tab=mutualfund`}>
          <img src="/images/backIcon.svg" height="20px" width="20px" />
        </Link>
      </Box> */}

      <Box px={{ xs: 1, md: 17 }} mt={2}>
      <Box
          display="flex"
          justifyContent="flex-start"
          alignContent={'center'}
          // px={17}
          mb={2}
          className={classes.backButton}
        >
          <Link to="/myportfolio" style={{display: 'flex', alignItems:' center', marginRight: '10px'}}>
            <img src="/images/backIcon.svg" height="20px" width="20px" alt="back" />
          </Link>
        <Typography className={classes.title}>SWITCH</Typography>
        </Box>
        <Typography className={classes.textgrey}>
          Transfer Invested money from one mutual fund to another within the
          same mutual fund family
        </Typography>

        <Box mt={4}>
          <Typography className={classes.textgrey}>TRANSFER TO</Typography>

          {/* search */}
          <Paper className={classes.searchBar} variant="outlined">
            <SearchIcon />
            <InputBase
              className={classes.input}
              placeholder="Search Funds"
              inputProps={{ "aria-label": "Search for Mutual Funds" }}
              onChange={handle_search}
            />
          </Paper>

          {/* list */}

          {!loading ? (

            <InfiniteScroll
              dataLength={filteredFund.length}
              next={get_funds}
              hasMore={hasMore}
              loader={<LoaderComp />}
              endMessage={
                <Typography className={classes.text}>
                  No result to display
                </Typography>
              }
            >
              {filteredFund?.map((fund, i) => {
                return (
                  <SwitchCard
                    key={i}
                    fundToData={fund}
                    schemeData={schemeData}
                    fundFromData={fundData}
                    switchData={switchData}
                  />
                );
              })}
            </InfiniteScroll>
          ) : (
            <Box mt={3} display="flex" justifyContent="center">
              <ReactLoading
                type="spinningBubbles"
                color="#0E76BC"
                height={"4%"}
                width={"4%"}
              />
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default Index;
