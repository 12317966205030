import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  makeStyles,
  CircularProgress,
  Button,
} from "@material-ui/core";
import httpInstance from "../../utils/instance";
import Lumsum from "./InvestType/Lumsum";
import Sip from "./InvestType/Sip";
import {
  CREATE_LUMSUM,
  INITIATE_PAYMENT,
  KYC_DATA,
  GET_LUMSUM_DETAIL,
  KYC_STATUS,
} from "../../utils/endpoints";

const Mf_Invest = ({ fundData, show_banner, fpData }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      // border: "1px solid #E4E4E4",
      borderRadius: "12px",
      // boxShadow: "0 0 8px -3px #444444",
      border: '1px solid #E4E4E4',
      // boxShadow: '0 0 8px -3px #444444',
      boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
    },
    stickyPart: {
      position: "sticky",
      top: "13%",
      width: "100%",
      zIndex: 100,
    },

    box2: {
      borderBottom: "1px solid #E4E4E4",
    },
    box3: {
      padding: "1rem 0px",
      cursor: "pointer",
    },
    bluebox3: {
      padding: "1rem 0px",
      color: "#0E76BC",
      borderBottom: "3px solid #0E76BC",
      cursor: "pointer",
    },

    // Button tabs design 
    tabs: {
      display: 'flex',
      position: 'relative',
      overflow: 'hidden',
      border: '1px solid #0E76BC',
      borderRadius: '10px',

    },
    tablink: {
      borderRadius: '10px',
      flex: 1,
      fontSize: "1rem",
      fontWeight: 600,
      // padding: '10px 20px',
      borderRadius: 0,
      '&:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
      position: 'relative',
      zIndex: 1,
      color: '#0E76BC',
      textTransform: 'capitalize',
    },
    active: {
      borderRadius: '10px',
      backgroundColor: '#0E76BC',
      color: theme.palette.secondary.contrastText,
    },
    activeBox: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '50%',
      height: '100%',
      // backgroundColor: theme.palette.secondary.main,
      backgroundColor: '#0E76BC',
      borderRadius: '8px',

      transition: 'left 0.3s ease-in-out',
    },


    ButtonInactive: {
      border: "none",
      background: "none",
      padding: "5px 20px",
      borderRadius: "20px",
      fontSize: "0.75rem",
      fontFamily: "inherit",
      marginRight: "1rem",
      marginLeft: "1rem",
      cursor: "pointer",
      outline: "none",
      "&:hover": {
        background: "#0E76BC",
        color: "white",

      },
      "&:active": {
        background: "#0E76BC",
        color: "white"
      }
    },

    activeButton: {
      background: "#0E76BC",
      color: "white",
      padding: "5px 20px",
      border: "none",
      borderRadius: "20px",
      fontSize: "0.75rem",
      fontFamily: "inherit",
      marginRight: "1rem",
      marginLeft: "1rem",
      cursor: "pointer",
      outline: "none",
    },
    activeYearButton: {
      background: "#0E76BC",
      color: "white",
      padding: "5px 20px",
      border: "none",
      borderRadius: "20px",
      fontSize: "0.7rem",
      fontWeight: 700,
      fontFamily: "inherit",
      marginRight: "1rem",
      marginLeft: "1rem",
      cursor: "pointer",
      outline: "none",

    },

    inactiveYearButton: {
      border: "1px solid #0E76BC ",
      background: "none",
      padding: "5px 20px",
      borderRadius: "20px",
      fontSize: "0.7rem",
      fontWeight: 700,
      fontFamily: "inherit",
      marginRight: "1rem",
      marginLeft: "1rem",
      color: "#0E76BC",
      cursor: "pointer",
      outline: "none",
      "&:hover": {
        background: "#0E76BC",
        color: "white",

      },
      "&:active": {
        background: "#0E76BC",
        color: "white"
      }
    },
    "@media (max-width: 959.99px)": {
      stickyPart: {
        position: "static", // Use "static" to remove sticky positioning
        // top: "15%", // No need for `top` when `position` is `static`
        width: "100%",
        zIndex: 0,
      },
       
    },

  }));
  const classes = useStyles();
  const [tab, setTab] = useState("sip");
  const [kycStatus, setKycStatus] = useState();
  const [kycData, setKycData] = useState(null)
  const [activeTab, setActiveTab] = useState('sip');
  const [activeBoxPosition, setActiveBoxPosition] = useState(0);

  const get_kycData = async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await httpInstance(`${KYC_STATUS}`, httpConfig);
      setKycData(response?.data?.data)
      setKycStatus(response.data?.data?.fp_kyc_status);
    } catch {
      setKycStatus("empty");
    }
  };

  useEffect(() => {
    get_kycData();

  }, []);

  useEffect(() => {
    // console.log(fundData?.stateValue)
    if (fundData?.stateValue?.transaction_basket_items[0]?.transaction_type) {
      tabClickHandler(fundData?.stateValue?.transaction_basket_items[0]?.transaction_type, fundData?.stateValue?.transaction_basket_items[0]?.transaction_type === 'sip' ? 0 : 50)
    }
  }, [fundData?.stateValue?.transaction_basket_items[0]?.transaction_type])

  // const handle_tab = (data) => {
  //   setTab(data);
  // };

  //-------------------------------handle lumsum invest----------------------------------------------------------//
  // const handle_invest= async()=>{
  //   if(sessionStorage.getItem("mpin")){
  //     if(kycStatus=="successful"){

  //      }else{
  //         show_banner()
  //      }
  //   }else{
  //     navigate("/user")
  //   }
  // }

  // console.log('fpdata', fundData, show_banner, fpData)




  const tabClickHandler = (tabName, position) => {
    setActiveTab(tabName);
    setActiveBoxPosition(position);
  };

  useEffect(()=>{
    if(fundData?.folio_number){
      tabClickHandler('lumpsum', 50)
    }

  },[fundData?.folio_number])


  return (
    <div className={classes.stickyPart}>
      <Box className={classes.root} mt={{ xs: 5, md: 0 }}>
        {/* <Box
          display="flex"
          justifyContent="space-between"
          px={6}
          className={classes.box2}
        >
          <Box
            className={tab == "sip" ? classes.bluebox3 : classes.box3}
            onClick={() => handle_tab("sip")}
          >
            <Typography>Monthly SIP</Typography>
          </Box>
          <Box
            className={tab == "lumpsum" ? classes.bluebox3 : classes.box3}
            onClick={() => handle_tab("lumpsum")}
          >
            <Typography>Lumpsum</Typography>
          </Box>
        </Box> */}
        <Box display='flex' justifyContent='center' pt={3} pb={1} px={1}>
          <Box className={classes.tabsFolder} maxWidth="350px" width='100%'>
            <div className={classes.tabs}>
              <div className={classes.activeBox} style={{ left: `${activeBoxPosition}%` }}></div>
              <Button
                className={`${classes.tablink} ${activeTab === 'sip' ? classes.active : ''}`}
                onClick={() => tabClickHandler('sip', 0)}
              // onMouseEnter={() => tabHoverHandler('tab1', 0)}
              >
                Start SIP
              </Button>
              <Button
                className={`${classes.tablink} ${activeTab === 'lumpsum' ? classes.active : ''}`}
                onClick={() => tabClickHandler('lumpsum', 50)}>
                One-Time
              </Button>
            </div>
          </Box>
        </Box>
        {/* <hr style={{margin: 0,  border: '1px solid #E4E4E4'}}></hr> */}
        {activeTab == "lumpsum" ? (
          <Lumsum
            fundData={fundData}
            fpData={fpData}
            kycStatus={kycStatus}
            show_banner={show_banner}
            kycData={kycData}
          />
        ) : (
          <Sip
            fundData={fundData}
            fpData={fpData}
            kycStatus={kycStatus}
            show_banner={show_banner}
            kycData={kycData}

          />
        )}
      </Box>
    </div>
  );
};

export default Mf_Invest;
