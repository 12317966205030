import React, { useState } from "react";
import { Box, Typography, makeStyles, Grid, capitalize } from "@material-ui/core";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link, useLocation } from "react-router-dom";
import { useStyles } from "../../../../components/signup/styles";
import moment from "moment";
import SouthIcon from "@mui/icons-material/South";
import { namespace } from "../../../../environment";
import number_ordinals from "../../../../hooks/NumberOrdinal";
import httpInstance from "../../../../utils/instance";
import ConfirmStopSip from "../DetailSip/ConfirmStopSip";
import { STOP_SIP, STOP_STP, STOP_SWP } from "../../../../utils/endpoints";
import {
  useNavigate,
} from "react-router-dom";
import LocaleStringTwoMin from "../../../../hooks/LocaleStringTwoMin";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

const DetailOrder = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      // minWidth: "30rem",
      // border: "1px solid #C4C4C4",
      // borderRadius: "6px",
      borderRadius: "12px",
      // boxShadow: "0 0 8px -3px #444444",
      border: '1px solid #E4E4E4',
      // boxShadow: '0 0 8px -3px #444444',
      boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
      padding: "1rem",
    },
    verticleLine: {
      borderLeft: "0.5px dashed #C4C4C4",
      height: "50px",
      marginTop: "-5px",
      marginLeft: "7px",
    },
    verticleLineSIP: {
      borderLeft: "0.5px dashed #C4C4C4",
      height: "50px",
      marginTop: "-5px",
      marginLeft: "12px",
    },
    verticleLineSIPNew: {
      borderLeft: "0.5px dashed #C4C4C4",
      height: "50px",
      // marginTop: "-5px",
      // marginLeft: "12px",
    },
    boxBorder: {
      borderBottom: "1px solid #C4C4C4",
    },

    header: {
      fontSize: "1.2rem",
      fontWeight: 500,
      textAlign: "center",
      textTransform: 'capitalize',
    },
    title: {
      fontSize: "1.4rem",
    },
    text1: {
      fontSize: "0.9rem",
      fontWeight: 600,
    },

    textgrey: {
      fontSize: "0.75rem",
      color: "#C0C0C0",
      marginBottom: "10px",
    },
    textgreyDetails: {
      fontSize: "0.8rem",
      color: "#292929",
      marginBottom: "10px",
    },

    text2: {
      fontSize: "0.75rem",
      fontWeight: 600,
      marginBottom: "10px",
    },
    transText: {
      fontSize: "0.8rem",
      color: "#676767",
    },
    orderText: {
      fontSize: "0.72rem",
      color: "#676767",
    },
    orderTextBold: {
      fontSize: "0.72rem",
      color: "#676767",
      fontWeight: 600,
    },
    subTabtext: {
      fontSize: "0.7rem",
      fontFamily: "inherit",
      fontWeight: 500,
    },
    textUnderline: {
      fontSize: "0.7rem",
      textDecoration: "underline",
      fontFamily: "inherit",
    },

    rupees: {
      fontFamily: "Open Sans",
    },

    unitBox: {
      background: "#E6E6E6",
      borderRadius: "5px",
      fontSize: "0.6rem",
      color: "#676767",
      marginLeft: "3.2rem",
    },
    buttonBorder: {
      border: "2px solid #0E76BC",
      borderRadius: "30px",
      color: "#0E76BC",
      padding: "0.8rem 3rem",
      background: "#fff",
      fontWeight: 500,
      cursor: "pointer",
      ":hover": {
        color: "#fff",
        background: "#0E76BC",
      }
    },
    "@media(max-width:600px)": {
      buttonBorder: {
        marginTop: '10px',
        padding: "1rem 2rem",
      },
    },
    "@media(min-width:601px)": {
      root: {
        minWidth: "30rem",
      },
    },
  }));

  const classes = useStyles();

  const { state } = useLocation();
  const { data, order, orderType, returnBack } = state;
  // console.log(data)

  const Stepper = ({ title, subtTitle, img, verticleLine }) => {
    return (
      <Grid container>
        <Grid item xs={2} sm={1} md={1} style={{ display: "flex", flexDirection: 'column', alignItems: 'center' }}>
          <img src={img} height="25px" width="25px" />
          {verticleLine && <div className={classes.verticleLineSIPNew}></div>}
        </Grid>
        <Grid item xs={10} md={6}>
          <Typography className={classes.orderTextBold}>{title}</Typography>
          <Typography className={classes.orderText}>{subtTitle}</Typography>
        </Grid>
      </Grid>
    );
  };

  const orderTypeObj = {
    1: "Lumpsum",
    2: "SIP",
    3: "Redemption",
    4: "Switch",
    5: "STP",
    6: "SWP",
  };

  const [showModal, setShowModal] = useState(false)
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  const handle_stop_SIP = async (selectionData) => {
    let httpConfig;

    if (orderType === 2) {
      httpConfig = {
        method: "GET",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        params: { type: 'sip', fp_id: data?.fp_sip_id, cancellation_code: selectionData },
      };
    }
    else if (orderType === 5) {
      httpConfig = {
        method: "GET",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        data: { type: 'stp', fp_id: data?.fp_stp_id, },
      };
    } else if (orderType === 6) {
      httpConfig = {
        method: "GET",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        data: { type: 'swp', fp_id: data?.fp_swp_id, },
      };
    }

    try {
      if (orderType === 2) {
        await httpInstance(`${STOP_SIP}`, httpConfig);
      }
      else if (orderType === 5) {
        await httpInstance(`${STOP_STP}`, httpConfig);
      } else if (orderType === 6) {
        await httpInstance(`${STOP_SWP}`, httpConfig);
      }

      setShowModal(false);
      // setStop(true);
      navigate(`${namespace}/my-profile/my-orders`);
    } catch (error) {
      setMsg(error?.response?.data?.message);
    }
  };

  const [showSIPInstallment, setShowSIPInstallment] = useState('');

  return (
    <div>
      <ConfirmStopSip
        show={showModal}
        handleClose={() => setShowModal(false)}
        handle_stop_SIP={handle_stop_SIP}
        type={orderTypeObj[orderType]}
        msg={msg}
      />
      {/* <Box
        display="flex"
        alignItems="start"
        px={{ xs: 1, md: 17 }}
        pt={2}
        style={{ borderBottom: "1px solid #E6E3E3" }}
      >
        <Link
          to="/myportfolio?tab=mutualfund&category=diy"
          style={{ color: "black" }}
        >
          <Typography className={classes.textUnderline}>
            My Portfolio
          </Typography>
        </Link>
        <Box display="flex">
          <ChevronRightIcon style={{ height: "1rem" }} />
          <Typography
            className={classes.subTabtext}
            display="flex"
            alignItems="start"
          >
            {orderTypeObj[data?.orderType]} Order Status
          </Typography>
        </Box>
      </Box> */}
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems={'center'}
        px={{ xs: 2, md: 30 }}
        mt={4}
        className={classes.backButton}
      >
        {showSIPInstallment ?
          <Box onClick={() => setShowSIPInstallment('')} style={{ cursor: "pointer" }} display={'flex'} alignItems={'center'}>
            <img src="/images/backIcon.svg" height="20px" width="20px" />

          </Box>
          :
          <Link
            to={
              order
                ? `${namespace}/my-profile/my-orders?order=${returnBack[0]}&status=${returnBack[1]}`
                : `/myportfolio`
            }
            style={{ display: "flex", alignItems: 'center' }}
          >
            <img src="/images/backIcon.svg" height="20px" width="20px" />
          </Link>
        }
        <Typography className={classes.header} style={{ paddingLeft: '10px' }}>
          {orderTypeObj[orderType]} Order {showSIPInstallment && "Installment"}
        </Typography>
      </Box>
      {/* {console.log(data, order, orderType, showSIPInstallment, 'order')} */}
      <Box>
        {showSIPInstallment ?
          <Box px={{ xs: 2, md: 30 }}>
            <Box textAlign="center">
              {(showSIPInstallment?.state === 'pending' || showSIPInstallment?.state === 'successful' || showSIPInstallment?.state === 'failed') &&
                <img src={`/images/${showSIPInstallment?.state} state.svg`} height={80} width={'auto'} alt="logo " style={{ paddingRight: '10px' }} />
              }
            </Box>

            <Typography className={classes.header}>
              Your {orderTypeObj[orderType]} Order is {showSIPInstallment?.state}{showSIPInstallment?.status}
            </Typography>
            <Box className={classes.root} mt={2} p={2}>
              <Box
                className={classes.boxBorder}
                pb={1}
                px={{ xs: 0, md: 3 }}
                display="flex"
                alignItems={'center'}
                justifyContent="space-between"
              >
                <Grid container >
                  <Grid item xs={12} md={8}>
                    <Box display="flex"
                      alignItems={'center'}>
                      <img src={data?.logo_url} height={40} width={'auto'} alt="logo " style={{ paddingRight: '10px' }} />
                      <Box>
                        <Typography className={classes.text1}>
                          {(orderType == 1 ||
                            orderType == 2 ||
                            orderType == 3 ||
                            orderType == 6) &&
                            data?.fund_name}
                          {/* {(data?.orderType == 4 || data?.orderType == 5) && */}
                          {(orderType == 4 || orderType == 5) &&
                            data?.fund_name}
                        </Typography>
                        {/* {(data?.orderType == 4 || data?.orderType == 5) && ( */}
                        {(orderType == 4 || orderType == 5) && (
                          <>
                            <SouthIcon />
                            <Typography className={classes.text1}>
                              {data?.to_fund_name}
                            </Typography>
                          </>
                        )}
                        {data?.units ? (
                          <Typography className={classes.title}>
                            {" "}
                            {data?.units} {`unit${data?.units > 1 ? "s " : ""}`}
                          </Typography>
                        ) : (
                          <Box display={'flex'}>
                            <Typography className={classes.title}>
                              <span className={classes.textgreyDetails}>Amount:</span>
                              <span className={classes.rupees}>₹</span>
                              {LocaleStringTwoMin(Number(showSIPInstallment?.amount))}
                              {" "}
                            </Typography>
                            {/* {console.log(data)} */}
                            {/* {showSIPInstallment?.allotted_units &&
                              <Typography className={classes.title} style={{ marginBottom: '0px' }}>
                                <span className={classes.textgreyDetails}>{`unit${showSIPInstallment?.allotted_units > 1 ? "s " : ""}`}
                                </span>

                                {" "}
                                {showSIPInstallment?.allotted_units}
                                 {`unit${showSIPInstallment?.allotted_units > 1 ? "s " : ""}`} 
                              </Typography>
                            } */}
                          </Box>
                        )}


                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={4} style={{ display: "flex", alignItems: 'center', justifyContent: "end" }}>
                    {/* <Box display="flex" justifyContent="end" alignContent={'center'}>
                      {orderType == 2 &&
                        <button
                          className={classes.buttonBorder}
                          onClick={() => setShowModal(true)}
                        >{`Stop SIP`}</button>
                      }
                    </Box> */}
                    {showSIPInstallment?.allotted_units &&
                      <Box display="flex" justifyContent="end" alignContent={'end'} flexDirection={'column'}>
                        <span style={{ textAlign: 'end', margin: 0 }}
                          className={classes.textgreyDetails}>{`Unit${showSIPInstallment?.allotted_units > 1 ? "s " : ""}`}
                        </span>
                        <Typography className={classes.title} style={{ marginBottom: '0px' }}>


                          {" "}
                          {showSIPInstallment?.allotted_units}
                          {/* {`unit${showSIPInstallment?.allotted_units > 1 ? "s " : ""}`} */}
                        </Typography>
                      </Box>

                    }
                  </Grid>

                </Grid>
              </Box>
              <Box className={classes.boxBorder} py={2}
                // px={{ xs: 2, md: 10 }}
                px={{ xs: 0, md: 3 }}

              >
                <Grid container>
                  {/* <Grid item xs={6} md={3} >
                    <Typography className={classes.textgreyDetails}>Nav Date</Typography>
                  </Grid>
                  <Grid item xs={6} md={3} >
                    <Typography className={classes.text2}>
                      {moment(data?.created_at)
                        .subtract(1, "days")
                        .format("DD MMM YYYY")}
                    </Typography>
                  </Grid> */}
                  <Grid item xs={6} md={3} >
                    <Typography className={classes.textgreyDetails}>
                      Folio no.
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={3} >
                    <Typography className={classes.text2}>
                      {showSIPInstallment?.folio_number && showSIPInstallment?.folio_number}
                      {/* {data?.fund_isin && data?.fund_isin} */}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={3} >
                    <Typography className={classes.textgreyDetails}>Order ID</Typography>
                  </Grid>
                  <Grid item xs={6} md={3} >

                    <Typography
                      style={{ fontSize: "0.75rem", marginBottom: "10px" }}
                    >
                      {showSIPInstallment?.id ? showSIPInstallment?.id : "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={3} >

                    <Typography className={classes.textgreyDetails}>
                      {showSIPInstallment?.next_installment_date ?
                        "SIP Date"
                        :
                        "Order Date"
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={3} >

                    <Typography style={{ fontSize: "0.75rem" }}>
                      {showSIPInstallment?.next_installment_date ?
                        moment(showSIPInstallment?.created_at).format("DD MMM YYYY hh:mm A")
                        :
                        moment(showSIPInstallment?.created_at).format("DD MMM YYYY hh:mm A")
                      }
                    </Typography>
                  </Grid>
                  {data?.mandate &&
                    <>
                      <Grid item xs={6} md={3} >
                        <Typography className={classes.textgreyDetails}>
                          Mandate
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={3} >

                        <Typography style={{ fontSize: "0.75rem", textTransform: "uppercase" }}>
                          {data?.Mandate?.bank?.name ? data?.frequency?.bank?.name : "-"}
                        </Typography>
                      </Grid>
                    </>
                  }
                  {data?.frequency &&
                    <>
                      <Grid item xs={6} md={3} >
                        <Typography className={classes.textgreyDetails}>
                          SIP Frequency
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={3} >

                        <Typography style={{ fontSize: "0.75rem", textTransform: "uppercase" }}>
                          {showSIPInstallment?.frequency ? showSIPInstallment?.frequency : "-"}
                        </Typography>
                      </Grid>
                    </>
                  }
                  <Grid item xs={6} md={3} >
                    <Typography className={classes.textgreyDetails}>
                      Order Status
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={3} >

                    <Typography style={{ fontSize: "0.75rem", textTransform: "uppercase" }}>
                      {showSIPInstallment?.state ? showSIPInstallment?.state : showSIPInstallment?.status || "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box py={4} px={{ xs: 0, md: 3 }}>
                <Typography className={classes.text1}>

                  Order Status

                </Typography>
                <Box p={1} mt={2}>
                  {/* {data?.orderType == 1 || data?.orderType == 2 ? ( */}

                  {orderType == 1 &&

                    data?.state == "pending" ? (
                    <>
                      <Stepper
                        title={"Order Placed on MF Wealth "}
                        subtTitle={moment(data?.created_at).format(
                          "DD MMM YYYY hh:mm A"
                        )}
                        img={"/images/Icontick.svg"}
                        verticleLine={true}
                      />
                      <Stepper
                        title={
                          data?.submitted_at
                            ? "Payment Pending"
                            : "Payment Processing"
                        }
                        subtTitle={
                          data?.submitted_at
                            ? moment(data?.submitted_at).format(
                              "DD MMM YYYY hh:mm A"
                            )
                            : ""
                        }
                        img={
                          // "/images/Icontimer.svg"
                          data?.submittedAt
                            ? "/images/Icontick.svg"
                            : "/images/Icontimer.svg"
                        }
                        verticleLine={data?.submitted_at ? true : false}
                      />

                      {data?.submitted_at && (
                        <>
                          <Stepper
                            title={"Unit(s) allocation in Progress"}
                            subtTitle={"Expect by 2-3 working days"}
                            img={"/images/Icontimer.svg"}
                            verticleLine={false}
                          />

                          <Box p={1} mt={3} className={classes.unitBox}>
                            <Typography
                              style={{ fontSize: "0.8rem" }}
                            >{`We are currently awaiting the status on your unit allocation from
                       ${data?.fundName
                              }. Dont worry, the NAV applicable will be as on ${moment(
                                data?.created_at
                              )
                                .subtract(1, "days")
                                .format("DD MMM YYYY")}`}</Typography>
                          </Box>
                        </>
                      )}
                    </>
                  ) : data?.state == "successful" ? (
                    <>
                      <Stepper
                        title={"Order Placed on MF Wealth "}
                        subtTitle={moment(data?.created_at).format(
                          "DD MMM YYYY hh:mm A"
                        )}
                        img={"/images/Icontick.svg"}
                        verticleLine={true}
                      />
                      <Stepper
                        title={"Payment Successful"}
                        subtTitle={moment(data?.submittedAt).format(
                          "DD MMM YYYY hh:mm A"
                        )}
                        img={"/images/Icontick.svg"}
                        verticleLine={true}
                      />
                      <Stepper
                        title={"Unit(s) allocation Completed"}
                        subtTitle={moment(data?.succeededAt).format(
                          "DD MMM YYYY hh:mm A"
                        )}
                        img={"/images/Icontick.svg"}
                        verticleLine={false}
                      />
                    </>
                  ) : data?.state == "failed" ? (
                    <>
                      <Stepper
                        title={"Order Placed on MF Wealth "}
                        subtTitle={moment(data?.created_at).format(
                          "DD MMM YYYY hh:mm A"
                        )}
                        img={"/images/Icontick.svg"}
                        verticleLine={true}
                      />
                      <Stepper
                        title={"Payment Failed"}
                        subtTitle={moment(data?.failed_at).format(
                          "DD MMM YYYY hh:mm A"
                        )}
                        img={"/images/failed.svg"}
                        verticleLine={false}
                      />
                    </>
                  ) : (
                    " "
                  )}
                  {/* // ) : ( */}
                  {(orderType !== 1 && orderType !== 2) && (
                    <>
                      <Stepper
                        title={
                          // data?.orderType == 3
                          //   ? "Redeem Order Placed"
                          //   : data?.orderType == 4
                          //     ? "Switch Order Placed"
                          //     : data?.orderType == 5
                          //       ? "STP Order Placed"
                          //       : data?.orderType == 6 && "SWP Order Placed"
                          orderType == 3
                            ? "Redeem Order Placed"
                            : orderType == 4
                              ? "Switch Order Placed"
                              : orderType == 5
                                ? "STP Order Placed"
                                : orderType == 6 && "SWP Order Placed"
                        }
                        subtTitle={moment(data?.created_at).format(
                          "DD MMM YYYY hh:mm A"
                        )}
                        img={"/images/Icontick.svg"}
                        verticleLine={true}
                      />

                      <Stepper
                        title={
                          data?.state == "Pending"
                            ? "Order Processing"
                            : data?.state == "Successful"
                              ? "Order Successful  "
                              : "Order Failed"
                        }
                        subtTitle={""}
                        img={
                          data?.state == "Pending"
                            ? "/images/Icontimer.svg"
                            : data?.state == "Successful"
                              ? "/images/Icontick.svg"
                              : "/images/Icontimer.svg"
                        }
                        verticleLine={false}
                      />
                    </>
                  )}


                  {orderType == 2 &&
                    <Box mt={0}>
                      {showSIPInstallment ?
                        <>
                          {showSIPInstallment?.created_at &&
                            <Stepper
                              title={
                                'SIP Order Placed'
                              }
                              subtTitle={moment(showSIPInstallment?.created_at).format(
                                "DD MMM YYYY hh:mm A"
                              )}
                              img={"/images/Icontick.svg"}
                              verticleLine={true}
                            />
                          }
                          <Stepper
                            title={
                              `Payment ${showSIPInstallment?.confirmed_at ? "Successful" : "Failed"}`

                            }
                            subtTitle={moment(showSIPInstallment?.confirmed_at).format(
                              "DD MMM YYYY hh:mm A"
                            )}
                            // img={"/images/Icontick.svg"}
                            img={
                              // "/images/Icontimer.svg"
                              showSIPInstallment?.confirmed_at
                                ? "/images/Icontick.svg"
                                : "/images/failed.svg"
                            }
                            verticleLine={true}
                          />
                          <Stepper
                            title={
                              `Order ${showSIPInstallment?.succeeded_at ? "Successful" : "Failed"}`
                            }
                            subtTitle={moment(showSIPInstallment?.succeeded_at).format(
                              "DD MMM YYYY hh:mm A"
                            )}
                            // img={"/images/Icontick.svg"}
                            img={
                              // "/images/Icontimer.svg"
                              showSIPInstallment?.succeeded_at
                                ? "/images/Icontick.svg"
                                : "/images/failed.svg"
                            }
                            verticleLine={true}
                          />
                          <Stepper
                            title={
                              `Unit ${showSIPInstallment?.succeeded_at ? "Allocated" : "Allocation Failed"}`
                            }
                            subtTitle={moment(showSIPInstallment?.succeeded_at).format(
                              "DD MMM YYYY hh:mm A"
                            )}
                            // img={"/images/Icontick.svg"}
                            img={
                              // "/images/Icontimer.svg"
                              showSIPInstallment?.succeeded_at
                                ? "/images/Icontick.svg"
                                : "/images/failed.svg"
                            }
                            verticleLine={false}
                          />
                        </>
                        :
                        <>
                          {/* <Typography>Transaction History</Typography> */}
                          {data?.installments?.length > 0 && (
                            <Box mt={3}>
                              {data?.status == "active" && (
                                <Grid container>
                                  <Grid item xs={1} >
                                    <img src="/images/info.svg" height="20px" width="20px" />
                                    <div className={classes.verticleLineSIP}></div>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography className={classes.transText}>
                                      {number_ordinals(data?.installments?.length + 1)}{" "}
                                      {`${orderTypeObj[orderType]}`} Due{" "}
                                    </Typography>
                                    <Typography className={classes.transText}>
                                      in{" "}
                                      {moment(data?.installments[data?.installments.length - 1]?.next_installment_date).diff(
                                        moment(new Date()),
                                        "days"
                                      )}{" "}
                                      days on {moment(data?.installments[data?.installments.length - 1]?.next_installment_date).format("DD MMM YYYY")}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              )}
                              {data?.installments?.map((ele, i) => {
                                // console.log(ele)
                                return (
                                  <Grid key={ele.id} container
                                    style={{
                                      cursor: orderTypeObj[orderType] === 'SIP' ? 'pointer' : 'default'
                                    }}
                                    onClick={() => {
                                      if (orderTypeObj[orderType] === 'SIP' &&
                                        //  ele.state == "successful" &&
                                        ele.created_at) {

                                        setShowSIPInstallment(ele)
                                      }
                                    }}>
                                    <Grid item xs={1}>
                                      <img
                                        src={
                                          ele.state == "successful"
                                            ? "/images/Icontick.svg"
                                            : ele.state == "failed"
                                              ? "/images/failed.svg"
                                              : "/images/Icontimer.svg"
                                        }
                                        height="20px"
                                        width="20px"
                                      />
                                      {i + 1 != data?.installments?.length && (
                                        <div className={classes.verticleLineSIP}></div>
                                      )}
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Typography className={classes.transText}>
                                        {number_ordinals(data?.installments?.length - i)}{" "}
                                        {`${orderTypeObj[orderType]} ${ele?.state == "successful"
                                          ? orderType === 2
                                            ? "Paid"
                                            : "Processed"
                                          : ele.state == "failed"
                                            ? "Failed"
                                            : "Pending"
                                          }`}{" "}
                                      </Typography>
                                      <Typography className={classes.transText}>
                                        {moment(
                                          ele.state == "successful"
                                            ? ele.submitted_at
                                            : ele.state == "failed"
                                              ? ele.failed_at
                                              : ele.submitted_at || ele.created_at
                                        ).format("DD MMM YYYY hh:mm A")}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                );
                              })}
                            </Box>
                          )}
                        </>
                      }
                    </Box>
                  }

                  {/* {data?.status=="Successful" && 
                     ((data.orderType==1 || data.orderType==2 ) &&
                     <>
                     <Stepper
                     title={ "Payment Successful"}
                     subtTitle={moment(data?.submittedAt).format("DD MMM YYYY hh:mm A")}
                     img={ "/images/Icontick.svg"}
                     verticleLine={true}
                     />
                     <Stepper
                    title={"Unit(s) allocation Completed"}
                    subtTitle={moment(data?.succeededAt).format("DD MMM YYYY hh:mm A")}
                    img={"/images/Icontimer.svg"}
                    verticleLine={false}
                    />
                     </>
                     ) 
                     
                  } */}
                </Box>
              </Box>
            </Box>
          </Box>

          :
          <Box px={{ xs: 2, md: 30 }}>

            <Box textAlign="center">
              {(data?.state === 'pending' || data?.state === 'successful' || data?.state === 'failed' || data?.state === 'confirmed') &&
                <img src={`/images/${data?.state === 'confirmed' ? "successful" : data?.state} state.svg`} height={80} width={'auto'} alt="logo " style={{ paddingRight: '10px' }} />
              }
            </Box>

            <Typography className={classes.header}>
              {orderTypeObj[orderType]} Order Has Been {data?.state}{data?.status}
            </Typography>
            <Box className={classes.root} mt={2} p={2}>
              <Box
                className={classes.boxBorder}
                pb={1}
                px={{ xs: 0, md: 3 }}
                display="flex"
                alignItems={'center'}
                justifyContent="space-between"
              >
                <Grid container >
                  <Grid item xs={12} md={8}>
                    <Box display="flex"
                      alignItems={'center'}>
                      {
                        (orderType == 1 ||
                          orderType == 2 ||
                          orderType == 3 ||
                          orderType == 6)
                        &&
                        <img src={data?.logo_url} height={40} width={'auto'} alt="logo " style={{ marginRight: '10px', borderRadius: '5px' }} />
                      }
                      <Box>
                        {
                          (orderType == 1 ||
                            orderType == 2 ||
                            orderType == 3 ||
                            orderType == 6)
                          &&
                          <Typography className={classes.text1}>
                            {data?.fund_name}
                          </Typography>
                        }
                        {(orderType == 4 || orderType == 5) && (
                          <Box>
                            <Box display={'flex'} alignItems={'center'} border={'2px solid #c4c4c4'} p={1} borderRadius={'12px'}>
                              <img src={data?.logo_url} height={40} width={'auto'} alt="logo " style={{ marginRight: '10px', borderRadius: '5px' }} />

                              <Typography className={classes.text1}>
                                {data?.fund_name}
                              </Typography>
                            </Box>
                            <Box display={'flex'} justifyContent={'center'}>
                              <KeyboardDoubleArrowDownIcon style={{fontSize: '35px', color: '#0E76BC', fontWeight: 600}}/>
                            </Box>
                            <Box display={'flex'} alignItems={'center'} border={'2px solid #0E76BC'} p={1} borderRadius={'12px'}>
                              <img src={data?.to_fund_logo_url} height={40} width={'auto'} alt="logo " style={{ marginRight: '10px', borderRadius: '5px' }} />

                              <Typography className={classes.text1}>
                                {data?.to_fund_name}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                        {data?.units ? (
                          <Typography className={classes.title}>
                            {" "}
                            {data?.units} {`unit${data?.units > 1 ? "s " : ""}`}
                          </Typography>
                        ) : (
                          <Box mt={1}>
                            <Typography className={classes.title}>
                              <span className={classes.textgreyDetails}>Amount:{" "}</span>
                              <span className={classes.rupees}>₹</span>
                              {LocaleStringTwoMin(Number(data?.amount))}
                              {" "}
                            </Typography>
                            {/* {console.log(data)} */}
                            {data?.allotted_units &&
                              <Typography className={classes.title} style={{ marginBottom: '0px' }}>
                                {" "}
                                {data?.allotted_units} {`unit${data?.allotted_units > 1 ? "s " : ""}`}
                              </Typography>
                            }
                          </Box>
                        )}


                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={4} style={{ display: "flex", alignItems: 'center', justifyContent: "end" }}>
                    <Box display="flex" justifyContent="end" alignContent={'center'}>
                      {(orderType === 2 || orderType === 6 || orderType === 5) &&
                        <button
                          className={classes.buttonBorder}
                          onClick={() => setShowModal(true)}
                        >{orderType === 6 ? `Stop SWP` : (orderType === 5 ? `Stop STP` : `Stop SIP`)}</button>
                      }
                    </Box>
                  </Grid>

                </Grid>
              </Box>
              <Box className={classes.boxBorder} py={2}
                // px={{ xs: 2, md: 10 }}
                px={{ xs: 0, md: 3 }}

              >
                <Grid container>
                  {/* <Grid item xs={4}>
                  <Typography className={classes.textgrey}>Nav Date</Typography>
                  <Typography className={classes.textgrey}>
                    Folio no.
                  </Typography>
                  <Typography className={classes.textgrey}>Order ID</Typography>
                  <Typography className={classes.textgrey}>
                    Transaction Date
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography className={classes.text2}>
                    {moment(data?.created_at)
                      .subtract(1, "days")
                      .format("DD MMM YYYY")}
                  </Typography>
                  <Typography className={classes.text2}>
                    {data?.folio_number && data?.folio_number}
                    {data?.fund_isin && data?.fund_isin}
                  </Typography>
                  <Typography
                    style={{ fontSize: "0.75rem", marginBottom: "10px" }}
                  >
                    {data?.id ? data?.id : "-"}
                  </Typography>
                  <Typography style={{ fontSize: "0.75rem" }}>
                    {moment(data?.created_at).format("DD MMM YYYY hh:mm A")}
                  </Typography>
                </Grid> */}
                  <Grid item xs={6} md={3} >
                    <Typography className={classes.textgreyDetails}>Nav Date</Typography>
                  </Grid>
                  <Grid item xs={6} md={3} >
                    <Typography className={classes.text2}>
                      {moment(data?.created_at)
                        .subtract(1, "days")
                        .format("DD MMM YYYY")}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={3} >
                    <Typography className={classes.textgreyDetails}>
                      {data?.scheme && "Scheme"}
                      {data?.folio_number && "Folio no."}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={3} >
                    <Typography className={classes.text2}>
                      {data?.scheme && data?.scheme}
                      {data?.folio_number && data?.folio_number}
                    </Typography>
                  </Grid>
                  {data?.fund_isin &&
                    <>
                      <Grid item xs={6} md={3}>
                        <Typography className={classes.textgreyDetails}>{data?.fund_isin && "Fund ISIN"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={3} >
                        <Typography className={classes.text2}>
                          {data?.fund_isin && data?.fund_isin}
                        </Typography>
                      </Grid>
                    </>
                  }
                  <Grid item xs={6} md={3} >
                    <Typography className={classes.textgreyDetails}>Order ID</Typography>
                  </Grid>
                  <Grid item xs={6} md={3} >

                    <Typography
                      style={{ fontSize: "0.75rem", marginBottom: "10px" }}
                    >
                      {data?.id ? data?.id : "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={3} >

                    <Typography className={classes.textgreyDetails}>
                      {data?.fp_sip_id ?
                        "SIP Created"
                        :
                        "Order Date"
                      }
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={3} >

                    <Typography style={{ fontSize: "0.75rem" }}>
                      {data?.fp_sip_id ?
                        moment(data?.mandate?.created_at).format("DD MMM YYYY hh:mm A")
                        :
                        moment(data?.created_at).format("DD MMM YYYY hh:mm A")
                      }
                    </Typography>
                  </Grid>
                  {data?.mandate &&
                    <>
                      <Grid item xs={6} md={3} >
                        <Typography className={classes.textgreyDetails}>
                          Mandate
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={3} >

                        <Typography style={{ fontSize: "0.75rem", textTransform: "uppercase" }}>
                          {data?.Mandate?.bank?.name ? data?.frequency?.bank?.name : "-"}
                        </Typography>
                      </Grid>
                    </>
                  }
                  {data?.frequency &&
                    <>
                      <Grid item xs={6} md={3} >
                        <Typography className={classes.textgreyDetails}>
                          SIP Frequency
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={3} >

                        <Typography style={{ fontSize: "0.75rem", textTransform: "uppercase" }}>
                          {data?.frequency ? data?.frequency : "-"}
                        </Typography>
                      </Grid>
                    </>
                  }
                  <Grid item xs={6} md={3} >
                    <Typography className={classes.textgreyDetails}>
                      Order Status
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={3} >

                    <Typography style={{ fontSize: "0.75rem", textTransform: "uppercase" }}>
                      {data?.state ? data?.state : data?.status || "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box py={4} px={{ xs: 0, md: 3 }}>
                {(orderType !== 6 && orderType !== 5) &&
                  <Typography className={classes.text1}>

                    Order Status

                  </Typography>
                }
                <Box p={1} mt={2}>
                  {/* {data?.orderType == 1 || data?.orderType == 2 ? ( */}
                  {orderType == 1 &&
                    <>
                      {data?.state == "pending" ? (
                        <>
                          <Stepper
                            title={"Order Placed on MF Wealth "}
                            subtTitle={moment(data?.created_at).format(
                              "DD MMM YYYY hh:mm A"
                            )}
                            img={"/images/Icontick.svg"}
                            verticleLine={true}
                          />
                          <Stepper
                            title={
                              data?.submitted_at
                                ? "Payment Pending"
                                : "Payment Processing"
                            }
                            subtTitle={
                              data?.submitted_at
                                ? moment(data?.submitted_at).format(
                                  "DD MMM YYYY hh:mm A"
                                )
                                : ""
                            }
                            img={
                              "/images/Icontimer.svg"
                              // data?.submittedAt
                              //   ? "/images/Icontick.svg"
                              //   : "/images/Icontimer.svg"
                            }
                            verticleLine={data?.submitted_at ? true : false}
                          />

                          {data?.submitted_at && (
                            <>
                              <Stepper
                                title={"Unit(s) allocation in Progress"}
                                subtTitle={"Expect by 2-3 working days"}
                                img={"/images/Icontimer.svg"}
                                verticleLine={false}
                              />

                              <Box p={1} mt={3} className={classes.unitBox}>
                                <Typography
                                  style={{ fontSize: "0.8rem" }}
                                >{`We are currently awaiting the status on your unit allocation from
                       ${data?.fundName
                                  }. Dont worry, the NAV applicable will be as on ${moment(
                                    data?.created_at
                                  )
                                    .subtract(1, "days")
                                    .format("DD MMM YYYY")}`}</Typography>
                              </Box>
                            </>
                          )}
                        </>
                      ) : (data?.state == "successful" || data?.state == "confirmed") ? (
                        <>
                          <Stepper
                            title={"Order Placed on MF Wealth "}
                            subtTitle={moment(data?.created_at).format(
                              "DD MMM YYYY hh:mm A"
                            )}
                            img={"/images/Icontick.svg"}
                            verticleLine={true}
                          />
                          <Stepper
                            title={"Payment Successful"}
                            subtTitle={moment(data?.submittedAt).format(
                              "DD MMM YYYY hh:mm A"
                            )}
                            img={"/images/Icontick.svg"}
                            verticleLine={true}
                          />
                          <Stepper
                            title={"Order Successful"}
                            subtTitle={moment(data?.succeededAt).format(
                              "DD MMM YYYY hh:mm A"
                            )}
                            img={"/images/Icontick.svg"}
                            verticleLine={true}
                          />
                          <Stepper
                            title={"Unit(s) allocation Completed"}
                            subtTitle={moment(data?.succeededAt).format(
                              "DD MMM YYYY hh:mm A"
                            )}
                            img={"/images/Icontick.svg"}
                            verticleLine={false}
                          />
                        </>
                      ) : data?.state == "failed" ? (
                        <>
                          <Stepper
                            title={"Order Placed on MF Wealth "}
                            subtTitle={moment(data?.created_at).format(
                              "DD MMM YYYY hh:mm A"
                            )}
                            img={"/images/Icontick.svg"}
                            verticleLine={true}
                          />
                          <Stepper
                            title={"Payment Failed"}
                            subtTitle={moment(data?.failed_at).format(
                              "DD MMM YYYY hh:mm A"
                            )}
                            img={"/images/failed.svg"}
                            verticleLine={false}
                          />
                        </>
                      ) : (
                        " "
                      )
                      }</>
                  }

                  {/* // ) : ( */}
                  {(orderType !== 1 && orderType !== 2 && orderType !== 6 && orderType !== 5) && (
                    <>
                      <Stepper
                        title={
                          // data?.orderType == 3
                          //   ? "Redeem Order Placed"
                          //   : data?.orderType == 4
                          //     ? "Switch Order Placed"
                          //     : data?.orderType == 5
                          //       ? "STP Order Placed"
                          //       : data?.orderType == 6 && "SWP Order Placed"
                          orderType == 3
                            ? "Redeem Order Placed"
                            : orderType == 4
                              ? "Switch Order Placed"
                              : orderType == 5
                                ? "STP Order Placed"
                                : orderType == 6 && "SWP Order Placed"

                        }
                        subtTitle={moment(data?.created_at).format(
                          "DD MMM YYYY hh:mm A"
                        )}
                        img={"/images/Icontick.svg"}
                        verticleLine={true}
                      />

                      <Stepper
                        title={
                          data?.state == "Pending" || data?.state == "pending"
                            ? "Order Processing"
                            : (data?.state == "Successful" || data?.state == "confirmed")
                              ? "Order SuccessfulLy  "
                              : "Order Failed"
                        }
                        subtTitle={moment(data?.succeeded_at).format(
                          "DD MMM YYYY hh:mm A"
                        )}
                        img={
                          data?.state == "Pending" || data?.state == "pending"
                            ? "/images/Icontimer.svg"
                            : (data?.state == "Successful" || data?.state == "confirmed")
                              ? "/images/Icontick.svg"
                              : "/images/Icontimer.svg"
                        }
                        verticleLine={false}
                      />
                    </>
                  )}


                  {orderType == 2 &&
                    <Box mt={2}>
                      {/* <Typography>Transaction History</Typography> */}
                      {data?.installments?.length > 0 && (
                        <Box mt={0}>
                          {data?.status == "active" && (
                            <Grid container>
                              <Grid item xs={1} style={{ display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                                <img src="/images/Icontimer.svg" height="25px" width="25px" />
                                <div className={classes.verticleLineSIPNew}></div>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography className={classes.transText}>
                                  {number_ordinals(data?.installments?.length + 1)}{" "}
                                  {`${orderTypeObj[orderType]}`} Due{" "}
                                </Typography>
                                <Typography className={classes.transText}>
                                  in{" "}
                                  {moment(data?.installments[data?.installments.length - 1]?.next_installment_date).diff(
                                    moment(new Date()),
                                    "days"
                                  )}{" "}
                                  days on {moment(data?.installments[data?.installments.length - 1]?.next_installment_date).format("DD MMM YYYY")}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {data?.installments?.map((ele, i) => {
                            // console.log(ele)
                            return (
                              <Grid key={ele.id} container
                                style={{
                                  cursor: orderTypeObj[orderType] === 'SIP' ? 'pointer' : 'default'
                                }}
                                onClick={() => {
                                  if (orderTypeObj[orderType] === 'SIP' &&
                                    //  ele.state == "successful" &&
                                    ele.created_at) {

                                    setShowSIPInstallment(ele)
                                  }
                                }}>
                                <Grid item xs={1} style={{ display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                                  <img
                                    src={
                                      ele.state == "successful"
                                        ? "/images/Icontick.svg"
                                        : ele.state == "failed"
                                          ? "/images/failed.svg"
                                          : "/images/Icontimer.svg"
                                    }
                                    height="25px"
                                    width="25px"
                                  />
                                  {i + 1 != data?.installments?.length && (
                                    <div className={classes.verticleLineSIPNew}></div>
                                  )}
                                </Grid>
                                <Grid item xs={6}  >
                                  <Typography className={classes.transText}>
                                    {number_ordinals(data?.installments?.length - i)}{" "}
                                    {`${orderTypeObj[orderType]} ${ele?.state == "successful"
                                      ? orderType === 2
                                        ? "Paid"
                                        : "Processed"
                                      : ele.state == "failed"
                                        ? "Failed"
                                        : "Pending"
                                      }`}{" "}
                                  </Typography>
                                  <Typography className={classes.transText}>
                                    {moment(
                                      ele.state == "successful"
                                        ? ele.submitted_at
                                        : ele.state == "failed"
                                          ? ele.failed_at
                                          : ele.submitted_at || ele.created_at
                                    ).format("DD MMM YYYY hh:mm A")}
                                  </Typography>
                                </Grid>
                              </Grid>
                            );
                          })}
                        </Box>
                      )}
                    </Box>
                  }

                  {/* {data?.status=="Successful" && 
                     ((data.orderType==1 || data.orderType==2 ) &&
                     <>
                     <Stepper
                     title={ "Payment Successful"}
                     subtTitle={moment(data?.submittedAt).format("DD MMM YYYY hh:mm A")}
                     img={ "/images/Icontick.svg"}
                     verticleLine={true}
                     />
                     <Stepper
                    title={"Unit(s) allocation Completed"}
                    subtTitle={moment(data?.succeededAt).format("DD MMM YYYY hh:mm A")}
                    img={"/images/Icontimer.svg"}
                    verticleLine={false}
                    />
                     </>
                     ) 
                     
                  } */}
                </Box>
              </Box>
            </Box>
          </Box>
        }
      </Box>
    </div >
  );
};

export default DetailOrder;
