// import React, { useState, useEffect } from "react";
// import { Box, makeStyles } from "@material-ui/core";
// import { Link } from "react-router-dom";
// import { namespace } from "../environment";
// import { useSelector } from "react-redux";

// function IntroSection({ offset, handleShowModal }) {
//   const [refreshToken, setRefreshToken] = useState("");
//   useEffect(() => {
//     setRefreshToken(localStorage.getItem("refresh_token"));
//   }, [localStorage]);
//   console.log(refreshToken);
//   const useStyles = makeStyles((theme) => ({
//     root: {
//       // backgroundImage: "url('./images/introBG.svg')",
//       backgroundColor: "#F7F7F9",
//       padding: "8rem 0rem 14rem 0rem",
//       display: "flex",
//       alignItems: "center",
//       justifyContent: "flex-start",
//       flexDirection: "column",
//     },
//     headingOne: {
//       fontSize: "56px",
//       fontWeight: 700,
//       color: "#000000",
//       marginBottom: "1.2rem",
//       textAlign: "center",
//     },
//     headingOneColor: {
//       color: "#0E76BC",
//     },
//     headingTwo: {
//       fontSize: "3rem",
//       fontWeight: 600,
//       color: "#F3C04F",
//       textAlign: "center",
//     },
//     button: {
//       padding: "1rem 4rem",
//       background: "#0E76BC",
//       color: "#fff",
//       fontSize: "1rem",
//       borderRadius: "40px",
//       border: "none",
//       margin: "1.8rem 0",
//       cursor: "pointer",
//     },
//     "@media (max-width: 900px)": {
//       root: {
//         padding: "8rem 0rem 1rem 0rem",
//       },
//       headingOne: {
//         fontSize: "1.6rem",
//       },
//       headingTwo: {
//         fontSize: "1.6rem",
//       },
//     },
//   }));
//   const classes = useStyles();

//   return (
//     <div className={classes.root} height="100%">
//       <Box display='flex' >
//         <Box className={classes.headingOne}>Innovative
//           <span className={classes.headingOneColor}>&nbsp;Mutual Fund&nbsp;</span>
//           Management Solution
//         </Box>
//         <Box>
//           <img 
//             src="./images/hamburger.svg"
//             height="22px"
//             width="22px"
//             onClick={() => toggleResponsive(true)}
//           />
//         </Box>
//       </Box>
//       {/* <Box className={classes.headingTwo}>Mutual funds</Box> */}

//       {/* {refreshToken === "" ||
//       typeof refreshToken === "undefined" ||
//       refreshToken === null ? (
//         <Link to={`${namespace}/user`}>
//           <button className={classes.button}>Invest Now</button>
//         </Link>
//       ) : (
//         <Link to={`${namespace}/dashboard/mutualfund`}>
//           <button className={classes.button}>Invest Now</button>
//         </Link>
//       )} */}
//     </div>
//   );
// }

// export default IntroSection;


// DashboardSection.js
import React, { useEffect, useState } from 'react';
import { Box, Container, Typography, Grid, Button, makeStyles } from "@material-ui/core";
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';
import { Link } from "react-router-dom";
import { namespace } from "../environment";
import { useSelector } from "react-redux";
import CountUp from 'react-countup';


function IntroSection({ offset, handleShowModal }) {
  const [refreshToken, setRefreshToken] = useState("");
  useEffect(() => {
    setRefreshToken(localStorage.getItem("refresh_token"));
  }, [localStorage]);
  console.log(refreshToken);
  const useStyles = makeStyles((theme) => ({
    root: {
      // backgroundImage: "url('./images/introBG.svg')",
      backgroundColor: "#F7F7F9",
      padding: "50px 0rem 68px 0rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      flexDirection: "column",
    },
    headingOne: {
      fontSize: "56px",
      fontWeight: 700,
      color: "#000000",
      marginBottom: "1.2rem",
      textAlign: "start",
    },
    headingOneColor: {
      color: "#0E76BC",
    },
    headingTwo: {
      fontSize: "16px",
      fontWeight: 400,
      color: "#F3C04F",
      textAlign: "start",
      color: "#000000B8",
      padding: "0px 50px 20px 0px",
    },

    button: {
      padding: "1rem 4rem",
      background: "#0E76BC",
      color: "#fff",
      fontSize: "1rem",
      borderRadius: "40px",
      border: "none",
      margin: "1.8rem 0",
      cursor: "pointer",
    },

    boxShadow: {
      background: '#FFFFFF40',
      boxShadow: ' 0px 4px 4px 0px #00000040',
      minWidth: '110px',
      borderRadius: '5px',
    },
    boxShadowNumber: {
      fontFamily: "Poppins",
      fontSize: '20px',
      fontWeight: 400,
      textAlign: 'center',
      color: "#000000",
    },
    boxShadowData: {
      fontFamily: "Poppins",
      fontSize: '14px',
      fontWeight: 700,
      textAlign: 'center',
      color: "#0E76BC",
    },
    "@media (max-width: 900px)": {
      root: {
        padding: "8rem 0rem 1rem 0rem",
      },
      headingOne: {
        fontSize: "1.6rem",
      },
      headingTwo: {
        fontSize: "12px",
      },
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.root} height="100%">
      <Container maxWidth="lg">
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Box sx={{ textAlign: { xs: 'center', md: 'left' }, p: 2 }}>
              <Typography variant="h4" component="div" gutterBottom className={classes.headingOne}>
                Innovative <span className={classes.headingOneColor}>Mutual Fund</span> Management Solution
              </Typography>
              <Box maxWidth={'450px'}>
                <Typography variant="body1" gutterBottom className={classes.headingTwo} >
                  Fully Digitalized One Stop Solution for all of your Mutual Funds
                </Typography>
              </Box>
              <Grid container spacing={2} justifyContent={{ xs: 'center', md: 'flex-start' }} sx={{ mt: 2 }}>
                <Grid item>
                  {/* <Button
                  variant="contained"
                  startIcon={<AndroidIcon />}
                  sx={{ mt: 1, backgroundColor: '#007bff', '&:hover': { backgroundColor: '#0056b3' } }}
                >
                  Get it on Google Play
                </Button> */}
                  <img src="/images/Google Play.svg" alt="Android" width={'171px'} onClick={() => {
                    window.open('https://play.google.com/store/apps/details?id=com.mfwealth.mfwealth&hl=en-US', '_blank')
                  }} />
                </Grid>
                <Grid item>
                  {/* <Button
                  variant="contained"
                  startIcon={<AppleIcon />}
                  sx={{ mt: 1, backgroundColor: '#007bff', '&:hover': { backgroundColor: '#0056b3' } }}
                >
                  Download on the App Store
                </Button> */}
                  <img src="/images/App Store.svg" alt="Iphone" width={'171px'} />

                </Grid>
              </Grid>
              {/* <Grid container spacing={3} justifyContent={{ xs: 'center', md: 'flex-start' }} sx={{ mt: 2 }} style={{ paddingTop: '37px', gap: '20px' }} >
                <Grid item className={classes.boxShadow}>
                  <Typography variant="h6" className={classes.boxShadowNumber}>
                    <CountUp end={500} duration={2.5} />
                    + Cr
                  </Typography>
                  <Typography variant="body2" className={classes.boxShadowData}>AUM</Typography>
                </Grid>
                <Grid item className={classes.boxShadow}>
                  <Typography variant="h6" className={classes.boxShadowNumber}>
                  <CountUp end={1350} duration={2.5} />
                    +</Typography>
                  <Typography variant="body2" className={classes.boxShadowData}>Clients</Typography>
                </Grid>
                <Grid item className={classes.boxShadow}>
                  <Typography variant="h6" className={classes.boxShadowNumber}>
                  <CountUp end={30} duration={2.5} />
                    +</Typography>
                  <Typography variant="body2" className={classes.boxShadowData}>Cities Served</Typography>
                </Grid>
              </Grid> */}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ textAlign: 'center' }}
              onClick={() => {
                window.open('https://apps.apple.com/in/app/mf-wealth/id6738738300', '_blank')
              }}
            >
              <img src="/images/Revenue-bro.svg" alt="Dashboard Illustration" width="100%" className='img-fluid' />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default IntroSection;

