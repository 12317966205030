import {
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useEffect } from "react";
import TextInputField from "../Input/TextInputField";
import TitleLayoult from "../TitleLayoult";
import { useStyles } from "../styles";
import { useForm } from "react-hook-form";
import ErrorMessage from "../../Validation/ErrorMessage";
import { VERIFY_PAN_STATUS, GET_KYC_DATA } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import { useNavigate, createSearchParams } from "react-router-dom";
import { namespace } from "../../../environment";
import CustomToast from "../../UI/CustomToast";
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Step3({ setData }) {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [complient, setComplient] = useState(null);
  const [error, setError] = useState();
  const [apiError, setApiError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [dialog, setDialog] = useState(true);

  const navigate = useNavigate();

  const closeDialog = () => setDialog(false);

  useEffect(async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };
    // console.log('sdfsdfsdf')
    try {
      const response = await httpInstance(`${GET_KYC_DATA}`, httpConfig);
      reset({
        pan: response.data?.data?.pan.toUpperCase(),
      });
      if (response.data?.data?.pan) {
        navigate(`${namespace}/dashboard/mutualfund`);
        // navigate({
        //   pathname: `${namespace}/signIn`,
        //   search: `?${createSearchParams(
        //     data?.status ? { stepcount: "2" } : { stepcount: "9" }
        //   )}`,
        // });
      }
    } catch { }
  }, []);

  const submit_pan = async (data) => {
    setLoading(true);
    setApiError(null);
    setError(false);
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        // pan_card_number: data.pan,
        pan: data.pan.toUpperCase(),
        user_id: localStorage.getItem("user_id"),
      },
    };

    try {
      const response = await httpInstance(`${VERIFY_PAN_STATUS}`, httpConfig);
      console.log(response)
      setError(false);
      const data = response.data?.data;
      let panDetails;
      // console.log(response)
      if (response.data?.data?.status == true) {
        setComplient("Yes");
        localStorage.setItem("complient", "Yes");
        panDetails = {
          // panNumber: response.data?.data?.panNumber,
          // fullName: response.data?.data?.fullName,
          panNumber: response.data?.data?.pan,
          fullName: response.data?.data?.name,
        };

        localStorage.setItem("panDetails", JSON.stringify(panDetails));
      } else {
        setComplient("No");
        localStorage.setItem("complient", "No");
        panDetails = {
          // panNumber: response.data?.data?.panNumber,
          panNumber: response.data?.data?.pan,
          // fullName: response.data?.data?.name
          //fullName:response.data?.data?.fullName
        };
        localStorage.setItem("panDetails", JSON.stringify(panDetails));
      }

      setData({ ...panDetails, complient: response.data?.data?.status });
      setTimeout(() => {
        navigate({
          pathname: `${namespace}/onBoarding`,
          search: `?${createSearchParams(
            data?.status ? { stepcount: "2" } : { stepcount: "9" }
          )}`,
        });
      }, 2000);
      setLoading(false);
    } catch (error) {
      CustomToast(error.response.data.error, "FAILED")
      if (error.response.data?.status === 400) {
        setError(true);
      } else {
        setError(false);
      }
      setApiError(error.response.data.error)
      setLoading(false);
    }
  };

  const handleback = () => {
    navigate({
      pathname: `${namespace}/onBoarding`,
      search: `?${createSearchParams({ stepcount: "2" })}`,
    });
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-start"
        px={20}
        className={classes.backButton}

      // onClick={handleback}
      >
        {/* <img src="/images/backIcon.svg" /> */}
      </Box>
      <Box display={"flex"} justifyContent="center" width={'100%'}>
        <Box display={"flex"} alignItems="flex-start" flexDirection={"column"}>
          <Box>
            <TitleLayoult
              title="Pan Verification"
              description="Please Enter your Pan Card Number for Verification"
            />
          </Box>
          <form onSubmit={handleSubmit(submit_pan)} style={{ width: '100%'}}>
            <Box mt={5}>
              <TextInputField
                name="pan"
                label="Enter Pan Number"
                placeholder="Pan Number"
                register={register}
                // inputPattern={/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/} // Example of pattern validation
                inputPattern={/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/i}
                // handleChange={(e) => console.log(e.target.value)} // Optional change handler
                maxLength={10}
                style={{ textTransform: 'uppercase' }}
              // type={'text'}
              />
            </Box>
            {errors.pan && <ErrorMessage message={errors.pan.message || 'Invalid PAN format'} />}
            {complient == "Yes" && (
              <p className={classes.verifiedKycText}>
                PAN Card is KYC Verified
              </p>
            )}
            {complient == "No" && (
              <ErrorMessage message="PAN Card is not KYC Verified" />
            )}
            {error && <ErrorMessage message={apiError || "Something wrong happened"} />}
            <Box mt={5} display={'flex'} justifyContent={'center'}>
              <button className={classes.primaryButton} type="submit" disabled={loading}>
                {loading ? (
                  <CircularProgress size="1rem" color="inherit" />
                ) : (
                  "Verify"
                )}
              </button>
            </Box>
          </form>
        </Box>
      </Box>

      {/* basic requirement */}
      <Box>
        <Dialog
          open={dialog}
          onClose={closeDialog}
          classes={{ paper: classes.dialogPaper }}

        >
          <Box display="flex" className={classes.closeIcon}>
            {" "}
            <CloseIcon onClick={closeDialog} style={{ cursor: "pointer" }} />
          </Box>

          <DialogTitle
            classes={{ paper: classes.dialogPaper, root: classes.dialogTitle }}
          ></DialogTitle>
          <DialogContent classes={{ root: classes.dialogContent }}>
            <Box className={classes.dialogImage}>
              <img src="/images/Checklist-pana.svg" width="200px" />
            </Box>
            <Typography className={classes.dialogtitleText}>
              Requirements
            </Typography>
            <Typography
              className={classes.dialogText}
              style={{ marginTop: "10px" }}
            >
              Please make sure you have the documents listed below with you at
              time of Onboarding
            </Typography>
            <ul style={{ fontSize: "0.8rem" }}>
              <li>Camera</li>
              <li>Microphone </li>
              {/* <li>Cancelled cheque</li>*/}
              <li>Location Permission</li>
              <li>Aadhar Card</li>
              <li>Pan Card</li>
            </ul>

            <Box my={3} display="flex" justifyContent="center">
              <button
                className={classes.dialogButton}
                type="submit"
                onClick={closeDialog}
              >
                Ok, got it
              </button>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
}

export default Step3;
