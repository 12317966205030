import React, { useState, useEffect } from "react";
import {
    Box,
    makeStyles,
    Typography,
    Grid,
    CircularProgress,
} from "@material-ui/core";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { INITIATE_PAYMENT, GET_ALL_BANK, GET_ORDER_DETAILS } from "../../../../utils/endpoints";
import httpInstance from "../../../../utils/instance";
import LocaleString from "../../../../hooks/LocaleString";
import number_ordinals from "../../../../hooks/NumberOrdinal";
import { namespace, BASE_URL } from "../../../../environment";
import { Skeleton } from "@mui/material";
import Lottie from "react-lottie";
import animationData from "../../../../lotties/Payment Successfull.json";
import animationDataFailed from "../../../../lotties/Payment Failed.json";
import CustomToast from "../../../../components/UI/CustomToast";

const settings = {
    dots: true, // Enable navigation dots
    infinite: false, // Prevent infinite scrolling
    speed: 500, // Transition speed
    slidesToShow: 1, // Number of items to show in view
    slidesToScroll: 1, // Number of items to scroll at a time
    // appendDots: dots => (
    //     <div
    //       style={{
    //         // backgroundColor: "#ddd",
    //         borderRadius: "10px",
    //         // padding: "10px"
    //       }}
    //     >
    //       <ul style={{ margin: "0px" }}> 1{dots} </ul>
    //     </div>
    //   ),
    responsive: [
        {
            breakpoint: 768, // For tablets and smaller
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 480, // For mobile devices
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};


const Index = ({ confirmData, handleClose, transaction_type }) => {
    const useStyles = makeStyles({
        root: {
            padding: "10px 50% ",
        },
        OrderSummaryData: {
            // border: '1px solid #E4E4E4',
            // boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
            borderRadius: "10px",
        },
        text1: {
            fontSize: "1rem",
            margin: "0.5rem 0",
            display: 'flex',
            alignItems: 'center',
            borderBottom: "1px solid #e4e4e4",
        },

        textBold: {
            fontSize: "1rem",
            fontWeight: 700,
            margin: "0.5rem 0",
            display: 'flex',
            alignItems: 'center',
            borderBottom: "1px solid #e4e4e4",
        },

        textSuccess: {
            color: "#48D4A5",
            fontWeight: 700,
            padding: '5px 3px',
            textTransform: 'capitalize',
        },
        textFails: {
            color: "red",
            fontWeight: 700,
            padding: '5px 3px',
            textTransform: 'capitalize',
        },
        header: {
            fontSize: "1.3rem",
            fontWeight: 600,
        },
        subTabtext: {
            fontSize: "0.7rem",
            fontFamily: "inherit",
            fontWeight: 500,
        },


        textUnderline: {
            fontSize: "0.7rem",
            textDecoration: "underline",
            fontFamily: "inherit",
        },
        text: {
            fontSize: "0.8rem",
            fontWeight: 700,
        },
        subText: {
            fontSize: "0.9rem",
            fontWeight: 500,
            textAlign: "center",
            fontFamily: "inherit",
            textTransform: 'uppercase',
        },


        title: {
            fontSize: "1.1rem",
        },
        primaryButton: {
            padding: "0.8rem 4rem",
            background: "#0E76BC",
            color: "#fff",
            fontSize: "0.8rem",
            fontWeight: 600,
            borderRadius: "45px",
            cursor: "pointer",
            marginTop: "1rem",
            border: "1px solid #0E76BC",
            '&:hover': {
                backgroundColor: "#fff",
                color: "#0E76BC",
            },
            '&:disabled': {
                opacity: '0.7',
                cursor: 'not-allowed',
            },
        },
        rupees: {
            fontFamily: "Open Sans",
        },

        "@media (max-width:600px)": {
            root: {
                padding: "10px ",
            },
        },

        "@media (min-width:601px) and (max-width:768px)": {
            root: {
                padding: "10px 23% ",
            },
        },
        sliderCarousel: {
            "& .slick-dots li button:before": {
                color: "#777777",
                backgroundColor: "#777777",
                // padding: "0px 5px",
                margin: "0px 5px",
                borderRadius: '3px',
                height: '12px',
            }
        },

    });
    const classes = useStyles();

    const location = useLocation();
    const navigate = useNavigate();

    const [orderDetails, setOrderDetails] = useState(null);
    const [loading, setLoading] = useState(false);

    // // Function to get query parameters from the URL
    // const getQueryParams = (param) => {
    //     const urlParams = new URLSearchParams(location.search);
    //     return urlParams.get(param);
    // };

    // // Example usage of getQueryParams function
    // const transactionBasketId = getQueryParams('transaction_basket_id');


    const get_the_transaction_detials = async () => {
        setLoading(true);
        const httpConfig1 = {
            method: "GET",
            tokenType: "user",
            headers: {
                "Content-Type": "application/json",
            },
            // params: { transaction_basket_id: transactionBasketId },
        };

        try {
            const response = await httpInstance(`${GET_ORDER_DETAILS}`, httpConfig1);
            if (response.status === 200) {
                setOrderDetails(response?.data?.data);
                setLoading(false);
            } else {
                setLoading(false);
                CustomToast(response?.data?.message ?? response?.data?.error ?? "Somthing went wrong", "FAILED");
                handleContinue();
            }
        } catch (error) {
            CustomToast(error?.response?.data?.message ?? error?.response?.data?.error ?? "Somthing went wrong", "FAILED");
            setLoading(false);
            handleContinue();
        }
    };


    useEffect(() => {
        // if (transactionBasketId) get_the_transaction_detials()
        // else {
        //     handleContinue();
        // }
    }, [])


    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData:  (confirmData?.payment_status === "success" || confirmData?.status === "initiated") ? animationData : animationDataFailed,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    const handleContinue = () => {
        // navigate({
        //     pathname: `${namespace}/dashboard/mutualfund`,
        //     // search: `?${createSearchParams({ stepcount: "2" })}`,
        // });

    }

    // console.log(confirmData)

    return (
        <div
        //  className={classes.root} 
        >
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" py={4} mt={1} width={'100%'} className={classes.OrderSummaryData}>
                {loading ?
                    <Box width={'100%'} display="flex" flexDirection="column" justifyContent="center" alignItems="center" px={2}>
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                            <Skeleton variant="rounded" width={'200px'} height={'200px'} style={{ marginTop: '1rem' }} />

                        </Box>
                        <Typography variant="h6" className={classes.header} style={{ textAlign: 'center', paddingBottom: '10px', width: '100%' }}>
                            <Skeleton variant="text" width={'100%'} height={'4rem'} />
                        </Typography>
                        {[1, 2].map((data, i) => {
                            return (
                                <Skeleton variant="text" width={'100%'} key={i} height={'3rem'} />

                            )
                        })}
                        <Box my={2} width={'100%'} maxWidth={'350px'}>
                            <Grid container spacing={2}>
                                <Grid item xs={6} sm={7} className={classes.textBold}>
                                    <Skeleton variant="text" width={'100%'} />

                                </Grid>
                                <Grid item xs={6} sm={5} className={classes.text1}>

                                    <Skeleton variant="text" width={'100%'} />
                                </Grid>
                                <Grid item xs={6} sm={7} className={classes.textBold}>
                                    <Skeleton variant="text" width={'100%'} />

                                </Grid>
                                <Grid item xs={6} sm={5} className={classes.text1}>

                                    <Skeleton variant="text" width={'100%'} />

                                </Grid>
                                <Grid item xs={6} sm={7} className={classes.textBold}>
                                    <Skeleton variant="text" width={'100%'} />

                                </Grid>
                                <Grid item xs={6} sm={5} className={`${classes.text1} ${confirmData?.transaction_basket?.payment_status === 'success' ? classes.textSuccess : classes.textfail}`}>

                                    <Skeleton variant="text" width={'100%'} />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    :
                    <Box width={'100%'} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        {/* {confirmData?.transaction_basket_items?.map((confirmDataTransaction, i) => {
                            return (
                                <div key={i}> */}
                                    <Box textAlign="center">
                                        {(confirmData?.payment_status === 'pending' || confirmData?.payment_status === 'success' || confirmData?.confirmData === 'failed' || confirmData?.status === "initiated" ) &&
                                            (confirmData?.payment_status === "success" || confirmData?.status === "initiated") ?
                                            <Lottie options={defaultOptions} height={200} width={200} />
                                            :
                                            <Lottie options={defaultOptions} height={200} width={200} />
                                        }

                                        {/* {(confirmData?.transaction_basket?.payment_status === 'pending' || confirmData?.transaction_basket?.payment_status === 'success' || confirmData?.transaction_basket?.confirmData === 'failed') &&
                                <img src={`/images/${confirmData?.transaction_basket?.payment_status === "success" ? 'successful' : 'failed'} state.svg`} height={80} width={'auto'} alt="logo " style={{ paddingRight: '10px' }} />
                            } */}
                                    </Box>
                                    <Typography variant="h6" className={classes.header} style={{ textAlign: 'center', paddingBottom: '10px', textTransform: 'capitalize' }}>
                                        Your order {transaction_type} is {confirmData?.transaction_basket_items?.[0]?.status == 'confirmed' ? 'placed' :  confirmData?.transaction_basket_items?.[0]?.status}
                                        
                                        {/* {confirmData?.transaction_basket?.payment_status === 'pending' ? 'Payment Pending' : confirmData?.transaction_basket?.payment_status === 'success' ? 'Payment Successfull' : 'Payment Failed'} */}
                                    </Typography>
                                    {confirmData?.purchases?.scheme.map((data, i) => {
                                        return (
                                            <Typography variant="body1" className={classes.subTabtext} style={{ textAlign: 'center', fontSize: '1rem', fontWeight: 400, marginBottom: '10px' }} key={i}>
                                                {data}
                                            </Typography>
                                        )
                                    })}
                                    <Box my={2} width={'100%'} maxWidth={'350px'}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6} sm={7} className={classes.textBold}>
                                                {" "}
                                                {" Order Id:"}
                                            </Grid>
                                            <Grid item xs={6} sm={5} className={classes.text1}>

                                                {confirmData?.transaction_basket_items?.[0]?.id}
                                            </Grid>
                                            <Grid item xs={6} sm={7} className={classes.textBold}>
                                                {" "}
                                                {" Amount:"}
                                            </Grid>
                                            <Grid item xs={6} sm={5} className={classes.text1}>

                                                <span className={classes.rupees}>₹</span>
                                                {confirmData?.transaction_basket_items?.[0]?.amount?.toLocaleString('en-IN')}
                                            </Grid>
                                            <Grid item xs={6} sm={7} className={classes.textBold}>
                                                {" "}
                                                {" Transaction Status:"}
                                            </Grid>
                                            <Grid item xs={6} sm={5} className={`${classes.text1} ${(confirmData?.transaction_basket_items?.[0]?.status === 'confirmed' || confirmData?.transaction_basket_items?.[0]?.status === 'active') ? classes.textSuccess : classes.textFails}`}>
                                                <img src={`/images/${(confirmData?.transaction_basket_items?.[0]?.status === 'confirmed' || confirmData?.transaction_basket_items?.[0]?.status === 'active') ? "Icontick" : "failed"}.svg`} alt="icon" style={{ height: '20px', width: '20px', marginRight: '5px' }} />
                                                {confirmData?.transaction_basket_items?.[0]?.status}
                                            </Grid>
                                        </Grid>
                                       

                                        {/* <Typography variant="body1" className={classes.header} style={{ textAlign: 'center', fontSize: '1rem', fontWeight: 400 }}>
                                Transaction Id: {confirmData?.transaction_basket?.transaction_id}
                            </Typography>
                            <Typography variant="body1" className={classes.header} style={{ textAlign: 'center', fontSize: '1rem', fontWeight: 400 }}>
                                Amount:      <span className={classes.rupees}>₹</span>
                                {confirmData?.purchases?.totalAmount}
                            </Typography>
                            <Typography variant="body1" className={classes.header} style={{ textAlign: 'center', fontSize: '1rem', fontWeight: 400 }}>
                                Transaction Status: {confirmData?.transaction_basket?.payment_status}
                            </Typography> */}
                                    </Box>
                                {/* </div>
                            )
                        })} */}


                        <button className={classes.primaryButton} onClick={handleClose}>Continue</button>
                    </Box>
                }
            </Box>

        </div>
    )
}
export default Index;
