import React, { useState, useEffect } from "react";
import { Box, CircularProgress, Select, FormControl } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import {
    GET_ALL_RELATIONSHIP,
    ADD_NOMINEES,
    GET_NOMINEES,
    KYC_STATUS,
    DELETE_NOMINEE,
    PROFILE_DATA_EDIT,
} from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import { useNavigate, createSearchParams, useSearchParams } from "react-router-dom";
import moment from "moment";
import { namespace } from "../../../environment";
import { useStyles } from "../../signup/styles";
import ErrorMessage from "../../Validation/ErrorMessage";
import CustomToast from "../../UI/CustomToast";
import { createTheme, Grid, MenuItem, ThemeProvider } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { occupationType, sourceIncome } from "../../../utils/constantData/constantData";

function ProfileDataEdit({ type, kycData, profileData, handleClose }) {
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();
    const [searchParams, setSearchParams] = useSearchParams();
    const steps = searchParams.get("stepcount");

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        getValues,
        control,
    } = useForm({
        defaultValues: {
            fullname: "",
            gender: '',
            SourceIncome: '',
            OccupationType: '',
        },
    });

    const navigate = useNavigate();

    const [apiError, setApiError] = useState(null);
    const [loading, setLoading] = useState(false)

    //------------------------------------------ Submit -------------------------------------------------------------//

    const submit_edit_profile = async (data) => {
        setApiError(null)
        setLoading(true);
        const formData = {
            annual_income: data?.SourceIncome,
            full_name: data?.fullName,
            gender: data?.gender,
            occupation: data?.OccupationType
        }
        const httpConfig = {
            method: "PATCH",
            tokenType: "user",
            headers: {
                "Content-Type": "application/json",
            },
            data: formData
        };

        try {
            await httpInstance(`${PROFILE_DATA_EDIT}`, httpConfig);
            setLoading(false);
            CustomToast("Nominee Details Updated", "SUCCESS")
            handleClose()
        } catch (error) {
            CustomToast(error?.response?.data?.error ?? error?.response?.data?.error ?? 'Somethin went wrong', "FAILED")
            setLoading(false);
            setApiError(error?.response?.data?.error ?? error?.response?.data?.error ?? 'Somethin went wrong')

        }
    }







const theme = createTheme({
    overrides: {
        MuiInputBase: {
            input: {
                padding: "8px 10px",
            },
        },
        MuiSelect: {
            select: {
                "&:focus": {
                    backgroundColor: "transparent",
                },
            },
        },
    },
});


// Set Profile data 
useEffect(() => {
    if (profileData) {
        reset({
            fullName: profileData?.full_name ?? '',
            gender: profileData?.gender ?? '',
            SourceIncome: profileData?.annual_income ?? '',
            OccupationType: profileData?.occupation ?? '',

        })
    }
}, [profileData])

return (
    <>
        <Box display={"flex"} justifyContent="center" width={'100%'} mb={2}>
            <Box display={"flex"} alignItems="flex-start" flexDirection={"column"}>


                <form onSubmit={handleSubmit(submit_edit_profile)} style={{}}>
                    <Grid container >
                        <Grid xs={12} md={6} >
                            <Box my={1} margin={'1rem'}>
                                <label className={classes.label}>Full Name</label>
                                <input
                                    className={classes.inputBox}
                                    {...register("fullName", {
                                        required: true,
                                        pattern: /^(?=(?:^\w))([A-Za-z. ]+)$/
                                    })}
                                />
                                {errors.fullName && errors.fullName.type == "required" && <ErrorMessage message="Enter Full Name" />}
                                {errors.fullName && errors.fullName.type == "pattern" && <ErrorMessage message="Enter Valid Name" />}
                            </Box>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <Box width={'100%'} margin={'1rem'}>
                                <Box my={1}>
                                    <Box my={1} className={classes.label}>
                                        Gender
                                    </Box>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        justifyContent="flex-start"
                                    >
                                        <Box
                                            display="flex"
                                            flexDirection="row"
                                            alignItems="center"
                                            justifyContent="flex-start"
                                        >
                                            <input
                                                type="radio"
                                                height="16px"
                                                width="16px"
                                                name="fillType"
                                                style={{ height: "16px", width: "16px", margin: 0 }}
                                                value={'male'}
                                                {...register("gender", { required: true })}
                                            />
                                            <Box fontSize={12} fontWeight={500} ml={1}>
                                                Male
                                            </Box>
                                        </Box>
                                        <Box
                                            display="flex"
                                            flexDirection="row"
                                            alignItems="center"
                                            justifyContent="flex-start"
                                            ml={6}
                                        >
                                            <input
                                                type="radio"
                                                height="16px"
                                                width="16px"
                                                name="fillType"
                                                style={{ height: "16px", width: "16px", margin: 0 }}
                                                value={'female'}
                                                {...register("gender", { required: true })}
                                            />
                                            <Box fontSize={12} fontWeight={500} ml={1}>
                                                Female
                                            </Box>
                                        </Box>
                                        {/* <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="flex-start"
                      ml={6}
                    >
                      <input
                        type="radio"
                        height="16px"
                        width="16px"
                        name="fillType"
                        style={{ height: "16px", width: "16px", margin: 0 }}
                        value={'transgender'}
                        {...register("gender", { required: true })}
                      />
                      <Box fontSize={12} fontWeight={500} ml={1}>
                        Transgender
                      </Box>
                    </Box> */}
                                    </Box>
                                </Box>
                                {errors.gender && (
                                    <ErrorMessage message={"Please select Gender"} />
                                )}
                            </Box>

                        </Grid>
                        <Grid xs={12} md={6}>
                            <Box margin={'1rem'}>
                                <ThemeProvider theme={theme}>
                                    <Box
                                        my={2}
                                        //display="flex"
                                        alignItems={"flex-start"}
                                        justifyContent="flex-start"
                                        flexDirection="column"
                                        width={"100%"}
                                    >
                                        <label className={classes.label}>Annual Income</label>
                                        <Box width={"100%"} className={`${classes.Select} ${classes.Select2}`}>
                                            <FormControl fullWidth className={classes.Select}>
                                                <Controller
                                                    control={control}
                                                    name={`SourceIncome`}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            disableUnderline
                                                            className={classes.selectItem}
                                                            IconComponent={KeyboardArrowDownIcon}
                                                            displayEmpty
                                                            MenuProps={{
                                                                style: {
                                                                    top: 5,
                                                                    minWidth: 200,
                                                                },
                                                                anchorOrigin: {
                                                                    vertical: "bottom",
                                                                    horizontal: "left",
                                                                },
                                                                transformOrigin: {
                                                                    vertical: "top",
                                                                    horizontal: "left",
                                                                },
                                                                getContentAnchorEl: null,
                                                            }}
                                                        >
                                                            <MenuItem value={null} disabled hidden className={classes.menuItem}>
                                                                Annual Income
                                                            </MenuItem>
                                                            {sourceIncome &&
                                                                sourceIncome.map((ele) => {
                                                                    return (
                                                                        <MenuItem
                                                                            key={ele.id}
                                                                            className={classes.menuItem}
                                                                            value={ele.value}
                                                                        >
                                                                            {ele.type}
                                                                        </MenuItem>
                                                                    );
                                                                })}
                                                        </Select>
                                                    )}
                                                />
                                            </FormControl>
                                        </Box>
                                        {errors.SourceIncome && (
                                            <ErrorMessage message="Please Select Source of Income" />
                                        )}
                                    </Box>
                                </ThemeProvider>
                            </Box>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <Box mx={'1rem'}>
                                <ThemeProvider theme={theme}>
                                    <Box
                                        my={2}
                                        //display="flex"
                                        alignItems={"flex-start"}
                                        justifyContent="flex-start"
                                        flexDirection="column"
                                        width={"100%"}
                                    >
                                        <label className={classes.label}>Occupation Type</label>
                                        <Box width={"100%"} className={`${classes.Select} ${classes.Select2}`}>
                                            <FormControl fullWidth className={classes.Select}>
                                                <Controller
                                                    control={control}
                                                    name={`OccupationType`}
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            disableUnderline
                                                            className={classes.selectItem}
                                                            style={{ textTransform: 'capitalize' }}
                                                            IconComponent={KeyboardArrowDownIcon}
                                                            displayEmpty
                                                            inputProps={{
                                                                style: {
                                                                    textTransform: "capitalize",
                                                                },
                                                            }}
                                                            MenuProps={{
                                                                style: {
                                                                    top: 5,
                                                                    minWidth: 200,
                                                                },
                                                                anchorOrigin: {
                                                                    vertical: "bottom",
                                                                    horizontal: "left",
                                                                },
                                                                transformOrigin: {
                                                                    vertical: "top",
                                                                    horizontal: "left",
                                                                },
                                                                getContentAnchorEl: null,
                                                            }}
                                                        >
                                                            <MenuItem value={null} disabled hidden className={classes.menuItem}>
                                                                Occupation Type
                                                            </MenuItem>
                                                            {occupationType &&
                                                                occupationType.map((ele) => {
                                                                    return (
                                                                        <MenuItem
                                                                            key={ele.id}
                                                                            className={classes.menuItem}
                                                                            style={{
                                                                                textTransform: "capitalize"
                                                                            }}
                                                                            value={ele.value}
                                                                        >
                                                                            {ele.type}
                                                                        </MenuItem>
                                                                    );
                                                                })}
                                                        </Select>
                                                    )}
                                                />
                                            </FormControl>
                                        </Box>
                                        {errors.OccupationType && (
                                            <ErrorMessage message="Please Select Source of Occupation Type" />
                                        )}
                                    </Box>
                                </ThemeProvider>
                            </Box>
                        </Grid>
                    </Grid>
                    {apiError && <ErrorMessage message={apiError || "Something wrong happened"} />}

                    <Box mt={2}>
                        <button className={classes.primaryButton} type="submit" disabled={loading}>
                            {loading ? (
                                <CircularProgress size="1rem" color="inherit" />
                            ) :
                                "Update"
                           
                            }
                        </button>
                    </Box>
                </form >
            </Box >
        </Box >
    </>
);
}

export default ProfileDataEdit;
