import React, { useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { Grid, Box, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const ProfileNominee = ({ formData }) => {

    const useStyles = makeStyles((theme) => ({
        root: {
            border: '1px solid #E4E4E4',
            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
            borderRadius: "12px",
            padding: '1rem',
        },
        header: {
            fontWeight: 500,
            fontSize: "1.3rem",
        },
        inputBox: {
            // background: "#E6E6E6",
            padding: "0.6rem 0.6rem",
            borderRadius: "0px",
            border: "none",
            fontFamily: "inherit",
            width: "90%",
            marginBottom: "10px",
            borderBottom: '1px solid #000',
            "&:disabled": {
                // opacity: 1,
                color: "#000",
                background: '#fff',
            }
        },

        text: {
            fontSize: "0.8rem",
            textAlign: "center",
            fontFamily: "inherit",
            marginTop: "10px",
            fontWeight: 500,
        },

        box1: {
            width: "90%",
        },
        "@media (max-width: 767px)": {
            box1: {
                width: "90%",
            },
        },
        cameraIcon: {
            height: "35px",
            width: "35px",
            background: "#0E76BC",
            borderRadius: "50%",
            position: "relative",
            top: -35,
            left: 75,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
        },
        primaryButton: {
            padding: "0.8rem 5rem",
            background: "#dc3545",
            color: "#fff",
            fontSize: "0.85rem",
            fontWeight: 600,
            borderRadius: "45px",
            border: "none",
            cursor: "pointer",
            fontFamily: "inherit",
        },
        textNomineeDetials: {
            fontSize: "1.1rem !important",
            // textAlign: "center !important",
            fontFamily: "inherit !important",
            marginTop: "10px !important",
            fontWeight: '600 !important',
            color: '#000',
            marginTop: '0rem !important',
          },
    }));

    const classes = useStyles();

    const { control, reset } = useForm({
        defaultValues: {
            entries: [], // Default empty array
        },
    });

    const { fields } = useFieldArray({
        control,
        name: 'entries',
    });

    useEffect(() => {
        if (formData && formData.length > 0) {
            const formattedData = formData.map((data) => ({
                name: data.name,
                date_of_birth: new Date(data.date_of_birth).toISOString().split('T')[0], // Format date
                relationship: data.relationship,
                allocation_percentage: data.allocation_percentage,
                guardian_name: data.guardian_name,
                guardian_relationship: data.guardian_relationship,
            }));
            reset({ entries: formattedData }); // Populate the array dynamically
        }
    }, [formData, reset]);

    return (
        <Box p={2} border="1px solid #ccc" borderRadius="8px">
            <form>
                {fields.map((field, index) => (
                    <Box key={field.id} mb={3} p={0} borderBottom="1px solid #ccc" borderRadius="0px">
                        <Typography variant="h6" gutterBottom className={classes.textNomineeDetials}>
                            Nominee {index + 1}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4} >
                                <label className={classes.text}>Name</label>
                                <br />
                                <input
                                    className={classes.inputBox}
                                    value={field.name}
                                    disabled
                                    style={{ textTransform: 'capitalize' }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <label className={classes.text}>Date of Birth</label>
                                <br />
                                <input
                                    className={classes.inputBox}
                                    value={field.date_of_birth}
                                    disabled
                                    type="date"
                                    style={{ textTransform: 'capitalize' }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <label className={classes.text}>Relationship</label>
                                <br />
                                <input
                                    className={classes.inputBox}
                                    value={field.relationship}
                                    disabled
                                    style={{ textTransform: 'capitalize' }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <label className={classes.text}>Allocation Percentage(%)</label>
                                <br />
                                <input
                                    className={classes.inputBox}
                                    value={field.allocation_percentage}
                                    disabled
                                    style={{ textTransform: 'capitalize' }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <label className={classes.text}>Guardian Name</label>
                                <br />
                                <input
                                    className={classes.inputBox}
                                    value={field.guardian_name}
                                    disabled
                                    style={{ textTransform: 'capitalize' }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <label className={classes.text}>Guardian Relationship</label>
                                <br />
                                <input
                                    className={classes.inputBox}
                                    value={field.guardian_relationship}
                                    disabled
                                    style={{ textTransform: 'capitalize' }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                ))}
            </form>
        </Box>
    );
};

export default ProfileNominee;
