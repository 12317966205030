import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { color } from "@mui/system";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: "4rem 0"
  },
  heading: {
    fontSize: "1.8rem",
    color: "#272727",
    fontWeight: 500,
    fontFamily: "Poppins, sans-serif"
  },
  socialButton: {
    height: "1rem 2rem",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    boxShadow: "0px 0px 20px 2px #96959540",
    border: "none",
    padding: "0rem 4rem",
    background: "#fff",
    borderRadius: "10px",
    color: "#333",
    fontWeight: 600,
    cursor: "pointer"

  },
  socialButtonImage: {
    height: "22px",
    width: "22px"
  },

  inputBox: {
    // background: "#E6E6E6",
    padding: "0.7rem 0.95rem",
    borderRadius: "0px",
    border: "none",
    borderBottom: "1px solid #000",
    width: "90%",
    fontFamily: "inherit",
    '&::placeholder': {
      color: '#A0A0A0', // Customize placeholder color
      opacity: 1, // For Firefox
      textTransform: "capitalize",
    },
    '&:hover': {
      background: '#D9D9D9', // Change background on hover
    },
    '&:focus': {
      outline: 'none', // Remove default outline
      background: '#D9D9D9', // Change background on focus
    },
  },
  otpinputBox: {
    background: "#E6E6E6",
    padding: "0.7rem 1rem",
    borderRadius: "10px",
    border: "none",
    width: "25%",
    marginRight: "10px"
  },

  checkBox: {
    width: 15,
    height: 15,
    cursor: "pointer",
    accentColor: "#0E76BC", 
  },
  createAccountText: {
    fontSize: '0.7rem',
    fontWeight: 400,
    textAlign: 'center',
    color: "#272727",
  },
  createAccountTextSpan: {
    color: "#0E76BC", 
    paddingLeft: '5px',
    cursor: "pointer",
    fontWeight: 500,
  },

  "@media (max-width: 970px)": {
    OtpBox: {
      justifyContent: "space-between"
    },
  },

  Select: {
    // background: "#E6E6E6",
    padding: "3px",
    borderRadius: "0px",
    border: "none",
    boxSizing: "border-box",
    textAlign: "left",
    width: "100%",
    minWidth: "300px",
    fontFamily: "inherit !important",
  },

  Select2:{
    borderBottom: "1px solid #000 !important",
  },

  selectItem: {
    fontSize: "0.85rem !important",
    fontFamily: "inherit !important",
  },


  menuItem: {
    fontSize: "0.8rem !important",
    fontFamily: "inherit"
  },

  selectPaper: {
    minWidth: "800px"
  },

  DateBox: {
    // background: "#E6E6E6",
    padding: "0.3rem 0.8rem",
    borderRadius: "0px",
    border: "none",
    borderBottom: '1px solid #000',
    boxSizing: "border-box",
    width: "100%"
  },
  label: {
    fontSize: "0.7rem",
    color: "#272727",
    fontWeight: 500,
    fontFamily: "Poppins"
  },
  title: {
    fontSize: "1.8rem",
    color: "#272727",
    fontWeight: 500,
    fontFamily: "Poppins"
  },
  headingDescription: {
    fontSize: "0.9rem",
    fontFamily: "Poppins",
    fontWeight: 400,
    textAlign: "left"
  },
  primaryButton: {
    padding: "0.8rem 7rem",
    // background: "#0E76BC",
    background: '#0E76BC',
    color: "#fff",
    fontSize: "0.9rem",
    fontWeight: 600,
    borderRadius: "45px",
    border: "none",
    cursor: "pointer",
    width: "100%",
    '&:hover': {
      background: '#2fa7f7', // Change background on hover
    },
    '&:disabled': {
      opacity: '0.5',
    },
  },

  clearButton: {
    padding: "0.4rem 3rem",
    background: "#ff4521",
    color: "#fff",
    fontSize: "0.9rem",
    fontWeight: 600,
    borderRadius: "45px",
    border: "none",
    cursor: "pointer",
    width: "100%"
  },
  LoginSignUpSwitchButton: {
    padding: "0.3rem 3rem",
    background: "#fff",
    color: "#0E76BC",
    fontSize: "0.7rem",
    fontWeight: 600,
    borderRadius: "4px",
    border: "1px solid #0E76BC",
    cursor: "pointer",
    width: "100%"
  },
  borderedButton: {
    padding: "0.8rem 7rem",
    background: "#fff",
    color: "#0E76BC",
    fontSize: "0.9rem",
    fontWeight: 600,
    borderRadius: "45px",
    border: "2px solid #0E76BC",
    width: "100%"
  },
  disabledButton: {
    padding: "0.8rem 7rem",
    background: "#c7c7c7",
    color: "#fff",
    fontSize: "0.9rem",
    fontWeight: 500,
    borderRadius: "45px",
    border: "none"
  },
  fileUpload: {
    border: "1px dashed #0E76BC",
    background: "#B3E1FF",
    borderRadius: "12px",
    color: "#272727",
    fontFamily: "Poppins"
  },
  file: {
    border: "1px solid #e7e7e7",
    borderRadius: "10px"
  },
  calendar: {
    background: "#e6e6e6!important",
    border: "none",
    margin: 0,
    padding: 0,
    width: "240px"
  },
  resendOtpText: {
    textDecoration: "underline",
    fontSize: "0.7rem",
    color: "#0E76BC",
    cursor: "pointer"
  },
  verifiedKycText: {
    fontSize: "0.7rem",
    color: "#48D4A5",
    marginRight: "5px"
  },

  dateInput: {
    "& MuiInputBase-roote": {
      fontSize: "0.5rem"
    },
    width: "110%"
  },

  nomineeHeader: {
    fontSize: "1rem",
    fontFamily: "Poppins",
    fontWeight: 500,
    textAlign: "left",

  },


  barColorPrimary: {
    backgroundColor: '#48D4A5 !important',
  },

  rupeesSymbol: {
    position: "absolute",
    marginLeft: "5px",
    marginTop: "29px",
    paddingRight: "2px",
    opacity: "0.6",
    fontSize: "0.9rem",
    fontFamily: "Open Sans"


  },

  recordingButton: {
    padding: "0.8rem 1rem",
    background: "#0E76BC",
    color: "#fff",
    fontSize: "0.8rem",
    fontWeight: 600,
    borderRadius: "45px",
    border: "none",
    cursor: "pointer",

  },

  recordIcon: {
    position: "absolute",

  },

  optTitle: {
    fontSize: "1.5rem"
  },

  backButton: {
    cursor: "pointer"
  },

  //basic requirement
  closeIcon: {
    padding: "5px",
    justifyContent: "flex-end",

  },

  dialogtitleText: {
    fontSize: "1.1rem",
    fontWeight: 600,
    textAlign: "center",
    fontFamily: "inherit",
    padding: "10px",

  },
  dialogTitle: {
    display: "flex",
    justifyContent: "center",
    alignItems: " center",
    textAlign: "center",
    padding: "5px",

  },
  dialogPaper: {
    maxWidth: "25.5rem",
    justifyContent: "center",
    borderRadius: "15px",
    padding: "10px"
  },
  dialogImage: {
    textAlign: "center",
  },
  dialogText: {
    fontSize: "0.8rem",
    textAlign: "center",
    fontFamily: "inherit"
  },
  dialogButton: {

    padding: "0.8rem 4rem",
    background: "#0E76BC",
    color: "#fff",
    fontSize: "0.9rem",
    fontWeight: 600,
    borderRadius: "45px",
    border: "none",
    cursor: "pointer",

  },

  toastMessage: {
    fontFamily: "Poppins",
    fontSize: "0.9rem"
  },
  
  primaryBackground: {
    background: 'transparent', // Slight white overlay for blur effect
    backdropFilter: 'blur(50px)',
    padding: "50px",
    borderRadius: "50px"
  },
  mainBackground: {
    display: "none",
  },
  "@media (max-width: 480px)": {
    mainBackground: {
      background: 'transparent', // Slight white overlay for blur effect
      backdropFilter: 'blur(50px)',
    },
    primaryBackground: {
      padding: "10px",
    },
  },
  googleLoginText: {
    fontSize: '1rem',
    color: '#000',
    fontWeight: 600,
    textAlign: 'center',
    padding: '10px 0px',
    margin: 0,
  },
  loginAndSignUpText: {
    fontSize: '1.5rem',
    color: '#000',
    fontWeight: 600,
    textAlign: 'center',
    paddingTop: '10px',
  },
  textToShowInBottom: {
    fontSize: '0.8rem',
    color: '#000',
    fontWeight: 400,
    textAlign: 'center',
    paddingTop: '5px'
  }
}));
