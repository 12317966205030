import {
    Dialog, DialogContent, DialogTitle, Modal, Skeleton, Box, Typography, Grid, Menu,
    MenuItem,
    IconButton,
    ButtonBase,
    Button,
    capitalize,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from '@mui/styles';
import { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { GET_FUND_DETAILS, GET_FUND_FP_DATA } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import { BASE_URL } from "../../../environment";
import moment from "moment";
import Redeem from "../../../containers/Portfolio/MutualFund/Redeem";
import SWP from "../../../containers/Portfolio/MutualFund/SWP";
import { Link, useNavigate } from "react-router-dom";
import { namespace } from "../../../environment";

const ActiveFundData = ({ schemeData, holdingsData, bankData, category }) => {
    const useStyles = makeStyles({
        // dialog
        dialogTitle: {
            display: "flex !important",
            justifyContent: "center !important",
            alignItems: " center !important",
            textAlign: "center !important",
            padding: "5px !important",
        },
        dialogPaper: {
            maxWidth: "30rem !important",
            width: '100% !important',
            justifyContent: "center !important",
        },
        dialogContent: {
            padding: "1rem !important",
        },

        closeIcon: {
            padding: "5px",
            justifyContent: "flex-end",
        },
        fundTitle: {
            fontWeight: '600 !important',
            fontSize: '1rem !important',
        },
        fundCategory: {
            fontWeight: '400 !important',
            fontSize: '0.8rem !important',

        },
        fundDetailsDataHead: {
            fontSize: '0.8rem !important',
            fontWeight: '400 !important',
            color: '#000 !important',
        },
        fundDetailsData: {
            fontSize: '1rem !important',
            fontWeight: '600 !important',
            color: '#000 !important',

        },
        fundDetailsDataReturn: {
            fontSize: '1rem !important',
            fontWeight: '600 !important',
            color: '#0E76BC !important',
        },
        rupees: {
            fontFamily: "Open Sans",
        },
        percentagePositive: {
            color: "#0BDA51",
        },
        percentageNegative: {
            color: "#D2042D",
        },
        ButtonDesign: {
            backgroundColor: '#fff !important',
            color: '#0E76BC !important',
            border: '1px solid #0E76BC !important',
            textTransform: 'capitalize !important',
            fontSize: '1rem !important',
            borderRadius: '12px!important',
            marginLeft: '5px !important',
            '&:hover': {
                backgroundColor: '#0E76BC !important',
                color: '#fff !important',
            },
            '&:disabled': {
                opacity: '0.5',
            },
        },
        ButtonDesignInvert: {
            backgroundColor: '#0E76BC !important',
            color: '#fff !important',
            border: '1px solid #0E76BC !important',
            textTransform: 'capitalize !important',
            fontSize: '1rem !important',
            borderRadius: '12px!important',
            '&:hover': {
                color: '#0E76BC !important',
                backgroundColor: '#fff !important',

            },
            '&:disabled': {
                opacity: '0.5',
            },
        },
        "@media (max-width: 600px)": {
            dialogTitle: {
                padding: "0.5rem !important",
            },
            title: {
                fontSize: '0.8rem !important',
            },
            dialogContent: {
                padding: "0.5rem !important",
            },
            ButtonDesignInvert: {
                fontSize: '0.6rem !important',
                // lineHeight: '0 !important',
            },
            ButtonDesign: {
                fontSize: '0.6rem !important',
                marginLeft: '3px !important',
                // lineHeight: '0 !important',
            },
        },
    });

    const classes = useStyles();
    const navigate = useNavigate();
    const [fundData, setFundData] = useState('');
    const [fpData, setFpData] = useState('');
    const [loading, setLoading] = useState(true);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleClick = (event) => {
        get_fundData();
        handleOpen()

    };


    const get_fundData = async () => {
        setLoading(true)
        const httpConfig = {
            method: "GET",
            // tokenType: "user",
            test: "test",
            // headers: {
            //   "Content-Type": "application/json",
            // },
            params: { fundPlanId: schemeData?.schemes?.[0]?.plan_id },
        };

        const httpConfig1 = {
            method: "GET",
            tokenType: "user",
            headers: {
                "Content-Type": "application/json",
            },
            params: { isin: schemeData?.schemes?.[0]?.isin },
        };

        try {
            const [fpdata, plData] = await Promise.all([
                httpInstance(`${GET_FUND_FP_DATA}`, httpConfig1),
                // httpInstance(`${SEARCH_BY_ISIN}`, httpConfig),
                httpInstance(`${GET_FUND_DETAILS}`, httpConfig),

            ]);
            //const response= await httpInstance(`${SEARCH_BY_ISIN}`,httpConfig)
            setFpData(fpdata.data?.data);
            setFundData(plData.data?.data);
            // navigate(`/mutualfund/scheme-code=${response.data?.data[0]?.scheme_code}`)
            setLoading(false)
        } catch {
            setLoading(false)
        }
    };


    // console.log(fundData, fpData, schemeData)

    const [data, setData] = useState([]);
    const [fpFundData, setFpFundData] = useState(null);
    const [redeem, setRedeem] = useState(false);
    const [fundDetail, setFundDetail] = useState([]);
    const [swp, setSwp] = useState(false);
    const swp_close = () => {
        setSwp(false);
        navigate(`${namespace}/myportfolio`, {
            replace: true,
        });
    };

    //----------------handle_redeem----------------------------------------------------//

    const handle_redeem = () => {
        handleClose()
        navigate(`${namespace}/myportfolio/redeem/${schemeData?.schemes?.[0]?.isin}`, {
            replace: true,
        });
        setData(schemeData);
        setFpFundData(fpData);
        setFundDetail(fundData);
        setRedeem(true);
    };

    //----------------------------handle SWP--------------------------------------------------------//
    const handle_swp = () => {
        handleClose()
        navigate(`${namespace}/myportfolio/swp/${schemeData?.schemes?.[0]?.isin}`, {
            replace: true,
        });
        setData(schemeData);
        setFundDetail(fundData);
        setFpFundData(fpData);
        setSwp(true);

    };


    //-----------------------------handle invest more -----------------------------------------------------//
    const handle_invest_more = () => {
        // console.log(holdingsData)
        // if (category == "diy") {
        navigate(
            `${namespace}/mutualfund/scheme-code/${fundData?.id}`,
            {
                state: {
                    folioNumber: schemeData?.folio_number
                },
            }
        );
        // }
        // else {
        //   navigate(
        //     `${namespace}/dashboard/mutualfund/assisted-services/invest-detail`,
        //     // {
        //     //   state: {
        //     //     fundsData: response.data?.data,
        //     //     totalAmount: amount,
        //     //     tenure: year,
        //     //   },
        //     // }
        //   );
        // }

    }


    //-----------------------------handle STP -----------------------------------------------------//
    const handle_invest_STP = () => {
        // console.log(holdingsData)
        // if (category == "diy") {
        navigate(
            `${namespace}/myportfolio/stp/${schemeData?.schemes?.[0]?.isin}`,
            {
                state: {
                    schemeData: schemeData,
                    fundData: fundData,
                    stpData: fpData,
                }
            }
        );
    }


    //-----------------------------handle SWITCH -----------------------------------------------------//
    const handle_switch = () => {
        // console.log(holdingsData)
        // if (category == "diy") {

        navigate(
            `${namespace}/myportfolio/switch/${schemeData?.schemes?.[0]?.isin}`,
            {
                state: {
                    schemeData: schemeData,
                    fundData: fundData,
                    switchData: fpData,
                    category: category,
                }
            }
        );
    }

    return (
        <>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                style={{ padding: "2px" }}
            >
                <MoreVertIcon />
            </IconButton>

            <Dialog
                open={open}
                // onClose={closeDialog}
                classes={{ paper: classes.dialogPaper }}
            >
                <Box display="flex" className={classes.closeIcon}>
                    {" "}
                    <CloseIcon onClick={() => {
                        handleClose();
                        // reset({
                        //   accountHolderName: '',
                        //   accountNumber: '',
                        //   bankName: '',
                        //   ifscode: '',
                        // })
                    }}
                        style={{ cursor: "pointer" }} />
                </Box>

                <DialogTitle
                    classes={{ paper: classes.dialogPaper, root: classes.dialogTitle }}
                >
                    <Typography className={classes.title}>Fund Detials</Typography>
                </DialogTitle>
                <DialogContent classes={{ root: classes.dialogContent }}>
                    <Box>
                        {schemeData?.schemes?.map((data, index) => {
                            return (
                                <>
                                    <Box display={'flex'} borderBottom={'1px solid #E4E4E4'} pb={1}>

                                        <Box display="flex" alignItems="center" mr={2}>
                                            {loading ?
                                                <Skeleton variant="rounded" border width={50} height={50} />
                                                :
                                                <img
                                                    // src={fund?.img_url}
                                                    src={`${data?.logo_url}`}
                                                    height={'auto'} width={54} style={{ borderRadius: '5px', marginRight: '10px' }} />

                                            }
                                        </Box>

                                        {/* {console.log(fundData)} */}
                                        {loading ?
                                            <Box width={'100%'}>
                                                <Skeleton variant="text" width={'100%'} />
                                                <Skeleton variant="text" width={'50%'} />
                                            </Box>
                                            :
                                            <Box>
                                                <Typography variant="h6" className={classes.fundTitle} >
                                                    {fpData?.name}
                                                </Typography>
                                                <Typography variant="body1" className={classes.fundCategory} >
                                                    {fundData?.category?.categoryName}
                                                </Typography>

                                            </Box>

                                        }
                                    </Box>
                                    <Box display="flex" alignItems="center" pt={1} >
                                        {loading ?

                                            <Skeleton variant="text" width={'100%'} />
                                            :
                                            <Typography variant="body1" className={classes.fundDetailsData} >
                                                <span>
                                                    <span className={classes.fundDetailsDataHead}>Current Value:</span>
                                                    <span className={classes.rupees}>₹</span>
                                                </span>
                                                {data?.market_value?.amount?.toLocaleString('en-IN')}
                                            </Typography>
                                        }
                                    </Box>

                                    <Box mt={1} borderBottom={'1px solid #E4E4E4'}>
                                        <Box display="flex" alignItems="center" justifyContent={'space-between'}>
                                            {loading ?
                                                <>
                                                    <Skeleton variant="text" width={'100%'} />
                                                    <Skeleton variant="text" width={'100%'} />
                                                </>
                                                :
                                                <>
                                                    <Typography variant="body1" className={classes.fundDetailsData} >
                                                        <span className={classes.fundDetailsDataHead}>Invested:</span>  {data?.invested_value?.amount?.toLocaleString('en-IN')}
                                                    </Typography>
                                                    <Typography variant="body1" className={classes.fundDetailsData} >
                                                        <span className={classes.fundDetailsDataHead}>Profit:</span>
                                                        <span className={classes.rupees}>₹</span>{" "}
                                                        {((data?.market_value?.amount) - (data?.invested_value?.amount)).toFixed(2)} {" "}
                                                        <span className={((((data?.market_value.amount) - (data?.invested_value.amount)) / (data?.invested_value.amount)) * 100) < 0 ? classes.percentageNegative : classes.percentagePositive}>
                                                            ({
                                                                ((((data?.market_value.amount) - (data?.invested_value.amount)) / (data?.invested_value.amount)) * 100).toFixed(2)
                                                            }%)
                                                        </span>
                                                    </Typography>
                                                </>
                                            }
                                        </Box>
                                    </Box>
                                    <Box display="flex" alignItems="center" mt={2} borderBottom={'1px solid #E4E4E4'}>
                                        {loading ?

                                            <Skeleton variant="text" width={'100%'} />
                                            :
                                            <Typography variant="body1" className={classes.fundDetailsDataReturn} >
                                                <span className={classes.fundDetailsDataHead}>Annual Return:</span>
                                                {fundData?.fundRet1year?.toFixed(2)}%
                                            </Typography>
                                        }
                                    </Box>
                                    <Box display="flex" alignItems="center" mt={2} borderBottom={'1px solid #E4E4E4'}>
                                        {loading ?

                                            <Skeleton variant="text" width={'100%'} />
                                            :
                                            <Typography variant="body1" className={classes.fundDetailsDataHead} >
                                                <span className={classes.fundDetailsDataHead}>Last Updated:</span>
                                                {moment(data.holdings?.as_on).format("DD MMM YYYY")}
                                            </Typography>
                                        }
                                    </Box>
                                    <Box mt={2} borderBottom={'1px solid #E4E4E4'}>
                                        <span className={classes.fundDetailsDataHead}>Invested Details</span>
                                        <Box display="flex" alignItems="center" justifyContent={'space-between'}>
                                            {loading ?
                                                <>
                                                    <Skeleton variant="text" width={'100%'} />
                                                    <Skeleton variant="text" width={'100%'} />
                                                </>
                                                :
                                                <>
                                                    <Typography variant="body1" className={classes.fundDetailsData} >
                                                        <span className={classes.fundDetailsDataHead}>Units:</span>  {data?.holdings?.units}
                                                    </Typography>
                                                    <Typography variant="body1" className={classes.fundDetailsData} >
                                                        <span className={classes.fundDetailsDataHead}>Folio Number:</span>  {schemeData?.folio_number}
                                                    </Typography>
                                                </>
                                            }
                                        </Box>
                                    </Box>
                                    <Box mt={2} borderBottom={'1px solid #E4E4E4'}>
                                        <span className={classes.fundDetailsDataHead}>Withdrawal Charge</span>
                                        <Box display="flex" alignItems="center" >
                                            {loading ?

                                                <Skeleton variant="text" width={'100%'} />
                                                :
                                                <Typography variant="body1" className={classes.fundDetailsData} >
                                                    {fundData.note ? fundData.note : 'No Exit load'}
                                                </Typography>
                                            }
                                        </Box>
                                    </Box>

                                </>
                            )
                        })}
                        {loading ?
                            <Box display={"flex"} width={'100%'} mt={2}>
                                {[1, 2, 3, 4, 5].map((data, index) => (
                                    <Skeleton key={data} variant="rounded" border width={'100%'} height={38} style={{ marginLeft: index === 0 ? '0px' : '10px' }} />
                                ))}
                            </Box>
                            :
                            <Box
                                display="flex"
                                justifyContent="center"
                                flexWrap="wrap"
                                gap="10px"
                                mt={2}
                                width="100%"
                            >
                                <Button className={classes.ButtonDesignInvert} onClick={handle_invest_more}> Invest More</Button>
                                <Button className={classes.ButtonDesign} disabled={!fpData?.redemption_allowed}
                                    onClick={() => {
                                        handleClose();
                                        setTimeout(() => {
                                            handle_redeem();
                                        }, 1000); // 1-second delay
                                    }}
                                > Redeem</Button>
                                <Button className={classes.ButtonDesign} style={{ textTransform: 'uppercase' }} disabled={!fpData?.swp_allowed} 
                                    onClick={() => {
                                        handleClose();
                                        setTimeout(() => {
                                            handle_swp();
                                        }, 1000); // 1-second delay
                                    }}
                                    > SWP</Button>
                                <Button className={classes.ButtonDesign} style={{ textTransform: 'uppercase' }} disabled={!fpData?.stp_out_allowed} onClick={handle_invest_STP}> STP</Button>
                                <Button className={classes.ButtonDesign} disabled={!fpData?.switch_out_allowed} onClick={handle_switch}> Switch </Button>
                            </Box>
                        }
                    </Box >
                </DialogContent >
            </Dialog >

            <Redeem
                show={redeem}
                handleClose={() => setRedeem(false)}
                schemeData={data}
                bankData={bankData}
                fundDetail={fundDetail}
                fpFundData={fpFundData}
                category={category}
            />
            <SWP
                show={swp}
                onClose={swp_close}
                schemeData={data}
                bankData={bankData}
                fundDetail={fundDetail}
                swpData={fpFundData}
                category={category}
            />
        </>
    )
}
export default ActiveFundData;