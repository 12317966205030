import React, { useState, useEffect } from "react";
import {
    Box,
    makeStyles,
    Typography,
    Grid,
    CircularProgress,
} from "@material-ui/core";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { INITIATE_PAYMENT, GET_ALL_BANK, GET_ORDER_DETAILS, MANDATES_DETAILS_OF_BANK, GET_MANDATE_INDIVIDUAL } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import LocaleString from "../../../hooks/LocaleString";
import number_ordinals from "../../../hooks/NumberOrdinal";
import { namespace, BASE_URL } from "../../../environment";
import { Skeleton } from "@mui/material";
import CustomToast from "../../../components/UI/CustomToast";
import Lottie from "react-lottie";
import animationData from "../../../lotties/Payment Successfull.json";
import animationDataFailed from "../../../lotties/Payment Failed.json";

const settings = {
    dots: true, // Enable navigation dots
    infinite: false, // Prevent infinite scrolling
    speed: 500, // Transition speed
    slidesToShow: 1, // Number of items to show in view
    slidesToScroll: 1, // Number of items to scroll at a time
    // appendDots: dots => (
    //     <div
    //       style={{
    //         // backgroundColor: "#ddd",
    //         borderRadius: "10px",
    //         // padding: "10px"
    //       }}
    //     >
    //       <ul style={{ margin: "0px" }}> 1{dots} </ul>
    //     </div>
    //   ),
    responsive: [
        {
            breakpoint: 768, // For tablets and smaller
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 480, // For mobile devices
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};


const MandateStatus = () => {
    const useStyles = makeStyles({
        root: {
            padding: "10px 35% ",
        },
        OrderSummaryData: {
            border: '1px solid #E4E4E4',
            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
            borderRadius: "10px",
        },
        text1: {
            fontSize: "1rem",
            margin: "0.5rem 0",
            display: 'flex',
            alignItems: 'center',
            borderBottom: "1px solid #e4e4e4",
        },

        textBold: {
            fontSize: "1rem",
            fontWeight: 700,
            margin: "0.5rem 0",
            display: 'flex',
            alignItems: 'center',
            borderBottom: "1px solid #e4e4e4",
        },

        textSuccess: {
            color: "#48D4A5",
            fontWeight: 700,
            padding: '5px 3px',
            textTransform: 'capitalize',
        },
        textFails: {
            color: "red",
            fontWeight: 700,
            padding: '5px 3px',
            textTransform: 'capitalize',
        },
        header: {
            fontSize: "1.3rem",
            fontWeight: 600,
        },
        subTabtext: {
            fontSize: "0.7rem",
            fontFamily: "inherit",
            fontWeight: 500,
        },


        textUnderline: {
            fontSize: "0.7rem",
            textDecoration: "underline",
            fontFamily: "inherit",
        },
        text: {
            fontSize: "0.8rem",
            fontWeight: 700,
        },
        subText: {
            fontSize: "0.9rem",
            fontWeight: 500,
            textAlign: "center",
            fontFamily: "inherit",
            textTransform: 'uppercase',
        },

        bankSelection: {
            border: "1px solid #cccc",
            cursor: 'pointer',
            marginBottom: '10px',
            position: 'relative',
            padding: '10px',
            borderRadius: '10px',

        },
        title: {
            fontSize: "1.1rem",
        },
        primaryButton: {
            padding: "0.8rem 4rem",
            background: "#0E76BC",
            color: "#fff",
            fontSize: "0.8rem",
            fontWeight: 600,
            borderRadius: "45px",
            cursor: "pointer",
            marginTop: "1rem",
            border: "1px solid #0E76BC",
            '&:hover': {
                backgroundColor: "#fff",
                color: "#0E76BC",
            },
            '&:disabled': {
                opacity: '0.7',
                cursor: 'not-allowed',
            },
        },
        rupees: {
            fontFamily: "Open Sans",
        },

        "@media (max-width:600px)": {
            root: {
                padding: "10px ",
            },
        },

        "@media (min-width:601px) and (max-width:768px)": {
            root: {
                padding: "10px 23% ",
            },
        },
        sliderCarousel: {
            "& .slick-dots li button:before": {
                color: "#777777",
                backgroundColor: "#777777",
                // padding: "0px 5px",
                margin: "0px 5px",
                borderRadius: '3px',
                height: '12px',
            }
        },

    });
    const classes = useStyles();

    const location = useLocation();
    const navigate = useNavigate();

    const [orderDetails, setOrderDetails] = useState(null);
    const [loading, setLoading] = useState(false);

    // Function to get query parameters from the URL
    const getQueryParams = (param) => {
        const urlParams = new URLSearchParams(location.search);
        return urlParams.get(param);
    };

    // Example usage of getQueryParams function
    const mandateId = getQueryParams('mandate_id');


    const get_the_mandate_detials = async () => {
        setLoading(true);
        const httpConfig1 = {
            method: "GET",
            tokenType: "user",
            headers: {
                "Content-Type": "application/json",
            },
            params: { id: mandateId },
        };

        try {
            const response = await httpInstance(`${GET_MANDATE_INDIVIDUAL}`, httpConfig1);
            if (response.status === 200) {
                // console.log(response?.data?.data);
                setOrderDetails(response?.data?.data);
                setLoading(false);
            } else {
                setLoading(false);
                CustomToast(response?.data?.message ?? response?.data?.error ?? "Somthing went wrong", "FAILED");
                handleContinue();
            }
        } catch (error) {
            CustomToast(error?.response?.data?.message ?? error?.response?.data?.error ?? "Somthing went wrong", "FAILED");
            setLoading(false);
            handleContinue();
        }
    };


    useEffect(() => {
        if (mandateId) get_the_mandate_detials()
        else {
            handleContinue();
        }
    }, [mandateId])


    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: orderDetails?.status === "success" ? animationData : animationDataFailed,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    const handleContinue = () => {
        navigate({
            pathname: `${namespace}/dashboard/mutualfund`,
            // search: `?${createSearchParams({ stepcount: "2" })}`,
        });

    }

    // ---------------------------- format account number ------------------------------------ //
    const formatAccountNumber = (accountNumber) => {
        if (!accountNumber) {
            return ''; // Return an empty string or a default value if accountNumber is undefined or null
        }
        const length = accountNumber.length;

        // Show only the first 2 and last 2 characters, replace the rest with "x"
        return accountNumber.substring(0, 2) + 'x'.repeat(length - 4) + accountNumber.substring(length - 2);
    };

    return (
        <div className={classes.root} >
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" py={4} mt={4} width={'100%'} className={classes.OrderSummaryData}>
                {loading ?
                    <Box width={'100%'} display="flex" flexDirection="column" justifyContent="center" alignItems="center" px={2}>
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                            <Skeleton variant="rounded" width={'200px'} height={'200px'} style={{ marginTop: '1rem' }} />

                        </Box>
                        <Typography variant="h6" className={classes.header} style={{ textAlign: 'center', paddingBottom: '10px', width: '100%' }}>
                            <Skeleton variant="text" width={'100%'} height={'4rem'} />
                        </Typography>
                        {[1, 2].map((data, i) => {
                            return (
                                <Skeleton variant="text" width={'100%'} key={i} height={'3rem'} />

                            )
                        })}
                        <Box my={2} width={'100%'} maxWidth={'350px'}>
                            <Grid container spacing={2}>
                                <Grid item xs={6} sm={7} className={classes.textBold}>
                                    <Skeleton variant="text" width={'100%'} />

                                </Grid>
                                <Grid item xs={6} sm={5} className={classes.text1}>

                                    <Skeleton variant="text" width={'100%'} />
                                </Grid>
                                <Grid item xs={6} sm={7} className={classes.textBold}>
                                    <Skeleton variant="text" width={'100%'} />

                                </Grid>
                                <Grid item xs={6} sm={5} className={classes.text1}>

                                    <Skeleton variant="text" width={'100%'} />

                                </Grid>
                                <Grid item xs={6} sm={7} className={classes.textBold}>
                                    <Skeleton variant="text" width={'100%'} />

                                </Grid>
                                <Grid item xs={6} sm={5} className={`${classes.text1} ${orderDetails?.transaction_basket?.payment_status === 'success' ? classes.textSuccess : classes.textfail}`}>

                                    <Skeleton variant="text" width={'100%'} />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    :
                    <Box width={'100%'} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <Box textAlign="center">
                            {(orderDetails?.status === 'pending' || orderDetails?.status === 'success' || orderDetails?.transaction_basket?.orderDetails === 'failed') &&
                                orderDetails?.status === "success" ?
                                <Lottie options={defaultOptions} height={200} width={200} />
                                :
                                <Lottie options={defaultOptions} height={200} width={200} />
                            }

                            {/* {(orderDetails?.transaction_basket?.payment_status === 'pending' || orderDetails?.transaction_basket?.payment_status === 'success' || orderDetails?.transaction_basket?.orderDetails === 'failed') &&
                                <img src={`/images/${orderDetails?.transaction_basket?.payment_status === "success" ? 'successful' : 'failed'} state.svg`} height={80} width={'auto'} alt="logo " style={{ paddingRight: '10px' }} />
                            } */}
                        </Box>
                        <Typography variant="h6" className={classes.header} style={{ textAlign: 'center', paddingBottom: '10px' }}>
                            {orderDetails?.status === 'pending' ? 'Mandate Pending' : orderDetails?.status === 'success' ? 'Mandate Successfull' : 'Mandate Failed'}
                        </Typography>
                        {/* {orderDetails?.purchases?.scheme.map((data, i) => {
                            return (
                                <Typography variant="body1" className={classes.subTabtext} style={{ textAlign: 'center', fontSize: '1rem', fontWeight: 400, marginBottom: '10px' }} key={i}>
                                    {data}
                                </Typography>
                            )
                        })} */}
                        <Box
                            item xs={2} md={1}
                            display="flex"
                            className={`${classes.bankSelection} `}
                            p={1}
                        >
                            <img src={orderDetails?.user_bank_detail?.logo_url ? `${BASE_URL}${orderDetails?.user_bank_detail?.logo_url}` : `/image/bank-svg.svg`} padding="2px 10px" height="50px" width='auto' style={{ borderRadius: '10px' }} alt="bank"/>

                            <Box width='100%' paddingLeft='10px' display='flex' justifyContent='center' alignItems={"center"} flexDirection={'column'}>
                                <Typography className={classes.text}>
                                    {/* {orderDetails?.user_bank_detail.account_number} */}
                                    {formatAccountNumber(orderDetails?.user_bank_detail?.account_number)}
                                </Typography>

                                <Typography className={classes.subTitle} style={{textTransform: 'capitalize'}}>
                                    {orderDetails?.user_bank_detail?.bank_name}
                                </Typography>
                            </Box>
                        </Box>

                        <Box my={2} width={'100%'} maxWidth={'350px'}>
                            <Grid container spacing={2}>
                                <Grid item xs={6} sm={7} className={classes.textBold}>
                                    {" "}
                                    {" Mandate type:"}
                                </Grid>
                                <Grid item xs={6} sm={5} className={classes.text1} style={{ textTransform: 'capitalize' }}>

                                    {orderDetails?.mandate_type}
                                </Grid>
                                <Grid item xs={6} sm={7} className={classes.textBold}>
                                    {" "}
                                    {" Mandate limit:"}
                                </Grid>
                                <Grid item xs={6} sm={5} className={classes.text1}>

                                    <span className={classes.rupees}>₹</span>
                                    {orderDetails?.mandate_limit?.toLocaleString('en-IN')}
                                </Grid>
                                <Grid item xs={6} sm={7} className={classes.textBold}>
                                    {" "}
                                    {" Transaction Status:"}
                                </Grid>
                                <Grid item xs={6} sm={5} className={`${classes.text1} ${orderDetails?.status === 'success' ? classes.textSuccess : classes.textFails}`}>
                                    <img src={`/images/${orderDetails?.status === 'success' ? "Icontick" : "failed"}.svg`} alt="icon" style={{ height: '20px', width: '20px', marginRight: '5px' }} />
                                    {orderDetails?.status}
                                </Grid>
                            </Grid>
                            {/* <Typography variant="body1" className={classes.header} style={{ textAlign: 'center', fontSize: '1rem', fontWeight: 400 }}>
                                Transaction Id: {orderDetails?.transaction_basket?.transaction_id}
                            </Typography>
                            <Typography variant="body1" className={classes.header} style={{ textAlign: 'center', fontSize: '1rem', fontWeight: 400 }}>
                                Amount:      <span className={classes.rupees}>₹</span>
                                {orderDetails?.purchases?.totalAmount}
                            </Typography>
                            <Typography variant="body1" className={classes.header} style={{ textAlign: 'center', fontSize: '1rem', fontWeight: 400 }}>
                                Transaction Status: {orderDetails?.transaction_basket?.payment_status}
                            </Typography> */}
                        </Box>
                        <button className={classes.primaryButton} onClick={handleContinue}>Continue</button>
                    </Box>
                }
            </Box>

        </div>
    )
}
export default MandateStatus;
