import React, { useEffect, useState } from "react";
import TitleLayoult from "../TitleLayoult";
import { Box } from "@material-ui/core";
import FileUpload from "../uploader/FileUpload";
import { useStyles } from "../styles";
import {
  SIGNATURE_UPLOAD,
  GET_SIGNATURE_UPLOAD,
  KYC_STATUS,
  GET_KYC_DATA,
} from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import { useNavigate, createSearchParams, useSearchParams } from "react-router-dom";
import { namespace } from "../../../environment";
import { BASE_URL } from '../../../environment';
import Loading from "react-loading";
import CustomToast from "../../UI/CustomToast";
import StepRedirector from "../Common/StepRedirector";

function Step8({ step }) {
  const classes = useStyles();

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const steps = searchParams.get("stepcount");

  const [uploadCount, setUploadStep] = React.useState(0);
  const [isComplient, setIsComplient] = useState(false);
  const [nextbutton, setNextButton] = useState(false);
  const [loading, setLoading] = useState(false)
  const [acceptedFile, setAcceptedFile] = useState([])

  const uploadStepCount = (uploadCount) => {
    setUploadStep(uploadCount);
  };
  const [signUrl, setSignUrl] = useState("");

  useEffect(async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await httpInstance(`${KYC_STATUS}`, httpConfig);
      setIsComplient(response.data?.data?.is_kyc_compliant);
      const signature = await httpInstance(`${GET_SIGNATURE_UPLOAD}`, httpConfig);
      const dataFinal = StepRedirector(response.data?.data, steps) // secutiry redirection
      // console.log(dataFinal, steps)
      if (dataFinal) {
        navigate({
          pathname: `${namespace}/dashboard/mutualfund`,
          // search: `?${createSearchParams({ stepcount: "2" })}`,
        });
      }
      if (signature.data?.data?.signature_url) {
        setSignUrl(
          `${BASE_URL}${signature.data?.data?.signature_url}`
        );
      } else {
        setSignUrl("");
      }
    } catch { }
  }, []);

  const submit_signature = async (acceptedFile) => {
    setAcceptedFile(acceptedFile)
    setNextButton(true)
  }

  const submit_signatures = async (data) => {
    setLoading(true)
    // console.log(acceptedFile, data)
    if (acceptedFile[0]) {
      const formData = new FormData();
      // formData.append("sign_file", acceptedFile[0]);
      formData.append("signature", acceptedFile[0]);
      const httpConfig = {
        method: "POST",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      };
      try {
        await httpInstance(`${SIGNATURE_UPLOAD}`, httpConfig);
        setNextButton(true);
        setLoading(false)
        // navigate({
        //   pathname: '/signIn',
        //   search: `?${createSearchParams(isComplient ? {stepcount:"14"}: {stepcount:"21"})}`,
        // });
        CustomToast('Upload Proof is uploaded', "SUCCESS")
        handleNext();

      } catch (error) {
        setLoading(false)
        CustomToast(error.response.data.error || 'Somthing went wrong', "FAILED")

      }
    } else {
      // setLoading(false)
      // handleNext();
      const formData = new FormData();
      // formData.append("sign_file", acceptedFile[0]);
      // Fetch the image from the URL
      const response = await fetch(data);
      if (!response.ok) {
        throw new Error("Failed to download the image.");
      }

      // Convert response to Blob
      const blob = await response.blob();

      // Extract the file name from the URL
      const fileName = data.split("/").pop(); // e.g., "f19f9c67eacf6109134e2bfbf4c85b16e.png"

      // Create a File object using the Blob and extracted name
      const file = new File([blob], fileName, { type: blob.type });

      // Append the File to formData
      formData.append("signature", file);
      const httpConfig = {
        method: "POST",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      };
      try {
        await httpInstance(`${SIGNATURE_UPLOAD}`, httpConfig);
        setNextButton(true);
        setLoading(false)
        // navigate({
        //   pathname: '/signIn',
        //   search: `?${createSearchParams(isComplient ? {stepcount:"14"}: {stepcount:"21"})}`,
        // });
        CustomToast('Upload Proof is uploaded', "SUCCESS")
        handleNext();

      } catch (error) {
        setLoading(false)
        CustomToast(error.response.data.error || 'Somthing went wrong', "FAILED")

      }

    }
  };

  const handleNext = () => {
    navigate({
      pathname: `${namespace}/onBoarding`,
      search: `?${createSearchParams(
        // isComplient ? { stepcount: "14" } : { stepcount: "21" }
        isComplient ? { stepcount: "8" } : { stepcount: "18" }
      )}`,
    });
  };

  const handleback = () => {
    navigate({
      pathname: `${namespace}/onBoarding`,
      search: `?${createSearchParams(
        // { stepcount: "5" }
        isComplient ? { stepcount: "6" } : { stepcount: "16" }
      )}`,
    });
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent="flex-start"
        // px={20}
        className={classes.backButton}
      // onClick={handleback}
      >
        {/* <img src="/images/backIcon.svg" /> */}
      </Box>
      <Box display={"flex"} justifyContent="center">

        <Box display={"flex"} alignItems="flex-start" flexDirection={"column"}>
          <Box
            display="flex"
            justifyContent="flex-start"
            // px={20}
            className={classes.backButton}
            onClick={handleback}
          >
            <img src="/images/backIcon.svg" alt="back" height={'20px'} width={'20px'} />
          </Box>
          <TitleLayoult
            title={"Digital Signature"}
            description={"Please Upload an Image of your signature"}
            align={""}
          />
          <Box>
            <FileUpload
              uploadCounter={uploadStepCount}
              handleSubmit={submit_signature}
              fileUrl={signUrl}
              handleNext={submit_signatures}
              nextbutton={nextbutton}
              validation={/\.(jpg|png|)$/}
              type=".png/.jpg"
              place="signature"
              loadings={loading}
            />
          </Box>
          {uploadCount === 3 ? (
            <Box mt={3}>
              <button className={classes.primaryButton} onClick={() => step(9)} disabled={loading}>
                Next
              </button>
            </Box>
          ) : (
            ""
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default Step8;
