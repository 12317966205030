import React, { useState, useEffect } from "react";
import {
  Box,
  makeStyles,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import {
  CREATE_REDUMPTION,
  VERIFY_REDEEM_OTP,
  RESEND_OTP,
  POST_TRANSACTION_BASKETS_GENERATE_CONSENT,
} from "../../../../utils/endpoints";
import httpInstance from "../../../../utils/instance";
import OTP from "../OTP";
import Transaction from "../Transaction";
import moment from "moment";
import localeString from "../../../../hooks/LocaleString";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../../../../components/Validation/ErrorMessage";
import { namespace, BASE_URL } from "../../../../environment";
import { Skeleton } from "@mui/material";
import CustomToast from "../../../../components/UI/CustomToast";

const Index = ({
  show,
  handleClose,
  schemeData,
  bankData,
  fundDetail,
  fpFundData,
  category,
}) => {
  const useStyles = makeStyles({
    root: {
      "& .MuiDialogContent-root": {
        fontSize: "0.8rem",
        lineHeight: "25px",
        padding: "20px 25px",
      },
      "& .MuiDialog-paperWidthSm": {
        minWidth: "50%",
        maxWidth: "300px",
        maxHeight: "100%",
      },
    },
    dialogTitle: {
      padding: "10px",
      "& .MuiTypography-body1": {
        fontSize: "1.3rem",
        fontWeight: 500,
      },
    },

    text: {
      fontSize: "0.8rem",

    },
    Fundtext: {
      fontSize: "0.8rem",
      padding: '0px 10px 10px 12px!important',
    },
    textGrey: {
      fontSize: "0.8rem",
      color: "#282828",
      marginTop: "10px",
    },

    inputBox: {
      // background: "#B3E1FF",
      border: "none",
      borderBottom: '1px solid #B3E1FF',
      borderRadius: "0px",
      padding: "10px",
      width: "-webkit-fill-available",
      color: "#0E76BC",
    },

    inputDisabled: {
      background: "#DDDDDD",
      border: "none",
      borderBottom: '1px solid #000',
      borderRadius: "0px",
      padding: "10px",
      width: "-webkit-fill-available",
    },

    rupeesSymbol: {
      position: "absolute",
      left: "8px",
      top: "5px",
      color: "#0E76BC",
      fontFamily: "Open Sans",
    },

    fundTitle: {
      fontWeight: '600 !important',
      fontSize: '1rem !important',
    },
    fundCategory: {
      fontWeight: '400 !important',
      fontSize: '0.8rem !important',

    },
    fundDetailsDataHead: {
      fontSize: '0.8rem !important',
      fontWeight: '400 !important',
      color: '#000 !important',
    },
    fundDetailsData: {
      fontSize: '1rem !important',
      fontWeight: '600 !important',
      color: '#000 !important',
      display: 'flex',
      flexDirection: "column",

    },

    checkBox: {
      display: "flex",
      justifyContent: "end",
      "& .MuiSvgIcon-root": {
        height: "0.9em",
      },
      "& .MuiTypography-body1": {
        fontSize: "0.75rem",
        color: "#000",
      },
      "& .MuiCheckbox-colorPrimary.Mui-checked": {
        color: "#0E76BC",
      },
      "& .MuiFormControlLabel-root": {
        marginRight: "0px",
      },
    },
    primaryButton: {
      padding: "0.8rem 3rem",
      background: "#0E76BC",
      color: "#fff",
      fontSize: "0.8rem",
      fontWeight: 600,
      borderRadius: "45px",
      cursor: "pointer",
      fontFamily: "inherit",
      border: "1px solid #0E76BC",
      "&:hover": {
        backgroundColor: "#fff",
        color: "#0E76BC",
      },
      '&:disabled': {
        opacity: '0.5',
      },

    },
    rupees: {
      fontFamily: "Open Sans",
      //fontSize: "0.83rem",
    },
    minimumText: {
      fontSize: "0.7rem",
      color: "#9D9D9D",
      marginLeft: "30px",
    },
    "@media (max-width:600px)": {
      root: {
        "& .MuiDialog-paperWidthSm": {
          maxWidth: "90%",
        },
      },
    },
  });


  const classes = useStyles();
  const transaction_type = "redemption"

  const [redeemType, setRedeemType] = useState("amount");
  const [units, setUnits] = useState("");
  const [amount, setAmount] = useState("");
  const [comp, setComp] = useState("redeem");
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadVerify, setLoadVerify] = useState(false);
  const [error, setError] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [confirmData, setConfirmData] = useState(null)

  const navigate = useNavigate();

  // console.log(fundDetail,
  //   fpFundData, schemeData)

  const handle_close = () => {
    setComp("redeem");
    setUnits("");
    setAmount("");
    handleClose();
    setRedeemType("units");
    setLoadVerify(false);
    setLoading(false);
    setError(false);
    setInputError(false);
    setOtpError(false);
    navigate(`${namespace}/myportfolio`, {
      replace: true,
    });
  };

  const handle_redeem_type = (value) => {
    setUnits("");
    setAmount("");
    setRedeemType(value);
    setError(false);
    setInputError(false);
  };

  const handle_redeem_all = (e) => {
    if (e.target.checked) {
      handle_redeem_type("all");
      //setAmount(schemeData?.redeemableAmount)
    } else {
      setRedeemType("amount");
    }
  };

  const handleAmount = (e) => {
    e.target.value = e.target.value
      .replace(/[^0-9.]/g, "")
      .replace(/(\..*)\./g, "$1")
      .toLowerCase();
    setAmount(e.target.value.replace(/^00+/, "0"));
    if (e.target.value > schemeData?.schemes?.[0]?.market_value?.redeemable_amount) {
      setInputError(
        `Maximum Redeemable Amount ${schemeData?.schemes?.[0]?.market_value?.redeemable_amount}`
      );
    } else if (e.target.value < fpFundData?.min_withdrawal_amount) {
      setInputError(
        `Minimum Redeem Amount ${fpFundData?.min_withdrawal_amount}`
      );
    } else {
      setInputError("");
    }
  };

  const handleUnits = (e) => {
    e.target.value = e.target.value
      .replace(/[^0-9.]/g, "")
      .replace(/(\..*)\./g, "$1")
      .toLowerCase();
    setUnits(e.target.value.replace(/^00+/, "0"));
    if (e.target.value > schemeData?.redeemableUnits) {
      setInputError(`Maximum Redeemable Units ${schemeData?.redeemableUnits}`);
    } else if (e.target.value < fpFundData?.minWithdrawalUnits) {
      setInputError(`Minimum Redeem Units ${fpFundData?.minWithdrawalUnits}`);
    } else {
      setInputError("");
    }
  };


  const generateOtpForReduption = async (id) => {
    // console.log(value?.data)
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      // test: "test",
      headers: {
        "Content-Type": "application/json",
      },
      params: { transaction_basket_id: id },
    };

    // const httpConfig1 = {
    //   method: "GET",
    //   tokenType: "user",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   params: { isin: schemeData?.schemes?.[0]?.isin },
    // };

    try {
      const response = await httpInstance(`${POST_TRANSACTION_BASKETS_GENERATE_CONSENT}`, httpConfig)
      // console.log(response)
      CustomToast("OTP sent successfully", "SUCCESS")
      setComp('otp');

      // navigate(`/mutualfund/scheme-code=${response.data?.data[0]?.scheme_code}`)
    } catch (error) {
      CustomToast(error?.response?.data?.error ?? error.response.data?.message ?? "Somthing went wrong", "FAILED")
      setComp('redeem')
      setError(error.response?.data?.message ?? error.response?.data?.error ?? "Something went wrong");


    }
  }


  const handle_comp = (value, id) => {
    // setComp(value);
    generateOtpForReduption(id)
    // console.log(value)
  };


  const submit_redeem = async () => {
    if (
      (redeemType == "units" && !units) ||
      (redeemType == "amount" && !amount) ||
      redeemType == ""
    ) {
      setError("Please enter Redeem Amount");

    } else if (schemeData?.schemes?.[0]?.market_value?.redeemable_amount <= 0) {
      setError("There is no Redeemable Amount or Units");

    } else if (!inputError) {
      setError(false);
      setLoading(true);
      // const formData = {
      //   userId: localStorage.getItem("user_id"),
      //   transactionTypeId: 3,
      //   paymentType: "NETBANKING",
      //   euin: "",
      //   isinCode: schemeData?.isin,
      //   fundAmount: redeemType == "amount" ? amount : null,
      //   fundUnits:
      //     redeemType == "units"
      //       ? units
      //       : redeemType == "all"
      //         ? schemeData?.redeemableUnits
      //         : null,
      //   isRedeemAll: redeemType == "all" ? true : false,
      //   folioNumber: schemeData?.folioNumber,
      //   fundName: fundDetail?.scheme_name,
      //   amcId: fundDetail?.amc_code,
      //   isAssisted: category == "assisted" ? true : false,
      // };

      const formData = {
        user_id: localStorage.getItem("user_id"),
        transaction_basket_items: [
          {
            transaction_type: transaction_type,
            fund_isin: schemeData?.schemes?.[0]?.isin,
            folio_number: schemeData?.folio_number,
            ...((redeemType === "amount" || redeemType == "all") && { amount: redeemType == "all" ? (schemeData?.schemes?.[0]?.market_value?.redeemable_amount ? schemeData?.schemes?.[0]?.market_value?.redeemable_amount : null) : Number(amount) }),
            ...(redeemType === "units" && {
              units:
                redeemType == "units"
                  ? units
                  : redeemType == "all"
                    ? schemeData?.schemes?.[0]?.holdings?.redeemable_units
                    : null
            }),
          }
        ]
      }
      const httpConfig = {
        method: "POST",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      };
      console.log(httpConfig, redeemType)

      try {
        const response = await httpInstance(`${CREATE_REDUMPTION}`, httpConfig);
        // console.log(response.data.data?.id)
        setId(response.data?.data?.id);
        // sessionStorage.setItem("redeem",formData?.fundAmount? `₹${formData?.fundAmount}` : `${formData?.units}units`)
        handle_comp("otp", response.data.data?.id);
        setLoading(false)
      } catch (error) {
        setLoading(false);
        setError(error.response?.data?.message ?? error.response?.data?.error ?? "Something went wrong");
        CustomToast(error?.response?.data?.error ?? error?.response?.data?.message ?? "Somthing went wrong", "FAILED")

      }
    }
    // handle_comp("otp", 104);
    // setId(104);
  };
  //--------------------------redeem OTP---------------------------------------------------------------------//
  const verify_otp = async (otp) => {
    setLoadVerify(true);
    setOtpError(false);
    const formData = {
      otp: otp,
      transaction_basket_id: id
    }
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    };

    try {
      const response = await httpInstance(`${VERIFY_REDEEM_OTP}`, httpConfig);
      if (response?.data) {
        setConfirmData(response?.data?.data)
        setComp("confirm")
      } else {
        handle_otp_error('Something Went Wrong')
        CustomToast("Somthing went wrong", "FAILED")
      }
      // if (response?.data?.data?.transaction_basket_items?.[0]?.status === 'failed') {
      //   handle_otp_error(response?.data?.data?.transaction_basket_items?.[0]?.response_message);
      //   setTimeout(() => {
      //     navigate(`${namespace}/myportfolio/redeem/${schemeData?.schemes?.[0]?.isin}`, {
      //       replace: true,
      //       // state: {
      //       //   fromFund: { name: fundDetail?.schemeName, img: `${BASE_URL}${fundDetail?.amc?.logoUrl}` },
      //       //   bankData: bankData,
      //       // },
      //     });
      //     setComp("redeem")
      //     setLoadVerify(false);
      //   }, 2000);

      // } else if (response?.data?.data?.transaction_basket_items?.[0]?.status === ("active" || "confirmed")) {
      //   navigate(`${namespace}/mutualfund/redeem-success`, {
      //     state: {
      //       value: amount ? `₹${amount}` : units ? `${units} units` : `all units`,
      //     },
      //   });
      //   setLoadVerify(false);

      // } else {
      //   handle_otp_error('Something Went Wrong')
      //   CustomToast("Somthing went wrong", "FAILED")
      // }


    } catch (error) {
      setLoadVerify(false);
      setOtpError(error.response.data?.message);
      CustomToast(error?.response?.data?.error ?? error.response.data?.message ?? "Somthing went wrong", "FAILED")

    }
  };

  const handle_otp_error = (error) => {
    setLoadVerify(false);
    setOtpError(error);
  };

  //------------------------resend OTP------------------------------------------------------------//


  return (
    <div>
      <Dialog className={classes.root} open={show}
      // disableEnforceFocus
      // disableRestoreFocus
      >
        <DialogTitle classes={{ root: classes.dialogTitle }}>
          <Grid container>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" mt={3}>
                <Typography className={classes.title}>Redeem</Typography>
              </Box>
            </Grid>
            <Box display={'flex'} width={'100%'} height={'0px'}>

              <Box width={'100%'} display="flex" justifyContent="start" position={'relative'} bottom={'25px'}>
                {comp === 'otp' && <img
                  src="/images/backIcon.svg"
                  alt="back"
                  onClick={() => {
                    setComp('redeem');
                  }}
                  style={{ cursor: "pointer" }}
                  height="20px" width="20px"
                />
                }
              </Box>
              <Box width={'100%'} display="flex" justifyContent="end" position={'relative'} bottom={'55px'}>
                <CloseIcon
                  onClick={handle_close}
                  style={{ cursor: "pointer" }}
                />
              </Box>

            </Box>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {comp == "redeem" && (
            <>
              <Box px={{ xs: 2, md: 6 }} >
                <Box display={'flex'} borderBottom={'1px solid #E4E4E4'} mb={2}>

                  <Box display="flex" alignItems="center" mr={2}>

                    <img
                      // src={fund?.img_url}
                      src={`${BASE_URL}${fundDetail?.amc?.logoUrl}`}
                      height={'auto'} width={54} style={{ borderRadius: '5px', marginRight: '10px' }} />


                  </Box>
                  <Box>
                    <Typography variant="h6" className={classes.fundTitle} >
                      {fpFundData?.name}
                    </Typography>
                    <Typography variant="body1" className={classes.fundCategory} >
                      {fundDetail?.category?.categoryName}
                    </Typography>
                  </Box>
                </Box>
                <Box borderBottom={'1px solid #E4E4E4'} mb={2} >
                  <Grid container spacing={3}>
                    <Grid item xs={6} className={classes.Fundtext}>
                      <Box className={classes.fundDetailsData} >
                        <span className={classes.fundDetailsDataHead}>Current Amount:</span>
                        <span>
                          <span className={classes.rupees}>₹</span>
                          {schemeData?.schemes?.[0]?.market_value?.amount?.toLocaleString('en-IN')}
                        </span>
                      </Box>
                    </Grid>
                    <Grid item xs={6} className={classes.Fundtext}>
                      {/* {moment(schemeData?.schemes?.[0]?.nav?.as_on).format("DD MMM YYYY")} */}
                      <Box className={classes.fundDetailsData} >
                        <span className={classes.fundDetailsDataHead}>Max. Redeemable Amount:</span>
                        <span>
                          <span className={classes.rupees}>₹</span>
                          {schemeData?.schemes?.[0]?.market_value?.redeemable_amount?.toLocaleString('en-IN')}
                        </span>

                      </Box>
                    </Grid>
                    <Grid item xs={6} className={classes.Fundtext}>
                      <Box className={classes.fundDetailsData} >
                        <span className={classes.fundDetailsDataHead}>Total Unites:</span>
                        <span>
                          {/* <span className={classes.rupees}>₹</span> */}
                          {schemeData?.schemes?.[0]?.holdings?.units}
                        </span>

                      </Box>
                    </Grid>
                    <Grid item xs={6} className={`${classes.Fundtext}`} >
                      <Box className={classes.fundDetailsData} >
                        <span className={classes.fundDetailsDataHead}>Max. Redeemable Units:</span>
                        <span>
                          {/* <span className={classes.rupees}>₹</span> */}
                          {schemeData?.schemes?.[0]?.holdings?.redeemable_units}
                        </span>

                      </Box>
                    </Grid>
                    <Grid item xs={12} className={classes.Fundtext}>
                      Applicable Nav Date:{" "}{moment(schemeData?.schemes?.[0]?.nav?.as_on).format("DD MMM YYYY")}

                    </Grid>
                    {/* <Grid item xs={4} className={classes.text}>
                  </Grid> */}
                  </Grid>
                </Box>
              </Box>
              <Box mt={4} px={{ xs: 2, md: 6 }}>
                <Grid container spacing={3}>

                  {/* <Grid item xs={8} className={classes.text}>
                    <Box display="flex" alignItems="center">
                      <input
                        type="radio"
                        id="r1"
                        checked={redeemType == "units"}
                        onChange={() => handle_redeem_type("units")}
                        style={{
                          height: "16px",
                          width: "16px",
                          marginRight: 10,
                        }}
                      />
                      <label htmlFor="r1"> Enter Redeem Units</label>
                      <br />
                    </Box>
                    <Typography
                      className={classes.minimumText}
                    >{`Minimum Redeem Units ${fpFundData?.min_withdrawal_units}`}</Typography> */}


                  {/* <Box display="flex" alignItems="center">
                        <input 
                        type="radio"
                        id="r2"
                        style={{ height: "16px", width: "16px", marginRight: 10}}
                        />
                        <label for="r2"> Enter Redeem Amount</label><br/>
                        </Box> */}


                  {/*  </Grid>
                <Grid item xs={4} className={classes.text}>
                    <Box>
                      <input
                        type="text"
                        className={
                          redeemType == "units"
                            ? classes.inputBox
                            : classes.inputDisabled
                        }
                        disabled={redeemType == "units" ? false : true}
                        value={units}
                        onChange={handleUnits}
                      /> */}

                  {/* <input
                            type="text"
                            className={classes.inputBox}
                            /> */}
                  {/* 
                    </Box>
                  </Grid> */}
                </Grid>
                <Box py={2} style={{ borderBottom: "1px solid #C4C4C4" }}>
                  <Grid container spacing={3}>
                    <Grid item xs={6} className={classes.text} style={{ display: "flex", alignItems: "center" }}>
                      <Box display="flex" alignItems="center">
                        <input
                          type="radio"
                          id="r2"
                          checked={redeemType == "amount"}
                          onChange={() => handle_redeem_type("amount")}
                          style={{
                            height: "16px",
                            width: "16px",
                            marginRight: 10,
                          }}
                        />
                        <label htmlFor="r2"> Enter Redeem Amount</label>
                        {/* <br /> */}
                      </Box>
                      {/* <Typography className={classes.minimumText}>
                          Minimum Redeem Amount{" "}
                          <span className={classes.rupees}>₹ </span>{" "}
                          {fundDetail?.min_withdrawal_amount}
                        </Typography> */}
                    </Grid>
                    <Grid item xs={6} className={classes.text}>
                      <Box style={{ position: "relative" }}>
                        {redeemType == "amount" && (
                          <span className={classes.rupeesSymbol}>₹</span>
                        )}
                        <input
                          type="text"
                          className={
                            redeemType == "amount"
                              ? classes.inputBox
                              : classes.inputDisabled
                          }
                          style={{ paddingLeft: "18px" }}
                          disabled={redeemType == "amount" ? false : true}
                          value={amount}
                          onChange={handleAmount}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box px={{ xs: 2, md: 6 }}>
                <Grid container>
                  {/* <Grid item xs={8}>
                    <Typography className={classes.textGrey}>
                      Available Amount
                    </Typography>
                    <Typography className={classes.textGrey}>
                      Available Units
                    </Typography>
                  </Grid> */}
                  <Grid item xs={12}>
                    {/* <Box display="flex" justifyContent="end">
                      {" "}
                      <Typography className={classes.textGrey}>
                        <span className={classes.rupees}>₹ </span>{" "}
                        {schemeData?.schemes?.[0]?.market_value?.redeemable_amount}{" "}
                      </Typography>
                    </Box>
                    <Box display="flex" justifyContent="end">
                      <Typography className={classes.textGrey}>
                        {schemeData?.schemes?.[0]?.holdings?.redeemable_units}
                      </Typography>
                    </Box> */}
                    <Box className={classes.checkBox}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            onChange={handle_redeem_all}
                            checked={redeemType == "all"}
                          />
                        }
                        label="Redeem All"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box px={{ xs: 2, md: 6 }} display={'flex'} alignItems={'center'} textAlign={'center'} justifyContent={'center'}>
                {error && <ErrorMessage message={error} />}
                {inputError && <ErrorMessage message={inputError} />}
              </Box>
              {/* <Box px={{ xs: 2, md: 6 }}>
                <Box p={2} style={{ border: "1px solid #E6E6E6" }}>
                  <Grid container>
                    <Grid item xs={1}>
                      <img
                        src={`${BASE_URL}${bankData?.[0]?.logo_url}`}
                        height="auto"
                        width="20px"
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography className={classes.text}>{`To ${bankData?.[0]?.bank_name
                        } ( *******${bankData?.[0]?.account_number?.slice(
                          -3
                        )})`}</Typography>
                      <Typography className={classes.textGrey}>
                        Expected Transfer by 4-5 workig days
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box> */}

              <Box mt={5} mb={0} display="flex" justifyContent="center">
                <button
                  className={classes.primaryButton}
                  onClick={submit_redeem}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size="1rem" color="inherit" />
                  ) : (
                    "Confirm"
                  )}
                </button>
              </Box>
            </>
          )}

          {/* otp screen */}
          {comp == "otp" && (
            <Box px={{ xs: 2, md: 6 }}>
              <OTP
                verify_otp={verify_otp}
                id={id}
                transactionTypeId={3}
                handle_otp_error={handle_otp_error}
                otpError={otpError}
                loadVerify={loadVerify}
              />
            </Box>
          )}

          {/* Confermation Screen */}
          {comp == "confirm" && (
            <Box px={{ xs: 2, md: 6 }}>
              <Transaction
                verify_otp={verify_otp}
                id={id}
                transactionTypeId={3}
                handle_otp_error={handle_otp_error}
                otpError={otpError}
                loadVerify={loadVerify}
                confirmData={confirmData}
                handleClose={handleClose}
                transaction_type={transaction_type}
              />
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Index;
