import React, { useState, useEffect } from "react";
import {
  Box,
  makeStyles,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import ErrorMessage from "../../../../components/Validation/ErrorMessage";
import { useLocation, useNavigate } from "react-router-dom";
import {
  GENERATE_SWITCH,
  VERIFY_SWITCH_OTP,
  GET_FUND_FP_DATA,
  POST_TRANSACTION_BASKETS_GENERATE_CONSENT,
} from "../../../../utils/endpoints";
import httpInstance from "../../../../utils/instance";
import OTP from "../OTP";
import { BASE_URL, namespace } from "../../../../environment";
import CustomToast from "../../../../components/UI/CustomToast";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { CircularProgress } from "@mui/material";
import Transaction from "../Transaction";

const SwitchDialog = ({
  show,
  handleClose,
  fundToData,
  fundFromData,
  category,
}) => {
  const useStyles = makeStyles({
    root: {
      "& .MuiDialogContent-root": {
        fontSize: "0.8rem",
        lineHeight: "25px",
        padding: "20px 25px",
      },
      "& .MuiDialog-paperWidthSm": {
        minWidth: "50%",
        maxWidth: "300px",
        maxHeight: "100%",
      },
    },
    dialogTitle: {
      padding: "10px",
      "& .MuiTypography-body1": {
        fontSize: "1.3rem",
        fontWeight: 500,
      },
    },

    text: {
      fontSize: "0.8rem",
    },
    textGrey: {
      fontSize: "0.8rem",
      color: "#282828",
      marginTop: "10px",
    },
    inputBox: {
      // background: "#B3E1FF",
      border: "none",
      borderBottom: '1px solid #B3E1FF',
      borderRadius: "0px",
      padding: "10px",
      width: "-webkit-fill-available",
      color: "#0E76BC",
    },

    inputDisabled: {
      background: "#DDDDDD",
      border: "none",
      borderBottom: '1px solid #000',
      borderRadius: "0px",
      padding: "10px",
      width: "-webkit-fill-available",
    },

    rupeesSymbol: {
      position: "absolute",
      left: "8px",
      top: "6px",
      color: "#0E76BC",
      fontFamily: "Open Sans",
    },
    checkBox: {
      display: "flex",
      justifyContent: "end",
      "& .MuiSvgIcon-root": {
        height: "0.9em",
      },
      "& .MuiTypography-body1": {
        fontSize: "0.75rem",
        color: "#000",
      },
      "& .MuiCheckbox-colorPrimary.Mui-checked": {
        color: "#0E76BC",
      },
      "& .MuiFormControlLabel-root": {
        marginRight: "0px",
      },
    },
    primaryButton: {
      padding: "0.8rem 3rem",
      background: "#0E76BC",
      color: "#fff",
      fontSize: "0.8rem",
      fontWeight: 600,
      borderRadius: "45px",
      cursor: "pointer",
      fontFamily: "inherit",
      border: "1px solid #0E76BC",
      "&:hover": {
        backgroundColor: "#fff",
        color: "#0E76BC",
      },
      '&:disabled': {
        opacity: '0.5',
      },
    },
    rupees: {
      fontFamily: "Open Sans",
    },
    minimumText: {
      fontSize: "0.7rem",
      color: "#9D9D9D",
      marginLeft: "30px",
    },
    fundTitle: {
      fontWeight: '600 !important',
      fontSize: '1rem !important',
    },
    fundCategory: {
      fontWeight: '400 !important',
      fontSize: '0.8rem !important',

    },
    Fundtext: {
      fontSize: "0.8rem",
      padding: '0px 10px 10px 12px!important',
    },
    fundDetailsDataHead: {
      fontSize: '0.8rem !important',
      fontWeight: '400 !important',
      color: '#000 !important',
    },
    fundDetailsData: {
      fontSize: '1rem !important',
      fontWeight: '600 !important',
      color: '#000 !important',
      display: 'flex',
      flexDirection: "column",
    },

    rupees: {
      fontFamily: "Open Sans",
      //fontSize: "0.83rem",
    },
    transferData: {
      fontSize: "0.8rem !important",
      fontWeight: 600,
      border: "1px solid #0E76BC !important",
      padding: "5px 10px 5px 10px !important",
      borderRadius: '12px',
      color: "#0E76BC !important",
      height: '25px',
      backgroundColor: '#fff',
      display: 'flex',
      alignItems: 'center'
    },
  });

  const classes = useStyles();
  const transaction_type = "switch"

  const [switchType, setSwitchType] = useState("amount");
  const [switchInFpData, setSwitchInFpData] = useState(null);
  const [units, setUnits] = useState("");
  const [amount, setAmount] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [comp, setComp] = useState("switch");
  const [loadVerify, setLoadVerify] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [id, setId] = useState("");
  const [inputError, setInputError] = useState("");
  const [confirmData, setConfirmData] = useState(null)


  const { state } = useLocation();
  const { schemeData, switchData } = state;
  // console.log(state, 'state', fundToData)
  const navigate = useNavigate();

  const get_switchIn_data = async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: { isin: fundToData?.isin },
    };
    try {
      const response = await httpInstance(`${GET_FUND_FP_DATA}`, httpConfig);
      setSwitchInFpData(response.data?.data);
    } catch { }
  };

  useEffect(() => {
    if (show) {
      get_switchIn_data();
    }
  }, [show]);

  const handle_close = () => {
    setLoading(false);
    setUnits("");
    setAmount("");
    handleClose();
    setComp("switch");
    setSwitchType("units");
  };

  const handle_switch_type = (value) => {
    setUnits("");
    setAmount("");
    setInputError("");
    setError("");
    setSwitchType(value);
  };

  const handleAmount = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "").toLowerCase();
    setAmount(e.target.value);
    let maxAmount = Math.max(
      switchData?.minSwitchOutAmount,
      switchInFpData?.minSwitchInAmount
    );
    if (e.target.value < maxAmount) {
      setInputError(`Minimum Switch Amount ${maxAmount}`);
    } else if (e.target.value > schemeData?.redeemableAmount) {
      setInputError(`Available Switch Amount ${schemeData?.redeemableAmount}`);
    } else {
      setInputError("");
    }
  };

  const handleUnits = (e) => {
    e.target.value = e.target.value.replace(/[^0-9.]/g, "").toLowerCase();
    setUnits(e.target.value);
    if (e.target.value < switchData?.minSwitchOutUnits) {
      setInputError(`Minimum Switch Amount ${switchData?.minSwitchOutUnits}`);
    } else if (e.target.value > schemeData?.redeemableUnits) {
      setInputError(`Available Switch Units ${schemeData?.redeemableUnits}`);
    } else {
      setInputError("");
    }
  };

  const handle_switch_all = (e) => {
    if (e.target.checked) {
      handle_switch_type("all");
      //setAmount(schemeData?.redeemableAmount)
    } else {
      setSwitchType("");
    }
  };


  // ----------------------------- handle Otp --------------------------------------------//

  const generateOtpForReduption = async (id) => {
    // console.log(value?.data)
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      // test: "test",
      headers: {
        "Content-Type": "application/json",
      },
      params: { transaction_basket_id: id },
    };

    try {
      const response = await httpInstance(`${POST_TRANSACTION_BASKETS_GENERATE_CONSENT}`, httpConfig)
      // console.log(response)
      // navigate(`/mutualfund/scheme-code=${response.data?.data[0]?.scheme_code}`)
      CustomToast("OTP sent successfully", "SUCCESS")
      setComp('otp');
    } catch (error) {
      CustomToast(error?.response?.data?.error ?? error.response.data?.message ?? "Somthing went wrong", "FAILED")
      setComp('switch')
      setError(error.response?.data?.message ?? error.response?.data?.error ?? "Something went wrong");

    }
  }



  //------------------------------confirm redeem-------------------------------------------//
  const submit_switch = async () => {
    if (
      (switchType == "units" && !units) ||
      (switchType == "amount" && !amount) ||
      switchType == ""
    ) {
      setError("Please enter Switch Units / Amount");
    } else if (!inputError) {
      setError("");
      setLoading(true);

      // const formData = {
      //   userId: localStorage.getItem("user_id"),
      //   transactionTypeId: 4,
      //   fundAmount:
      //     switchType == "amount"
      //       ? amount
      //       : switchType == "all"
      //         ? schemeData?.redeemableAmount
      //         : 0,
      //   folioNumber: schemeData?.folioNumber,
      //   nav: schemeData?.nav,
      //   schemeOutIsin: schemeData?.isin,
      //   schemeInIsin: fundToData?.isin,
      //   schemeOutFundPlanId: fundFromData?.scheme_code,
      //   schemeInFundPlanId: fundToData?.scheme_code,
      //   switchOutMinAmt: fundFromData?.switch_min_amt,
      //   switchInAllowed: true,
      //   switchInSchemeName: fundToData?.scheme_name,
      //   switchOutSchemeName: fundFromData?.scheme_name,
      //   switchInImgUrl: fundToData?.img_url,
      //   switchOutImgUrl: fundFromData?.img_url,
      //   units: switchType == "units" ? units : null,
      //   isAssisted: category == "assisted" ? true : false,
      // };


      const formData = {
        user_id: localStorage.getItem("user_id"),
        transaction_basket_items: [
          {
            transaction_type: "switch_fund",
            fund_isin: switchData?.isin,
            folio_number: schemeData?.folio_number,
            amount: switchType == "amount"
              ? amount
              : switchType == "all"
                ? schemeData?.schemes?.[0]?.market_value?.redeemable_amount
                : 0,
            to_fund_isin: fundToData?.isinCode
          }
        ]
      }


      const httpConfig = {
        method: "POST",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      };

      try {
        const response = await httpInstance(`${GENERATE_SWITCH}`, httpConfig);
        setId(response.data?.data?.id);
        setComp("otp");
        generateOtpForReduption(response.data?.data?.id)
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(error.response?.data?.message ?? error.response?.data?.error ?? "Something went wrong");
        CustomToast(error?.response?.data?.error ?? error.response.data?.message ?? "Somthing went wrong", "FAILED")
      }
    }
  };

  //---------------------------verify OTP---------------------------------------------------//
  const verify_otp = async (otp) => {
    setLoadVerify(true);
    setOtpError(false);
    const formData = {
      otp: otp,
      transaction_basket_id: id
    }
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      // params: { id: id, otp: otp },
      data: formData,
    };

    try {
      const response = await httpInstance(`${VERIFY_SWITCH_OTP}`, httpConfig);
      // if (response?.data?.data?.transaction_basket_items?.[0]?.status === 'failed') {
      //   handle_otp_error(response?.data?.data?.transaction_basket_items?.[0]?.response_message);
      //   setTimeout(() => {
      //     // navigate(`${namespace}/myportfolio/redeem/${schemeData?.schemes?.[0]?.isin}`, {
      //     //   replace: true,
      //     //   // state: {
      //     //   //   fromFund: { name: fundDetail?.schemeName, img: `${BASE_URL}${fundDetail?.amc?.logoUrl}` },
      //     //   //   bankData: bankData,
      //     //   // },
      //     // });
      //     setComp("switch")
      //     setLoadVerify(false);
      //   }, 2000);

      // } else if (response?.data?.data?.transaction_basket_items?.[0]?.status === "active" || response?.data?.data?.transaction_basket_items?.[0]?.status === "confirmed") {
      //   navigate(`${namespace}/mutualfund/switch-success`, {
      //     state: {
      //       fromFund: {
      //         name: fundFromData?.schemeName,
      //         // img: fundFromData?.amcLogoUrl,
      //         img: schemeData?.schemes?.[0]?.logo_url,
      //       },
      //       toFund: { name: fundToData?.schemeName, img: fundToData?.amcLogoUrl },
      //     },
      //   });
      //   setLoadVerify(false);
      // } else {
      //   handle_otp_error('Something Went Wrong')
      //   CustomToast("Somthing went wrong", "FAILED")

      // }
      if (response?.data) {
        setConfirmData(response?.data?.data)
        setComp("confirm")
        setLoadVerify(false);

      } else {
        setLoadVerify(false);
        handle_otp_error('Something Went Wrong')
        CustomToast("Somthing went wrong", "FAILED")
      }

    } catch (error) {
      setLoadVerify(false);
      handle_otp_error(error?.response?.data?.error ?? error.response.data?.message ?? "Somthing went wrong");
      CustomToast(error?.response?.data?.error ?? error.response.data?.message ?? "Somthing went wrong", "FAILED")
    }
  };

  const handle_otp_error = (error) => {
    setLoadVerify(false);
    setOtpError(error);
  };
  return (
    <div>
      <Dialog className={classes.root} open={show}>
        <DialogTitle classes={{ root: classes.dialogTitle }}>
          <Grid container>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <Typography className={classes.title}>Switch</Typography>
              </Box>
            </Grid>
            <Box display={'flex'} width={'100%'} height={'0px'}>
              <Box width={'100%'} display="flex" justifyContent="start" position={'relative'} bottom={'30px'}>
                {comp == 'otp' && <img
                  src="/images/backIcon.svg"
                  alt="back"
                  onClick={() => {
                    setComp('switch');
                  }}
                  style={{ cursor: "pointer" }}
                  height="20px" width="20px"
                />
                }
              </Box>
              <Box width={'100%'} display="flex" justifyContent="end" position={'relative'} bottom={'30px'}>
                <CloseIcon
                  onClick={handle_close}
                  style={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {comp == "switch" && (
            <>
              <Box px={{ xs: 2, md: 6 }}>
                <Box width={'100%'} borderBottom={'1px solid #E4E4E4'} my={2}>
                  <Box display={'flex'} my={2}>
                    <Box display="flex" alignItems="center" mr={2}>
                      <img
                        // src={fund?.img_url}
                        src={`${BASE_URL}${fundFromData?.amc?.logoUrl}`}
                        height={'auto'} width={54} style={{ borderRadius: '5px', marginRight: '10px' }} />
                    </Box>
                    <Box>
                      <Typography variant="h6" className={classes.fundTitle} >
                        {fundFromData?.schemeName}
                      </Typography>
                      <Typography variant="body1" className={classes.fundCategory} >
                        {fundFromData?.category?.categoryName}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <hr
                      style={{
                        color: '#0E76BC', // Change the color
                        backgroundColor: '#0E76BC', // Ensures color is applied
                        height: '0.5px', // Adjust thickness if needed
                        marginTop: '25px', // Add margin to the top
                        marginBottom: '20px', // Add margin to the bottom
                        border: 'none', // Remove default border if needed
                      }}
                    />
                    <Box display={'flex'} justifyContent={'center'} position={'relative'} bottom={'40px'} height={'0px'}>
                      <span className={classes.transferData}>
                        <ArrowDownwardIcon style={{ fill: "#0E76BC", fontSize: "20px" }} />
                        {" "} Transfer
                      </span>
                    </Box>

                  </Box>
                  <Box display={'flex'} my={2}>
                    <Box display="flex" alignItems="center" mr={2}>
                      <img
                        // src={fund?.img_url}
                        src={`${BASE_URL}${fundToData?.amcLogoUrl}`}
                        height={'auto'} width={54} style={{ borderRadius: '5px', marginRight: '10px' }} />
                    </Box>
                    <Box>
                      <Typography variant="h6" className={classes.fundTitle} >
                        {fundToData?.schemeName}
                      </Typography>
                      <Typography variant="body1" className={classes.fundCategory} >
                        {fundToData?.categoryName}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box borderBottom={'1px solid #E4E4E4'} mb={2} >
                  <Grid container spacing={3}>
                    <Grid item xs={6} className={classes.Fundtext}>
                      <Box className={classes.fundDetailsData} >
                        <span className={classes.fundDetailsDataHead}>Current Amount:</span>
                        <span>
                          <span className={classes.rupees}>₹</span>
                          {schemeData?.schemes?.[0]?.market_value?.amount?.toLocaleString('en-IN')}
                        </span>
                      </Box>
                    </Grid>
                    <Grid item xs={6} className={classes.Fundtext}>
                      {/* {moment(schemeData?.schemes?.[0]?.nav?.as_on).format("DD MMM YYYY")} */}
                      <Box className={classes.fundDetailsData} >
                        <span className={classes.fundDetailsDataHead}>Total Unites:</span>
                        <span>
                          {/* <span className={classes.rupees}>₹</span> */}
                          {schemeData?.schemes?.[0]?.holdings?.units}
                        </span>
                      </Box>
                    </Grid>
                    <Grid item xs={6} className={classes.Fundtext}>
                      <Box className={classes.fundDetailsData} >
                        <span className={classes.fundDetailsDataHead}>Max. Redeemable Amount:</span>
                        <span>
                          <span className={classes.rupees}>₹</span>
                          {schemeData?.schemes?.[0]?.market_value?.redeemable_amount?.toLocaleString('en-IN')}
                        </span>

                      </Box>
                    </Grid>
                    <Grid item xs={6} className={`${classes.Fundtext}`} >
                      <Box className={classes.fundDetailsData} >
                        <span className={classes.fundDetailsDataHead}>Max. Redeemable Units:</span>
                        <span>
                          {/* <span className={classes.rupees}>₹</span> */}
                          {schemeData?.schemes?.[0]?.holdings?.redeemable_units}
                        </span>

                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Grid container>
                  {/* <Grid item xs={8} className={classes.text}>
                    <Box display="flex" alignItems="center">
                      <input
                        type="radio"
                        id="r1"
                        checked={switchType == "units"}
                        onChange={() => handle_switch_type("units")}
                        style={{
                          height: "16px",
                          width: "16px",
                          marginRight: 10,
                        }}
                      />
                      <label htmlFor="r1"> Enter Units</label>
                      <br />
                    </Box>
                    <Typography
                      className={classes.minimumText}
                    >{`Minimum Switch Units ${switchData?.min_withdrawal_units}`}</Typography>
                  </Grid> 
                  <Grid item xs={4} className={classes.text}>
                    <Box>
                      <input
                        type="text"
                        className={
                          switchType == "units"
                            ? classes.inputBox
                            : classes.inputDisabled
                        }
                        disabled={switchType == "units" ? false : true}
                        value={units}
                        onChange={handleUnits}
                      />
                    </Box>
                  </Grid>*/}
                </Grid>
                <Box py={2} style={{ borderBottom: "1px solid #C4C4C4" }}>
                  <Grid container>
                    <Grid item xs={6} className={classes.text} style={{ display: "flex", alignItems: "center" }}>
                      <Box display="flex" alignItems="center">
                        <input
                          type="radio"
                          id="r2"
                          checked={switchType == "amount"}
                          onChange={() => handle_switch_type("amount")}
                          style={{
                            height: "16px",
                            width: "16px",
                            marginRight: 10,
                            marginTop: 0,
                          }}
                        />
                        <label htmlFor="r2"> Enter Amount</label>
                        <br />
                      </Box>
                      {/* <Typography
                        className={classes.minimumText}
                      > */}

                      {/* {`Minimum Switch Amount ${Math.max(
                          switchData?.minSwitchOutAmount,
                          switchInFpData?.minSwitchInAmount
                        )}`} */}

                      {/* {`Minimum Switch Amount ${switchData?.min_withdrawal_amount}`}
                      </Typography> */}
                    </Grid>
                    <Grid item xs={4} className={classes.text}>
                      <Box style={{ position: "relative" }}>
                        {switchType == "amount" && (
                          <span className={classes.rupeesSymbol}>₹</span>
                        )}
                        <input
                          type="text"
                          className={
                            switchType == "amount"
                              ? classes.inputBox
                              : classes.inputDisabled
                          }
                          style={{ paddingLeft: "18px" }}
                          disabled={switchType == "amount" ? false : true}
                          value={amount}
                          onChange={handleAmount}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box px={{ xs: 2, md: 6 }}>
                <Grid container>
                  <Grid item xs={8}>
                    <Typography className={classes.textGrey}>
                      Available Amount
                    </Typography>
                    <Typography className={classes.textGrey}>
                      Available Units
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Box display="flex" justifyContent="end">
                      {" "}
                      <Typography className={classes.textGrey}>
                        <span className={classes.rupees}>₹ </span>
                        {schemeData?.schemes?.[0]?.market_value?.redeemable_amount}
                      </Typography>
                    </Box>
                    <Box display="flex" justifyContent="end">
                      <Typography className={classes.textGrey}>
                        {schemeData?.schemes?.[0]?.holdings?.redeemable_units}
                      </Typography>
                    </Box>
                    <Box className={classes.checkBox}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            onChange={handle_switch_all}
                            checked={switchType == "all"}
                          />
                        }
                        label="Switch All"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box px={{ xs: 2, md: 6 }} display={'flex'} alignItems={'center'} textAlign={'center'} justifyContent={'center'}>
                {error && <ErrorMessage message={error} />}
                {inputError && <ErrorMessage message={inputError} />}
              </Box>
              <Box mt={5} mb={0} display="flex" justifyContent="center">
                <button
                  className={classes.primaryButton}
                  onClick={submit_switch}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size="1rem" color="inherit" />
                  ) : (
                    "Confirm"
                  )}
                </button>
              </Box>
            </>
          )}
          {comp == "otp" && (
            <Box px={{ xs: 2, md: 6 }}>
              <OTP
                verify_otp={verify_otp}
                id={id}
                transactionTypeId={4}
                handle_otp_error={handle_otp_error}
                otpError={otpError}
                loadVerify={loadVerify}
              />
            </Box>
          )}

          {/* Confermation Screen */}
          {comp == "confirm" && (
            <Box px={{ xs: 2, md: 6 }}>
              <Transaction
                verify_otp={verify_otp}
                id={id}
                transactionTypeId={3}
                handle_otp_error={handle_otp_error}
                otpError={otpError}
                loadVerify={loadVerify}
                confirmData={confirmData}
                handleClose={handle_close}
                transaction_type={transaction_type}
              />
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SwitchDialog;
