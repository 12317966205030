import React from "react";
import { Box, Container, Hidden, makeStyles } from "@material-ui/core";
function FooterStatic() {
  const useStyles = makeStyles((theme) => ({
    root: {
      // display: "flex",
      // alignItems: "flex-start",
      // justifyContent: "space-around",
      // flexDirection: "row",
      padding: "4rem 0rem 1rem 0rem",
      // background: "#484848",
      background: "#042338"
    },
    heddingBox: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-around",
      flexDirection: "row",
    },
    heading: {
      fontWeight: 500,
      fontSize: "1.2rem",
      color: "#FFFFFF",
      margin: "0px 0px 1.4rem 0px",
    },
    subtitle: {
      fontWeight: 400,
      fontSize: "0.9rem",
      color: "#FFFFFF",
      margin: "0.1rem 0",
    },
    subDataAddressMobileNumber: {
      fontSize: '15px',
      fontWeight: '400',
      lineHeight: '27px',
      letterSpacing: '0em',
      textAlign: 'left',
      color: '#FFFFFF',
    },
    inputFiled: {
      background: '#FFFFFF',
      boxShadow: '-13.01px 13.01px 104.09px 0px #A1A1A11A',
      borderRadious: '10px',
      paddingLeft: '10px',
      paddingRight: '50px',
      "&::placeholder": {
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '10px',

      }
    },
    button: {
      background: "#0E76BC",
      color: "#FFFFFF",
      border: "none",
    },
    "@media (max-width: 900px)": {
      root: {
        display: "flex",
        alignItems: "start",
        flexDirection: "column",
        padding: "20px",
      },
      heddingBox: {
        display: "flex",
        alignItems: "start",
        flexDirection: "column",
        padding: "20px",
      },
      logo: {
        margin: "0 auto!important",
      },
    },
  }));
  const classes = useStyles();
  return (
    <Box maxWidth="lg" style={{ background: "#042338" }}>
      <footer className={classes.root}>
        <Box className={classes.heddingBox} width={'100%'}>
          <Box display={"flex"} flexDirection="column">
            <Box overflow={'hidden'} height={'130px'} width={'250px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <img src="/images/MF 5 5.svg" alt="MF Wealth" height={'219px'} width={'425.12px'} />
            </Box>
            <Box pt={3} className={classes.subDataAddressMobileNumber}>
              D13, 3RD FLOOR, SECTOR 3, NOIDA,<br />
              UTTAR PRADESH, PIN- 201301, <br />
            </Box>
            <Box pt={3} className={classes.subDataAddressMobileNumber}>
              <a href="mailto:support@mfwealth.co.in" style={{ textDecoration: 'none' }} className={classes.subDataAddressMobileNumber}> &nbsp; support@mfwealth.co.in</a>
            </Box>
            <Box pt={3} className={classes.subDataAddressMobileNumber}>
              <a href="tel:+919717438991" style={{ textDecoration: 'none' }} className={classes.subDataAddressMobileNumber}>+91 9717438991</a>
            </Box>
          </Box>
          <Box my={1}>
            <Box className={classes.heading}>Links</Box>
            <Box display="flex" flexDirection="column" mt={1}>
              <a href="/terms-conditions" style={{ textDecoration: "none" }}>
                <Box className={classes.subtitle}>Terms And Conditions</Box>
              </a>
            </Box>
            <Box display="flex" flexDirection="column" mt={1}>
              <a href="/privacy-policy" style={{ textDecoration: "none" }}>
                <Box className={classes.subtitle}>Privacy Policy</Box>
              </a>
            </Box>
            <Box display="flex" flexDirection="column" mt={1}>
              <a href="/cancellation-policy" style={{ textDecoration: "none" }}>
                <Box className={classes.subtitle}>Refund or Cancelation policy</Box>
              </a>
            </Box>
            <Box display="flex" flexDirection="column" mt={1}>
              <a href="/contact-us" style={{ textDecoration: "none" }}>
                <Box className={classes.subtitle}>Contact Us</Box>
              </a>
            </Box>
            <Box display="flex" flexDirection="column" mt={1}>
              <a href="/about-us" style={{ textDecoration: "none" }}>
                <Box className={classes.subtitle}>About Us</Box>
              </a>
            </Box>
            <Box display="flex" flexDirection="column" mt={1}>
              <a href="/images/AMFI/AMFI REGISTRATION LETTER.pdf" style={{ textDecoration: "none" }} target="_blank" rel="noreferrer">
                <Box className={classes.subtitle}>AMFI Certificate</Box>
              </a>
            </Box>
          </Box>
          <Box my={1}>
            <Box className={classes.heading}>Social Media</Box>
            <Box display="flex" flexDirection="column" >
              <Box display="flex" flexDirection="column" >
                <a
                  href="https://www.instagram.com/mf_wealth/?next=%2F"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <Box className={classes.subtitle}>Instagram</Box>
                </a>
              </Box>

              <Box display="flex" flexDirection="column" mt={1}>
                <a
                  href="https://www.facebook.com/"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <Box className={classes.subtitle}>Facebook</Box>
                </a>
              </Box>
              <Box display="flex" flexDirection="column" mt={1}>
                <a
                  href="https://x.com/MF_Wealth"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <Box className={classes.subtitle}>Twitter</Box>
                </a>
              </Box>
            </Box>
          </Box>
          <Box my={1}>
            <Box className={classes.heading}>Newsletter</Box>
            <Box maxWidth={'237px'}>
              <Box display="flex" flexDirection="column" mt={1}>
                <Box className={classes.subtitle}>Our Weekly Finance Newsletter
                  With good Insights
                </Box>
                <Box width={'100%'} display={'flex'} alignItems={'end'} flexDirection={'column'} mt={2}>
                  <input
                    className={classes.inputFiled}
                    style={{ height: "40px", width: "80%", borderRadius: '10px' }}
                    type="email"
                    placeholder="Your email address"
                  />
                  <button
                    style={{
                      minWidth: "auto",
                      position: "relative",
                      bottom: "40px",
                      right: "8px",
                      height: "35px",
                      borderRadius: '10px'
                    }}

                    className={classes.button}
                  >
                    Subscribe
                  </button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* <Container>  */}
        <Box container className={classes.heddingBox}>
          <Box pt={3} className={classes.subDataAddressMobileNumber} textAlign={'start'}>
            Copyright @2024 Infiwealth Financial Services LLP
          </Box>
          {/* <Box pt={3} className={classes.subDataAddressMobileNumber} display={'flex'}>
            <Box display="flex" flexDirection="column" mt={1}>
              <a href="" style={{ textDecoration: "none" }}>
                <Box className={classes.subtitle}>Privacy</Box>
              </a>
            </Box>
            <Box display="flex" flexDirection="column" mt={1} mx={4}>
              <a href="" style={{ textDecoration: "none" }}>
                <Box className={classes.subtitle}>security</Box>
              </a>
            </Box>
            <Box display="flex" flexDirection="column" mt={1}>
              <a href="/terms-conditions" style={{ textDecoration: "none" }}>
                <Box className={classes.subtitle}>Term</Box>
              </a>
            </Box> */}

          {/* </Box> */}
        </Box>
      </footer>

    </Box>
  );
}

export default FooterStatic;
