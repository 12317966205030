import React, { useState } from "react";
import {
    Box,
    makeStyles,
    Container,
    Typography,
    Grid,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import { namespace, BASE_URL } from "../../../environment";
import ErrorMessage from "../../../components/Validation/ErrorMessage";
import { MANDATES_DETAILS_OF_BANK } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import { Skeleton } from "@mui/material";
import CustomToast from "../../../components/UI/CustomToast";

const EMandate = ({ dialog, closeDialog, bankList, pageType }) => {
    const useStyles = makeStyles((theme) => ({
        closeIcon: {
            padding: "5px",
            justifyContent: "flex-end",
        },
        backButton: {
            marginTop: '10px',
            padding: "5px",
            justifyContent: "flex-start",
            cursor: 'pointer',
        },
        title: {
            fontSize: "1.1rem",
            fontWeight: 500,
            textAlign: "center",
            fontFamily: "inherit",
        },
        subTitle: {
            fontSize: "0.9rem",
            fontWeight: 500,
            textAlign: "center",
            fontFamily: "inherit",
            textTransform: 'uppercase',
        },
        dialogTitle: {
            display: "flex",
            justifyContent: "center",
            alignItems: " center",
            textAlign: "center",
            padding: "5px",
        },
        dialogPaper: {
            // maxWidth: "25.5rem",
            justifyContent: "center",
        },
        image: {
            textAlign: "center",
        },
        text: {
            fontSize: "0.8rem",
            textAlign: "center",
            fontFamily: "inherit",
        },
        primaryButton: {
            padding: "0.8rem 3rem",
            background: "#0E76BC",
            color: "#fff",
            fontSize: "0.9rem",
            fontWeight: 600,
            borderRadius: "45px",
            border: "none",
            cursor: "pointer",
            border: "1px solid #0E76BC",
            '&:hover': {
                backgroundColor: "#fff",
                color: "#0E76BC",
            },
            '&:disabled': {
                opacity: '0.7',
                cursor: 'not-allowed',
            },
        },
        dialogContent: {
            padding: "8px 30px 30px",
        },
        box2: {
            borderBottom: "1px solid #E4E4E4",
        },
        box3: {
            padding: "1rem 10px",
            cursor: "pointer",
        },
        bluebox3: {
            padding: "1rem 10px",
            color: "#0E76BC",
            borderBottom: "3px solid #0E76BC",
            cursor: "pointer",
        },
        // Button tabs design 
        tabs: {
            display: 'flex',
            position: 'relative',
            overflow: 'hidden',
            border: '1px solid #0E76BC',
            borderRadius: '10px',

        },
        tablink: {
            borderRadius: '10px',
            flex: 1,
            fontSize: "1rem",
            fontWeight: 600,
            // padding: '10px 20px',
            borderRadius: 0,
            '&:not(:last-child)': {
                marginRight: theme.spacing(1),
            },
            position: 'relative',
            zIndex: 1,
            color: '#0E76BC',
            textTransform: 'capitalize',
        },
        active: {
            borderRadius: '10px',
            backgroundColor: '#0E76BC',
            color: theme.palette.secondary.contrastText,
        },
        activeBox: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '50%',
            height: '100%',
            // backgroundColor: theme.palette.secondary.main,
            backgroundColor: '#0E76BC',
            borderRadius: '8px',

            transition: 'left 0.3s ease-in-out',
        },
        bankSelection: {
            border: "1px solid #cccc",
            cursor: 'pointer',
            marginBottom: '10px',
            position: 'relative',
            padding: '10px',
            borderRadius: '10px',
            "&:hover": {
                border: "1px solid #0E76BC",

            }
        },
        bankSelectionActive: {
            border: "1px solid #0E76BC",
        },
    }));

    const classes = useStyles();
    const [selectedBank, setSelectedBank] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedMandateTypeTab, setSelectedMandateTypeTab] = useState("UPI");

    const [activeTab, setActiveTab] = useState("UPI");
    const [activeBoxPosition, setActiveBoxPosition] = useState(0);

    const selectionOfBank = (selectionOfBank) => {
        setError('')
        const updatedSelectionOfBank = { ...selectionOfBank };
        setSelectedBank(updatedSelectionOfBank);
    }

    // ---------------------------- format account number ------------------------------------ //
    const formatAccountNumber = (accountNumber) => {
        const length = accountNumber.length;

        // Show only the first 2 and last 2 characters, replace the rest with "x"
        return accountNumber.substring(0, 2) + 'x'.repeat(length - 4) + accountNumber.substring(length - 2);
    };

    // ---------------------------- e mandate type selection ------------------------------------ // 

    const handle_tab = (data) => {
        setSelectedMandateTypeTab(data);
    };


    const tabClickHandler = (tabName, position) => {
        setActiveTab(tabName);
        setActiveBoxPosition(position);
    };

    // ---------------------------- create e mandate here ------------------------------------ // 
    const handleEMandate = async () => {
        // console.log(selectedBank)
        setLoading(true)
        const url = window.location.origin;
        const strippedUrl = url.replace(/^https?:\/\//, '');
        if (!selectedBank) {
            setError('Please select a bank to proceed!')
            return
        }
        const formData = {
            bank_id: selectedBank?.id,
            mandate_limit: selectedBank?.mandates?.[0]?.mandate_limit ? selectedBank?.mandates?.[0]?.mandate_limit : (activeTab === 'UPI' ? 15000 : 100000),
            // mandate_type: selectedBank?.mandates?.[0]?.mandate_type,
            mandate_type: activeTab,
            url: strippedUrl,

        };
        const httpConfig = {
            method: "POST",
            tokenType: "user",
            headers: {
                "Content-Type": "application/json",
            },
            data: formData,
        };
        // sessionStorage.removeItem("sip");
        // sessionStorage.setItem("lumsumId", orderData?.id);

        try {
            const response = await httpInstance(`${MANDATES_DETAILS_OF_BANK}`, httpConfig);
            console.log(response?.data)
            if(response?.status === 200){
                window.location.replace(response.data?.data?.token_url);

            } else {
                setLoading(false);
                setError(response?.data?.error ?? response?.data?.message ?? 'Something went wrong')
                CustomToast(response?.data?.error ?? response?.data?.message ?? 'Something went wrong', "FAILED")
            }
        } catch (error) {
            setLoading(false);
            console.log(error.response.data)
            setError(error?.response?.data?.error ?? error?.response?.data?.message ?? 'Something went wrong')
            CustomToast(error?.response?.data?.error ?? error?.response?.data?.message ?? 'Something went wrong', "FAILED")
        }
        // }
    }


    return (
        <div>
            {/* <Dialog
        open={dialog}
        onClose={closeDialog}
        classes={{ paper: classes.dialogPaper }}
      > */}
            <Box mx={5}>
                {!pageType &&
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        px={20}
                        className={classes.backButton}
                        // onClick={handleButtonBack}
                        onClick={closeDialog}
                    >
                        <img src="/images/backIcon.svg" width={15} height={15} />
                    </Box>
                }
                {/* <Box display="flex" className={classes.closeIcon}>
                    {" "}
                    <CloseIcon  style={{ cursor: "pointer" }} />
                </Box> */}

                {/* <DialogTitle classes={{ root: classes.dialogTitle }}> */}

                {/* <Typography className={classes.subTitle}>No Mandate Found</Typography> */}


                {/* </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}> */}


                {/* <Box className={classes.image}>
                    <img
                        src="/images/undraw_cancel_u-1-it 1.svg"
                        width="150px"
                        height="150px"
                    />
                </Box>
                <Typography className={classes.text}>
                    It seems like no mandate is created for the selected primary bank.
                </Typography>
                <Typography className={classes.text}>
                    You ned to create mandate before creating an SIP Order
                </Typography> */}
                {/* <Box
                    display="flex"
                    justifyContent="space-between"
                    px={6}
                    pb={2}
                    className={classes.box2}
                >
                    <Box
                        px={3}
                        className={selectedMandateTypeTab == "UPI" ? classes.bluebox3 : classes.box3}
                        onClick={() => handle_tab("UPI")}
                    >
                        <Typography  >UPI</Typography>
                    </Box>
                    <Box
                        px={3}
                        className={selectedMandateTypeTab == "E_Mandate" ? classes.bluebox3 : classes.box3}
                        onClick={() => handle_tab("E_Mandate")}
                    >
                        <Typography  >EMandate</Typography>
                    </Box>
                </Box> */}

                <Box display='flex' justifyContent='center' pt={3} pb={1} px={1}>
                    <Box className={classes.tabsFolder} maxWidth="350px" width='100%'>
                        <div className={classes.tabs}>
                            <div className={classes.activeBox} style={{ left: `${activeBoxPosition}%` }}></div>
                            <Button
                                className={`${classes.tablink} ${activeTab === 'UPI' ? classes.active : ''}`}
                                onClick={() => tabClickHandler('UPI', 0)}
                            // onMouseEnter={() => tabHoverHandler('tab1', 0)}
                            >
                                UPI
                            </Button>
                            <Button
                                className={`${classes.tablink} ${activeTab === 'E_Mandate' ? classes.active : ''}`}
                                onClick={() => tabClickHandler('E_Mandate', 50)}>
                                E-Mandate
                            </Button>
                        </div>
                    </Box>
                </Box>
                {!bankList ?
                    <>
                        <Box
                            item xs={2} md={1}
                            display="flex"
                            // key={i}
                            style={{
                                border: '1px solid #cccc',
                                cursor: 'pointer',
                                marginBottom: '10px', // Add margin to create spacing between items
                                position: 'relative',
                                padding: '10px', // Add padding for better aesthetics
                                borderRadius: '10px',
                            }}
                            p={1}
                        >
                            <Skeleton variant="rounded" border width={50} height={50} />
                            <Box width='100%' paddingLeft='10px' display='flex' justifyContent='center' alignItems={"center"} flexDirection={'column'}>

                                <Skeleton variant="text" width={'40%'} />

                                <Skeleton variant="text" width={'40%'} />

                            </Box>
                        </Box>
                    </>

                    :
                    bankList?.map((list, i) => {
                        return (
                            <Box
                                item xs={2} md={1}
                                display="flex"
                                key={i}
                                // style={{
                                //     border: (selectedBank?.id === list?.id) ? '1px solid #007bff' : '1px solid #cccc',
                                //     cursor: 'pointer',
                                //     marginBottom: '10px', // Add margin to create spacing between items
                                //     position: 'relative',
                                //     padding: '10px', // Add padding for better aesthetics
                                //     borderRadius: '10px',
                                // }}
                                className={`${classes.bankSelection} ${selectedBank?.id === list?.id && classes.bankSelectionActive}`}
                                p={1}
                                onClick={() => selectionOfBank(list)}
                            >
                                <img src={list?.logo_url ? `${BASE_URL}${list?.logo_url}` : `/image/bank-svg.svg`} padding="2px 10px" height="50px" width='auto' style={{ borderRadius: '10px' }} />

                                <Box width='100%' paddingLeft='10px' display='flex' justifyContent='center' alignItems={"center"} flexDirection={'column'}>
                                    <Typography className={classes.text}>
                                        {/* {list.account_number} */}
                                        {formatAccountNumber(list?.account_number)}

                                    </Typography>

                                    <Typography className={classes.subTitle}>
                                        {list?.bank_name}
                                    </Typography>
                                </Box>
                                <Box width={17} marginRight='10px' display='flex' justifyContent='center' alignItems={"center"} >
                                    {(selectedBank?.id === list?.id) && (

                                        <img
                                            src="/images/checkMark.svg"
                                            height="16"
                                            width="16"
                                        // className={classes.closeBtn}
                                        />
                                    )}
                                </Box>

                            </Box>
                        );
                    })}
                {error && <ErrorMessage message={error} />}

                <Box my={3} display="flex" justifyContent="center">
                    {/* <Link to={`${namespace}/my-profile/bank-and-autoMandate`}> */}
                    <button
                        className={classes.primaryButton}
                        type="button"

                        onClick={handleEMandate}
                        disabled={loading}
                    >
                        {loading ? (
                            <CircularProgress size="1rem" color="inherit" />
                        ) : (
                            "Create Mandate"
                        )}
                    </button>
                    {/* </Link> */}
                </Box>
                {/* </DialogContent>
      </Dialog> */}
            </Box>
        </div>
    );
};

export default EMandate;
