import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  makeStyles,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import {
  CREATE_LUMSUM,
  INITIATE_PAYMENT,
  KYC_DATA,
  GET_LUMSUM_DETAIL,
  KYC_STATUS,
  GET_CAS_LIST,
  POST_CAS_OTP,
  GET_CAS_FUND_DATA,
  GET_USER_ONBOARDING_DETAILS,

} from "../../../utils/endpoints";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import httpInstance from "../../../utils/instance";
import ErrorMessage from "../../../components/Validation/ErrorMessage";
import { useSelector } from "react-redux";
import {
  GET_CONSENT_LUMPSUM_OTP,
  VERIFY_CONSENT_LUMPSUM_OTP,
  POST_TRANSACTION_BASKETS,
  POST_TRANSACTION_BASKETS_GENERATE_CONSENT,
  VERIFY_AND_VALIDATE_BASKET_CONSENT_LUMPSUMP_OTP,
} from "../../../utils/endpoints";
import { namespace } from "../../../environment";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useForm } from "react-hook-form";
import TextInputField from "../../../components/signup/Input/TextInputField";
import CustomToast from "../../../components/UI/CustomToast";
import OtpInput from "react-otp-input";


// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
//     </div>
//   );
// }

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }

// const LoaderComp = () => {
//   return (
//     <div
//       style={{
//         display: "flex",
//         justifyContent: "center",
//         marginTop: "1rem",
//         overflow: "hidden",
//       }}
//     >
//       <CircularProgress size="2rem" />
//     </div>
//   );
// };

const Index = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const useStyles = makeStyles((theme) => ({
    subTabtext: {
      fontSize: "0.7rem",
      fontFamily: "inherit",
      fontWeight: 500,
    },
    textUnderline: {
      fontSize: "0.7rem",
      fontFamily: "inherit",
    },
    title: {
      fontSize: "1.2rem",
      fontWeight: 600,
    },
    text: {
      textAlign: "center",
      marginTop: "10px",
    },
    heading: {
      fontSize: "1.2rem",
      textAlign: "center",
      fontWeight: 500,
      marginBottom: "20px",
    },
    inputBox: {
      background: "#E6E6E6",
      padding: "0.7rem 0.95rem",
      borderRadius: "10px",
      border: "none",
      width: "90.5%",
      fontFamily: "inherit",
      paddingLeft: '20px',

    },
    inputBoxInput: {
      // background: "#E6E6E6",
      padding: "0.7rem 0.95rem",
      borderRadius: "0px",
      border: "none",
      borderBottom: "1px solid #000",
      width: "100%",
      maxWidth: "400px",
      fontFamily: "inherit",
      paddingLeft: '20px',

    },
    label: {
      fontSize: "0.7rem",
      fontWeight: 500,
    },
    primaryButton: {
      padding: "0.8rem 3rem",
      background: "#0E76BC",
      color: "#fff",
      fontSize: "0.8rem",
      fontWeight: 600,
      borderRadius: "45px",
      border: "none",
      cursor: "pointer",
      '&:hover': {
        background: '#2fa7f7', // Change background on hover
      },
      '&:disabled': {
        opacity: '0.7',
        cursor: 'not-allowed',
      },
    },
    root: {
      "& .MuiTab-root": {
        fontFamily: "inherit",
        fontSize: "1rem",
        color: "black",
        opacity: 1,
        textTransform: "none",
        background: "none",
        minWidth: "auto",
      },
      "& .MuiTab-textColorInheri": {
        opacity: 0.9,
      },
    },

    activeTab: {
      "& .MuiTab-wrapper": {
        borderBottom: "2px solid #0E76BC",
        color: "#0E76BC",
        fontSize: "1rem",
      },
    },
    backButton: {
      cursor: "pointer",
    }
  }));
  const classes = useStyles();






  const [error, setError] = useState("");
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");

  const { userStore } = useSelector((state) => state);
  const navigate = useNavigate();
  const { state } = useLocation()

  const { code } = useParams();
  const [otp, SetOTP] = useState("");
  const [consentId, SetConsentId] = useState(null);
  const [timer, setTimer] = useState(30);
  const [isTimerActive, setIsTimerActive] = useState(true);
  const [otpHasBeenSent, setotpHasBeenSent] = useState(true);
  const [handleOTP, setHandleOTP] = useState(0);

  const [kycStatus, setKycStatus] = useState();
  const [kycData, setKycData] = useState(null)

  const [casPhoneNumber, setCasPhoneNumber] = useState(null)

  const get_kycData = async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await httpInstance(`${KYC_STATUS}`, httpConfig);
      setKycData(response?.data?.data)
      setKycStatus(response.data?.data?.fp_kyc_status);
    } catch {
      setKycStatus("empty");
    }
  };



  useEffect(() => {
    setAmount("");
    get_kycData()
  }, [code]);

  // useEffect(() => {
  //   if (fundData?.stateValue?.transaction_basket_items[0]?.id && fundData?.stateValue?.transaction_basket_items[0]?.transaction_type === "lumpsum") {
  //     setAmount(fundData?.stateValue?.transaction_basket_items[0]?.amount)
  //     handle_CASInput();
  //   }
  // }, []);

  const handle_amount = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "").toLowerCase();

    setAmount(e.target.value);
  };

  const handle_otp_value = (value) => {
    // e.target.value = e.target.value.replace(/[^0-9]/g, "").toLowerCase();
    // SetOTP(e.target.value);
    value = value.replace(/[^0-9]/g, "").toLowerCase();
    SetOTP(value);
  };



  const handle_otp = async () => {
    // if (sessionStorage.getItem("mpin")) {

    if ((kycData?.status == 'nominee' && kycData?.is_kyc_compliant == true && kycStatus == 'pending') || (!kycData?.is_kyc_compliant && kycStatus == "successful")) {
      setError("");
      setLoading(true);

      const formDataOTP = {
        id: consentId?.id,
        otp: otp,
      };

      const httpConfigOTP = {
        method: "POST",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        data: formDataOTP,
      };

      try {
        const responseOTP = await httpInstance(
          `${POST_CAS_OTP}`,
          httpConfigOTP
        );
        console.log(responseOTP)
        navigate(`${namespace}/my-profile/cas/cas_funds`, {
          state: {
            // OTPData: responseOTP.data?.data,
            consentId: consentId,
          },
        });

      } catch (error) {
        setLoading(false);
        setApiError(error.response.data?.message);
      }
    } else {
      // show_banner();
      setApiError(
        kycStatus == "submitted"
          ? "Your KYC is under review"
          : "Please complete your KYC"
      );
    }
  }
  // else {
  //   navigate(`${namespace}/user`);
  // }


  const handle_CASInput = async (data) => {
    if (
      userStore?.user?.id == "" ||
      userStore?.user?.id == "undefined" ||
      userStore?.user?.id == null
    ) {
      navigate(`${namespace}/user`);
    }
    // console.log("kycStatus", kycStatus);
    // console.log(kycData, data)
    setError("");
    if (data?.mobile) {
      setCasPhoneNumber(data)
    }
    setLoading(true);

    const formData = {
      mobile_number: data?.mobile ? data.mobile : casPhoneNumber?.mobile,
      pan: data?.pan ? data?.pan.toUpperCase() : casPhoneNumber?.pan.toUpperCase(),
    }

    const httpConfig1 = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      // params: { transaction_basket_id: idSet },
      data: formData,
    }
    // console.log(httpConfig1)
    try {
      const responseGenerate = await httpInstance(
        `${GET_CAS_LIST}`,
        httpConfig1
      );
      SetOTP("");

      if (responseGenerate.status == 200) {
        setHandleOTP(1);

        CustomToast("OTP sent successfully", "SUCCESS")

        // console.log(responseGenerate.data, 'responseGenerate')
        SetConsentId(responseGenerate.data?.external_fund);
        // handle_CASInput_otp(response.data?.data.id);

        var timeLeft = 30; // set the time limit in seconds
        var timer = setInterval(function () {
          setIsTimerActive(true);
          setotpHasBeenSent(true);
          timeLeft--;
          setTimer(timeLeft);

          if (timeLeft <= 0) {
            clearInterval(timer);
            setotpHasBeenSent(false);
            setIsTimerActive(false);
          }
        }, 1000); // set the interval to 1000 milliseconds (1 second)
      } else {
        SetOTP("");
        CustomToast(responseGenerate.data.error || "Somthing went wrong", "FAILED")
      }
      setLoading(false);

    } catch (error) {
      SetOTP("");
      setLoading(false);
      CustomToast(error.response.data.error || "Somthing went wrong", "FAILED")

    }
    // }
    // }
  };

  const [userData, setUserData] = useState('')

  const GetUserData = async (e) => {

    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await httpInstance(
        `${GET_USER_ONBOARDING_DETAILS}`,
        httpConfig
      );
      const responseUserData = response?.data?.data
      // setUserData()
      reset(
        {
          mobile: responseUserData?.mobile,
          pan: responseUserData?.pan,
        }
      );
    } catch { }
  };


  const getAllFundData = async () => {
    setLoading(true)
    const httpConfig = {
      method: "PATCH",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await httpInstance(`${GET_CAS_FUND_DATA}`, httpConfig);
      if (response?.data?.data?.is_registered && !state?.refresh) {
        navigate(`${namespace}/my-profile/cas/cas_funds`, {
          state: {
            consentId: response?.data?.data,
          },
        });
      } else {
        GetUserData()
      }
      setLoading(false)
    } catch {
      setLoading(false)
      GetUserData()
      // setKycStatus("empty");
    }
  };


  useEffect(() => {

    getAllFundData()

    // } else {
    //   navigate(`${namespace}/my-profile/cas`, {
    //     // state: {
    //     //   orderData: responseOTP.data?.data,
    //     //   type: "lumsum",
    //     //   schemeCode: fundData?.id,
    //     //   fundData: fundData,
    //     //   consentId: consentId,
    //     // },
    //   });
    // }

  }, []);


  return (
    <div>
      {/* <Box display="flex" alignItems="start" px={{ xs: 1, md: 17 }} pt={2}>
        <Typography className={classes.subTabtext}>My Profile</Typography>

        <Box display="flex">
          <ChevronRightIcon style={{ height: "1rem" }} />
          <Typography className={classes.textUnderline}>CAS</Typography>
        </Box>
      </Box> */}


      <Box sx={{
        width: "100%",
        height: {
          xs: "auto", // Default for smaller screens
          md: "40vh", // Applies when the screen size is `md` or larger
        },
      }}>
        <Box px={{ xs: 2, md: 15 }}>

          <Box mt={4} display={'flex'} className={classes.backButton}
          >
            <Box onClick={() => {
              if (handleOTP == 0) {
                navigate(`${namespace}/dashboard/mutualfund`, {
                });
              } else {
                setHandleOTP(0)
              }
            }} mr={2}>
              <img src="/images/backIcon.svg" height="20px" width="20px" />
            </Box>
            <Typography variant="h4" className={classes.title}>CAS</Typography>

          </Box>
          <div>
            {handleOTP == 1 ? (
              <Box mt={2} p={0}>
                <Box display={'flex'} flexDirection={'column'}>
                  <label className={classes.label}>Enter OTP</label>
                  {/* <input
                    className={classes.inputBoxInput}
                    type="text"
                    placeholder="One Time Password"
                    onChange={handle_otp_value}
                    value={otp}
                  /> */}
                  <OtpInput

                    value={otp}
                    onChange={handle_otp_value}
                    numInputs={6}
                    renderSeparator={<span style={{ width: "8px" }}></span>}
                    isInputNum={true}
                    shouldAutoFocus={true}
                    renderInput={(props) => <input {...props} />}
                    // inputStyle={{
                    //   border: "0px 0px 0px 1px",
                    //   // borderRadius: "8px", 
                    //   borderRadius: "0px",
                    //   borderBottom: "1px solid #000 !important",
                    //   width: "40px",
                    //   height: "40px",
                    //   fontSize: "13px",
                    //   color: "#000",
                    //   fontWeight: "400",
                    //   caretColor: "blue",
                    //   background: "#E6E6E6",
                    // }}
                    // focusStyle={{
                    //   // border: "1px solid #CFD3DB",
                    //   borderBottom: "1px solid #000",
                    //   background: "#E6E6E6",
                    //   outline: "none",
                    // }}
                    inputStyle={{
                      border: "none",
                      borderRadius: "0px",
                      borderBottom: "1px solid #000",
                      width: "40px",
                      height: "40px",
                      fontSize: "13px",
                      color: "#000",
                      fontWeight: "400",
                      caretColor: "blue",
                    }}
                    focusStyle={{
                      border: "none",
                      borderRadius: "0px",
                      borderBottom: "1px solid #000", // Ensure this matches inputStyle
                      background: "#E6E6E6",
                      outline: "none",
                    }}
                  />
                </Box>
                <div className="resendOTP">
                  {otpHasBeenSent ? (
                    <></>
                  ) : (
                    <>
                      <p className="otpWillRecivie">
                        OTP has been sent you will receive shortly
                      </p>
                    </>
                  )}
                  {isTimerActive ? (
                    <div>
                      <p className="resendOTPTimer">Resend OTP in {timer} seconds</p>
                    </div>
                  ) : (
                    <div>
                      <p className="resendOTPRequest">
                        Didn't receive OTP?{" "}
                        <span
                          // href="javascript:void" 
                          style={{ color: 'blue', cursor: 'pointer' }}
                          onClick={() => handleSubmit(handle_CASInput(casPhoneNumber))}>
                          Resend OTP
                        </span>
                      </p>
                    </div>
                  )}
                </div>
                {apiError && <ErrorMessage message={apiError} />}
                <div>
                  {error && <ErrorMessage message={error} />}
                </div>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="start"
                  mt={15}
                >
                  <button
                    className={classes.primaryButton}
                    onClick={handle_otp}
                  // style={{ marginTop: "10px" }}
                  >
                    {loading ? (
                      <CircularProgress size="1rem" color="inherit" />
                    ) : (
                      "Verify OTP"
                    )}
                  </button>
                </Box>

              </Box>
            ) : (
              <Box p={0}>
                {/* <label className={classes.label}>Enter Amount</label>
                <span className={classes.rupeesSymbol} style={{ marginRight: '5px' }}>₹</span> */}
                <form onSubmit={handleSubmit(handle_CASInput)}>
                  <Grid container >
                    <Grid item lg={6} xs={12}>


                      <Box my={2}>
                        <TextInputField
                          name="mobile"
                          label="Enter Phone Number"
                          placeholder="Enter Phone Number"
                          register={register}
                          inputPattern={/^[0-9-+]{10,10}$/}
                          handleChange={() => setError('')}
                          inputmode="numeric"
                          disable={loading}
                        />
                        {errors.mobile && (
                          <ErrorMessage message="Enter valid Phone Number" />
                        )}
                      </Box>
                    </Grid>
                    <Grid item lg={6} xs={12}>

                      <Box my={2}>
                        <TextInputField
                          name="pan"
                          label="Enter PAN Number"
                          placeholder="Enter PAN Number"
                          register={register}
                          // inputPattern={/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/} // Example of pattern validation
                          inputPattern={/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/i}
                          // handleChange={(e) => console.log(e.target.value)} // Optional change handler
                          maxLength={10}
                          style={{ textTransform: 'uppercase' }}
                          handleChange={() => setError('')}
                          disable={loading}
                        />
                        {errors.pan && (
                          <ErrorMessage message="Enter valid PAN Number" />
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                  {/* {error && <ErrorMessage message={error} />} */}

                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    mt={15}
                  >
                    {apiError && <ErrorMessage message={apiError} />}
                    <button className={classes.primaryButton}
                      // onClick={}
                      disabled={loading}
                      type="submit"
                    >
                      {loading ? (
                        <CircularProgress size="1rem" color="inherit" />
                      ) : (
                        "Continue"
                      )}
                    </button>
                  </Box>
                </form>
              </Box>
            )}
          </div>
        </Box>
      </Box>

    </div>
  );
};

export default Index;
