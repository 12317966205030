import React, { useEffect, useState } from "react";
import {
  Box, makeStyles, Typography, Grid, Menu,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { GET_FUND_DETAILS, GET_FUND_FP_DATA, GET_MY_INVESTMENT } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { namespace } from "../../../environment";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Redeem from "../MutualFund/Redeem";
import SWP from "../MutualFund/SWP";
import { Dialog, DialogContent, DialogTitle, Modal, Skeleton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ActiveFundData from "../../../components/Portfolio/FundsData/ActiveFundData";

const SipList = ({ holdingsData, category, bankData }) => {

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const useStyles = makeStyles({
    root: {
      borderRadius: "12px",
      // boxShadow: "0 0 8px -3px #444444",
      border: '1px solid #E4E4E4',
      // boxShadow: '0 0 8px -3px #444444',
      boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
      transition: '0.5s ease',
      "&:hover": {
        border: '1px solid #0E76BC',
      }
    },

    greyText: {
      color: "#222222",
    },

    title: {
      fontSize: "1.1rem",
      fontWeight: 500,
    },
    buttonBox: {
      background: "#EAEAEA",
      borderRadius: "8px",
      padding: "2px 5px",
    },
    text: {
      fontSize: "1rem",
      fontWeight: 600,
    },
    subText: {
      fontSize: "0.7rem",
      fontWeight: 400,
    },
    primaryButton: {
      padding: "0.7rem 3rem",
      background: "#0E76BC",
      color: "#fff",
      fontSize: "0.9rem",
      fontFamily: "Poppins",
      fontWeight: 600,
      borderRadius: "5px",
      border: "none",
      outline: "none",
    },
    button: {
      background: "#EAEAEA",
      padding: "0.7rem 3rem",
      fontSize: "0.9rem",
      fontFamily: "Poppins",
      borderRadius: "5px",
      border: "none",
      cursor: "pointer",
      outline: "none",
    },
    rupees: {
      fontFamily: "Open Sans",
    },
    type: {
      fontSize: "0.7rem",
      borderRadius: "30px",
      //background:"#FFD7C4",
      //color:"#F27E45",
      width: "fit-content",
      padding: "3px 20px",
      margin: "0px 15px",
      height: "fit-content",
    },
    percentagePositive: {
      color: "#0BDA51",
    },
    percentageNegative: {
      color: "#D2042D",
    },
    loss: {
      fontSize: "0.8rem",
      color: "#F32424",
    },

    link: {
      textDecoration: "none",
      color: "inherit",
    },
    pulse: {
      //color: "#fff",
      fontSize: "0.8rem",
      animation: "$pulse 3s infinite",
    },
    "@media (max-width:600px)": {
      primaryButton: {
        padding: "0.7rem 1rem",
      },
      button: {
        padding: "0.7rem 1rem",
      },
      type: {
        margin: "10px 0px",
      },
    },
    // dialog
    dialogTitle: {
      display: "flex",
      justifyContent: "center",
      alignItems: " center",
      textAlign: "center",
      padding: "5px",
    },
    dialogPaper: {
      maxWidth: "23rem",
      justifyContent: "center",
    },
    dialogContent: {
      padding: "8px 30px",
    },
    closeIcon: {
      padding: "5px",
      justifyContent: "flex-end",
    },
  });
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)

  const [data, setData] = useState([]);
  const [fpFundData, setFpFundData] = useState(null);
  const [redeem, setRedeem] = useState(false);
  const [fundDetail, setFundDetail] = useState([]);
  const [swp, setSwp] = useState(false);

  const [investements, setInvestments] = useState([]);
  const [inactiveInvest, setInactiveInvest] = useState([]);
  const [value, setValue] = useState("active");


  const swp_close = () => {
    setSwp(false);
    navigate(`${namespace}/myportfolio`, {
      replace: true,
    });
  };

  const orderTypeObj = {
    2: "SIP",
    5: "STP",
    6: "SWP",
  };
  const orderTypeColor = {
    2: "#F27E45",
    5: "#4579F5",
    6: "#CB5D38",
  };

  const get_investment = async () => {
    const userId = localStorage.getItem("user_id");
    setLoading(true)
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      // params: { userId: userId, isActive: true },
    };

    const httpConfig1 = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: { userId: userId, isActive: false },
    };

    try {
      const [response1, response2] = await Promise.all([
        httpInstance(`${GET_MY_INVESTMENT}`, httpConfig),
        // httpInstance(`${GET_MY_INVESTMENT}`, httpConfig1),
      ]);
      // console.log(response1)
      setInvestments(response1.data?.data);
      holdingsData(response1.data?.data)
      // setInactiveInvest(response2.data?.data);

      // const inactive= await httpInstance(`${GET_INACTIVE_SIP}`,httpConfig)
      // setInactiveSip(inactive.data?.data)
      setLoading(false)

    } catch {

      setLoading(false)

    }
  };

  useEffect(() => {
    get_investment();
  }, []);


  // console.log(investements, 'orderTypeObj')




  // -------------Menu comp---------------------------------------------------------------------
  const MenuComp = ({ schemeData, }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [fundData, setFundData] = useState('');
    const [fpData, setFpData] = useState('');

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      get_fundData();
      // handleOpen()

      setAnchorEl(event.currentTarget);
    };


    const handleClose = () => {
      // handleCloses()
      setAnchorEl(null);
    };

    const get_fundData = async () => {
      const httpConfig = {
        method: "GET",
        // tokenType: "user",
        test: "test",
        // headers: {
        //   "Content-Type": "application/json",
        // },
        params: { fundPlanId: schemeData?.schemes?.[0]?.plan_id },
      };

      const httpConfig1 = {
        method: "GET",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        params: { isin: schemeData?.schemes?.[0]?.isin },
      };

      try {
        const [fpdata, plData] = await Promise.all([
          httpInstance(`${GET_FUND_FP_DATA}`, httpConfig1),
          // httpInstance(`${SEARCH_BY_ISIN}`, httpConfig),
          httpInstance(`${GET_FUND_DETAILS}`, httpConfig),

        ]);
        //const response= await httpInstance(`${SEARCH_BY_ISIN}`,httpConfig)
        setFpData(fpdata.data?.data);
        setFundData(plData.data?.data);
        console.log(fpdata.data?.data, plData.data?.data)
        // navigate(`/mutualfund/scheme-code=${response.data?.data[0]?.scheme_code}`)
      } catch { }
    };

    //----------------handle_redeem----------------------------------------------------//

    const handle_redeem = () => {
      setData(schemeData);
      setFpFundData(fpData);
      setFundDetail(fundData);
      setRedeem(true);
      navigate(`${namespace}/myportfolio/redeem/${schemeData?.schemes?.[0]?.isin}`, {
        replace: true,
      });

    };

    //----------------------------handle SWP--------------------------------------------------------//
    const handle_swp = () => {
      setData(schemeData);
      setFundDetail(fundData);
      setFpFundData(fpData);
      setSwp(true);
      navigate(`${namespace}/myportfolio/swp/${schemeData?.schemes?.[0]?.isin}`, {
        replace: true,
      });

    };


    //-----------------------------handle invest more -----------------------------------------------------//
    const handle_invest_more = () => {
      // console.log(holdingsData)
      // if (category == "diy") {
      navigate(
        `${namespace}/mutualfund/scheme-code/${fundData?.id}`,
        {
          state: {
            folioNumber: holdingsData?.folios?.[0]?.folio_number
          },
        }
      );
      // }
      // else {
      //   navigate(
      //     `${namespace}/dashboard/mutualfund/assisted-services/invest-detail`,
      //     // {
      //     //   state: {
      //     //     fundsData: response.data?.data,
      //     //     totalAmount: amount,
      //     //     tenure: year,
      //     //   },
      //     // }
      //   );
      // }

    }

    return (
      <>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          style={{ padding: "2px" }}
        >
          <MoreVertIcon />
        </IconButton>

        {/* <ActiveFundData open={open} onClose={handleClose} fundData={fundData} />
        {console.log(fundData, fpData)} */}
        {fundData && (

          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                boxShadow:
                  "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
              },
            }}
          >
            {/* <Link
              to={
                category == "diy"
                  ? `/mutualfund/scheme-code/${fundData?.id}`
                  : `/dashboard/mutualfund/assisted-services/invest-detail`
              }
              className={classes.link}
            > */}
            <MenuItem onClick={handle_invest_more} style={{ fontSize: "0.8rem" }}>Invest More</MenuItem>
            {/* </Link> */}
            {fpData?.redemption_allowed && (
              <MenuItem style={{ fontSize: "0.8rem" }} onClick={handle_redeem}>
                Redeem
              </MenuItem>
            )}
            {category == "diy" && fpData?.swp_allowed && (
              <MenuItem style={{ fontSize: "0.8rem" }} onClick={handle_swp}>
                Start SWP
              </MenuItem>
            )}
            {fpData?.switch_out_allowed && (
              <Link
                to={`/myportfolio/switch/${schemeData?.schemes?.[0]?.isin}`}
                state={{
                  schemeData: schemeData,
                  fundData: fundData,
                  switchData: fpData,
                  category: category,
                }}
                className={classes.link}
              >
                <MenuItem style={{ fontSize: "0.8rem" }}>Switch</MenuItem>
              </Link>
            )}
            {category == "diy" && fpData?.stp_out_allowed && (
              <Link
                to={`/myportfolio/stp/${schemeData?.schemes?.[0]?.isin}`}
                state={{
                  schemeData: schemeData,
                  fundData: fundData,
                  stpData: fpData,
                }}
                className={classes.link}
              >
                <MenuItem style={{ fontSize: "0.8rem" }}>Start STP</MenuItem>
              </Link>
            )}
            {/* <Link
              to={`/myportfolio/all-fund-transactions/${schemeData?.schemes?.[0]?.isin}`}
              state={{ schemeData: schemeData, fundData: fundData }}
              className={classes.link}
            >
              <MenuItem style={{ fontSize: "0.8rem" }}>
                All Transactions
              </MenuItem>
            </Link> */}

            {/* handle_change(id) */}
          </Menu>

        )}
      </>
    );
  };







  return (
    <div>
      <>
        <Box mt={5}>
          <Typography className={classes.title}>
            My Funds
          </Typography>
        </Box>

        {/* <Box display="flex" justifyContent="center" my={3}>
            <Box className={classes.buttonBox}>
              <button
                className={
                  value === "active" ? classes.primaryButton : classes.button
                }
                onClick={() => setValue("active")}
              >
                Active
              </button>
              <button
                className={
                  value === "inactive" ? classes.primaryButton : classes.button
                }
                onClick={() => setValue("inactive")}
              >
                Inactive
              </button>
            </Box>
          </Box> */}
      </>
      {!loading ? (

        (investements?.folios?.length > 0 || inactiveInvest?.length > 0) ?
          (value == "active" ? investements?.folios : inactiveInvest)?.map((ele, i) => {
            return (
              <>
                <Box key={i} className={classes.root} mt={3} p={2}>
                  <Grid container display="flex" alignItems="center">
                    <Grid item xs={3} md={1}>
                      <img src={ele?.schemes?.[0]?.logo_url} height="auto" width="50px" />
                    </Grid>
                    <Grid item xs={9} md={4}>
                      <Box display={{ sm: "flex" }} alignItems="center">
                        <Box>
                          <Typography>{ele?.schemes?.[0]?.name} </Typography>

                        </Box>
                        <Box
                          className={classes.type}
                          style={{
                            color: orderTypeColor[ele?.orderType],
                            background: `${orderTypeColor[ele?.orderType]}80`,
                          }}
                        >
                          {orderTypeObj[ele?.schemes?.[0]?.type]}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <Box display="flex" justifyContent="start" px={1}>
                        {value === "active" && (
                          <Typography className={classes.greyText}>
                            {/* {`Next ${orderTypeObj[ele?.orderType]} on`}{" "} */}
                            {/* {moment(ele?.schemes?.[0]?.nextInstallmentDate).format("MMM DD YYYY")} */}
                            {/* {ele?.schemes?.[0]?.holdings?.as_on} */}
                            {moment(ele?.schemes?.[0]?.holdings?.as_on).format("DD MMM YYYY")}
                          </Typography>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={5} >
                      <Box display='flex' flexDirection='row' alignItems={'center'}>
                        <Grid item xs={12} sm={6} lg={4}>
                          <Typography className={classes.subText}>
                            Invested
                          </Typography>
                          <Typography className={classes.text}>
                            <span className={classes.rupees}>₹</span>{" "}

                            {ele?.schemes?.[0]?.invested_value?.amount.toFixed(2)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                          <Typography className={classes.subText}>
                            Current Value
                          </Typography>
                          <Typography className={classes.text}>
                            <span className={classes.rupees}>₹</span>{" "}

                            {ele?.schemes?.[0]?.market_value?.amount.toFixed(2)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                          <Typography className={classes.subText}>
                            Gain/Loss
                          </Typography>
                          <Typography className={classes.text}>
                            <span className={classes.rupees}>₹</span>{" "}

                            {((ele?.schemes?.[0]?.market_value?.amount) - (ele?.schemes?.[0]?.invested_value?.amount)).toFixed(2)} {" "}
                          </Typography>

                          <Typography className={classes.text}>
                            <span className={((((ele?.schemes?.[0]?.market_value.amount) - (ele?.schemes?.[0]?.invested_value.amount)) / (ele?.schemes?.[0]?.invested_value.amount)) * 100) < 0 ? classes.percentageNegative : classes.percentagePositive}> {
                              ((((ele?.schemes?.[0]?.market_value.amount) - (ele?.schemes?.[0]?.invested_value.amount)) / (ele?.schemes?.[0]?.invested_value.amount)) * 100).toFixed(2)
                            }%
                            </span>
                          </Typography>
                        </Grid>
                        <Box>
                          {/* <MenuComp schemeData={ele} /> */}
                          <ActiveFundData schemeData={ele} holdingsData={holdingsData} bankData={bankData} category={category} />
                        </Box>
                      </Box>
                      <Box display="flex" justifyContent="space-around">
                        {/* <Typography>
                      <span className={classes.rupees}>₹</span>{" "}
                      {ele?.schemes?.[0]?.market_value?.amount.toLocaleString("en-IN")}
                    </Typography> */}
                        {/* <Link
                      to={`/myportfolio/my-systematic-investment/${ele?.schemes?.[0]?.plan_id}`}
                      state={{ fundData: ele, status: value }}
                      style={{ color: "inherit" }}
                    >
                      <ChevronRightIcon style={{ cursor: "pointer" }} />
                    </Link> */}

                      </Box>
                    </Grid>
                  </Grid>
                </Box >
                <Redeem
                  show={redeem}
                  handleClose={() => setRedeem(false)}
                  schemeData={data}
                  bankData={bankData}
                  fundDetail={fundDetail}
                  fpFundData={fpFundData}
                  category={category}
                />
                <SWP
                  show={swp}
                  onClose={swp_close}
                  schemeData={data}
                  bankData={bankData}
                  fundDetail={fundDetail}
                  swpData={fpFundData}
                  category={category}
                />
                {/* <Dialog
                  open={open}
                  // onClose={closeDialog}
                  classes={{ paper: classes.dialogPaper }}
                >
                  <Box display="flex" className={classes.closeIcon}>
                    {" "}
                    <CloseIcon onClick={() => {
                      handleClose();
                      // reset({
                      //   accountHolderName: '',
                      //   accountNumber: '',
                      //   bankName: '',
                      //   ifscode: '',
                      // })
                    }}
                      style={{ cursor: "pointer" }} />
                  </Box>

                  <DialogTitle
                    classes={{ paper: classes.dialogPaper, root: classes.dialogTitle }}
                  >
                    <Typography className={classes.title}>Add New Bank</Typography>
                  </DialogTitle>
                  <DialogContent classes={{ root: classes.dialogContent }}>

                  </DialogContent >
                </Dialog> */}
              </>
            );
          })
          :
          (
            <>
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
                my={4}
              >
                <img
                  src="/images/undraw_book_reading_re_fu2c 1.svg"
                  height="175px"
                  width="175px"
                />
                <Typography className={classes.title}>
                  Funds are empty
                </Typography>
              </Box>
            </>
          )
      )
        :
        (
          <Box className={classes.root} mt={3} p={2}>
            <Grid container display="flex" alignItems="center">
              <Grid item xs={3} md={1}>
                <Skeleton variant="rounded" border width={50} height={50} />
              </Grid>
              <Grid item xs={9} md={4}>
                <Box display={{ sm: "flex" }} alignItems="center" >
                  <Box width={'100%'}>
                    <Skeleton variant="text" width={'80%'} />

                  </Box>
                  <Box
                    className={classes.type}
                    width={'100%'}
                  // style={{
                  //   color: orderTypeColor[ele?.orderType],
                  //   background: `${orderTypeColor[ele?.orderType]}80`,
                  // }}
                  >
                    <Skeleton variant="text" width={'80%'} />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} md={2}>
                <Box display="flex" justifyContent="start" px={1}>
                  <Skeleton variant="text" width={'80%'} />

                </Box>
              </Grid>
              <Grid item xs={12} md={5} >
                <Box display='flex' flexDirection='row' alignItems={'center'}>
                  <Grid item xs={12} sm={6} lg={4}>
                    <Skeleton variant="text" width={'80%'} />

                    <Skeleton variant="text" width={'80%'} />

                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <Skeleton variant="text" width={'80%'} />

                    <Skeleton variant="text" width={'80%'} />

                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}>
                    <Skeleton variant="text" width={'80%'} />

                    <Skeleton variant="text" width={'80%'} />

                    <Skeleton variant="text" width={'80%'} />

                  </Grid>
                  <Box>
                    {/* <MenuComp schemeData={ele} /> */}
                    <Skeleton variant="text" width={'40%'} />
                  </Box>
                </Box>
                <Box display="flex" justifyContent="space-around">
                </Box>
              </Grid>
            </Grid>
            {/* <Grid container display="flex" alignItems="center">
              <Grid item xs={3} md={1}>
                <Skeleton variant="rounded" border width={50} height={50} />
              </Grid>
              <Grid item xs={9} md={6}>
                <Box display={{ sm: "flex" }} alignItems="center" width={'100%'}>
                  <Box display="flex" alignItems="center" flexDirection={'column'} width={'100%'}>
                    <Skeleton variant="text" width={'80%'} />
                    <Skeleton variant="text" width={'40%'} />
                  </Box>
                  <Box
                    width={'100%'}
                 
                  >
                    <Skeleton variant="text" width={'40%'} />

                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} md={3}>
                <Box pl={2} width={'100%'}>
                  
                  <Skeleton variant="text" width={'70%'} />
                  <Skeleton variant="text" width={'40%'} />
                </Box>
              </Grid>
              <Grid item xs={6} md={2}>
                <Box display="flex" justifyContent="space-around" width={'100%'}>
                  <Typography>
                  
                    <Skeleton variant="text" width={'60%'} />
                  </Typography>
                  <Box width={'100%'}
                 
                    style={{ color: "inherit" }}
                  >
                    <Skeleton variant="text" width={'60%'} />

                  </Box>
               
                  <Skeleton variant="text" width={'60%'} />

                </Box>
              </Grid>
            </Grid> */}
          </Box>
        )
      }
    </div >
  );
};

export default SipList;
