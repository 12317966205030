import React, { useState } from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  CircularProgress,
  IconButton,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";

const ConfirmStopSip = ({ show, handleClose, handle_stop_SIP, type, msg }) => {
  const useStyles = makeStyles({
    root: {
      "& .MuiDialogContent-root": {
        fontSize: "0.8rem",
        lineHeight: "25px",
        padding: "20px 25px",
      },
      "& .MuiDialog-paperWidthSm": {
        minWidth: "25%",
        maxWidth: "300px",
        maxHeight: "100%",
      },
    },
    dialogTitle: {
      //display: "flex",
      justifyContent: "center",
      alignItems: " center",
      textAlign: "center",
      padding: "10px 10px 0px 25px",
      marginTop: "-10px",
      "& .MuiTypography-body1": {
        fontSize: "1.1rem",
        fontWeight: 600,
      },
    },
    closeIcon: {
      padding: "5px",
      justifyContent: "flex-end",
    },
    text: {
      fontSize: "0.8rem",
    },
    textCancel: {
      fontSize: "0.8rem",
      cursor: "pointer",
    },
    textConform: {
      fontSize: "0.8rem",
      color: "#0E76BC",
      cursor: "pointer",
    },
    errorMessage: {
      color: "red",
      fontSize: "0.75rem",
      marginTop: "5px",
    },
    menuItem: {
      whiteSpace: "normal",
      wordWrap: "break-word",
    },
  });
  const menuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    PaperProps: {
      style: {
        maxHeight: 200, // Adjust as needed
        width: 200, // Ensure menu width matches the select input
      },
    },
  };

  const classes = useStyles();
  const [selectedReason, setSelectedReason] = useState("");
  const [error, setError] = useState("");
  const reasons = {
    '': '--Select--',
    'amount_not_available': 'Do not have amount or unable to continue due to some bank level debit issues',
    'investment_returns_not_as_expected': 'Did not get returns as expected as the scheme is not performing',
    'exit_load_not_as_expected': 'Not satisfied with exit load (existing or revised)',
    'switch_to_other_scheme': 'Wish to switch to another scheme',
    'fund_manager_changed': 'Not satisfied with change in fund manager',
    'investment_goal_complete': 'Has achieved the investment goal earlier than planned',
    'mandate_not_ready': 'Mandate is not ready for use (either not in approved state or not created)',
    'invest_later': 'Chose to invest at a later point in time',
    'customer_support_not_satisfactory': 'Not satisfied with the service at distribution level',
    'amc_support_not_satisfactory': 'Not satisfied with the service at AMC level',
  };
  const handleReasonChange = (event) => {
    setSelectedReason(event.target.value);
    setError(""); // Clear error when user selects a reason
  };

  const onConfirmClick = () => {
    if (selectedReason || type === 'SWP') {
      handle_stop_SIP(selectedReason);
      setError("");
    } else {
      setError("Please select a reason to proceed.");
    }
  };


  return (
    <div>
      <Dialog className={classes.root} open={show} onClose={handleClose}>
        <Box display="flex" className={classes.closeIcon}>
          {" "}
        </Box>
        <DialogTitle classes={{ root: classes.dialogTitle }}>
          <Box display="flex" justifyContent="space-between">
            <Typography
              className={classes.title}
            >{`Confirm Stop ${type}`}</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography
            className={classes.text}
          >{`Are you sure you want to stop ${type}?`}</Typography>
          {type === 'SIP' &&
            <>
              <FormControl fullWidth margin="normal">
                <InputLabel id="reason-select-label">Select Reason</InputLabel>
                <Select
                  labelId="reason-select-label"
                  value={selectedReason}
                  onChange={handleReasonChange}
                  MenuProps={menuProps}
                >
                  {Object.entries(reasons).map(([value, label]) => (
                    <MenuItem key={value} value={value} className={classes.menuItem}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {error && <Typography className={classes.errorMessage}>{error}</Typography>}

              <small style={{ color: "red" }}>{msg}</small>
            </>
          }
          <Box
            display="flex"
            justifyContent="space-between"
            px={5}
            py={1}
            mt={3}
            style={{ borderTop: "1px solid #cccc" }}
          >
            <Typography className={classes.textCancel} onClick={handleClose}>
              Cancel
            </Typography>
            <Typography
              className={classes.textConform}
              // onClick={handle_stop_SIP}
              onClick={onConfirmClick}
            >
              Confirm
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ConfirmStopSip;
