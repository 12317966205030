import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  makeStyles,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import {
  CREATE_LUMSUM,
  INITIATE_PAYMENT,
  KYC_DATA,
  GET_LUMSUM_DETAIL,
  KYC_STATUS,
  GET_CAS_LIST,
  POST_CAS_OTP,
  GET_CAS_FUND_DATA,

} from "../../../utils/endpoints";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import httpInstance from "../../../utils/instance";
import ErrorMessage from "../../../components/Validation/ErrorMessage";
import { useSelector } from "react-redux";
import {
  GET_CONSENT_LUMPSUM_OTP,
  VERIFY_CONSENT_LUMPSUM_OTP,
  POST_TRANSACTION_BASKETS,
  POST_TRANSACTION_BASKETS_GENERATE_CONSENT,
  VERIFY_AND_VALIDATE_BASKET_CONSENT_LUMPSUMP_OTP,
} from "../../../utils/endpoints";
import { namespace } from "../../../environment";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useForm } from "react-hook-form";
import TextInputField from "../../../components/signup/Input/TextInputField";
import CustomToast from "../../../components/UI/CustomToast";
import OtpInput from "react-otp-input";
import InfiniteScroll from "react-infinite-scroll-component";
import SimpleCard from "../../Dashboard/Funds/card";
import FundSkeleton from "../../Dashboard/Funds/CommonLoader/FundSkeleton";
import CASFundSkeleton from "../../../components/CAS/CASFundSkeleton";
import CASFundCard from "../../../components/CAS/CASFundCard";
import CachedIcon from '@mui/icons-material/Cached';

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
//     </div>
//   );
// }

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }

// const LoaderComp = () => {
//   return (
//     <div
//       style={{
//         display: "flex",
//         justifyContent: "center",
//         marginTop: "1rem",
//         overflow: "hidden",
//       }}
//     >
//       <CircularProgress size="2rem" />
//     </div>
//   );
// };

const MyCASFunds = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const useStyles = makeStyles((theme) => ({
    subTabtext: {
      fontSize: "0.7rem",
      fontFamily: "inherit",
      fontWeight: 500,
    },
    textUnderline: {
      fontSize: "0.7rem",
      fontFamily: "inherit",
    },
    title: {
      fontSize: "1.2rem",
      fontWeight: 600,
    },
    titleRefresh: {
      fontSize: "1rem",
      fontWeight: 400,
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
      color: "#0E76BC",
      cursor: 'pointer',

    },
    text: {
      textAlign: "center",
      marginTop: "10px",
    },
    heading: {
      fontSize: "1.2rem",
      textAlign: "center",
      fontWeight: 500,
      marginBottom: "20px",
    },
    inputBox: {
      background: "#E6E6E6",
      padding: "0.7rem 0.95rem",
      borderRadius: "10px",
      border: "none",
      width: "90.5%",
      fontFamily: "inherit",
      paddingLeft: '20px',

    },
    inputBoxInput: {
      // background: "#E6E6E6",
      padding: "0.7rem 0.95rem",
      borderRadius: "0px",
      border: "none",
      borderBottom: "1px solid #000",
      width: "100%",
      maxWidth: "400px",
      fontFamily: "inherit",
      paddingLeft: '20px',

    },
    label: {
      fontSize: "0.7rem",
      fontWeight: 500,
    },
    primaryButton: {
      padding: "0.8rem 3rem",
      background: "#0E76BC",
      color: "#fff",
      fontSize: "0.8rem",
      fontWeight: 600,
      borderRadius: "45px",
      border: "none",
      cursor: "pointer",
      '&:disabled': {
        opacity: '0.7',
        cursor: 'not-allowed',
      },
    },
    root: {
      "& .MuiTab-root": {
        fontFamily: "inherit",
        fontSize: "1rem",
        color: "black",
        opacity: 1,
        textTransform: "none",
        background: "none",
        minWidth: "auto",
      },
      "& .MuiTab-textColorInheri": {
        opacity: 0.9,
      },
    },

    activeTab: {
      "& .MuiTab-wrapper": {
        borderBottom: "2px solid #0E76BC",
        color: "#0E76BC",
        fontSize: "1rem",
      },
    },
    backButton: {
      cursor: "pointer",
    }
  }));
  const classes = useStyles();






  const [error, setError] = useState("");
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");

  const { userStore } = useSelector((state) => state);
  const navigate = useNavigate();
  const { state } = useLocation()
  const { code } = useParams();

  const [kycStatus, setKycStatus] = useState();
  const [kycData, setKycData] = useState(null)

  const [allFundsList, setAllFundsList] = useState(null)
  const [hasMore, setHasMore] = useState(true);

  const get_kycData = async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await httpInstance(`${KYC_STATUS}`, httpConfig);
      setKycData(response?.data?.data)
      setKycStatus(response.data?.data?.fp_kyc_status);
    } catch {
      setKycStatus("empty");
    }
  };


  const getAllFundData = async () => {
    setHasMore(true)
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await httpInstance(`${GET_CAS_FUND_DATA}`, httpConfig);
      // setKycData(response?.data?.data)
      // setKycStatus(response.data?.data?.fp_kyc_status);
      setAllFundsList(response?.data?.data)
      // console.log(response)
      setHasMore(false)
    } catch {
      setHasMore(false)

      // setKycStatus("empty");
    }
  };



  const getPatchAllFundData = async () => {
    setLoading(true)
    const httpConfig = {
      method: "PATCH",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await httpInstance(`${GET_CAS_FUND_DATA}`, httpConfig);
      // setKycData(response?.data?.data)
      // setKycStatus(response.data?.data?.fp_kyc_status);
      // setAllFundsList(response?.data?.data)
      // console.log(response?.data?.data)
      if (response?.data?.data?.is_registered) {
        // navigate(`${namespace}/my-profile/cas/cas_funds`, {
        //   state: {
        //     consentId: response?.data?.data,
        //   },
        // });
        getAllFundData()
      } else {
        navigate(`${namespace}/my-profile/cas`, {
          // state: {
          //   consentId: response?.data?.data,
          // },
        });
      }
      setLoading(false)
    } catch {
      setLoading(false)

      // setKycStatus("empty");
    }
  };

  useEffect(() => {
    setAmount("");
    // get_kycData()
    // if (state?.consentId) {
    //   getAllFundData()
    // } else {
    //   navigate(`${namespace}/my-profile/cas`, {
    //     // state: {
    //     //   orderData: responseOTP.data?.data,
    //     //   type: "lumsum",
    //     //   schemeCode: fundData?.id,
    //     //   fundData: fundData,
    //     //   consentId: consentId,
    //     // },
    //   });
    // }
    getPatchAllFundData()

  }, []);

  const fetchData = () => {
    // get_allFunds();
  };

  const LoaderComp = () => {
    return (
      // <div
      //   style={{
      //     display: "flex",
      //     justifyContent: "center",
      //     marginTop: "2rem",
      //     overflow: "hidden",
      //   }}
      // >
      //   <CircularProgress size="2rem" />
      // </div>
      <>
        {allFundsList?.funds?.length <= 0 ?
          [1, 2, 3].map((key, index) => (
            <div key={key}>
              <CASFundSkeleton />
            </div>
          ))
          :
          [1].map((key, index) => (
            <div key={key}>
              <CASFundSkeleton />
            </div>
          ))
        }
      </>
    );
  };



  return (
    <div>
      {/* <Box display="flex" alignItems="start" px={{ xs: 1, md: 17 }} pt={2}>
        <Typography className={classes.subTabtext}>My Profile</Typography>

        <Box display="flex">
          <ChevronRightIcon style={{ height: "1rem" }} />
          <Typography className={classes.textUnderline}>CAS</Typography>
        </Box>
      </Box> */}
      <Box
        // sx={{
        //   width: "100%",
        //   height: {
        //     xs: "auto", // Default for smaller screens
        //     md: allFundsList?.funds?.length < 1 ? 'auto' : "40vh", // Applies when the screen size is `md` or larger
        //   },
        // }}
        style={{
          width: "100%",
          height: allFundsList?.funds?.length > 1 ? 'auto' : "40vh", // Applies when the screen size is `md` or larger
        }}
        pb={6}
      >
        <Box px={{ xs: 2, md: 15 }}>

          <Box mt={4} display={'flex'}
            width={'100%'}
          >
            <Box width={'100%'} display={'flex'} justifyContent={'start'} alignItems={'center'}>
              <Box onClick={() => {
                // if (allFundsList?.is_registered) {
                //   navigate(`${namespace}/my-profile/cas`, {
                //   });
                // } else {
                navigate(`${namespace}/dashboard/mutualfund`, {
                });

                // }

              }}
                display={'flex'} justifyContent={'start'} alignItems={'center'}
                className={classes.backButton}
                mr={2}>
                <img src="/images/backIcon.svg" height="20px" width="20px" />
              </Box>
              <Typography variant="h4" className={classes.title}>CAS Funds List </Typography>
            </Box>
            <Box className={`${classes.titleRefresh}`} width={'100%'} onClick={()=>{
              navigate(`${namespace}/my-profile/cas`, {
                state: {
                 refresh: 'refresh'
                },
              });
            }}>
              Refresh
              <CachedIcon style={{ fill: "#0E76BC", fontSize: "25px", marginLeft: '5px', }} />
            </Box>
          </Box>
          <div>
            <Box >
              {/* {allFundsList?.funds?.map((funds, index) => {
                return (
                  <Box key={index}>

                  </Box>
                )
              })} */}
              <div>
                <InfiniteScroll
                  style={{
                    overflow: "hidden"
                  }}
                  dataLength={allFundsList?.funds.length ? allFundsList?.funds.length : 0}
                  next={fetchData}
                  hasMore={hasMore}
                  loader={<LoaderComp />}
                  endMessage={
                    <Typography className={classes.text}>
                      No More Cas Funds to display
                    </Typography>
                  }
                >
                  {allFundsList?.funds.map((fund, index) => {
                    return (
                      <>
                        {/* <SimpleCard key={index} fund={fund} /> */}
                        <CASFundCard key={index} fund={fund} />
                      </>
                    )
                  })}
                </InfiniteScroll>
              </div>
            </Box>


          </div>

        </Box>
      </Box>

    </div>
  );
};

export default MyCASFunds;
