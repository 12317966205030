import React, { useEffect, useState } from "react";
import EmailOtp from "../../components/signup/complient/EmailOtp";
import Step2 from "../../components/signup/complient/Step2";
import PhoneOtp from "../../components/signup/complient/PhoneOtp";
import Step3 from "../../components/signup/complient/Step3";
import Step4 from "../../components/signup/complient/Step4";
import Step5 from "../../components/signup/complient/Step5";
import FatcaDetails from "../../components/signup/complient/FatcaDetails";
import Step6 from "../../components/signup/complient/Step6";
import Step7 from "../../components/signup/complient/Step7";
import Step8 from "../../components/signup/complient/Step8";
import Step9 from "../../components/signup/complient/Step9";
import Step10 from "../../components/signup/complient/Step10";
import Step11 from "../../components/signup/complient/Step11";
import Step12 from "../../components/signup/complient/Step12";
import Step13 from "../../components/signup/complient/Step13";
import Step14 from "../../components/signup/complient/Step14";
import UploadPan from "../../components/signup/nonComplient/UploadPan";
import ProofOfAddress from "../../components/signup/nonComplient/ProofOfAddress";
import Mpin from "../../components/signup/complient/Mpin";
import GenerateMpin from "../../components/signup/complient/GenerateMpin";

import PanVerification from "../../components/signup/nonComplient/PanVerification";
import TakePicture from "../../components/signup/nonComplient/TakePicture";
import TakeVideo from "../../components/signup/nonComplient/TakeVideo";
import AdharVerification from "../../components/signup/nonComplient/AadharVerification";

import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import {
  useNavigate,
  useLocation,
  useSearchParams,
  Link,
} from "react-router-dom";
import { namespace } from "../../environment";

function Complient() {
  const [step, setStep] = React.useState(1);
  const [descriptionData, setDescriptionData] = useState({});

  const [searchParams, setSearchParams] = useSearchParams();

  const steps = searchParams.get("stepcount");

  const stepCount = (step) => {
    setStep(step);
  };

  const setData = (data) => {
    setDescriptionData({ ...descriptionData, ...data });
  };

  const useStyles = makeStyles((theme) => ({
    // rotatedOne: {
    //   // width: '886.68px',
    //   width: '900px',
    //   height: '1167px',
    //   // bottom: '-100px',
    //   left: '-400px',
    //   top: '-115px',
    //   borderRadius: '66px',
    //   position: 'fixed',
    //   // angle: '154.95 deg',

    //   // barderRaduis: '106.11 deg',
    //   background: ' #0E76BC',

    //   // background: 'linear-gradient(to bottom, #1a237e, #283593)', /* Gradient colors */
    //   transform: 'rotate(20deg)',
    //   // zIndex: '-1',
    //   // angle: 106.11 deg
    //   zIndex: '-1',
    // },
    // rotatedTwo: {
    //   width: '900px',
    //   height: '1167px',
    //   left: '-350px',
    //   top: '100px',
    //   borderRadius: '66px',
    //   position: 'fixed',
    //   background: '#25AAE1',
    //   // background: 'linear-gradient(to bottom, #1a237e, #283593)', /* Gradient colors */
    //   transform: 'rotate(20deg)',
    //   zIndex: '-2',
    // },
    rotatedOne: {
      // content: ''; /* Create a pseudo-element */
      // position: absolute;
      // top: 0;
      // left: 0;
      // width: 100%;
      // height: 100%;
      // background: url('your-background-image.jpg'); /* Set your background image */

      // width: '886.68px',
      width: '1180px',
      height: '1180px',
      // bottom: '-100px',
      left: '-513px',
      top: '-442px',
      borderRadius: '100%',
      position: 'fixed',
      // angle: '154.95 deg',

      // barderRaduis: '106.11 deg',
      background: ' #25AAE1',
      transition: 'all 1s ease-out',
      // background: 'linear-gradient(to bottom, #1a237e, #283593)', /* Gradient colors */
      // transform: 'rotate(20deg)',
      // zIndex: '-1',
      // angle: 106.11 deg
      zIndex: '-1',
      [theme.breakpoints.up('lg')]: {
        left: '-513px',
        top: '-442px',
      },
      [theme.breakpoints.down('lg')]: {
        left: '-620px', // Adjust based on your requirement
        top: '-442px', // Adjust based on your requirement
      },
      [theme.breakpoints.down('md')]: {
        left: '-750px', // Adjust for smaller screens
        top: '-442px', // Adjust for smaller screens
      },
      [theme.breakpoints.down('sm')]: {
        left: '-850px', // Adjust for smaller screens
        top: '-442px', // Adjust for smaller screens
      },
    },
    rotatedTwo: {
      width: '1180px',
      height: '1180px',
      left: '-470px',
      top: '-442px',
      borderRadius: '50%',
      position: 'fixed',
      background: '#0E76BC',
      transition: 'all 1s ease-out',
      // background: 'linear-gradient(to bottom, #1a237e, #283593)', /* Gradient colors */
      // transform: 'rotate(20deg)',
      zIndex: '-2',

      [theme.breakpoints.up('lg')]: {
        left: '-470px',
        top: '-442px',
      },
      [theme.breakpoints.down('lg')]: {
        left: '-577px', // Adjust based on your requirement
        top: '-442px', // Adjust based on your requirement
      },
      [theme.breakpoints.down('md')]: {
        left: '-720px', // Adjust for smaller screens
        top: '-442px', // Adjust for smaller screens
      },
      [theme.breakpoints.down('sm')]: {
        left: '-820px', // Adjust for smaller screens
        top: '-442px', // Adjust for smaller screens
      },
    },
    section1: {
      // background: "#0E76BC",
      minHeight: "100vh",
      width: "30%",
      boxSizing: "border-box",
      position: "fixed",
      height: "100%",
    },
    sectionTwo: {
      height: "80vh",
      padding: "4rem 0",
      display: "flex",
      //alignItems: "center",
      //justifyContent: "center",
      flexDirection: "column",
      flex: 1,
      width: "70%",
      overflowY: "scroll",
      position: "sticky",
    },
    detailsBox: {
      margin: "0 auto",
    },
    headingOne: {
      // fontSize: "1.6rem",
      fontSize: '40px',
      color: "white",
      fontWeight: 400,
      padding: "0px 2rem",
    },
    headingTwo: {
      // fontSize: "1.3rem",
      fontSize: '32px',
      color: "#fff",
      fontWeight: 400,
      textAlign: "center",
    },
    headingThree: {
      fontSize: "0.9rem",
      color: "#fff",
      fontWeight: 400,
      textAlign: "center",
    },
    paragraph: {
      fontSize: "0.7rem",
      color: "white",
    },
    "@media (max-width: 480px)": {
      section1: {
        display: "none",
      },
      rotatedOne: {
        display: "none",
      },
      rotatedTwo: {
        display: "none",
      },
      sideSection: {
        display: "none",
      }
    },
  }));
  const classes = useStyles();

  const navigate = useNavigate();

  const handleMoveToDashBoard = () => {
    navigate({
      pathname: `${namespace}/dashboard/mutualfund`,
      // search: `?${createSearchParams({ stepcount: "2" })}`,
    });
  }

  useEffect(() => {
    if (steps > 18) {
      handleMoveToDashBoard()
    }
  }, [steps])

  return (
    <React.Fragment>
      <Box display="flex" flexDirection="row" flex={1} height="100%">
        <Box width={'35%'} className={classes.sideSection}>
          {/* <Box className={classes.rotatedOne}>
          </Box>
          <Box className={classes.rotatedTwo}>
          </Box> */}
          {/* <Box className={classes.section1}  position={'fixed'} py={4}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="space-between"
            justifyContent="space-around"
            height="100%"
            px={2}
          >
            <Box
                display="flex"
                alignItems="flex-start"
                justifyContent="center"
                flexDirection="column"
                className={classes.detailsBox}
              >
            <Box className={classes.headingOne} mb={6}>
              Investment made simple
            </Box>
            </Box>
            <img src="/images/Progress overview-pana 1.svg" height="230" width="100%" />
            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent="center"
              flexDirection="column"
              className={classes.detailsBox}
            >
              <Box className={classes.headingTwo} mt={3} textAlign="center">
                Invest in Mutual Funds
                //  <span style={{ color: "#6FDDB8" }}>
                //   Mutual Funds
                //   </span> 
              </Box>
              <Box className={classes.headingThree} mt={2}>
                MF Wealth is secure and private
              </Box>
              <Link
                to="/terms-conditions"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Box className={classes.paragraph} mt={1}>
                  Terms & Conditions *
                </Box>
              </Link>
            </Box>
          </Box>
        </Box> */}
          <Box className={classes.rotatedOne}>
          </Box>
          <Box className={classes.rotatedTwo}>
          </Box>
          <Box className={classes.section1} position={'fixed'} py={4}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="space-between"
              justifyContent="space-around"
              height="100%"
              px={2}
            >
              <Box
                display="flex"
                alignItems="flex-start"
                justifyContent="center"
                flexDirection="column"
                className={classes.detailsBox}
              >
                <Box className={classes.headingOne} pl={4} mb={3}>
                  Investment made simple
                  {/* <br />  */}
                </Box>
              </Box>
              <img src="/images/pngtree-financial-expert-concept-with-woman-specialist-with-laptop-sit-on-stack.svg" height="318" width="100%" />
              <Box
                display="flex"
                alignItems="flex-start"
                justifyContent="center"
                flexDirection="column"
                className={classes.detailsBox}
              >
                {/* <Box className={classes.headingTwo} mt={3} textAlign="center">
                  Invest in Mutual Funds
                  // {/* <span style={{ color: "#6FDDB8" }}>
                  //    Mutual Funds
                  //   </span> 
                </Box>
                <Box className={classes.headingThree} mt={2}>
                  MF Wealth is secure and private
                </Box>
                <Link
                  to="/terms-conditions"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Box className={classes.paragraph} mt={1}>
                    Terms & Conditions *
                  </Box>
                </Link> */}
              </Box>
            </Box>
          </Box>
        </Box>
        {/* </Box> */}

        <Box className={`${classes.sectionTwo}`} >
          <Box display={'flex'} justifyContent={'center'}>
            <Box height={80} width={160} overflow={'hidden'} display={'flex'} justifyContent={'center'}>
              <img src="/MF 5 6.svg" height={100} width={250} alt="logo" onClick={handleMoveToDashBoard} style={{ cursor: "pointer" }} />
            </Box>
          </Box>
          {/* {steps ===null ? <Step1 step={stepCount} setData={setDescriptionData}/> : ""}
          {steps === "1" ? <EmailOtp step={stepCount} descriptionData={descriptionData}/> : ""} */}
          {/* {steps === "2" ? (
            <Step2 step={stepCount} setData={setDescriptionData} />
          ) : (
            ""
          )}
          {steps === "mpin" ? <Mpin setData={setDescriptionData} /> : ""}
          {steps === "mpin_generate" ? <GenerateMpin /> : ""}
          {steps === "3" ? (
            <EmailOtp step={stepCount} descriptionData={descriptionData} />
          ) : (
            ""
          )} */}
          {steps === "1" ? (
            <Step3 step={stepCount} setData={setDescriptionData} />
          ) : (
            ""
          )}
          {/* ---------------complient flow------------------------------------------ */}

          {steps === "2" ? (
            <Step4
              step={stepCount}
              descriptionData={descriptionData}
              setData={setDescriptionData}
            />
          ) : (
            ""
          )}
          {steps === "3" ? <Step5 step={stepCount} /> : ""}
          {steps === "4" ? <FatcaDetails /> : ""}
          {steps === "5" ? <Step9 step={stepCount} /> : ""}
          {steps === "6" ? <Step7 /> : ""}
          {steps === "7" ? <Step8 step={stepCount} /> : ""}
          {steps === "8" ? <Step6 /> : ""}
          {/* {steps === "12" ? <Step11 setData={setData} /> : ""}
          {steps === "12" ? <Step9 /> : ""}
          {steps === "13" ? <Step10 /> : ""}
          {steps === "14" ? <Step8 /> : ""}
          {steps === "15" ? <Step12 /> : ""}
          {steps === "16" ? <Step14 /> : ""} */}


          {/* //---------------------------non complient--------------------------------------------// */}
          {/* Pan details  */}
          {steps === "9" ? (
            <Step4
              title="Enter Pan Card Details"
              descriptionData={descriptionData}
              setData={setDescriptionData}
            />
          ) : (
            ""
          )}

          {/* Personal Detials */}
          {steps === "10" ?
            <Step5 step={stepCount} />
            : ""}
          {/* Occupation detials */}
          {steps === "11" ? <FatcaDetails /> : ""}
          {/* Bank details */}
          {steps === "12" ? <Step9 step={stepCount} /> : ""}
          {/* Nominee detials  */}
          {steps === "13" ? <Step6 /> : ""}
          {/* take a selfi part  */}
          {steps === "14" ? <TakePicture /> : ""}
          {/* Upload Pan details */}
          {steps === "15" ? <UploadPan /> : ""}
          {/* Address details */}
          {steps === "16" ? <Step7 /> : ""}
          {/* Upload Sign */}
          {steps === "17" ? <Step8 step={stepCount} /> : ""}
          {/* Adhar data upload */}
          {steps === "18" ? <AdharVerification /> : ""}

          {/* {steps === "20" ? <ProofOfAddress /> : ""} */}
          {/* {steps === "26" ? <TakeVideo /> : ""} */}

          {/* 
          
         
           
         
          {step === 7 ? <Step7 step={stepCount} /> : ""}
          {step === 8 ? <Step8 step={stepCount} /> : ""}
          {step === 9 ? <Step9 step={stepCount} /> : ""}
          {step === 10 ? <Step10 step={stepCount} /> : ""}
          
          {step === 12 ? <Step12 step={stepCount} /> : ""}
          {step === 13 ? <Step13 step={stepCount} /> : ""}
          {step === 14 ? <Step14 step={stepCount} /> : ""} */}
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default Complient;

{
  /* <Route path="/complient" element={<Complient />} />
        <Route path="/non-complient" element={<NonComplient />} />
        <Route path="/funds" element={<Funds />} /> */
}
