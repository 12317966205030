import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import {
  Box,
  makeStyles,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import ErrorMessage from "../../../../components/Validation/ErrorMessage";
import { RESEND_OTP, POST_TRANSACTION_BASKETS_GENERATE_CONSENT } from "../../../../utils/endpoints";
import httpInstance from "../../../../utils/instance";
import CustomToast from "../../../../components/UI/CustomToast";

const Index = ({
  verify_otp,
  id,
  transactionTypeId,
  otpError,
  handle_otp_error,
  loadVerify,
}) => {
  const useStyles = makeStyles({
    text: {
      fontSize: "0.9rem",
    },
    primaryButton: {
      padding: "0.8rem 3rem",
      background: "#0E76BC",
      color: "#fff",
      fontSize: "0.8rem",
      fontWeight: 600,
      borderRadius: "45px",
      cursor: "pointer",
      fontFamily: "inherit",
      border: "1px solid #0E76BC",
      "&:hover": {
        backgroundColor: "#fff",
        color: "#0E76BC",
      },
      '&:disabled': {
        opacity: '0.5',
      },
    },

    otpInput: {
      border: "none",
      borderRadius: "8px",
      width: "40px !important",
      height: "40px",
      fontSize: "13px",
      color: "#000",
      fontWeight: "400",
      caretColor: "blue",
      background: "#E6E6E6",
    },
    resendOtp: {
      fontSize: "0.7rem",
      color: "#0E76BC",
      textAlign: "center",
      cursor: "pointer",
    },
    resendOtptext: {
      fontSize: "0.7rem",
      color: "#292929",
      textAlign: "center",
      // cursor: "pointer",
    },
  });

  const classes = useStyles();

  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (code) => {
    setOtp(code)
  };

  const resend_OTP = async () => {
    const formData = {
      userId: localStorage.getItem("user_id"),
      id: id,
      transactionTypeId: 3,
    };
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        // userId: localStorage.getItem("user_id"),
        transaction_basket_id: id,
        // transactionTypeId: transactionTypeId,
      },
    };
    try {
      const response = await httpInstance(`${POST_TRANSACTION_BASKETS_GENERATE_CONSENT}`, httpConfig);
      if (response?.status == 200) {
        CustomToast("OTP sent successfully", "SUCCESS")
      }

    } catch (error) {
      handle_otp_error(error.response.data?.message);
      CustomToast(error?.response?.data?.error ?? error.response.data?.message ?? "Somthing went wrong", "FAILED")
    }
  };

  //-----------------submit otp--------------------------//
  const submit_otp = (e) => {
    e.preventDefault()
    verify_otp(otp)
  }


  const inputRefs = Array.from({ length: 6 }, () => React.createRef());

  const handleKeyUp = (index, value) => {
    if (value && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }
  };

  return (
    <div>
      <Box display="flex" justifyContent="center" alignItems={'center'} flexDirection={'column'} my={0}>
        <img src="/images/otpScreen.svg" alt="otp" height={100} width={100} />

        <Typography className={classes.text}>
          Confirm your order with OTP
          {/* Please enter the OTP sent to your registered Mobile number */}
        </Typography>
      </Box>
      <form onSubmit={submit_otp}>
        <Box mt={3} px={5} display="flex" justifyContent="center">

          {/* <OtpInput
          value={otp}
          onChange={handleChange}
          numInputs={4}
          separator={<span style={{ width: "8px" }}></span>}
          isInputNum={true}
          shouldAutoFocus={true}
          inputStyle={classes.otpInput}
          renderInput={(props) => <input {...props} />}

          focusStyle={{
            border: "1px solid #CFD3DB",
            outline: "none",
          }}
          
         // inputStyle={{
         //   border: "none",
         //   borderRadius: "8px",
         //   width: "40px",
         //   height: "40px",
         //   fontSize: "13px",
         //   color: "#000",
         //   fontWeight: "400",
         //   caretColor: "blue",
         //   background: "#E6E6E6",
         // }}
         // focusStyle={{
         //   border: "1px solid #CFD3DB",
         //   outline: "none",
         // }}
        //  inputStyle={{
        //    border: "none",
        //    borderRadius: "0px",
        //    borderBottom: "1px solid #000",
        //    width: "40px",
        //    height: "40px",
        //    fontSize: "13px",
        //    color: "#000",
        //    fontWeight: "400",
        //    caretColor: "blue",
        //  }}
        //  focusStyle={{
        //    border: "none",
        //    borderRadius: "0px",
        //    borderBottom: "1px solid #000", // Ensure this matches inputStyle
        //    background: "#E6E6E6",
        //    outline: "none",
        //  }}
        /> */}
          <OtpInput
            value={otp}
            onChange={handleChange}
            numInputs={4}
            renderSeparator={<span style={{ width: "8px" }}></span>}
            isInputNum={true}
            shouldAutoFocus={true}
            renderInput={(props) => <input {...props} />}
            // renderInput={(props, index) => (
            //   <input
            //     {...props}
            //     ref={inputRefs[index]}
            //     onKeyUp={(e) => handleKeyUp(index, e.target.value)}
            //   />
            // )}
            // inputStyle={{
            //   border: "0px 0px 0px 1px",
            //   // borderRadius: "8px", 
            //   borderRadius: "0px",
            //   borderBottom: "1px solid #000 !important",
            //   width: "40px",
            //   height: "40px",
            //   fontSize: "13px",
            //   color: "#000",
            //   fontWeight: "400",
            //   caretColor: "blue",
            //   background: "#E6E6E6",
            // }}
            // focusStyle={{
            //   // border: "1px solid #CFD3DB",
            //   borderBottom: "1px solid #000",
            //   background: "#E6E6E6",
            //   outline: "none",
            // }}
            inputStyle={{
              border: "none",
              borderRadius: "0px",
              borderBottom: "1px solid #000",
              width: "40px",
              height: "40px",
              fontSize: "13px",
              color: "#000",
              fontWeight: "400",
              caretColor: "blue",
            }}
            focusStyle={{
              border: "none",
              borderRadius: "0px",
              borderBottom: "1px solid #000", // Ensure this matches inputStyle
              background: "#E6E6E6",
              outline: "none",
            }}
          />
        </Box>
        <Box px={11} mt={1} textAlign={'center'}>

          <Typography className={classes.resendOtptext}>
            Didn't receive OTP? {" "} <span className={classes.resendOtp} onClick={resend_OTP}>Resend OTP</span>
          </Typography>
        </Box>

        <Box display="flex" justifyContent="center">
          {otpError && <ErrorMessage message={otpError} />}
        </Box>
        <Box display="flex" justifyContent="center" mt={5} mb={10}>
          <button
            className={classes.primaryButton}
            disabled={loadVerify}
          //onClick={() => verify_otp(otp)}
          >
            {loadVerify ? (
              <CircularProgress size="1rem" color="inherit" />
            ) : (
              "Verify"
            )}
          </button>
        </Box>
      </form>
    </div>
  );
};

export default Index;
